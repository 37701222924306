import { ActionIcon, AppShell, Box, Button, createStyles, Divider, Group, Header, Input, Kbd, Modal, Navbar, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconArrowsMaximize, IconInfoCircle, IconSearch } from '@tabler/icons';

import { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ApprovalImageToolbarModes, ApprovalLayoutTypes, FlagRating, SessionTypes } from 'utils/constants';

import BottomTray from './components/BottomTray';
import MainPanel from './components/MainPanel';
import Toolbar from './components/Toolbar';
import {
    setApprovalSettings,
    setSelectedItem,
    setSelectedItems,
    setComparisonItems,
    setSelectedCompareFrame,
    setCaptures,
    fetchCaptures,
    setFilter,
    clearNotifications,
    setCapturesSuccess,
    getCapturesSuccess,
    refreshState,
    fetchLatestCaptures,
} from './approvalSlice';

import { useFullscreen } from '@mantine/hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Shortcuts from './components/Shortcuts';
import InfoPanel from '../../core/components/capture/InfoPanel';

import { clear } from 'idb-keyval';
import { showNotification } from '@mantine/notifications';
import { selectSortedCaptures } from './approvalSelectors';
import FilterPanel from './components/FilterPanel';
import { setLoading } from 'app/appSlice';
import { clearLocalStorage } from 'app/store';
import { setUser } from 'modules/Auth/authSlice';

import { CaptureFilterModel } from 'core/models/CaptureFilterModel';
import { CaptureEventType } from 'core/components/capture/CaptureThumb';
import { logger } from 'utils/logger';
import { handleZipDownload, isPureObject } from 'utils/helper';
import moment from 'moment';
import { closeAllModals, ModalsProvider, openModal } from '@mantine/modals';
import TagTalent from '../../core/components/capture/TagTalent';

var _ = require('lodash');

const useStyles = createStyles((theme, _params, getRef) => ({
    shell: {
        '.info-panel': {
            width: 0,
            overflow: 'hidden',
            transition: 'width .2s',
            visibility: 'none',
        },
        '&.info-open': {
            '.info-panel': {
                maxWidth: 400,
                width: 400,
            },
        },

        '.filter-panel': {
            left: -350,
            width: 350,
            overflow: 'hidden',
            transition: 'left .2s ease-in-out',
            visibility: 'none',
        },
        '&.filter-open': {
            '.filter-panel': {
                left: 0,
                width: 350,
            },
            '.mantine-AppShell-main': {
                paddingLeft: 350,
            },
        },
    },
    container: {
        flexGrow: 1,
        ".approvaltoolbar[data-layouttype='compare']": {
            "&[data-selectedframe='1']": {
                '.openseacontrols': {
                    opacity: 0.5,
                    display: 'none',
                },
                '.openseacontrols:last-of-type': {
                    opacity: 1,
                    display: 'inline',
                },
            },
            "&[data-selectedframe='0']": {
                '.openseacontrols': {
                    opacity: 1,
                },
                '.openseacontrols:last-of-type': {
                    opacity: 0.5,
                    display: 'none',
                },
            },
        },
    },
    keywordSearch: {
        input: {
            paddingRight: 67,
        },
        span: {
            fontSize: 10,
        },
        '.mantine-Input-rightSection': {
            width: 67,
        },
    },
    captureMenuItem: {
        marginLeft: theme.spacing.xl * 1.2,
        flexGrow: '1 !important',
        borderRadius: theme.radius.xs,
        paddingTop: theme.spacing.xs / 3,
        paddingBottom: theme.spacing.xs / 3,
        paddingLeft: theme.spacing.xs,
        // width:'100%',
        '&:hover': {
            backgroundColor: theme.colors.dark[4],
        },
        '&.selected': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
            color: theme.colors.brand[4],
        },
    },
}));

const ApprovalModule = ({
    user,
    filteredSelections,
    captures,
    settings,
    selectedItem,
    selectedItems,
    comparisonItems,
    aggData,
    action,
    success,
    error,
    approvalFilter,
    setApprovalSettings,
    setSelectedItems,
    setSelectedItem,
    setComparisonItems,
    setSelectedCompareFrame,
    selectedCompareFrame,
    setFilter,
    setUser,
    isLoading,
    appLoading,
    setLoading,
    previousRoute,
    searchCursor,
}) => {
    const { toggle } = useFullscreen();
    const navigate = useNavigate();
    const [imageControlSettings, setImageControlSettings] = useState({ zoomDirection: 1 });
    const [infoShown, setInfoShown] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    // hash lookup for image locations.
    const imageLocHash = useRef({});

    const { classes } = useStyles();
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const filterKeys = ['client', 'catalogue', 'sessionId', 'star', 'sak', 'tags', 'machineId'];

    const selectAll = () => {
        setSelectedItems([...captures]);
    };

    // clear the cache
    useEffect(() => {
        clearLocalStorage();
        // need to clear the captures and selected captures.
        dispatch(refreshState(null, user));

        return () => {
            dispatch(setFilter(new CaptureFilterModel()));
        };
    }, []);

    // SUCCESS AND ERROR HANDLERS
    useEffect(() => {
        if (error) {
            showNotification({
                title: 'Whoops something went wrong.',
                message: error.message,
                position: 'top-center',
                color: 'red',
            });
            setLoading(false);
            setIsSearching(false);
            dispatch(clearNotifications());
        }

        logger.log(success, setCapturesSuccess.toString());
        if (success && success.type === getCapturesSuccess.toString()) {
            setLoading(false);
            setIsSearching(false);
        }
    }, [error, success, dispatch]);

    /**
     * Debounce fetch so it isn't called multiple times.
     */
    // const fetchAdminData = useCallback(
    //     _.debounce(function (criteria) {
    //         dispatch(fetchAggData());
    //     }, 100),
    //     [],
    // );

    useEffect(() => {
        const findItemIndex = (item) => {
            const idx = captures.findIndex((val) => val.id === item.id);
            return idx;
        };
        const keyHandler = (e) => {
            logger.log(e, ' KEY');
            if (e.keyCode === 65 && e.metaKey) {
                selectAll();
                return false;
            }
            if (selectedItems) {
                switch (e.code) {
                    case 'Numpad5':
                    case 'Numpad4':
                    case 'Numpad3':
                    case 'Numpad2':
                    case 'Numpad1':
                    case 'Digit5':
                    case 'Digit4':
                    case 'Digit3':
                    case 'Digit2':
                    case 'Digit1': {
                        // set the rating.
                        if (selectedItems && selectedItems.length) {
                            const data = { data: e.key };
                            onCaptureChange(CaptureEventType.RATING_CHANGE, data, selectedItems);
                        }

                        break;
                    }
                    case 'BracketLeft':
                    case 'BracketRight': {
                        // set the rating.
                        if (selectedItems && selectedItems.length) {
                            const data = { data: e.code === 'BracketLeft' ? FlagRating.FLAG_SELECT : FlagRating.FLAG_KILL };
                            onCaptureChange(CaptureEventType.STATUS_CHANGE, data, selectedItems);
                        }

                        break;
                    }
                    case 'ArrowRight': {
                        // set the next index.
                        // get the current index.
                        let idx = 0;
                        let setCompare = false;

                        if (comparisonItems && settings.layoutType === ApprovalLayoutTypes.COMPARE) {
                            let item = comparisonItems[selectedCompareFrame];
                            idx = findItemIndex(item);
                            setCompare = true;
                        } else {
                            idx = findItemIndex(selectedItems[0]);
                        }

                        if (idx > -1) {
                            if (idx + 1 < captures.length) {
                                const newItem = captures[idx + 1];
                                setSelectedItems([newItem]);
                                setSelectedItem({ ...newItem });

                                if (setCompare) {
                                    // set the selected frame with the selected capture.
                                    let compareFrames = [...comparisonItems];
                                    compareFrames[selectedCompareFrame] = newItem;
                                    setComparisonItems(compareFrames);
                                }
                            }
                        }
                        break;
                    }
                    case 'ArrowLeft': {
                        let idx = 0;
                        let setCompare = false;

                        if (comparisonItems && settings.layoutType === ApprovalLayoutTypes.COMPARE) {
                            let item = comparisonItems[selectedCompareFrame];
                            idx = findItemIndex(item);
                            setCompare = true;
                        } else {
                            idx = findItemIndex(selectedItems[0]);
                        }

                        if (idx > -1) {
                            if (idx - 1 > -1) {
                                const newItem = captures[idx - 1];
                                setSelectedItems([newItem]);
                                setSelectedItem({ ...newItem });

                                if (setCompare) {
                                    // set the selected frame with the selected capture.
                                    let compareFrames = [...comparisonItems];
                                    compareFrames[selectedCompareFrame] = newItem;
                                    setComparisonItems(compareFrames);
                                }
                            }
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        };

        window.addEventListener('keydown', keyHandler);

        // if (!aggData) {
        //     fetchAdminData();
        // }

        return () => {
            window.removeEventListener('keydown', keyHandler);
            // clear the image path cache
            imageLocHash.current = {};

            logger.log('CLEARING');
            clear();
        };
    }, [captures, selectedItems, comparisonItems, selectedCompareFrame]);

    const form = useForm({
        initialValues: {
            client: '',
            sessions: '',
            sessionId: `-1|undefined`,
            unpinned: false,
            star: '-1',
            sak: '-1',
            tags: [],
            machineId: '',
        },

        validate: {
            // client: (value) => (value.length ? null : 'Please select a client'),
        },
    });

    const settingsChange = (type, data) => {
        logger.log(type, data);
        switch (type) {
            case ApprovalEventTypes.SETTINGS_CHANGE: {
                setApprovalSettings({ ...data });
                break;
            }
            default: {
                break;
            }
        }

        let urlParms = Object.fromEntries([...searchParams]);
        // const urlParms = new URLSearchParams(window.location.search);
        logger.log(urlParms, ' PARMS CHANGE');
        if (data.layoutType !== urlParms.layoutType) {
            // set the settings as part of the url.
            searchParams.set('layoutType', data.layoutType);

            urlParms.layoutType = data.layoutType;

            setSearchParams(urlParms);
        }
    };

    const openTagTalent = () => {
        openModal({
            title: 'Edit Guide',
            size: 'xl',
            children: (
                <>
                    <TagTalent
                        talents={[]}
                        // guide={guide}
                        // session={session}
                        // user={user}
                        // task={selectedTask}
                        onCancel={() => {
                            closeAllModals();
                        }}
                        // onMenuClick={onMenuClick}
                    />
                </>
            ),
            onClose: closeAllModals,
        });
    }
    const onImageEvent = (type, data, originalEvent) => {
        logger.log(type, data);
        var cItems, items;

        switch (type) {
            case CaptureEventType.IMAGE_CLICK: {
                logger.log(comparisonItems);
                //if(settings.layoutType !== ApprovalLayoutTypes.COMPARE){

                // if we already have one item

                // if control or shift is not held we set it to a single item
                if (originalEvent.metaKey) {
                    // if command held down we add the item to the array
                    // if it's not yet selected.
                    const obj = selectedItems.find((val) => val.id === data.id);
                    // if the object is already selected then we unselect it
                    if (obj) {
                        //items  = [...selectedItems];

                        items = _.remove([...selectedItems], (val) => {
                            return val.id !== data.id;
                        });

                        cItems = _.remove([...comparisonItems], (val) => {
                            return val.id !== data.id;
                        });
                    } else {
                        items = [...selectedItems, data];

                        cItems = comparisonItems;

                        // if(comparisonItems && comparisonItems.length){
                        //   cItems = [...comparisonItems];
                        //   cItems[selectedCompareFrame] = data;
                        // }else{
                        //   cItems = [data];
                        // }
                    }

                    // capture items remain the same;
                } else if (originalEvent.shiftKey) {
                    // if shift key we push all items from the last selected item
                    // get the index of the last element selected.
                    let lastObj = selectedItems ? selectedItems[selectedItems.length - 1] : null;
                    if (lastObj) {
                        let lastIdx = _.findIndex(captures, lastObj);
                        let currIdx = _.findIndex(captures, data);
                        let newItems = [...captures.slice(lastIdx + 1, currIdx + 1)];
                        items = _.concat(selectedItems, newItems);
                    }
                } else {
                    items = [data];

                    // setSelectedItems([data]);

                    // if no comparison items is selected
                    if (comparisonItems && comparisonItems.length) {
                        cItems = [...comparisonItems];
                        if (comparisonItems.length === 1) {
                            cItems[1] = data;
                        } else {
                            cItems[selectedCompareFrame] = data;
                        }
                    } else {
                        cItems = [data];
                    }

                    setSelectedItem({ ...data });

                    // searchParams.set('captureId',data.captureId);
                    // setSearchParams(searchParams);
                }

                setSelectedItems(items);
                setComparisonItems(cItems);

                // if(comparisonItems && comparisonItems.length){
                //   var items = [...comparisonItems];

                //   items[selectedCompareFrame] = data;
                //   setComparisonItems(items)
                // }else{
                //   setComparisonItems([data])
                // }

                break;
            }
            case CaptureEventType.IMAGE_DBL_CLICK: {
                setSelectedItem(data);
                setApprovalSettings({ ...settings, layoutType: ApprovalLayoutTypes.SINGLE });
                break;
            }
            case ApprovalEventTypes.COMPARE_FRAME_CLICK: {
                setSelectedCompareFrame(data.index);
                break;
            }
            default: {
                break;
            }
        }
    };



    /**
     * Handler for image click
     */
    const onImageControlClick = useCallback(
        (type) => {
            logger.log('IMAGEW ZOOM CLICK');
            switch (type) {
                case ApprovalImageToolbarModes.ZOOM_RESET:
                case ApprovalImageToolbarModes.ZOOM_OUT:
                case ApprovalImageToolbarModes.ZOOM_IN: {
                    let props = { ...imageControlSettings, zoomDirection: type };
                    setImageControlSettings(props);
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [imageControlSettings],
    );

    /**
     * Handler for Capture Change
     * @param {*} type
     * @param {*} data
     * @param {*} items
     */
    const onCaptureChange = (type, data, items) => {
        logger.log(type, data, items);
        switch (type) {
            case CaptureEventType.RATING_CHANGE: {
                const tmpItems = items.map((val) => {
                    return { star: data.data, actionPart: val.actionPart, actionSort: val.actionSort };
                });
                dispatch(setCaptures(tmpItems, user));
                break;
            }
            case CaptureEventType.STATUS_CHANGE: {
                const tmpItems = items.map((val) => {
                    return { sak: data.data, actionPart: val.actionPart, actionSort: val.actionSort };
                });
                dispatch(setCaptures(tmpItems, user));
                break;
            }
            case CaptureEventType.DOWNLOAD: {
                const tmpDate = moment(new Date()).format('DDMMYYYY_HHMMSS');
                const filename = `Batch_${tmpDate}`;
                const extension = data.type && data.type === 'eipURL' ? 'eip' : 'png';
                handleZipDownload(items, 'originalFilename', filename, data.type, extension);
                break;
            }

            case CaptureEventType.TAG_TALENT: {
                // openTagTalent()
                break;
            }

            default: {
                break;
            }


        }
    };

    /**
     * Search Submit handler
     * @param {*} values
     */
    const onSearchSubmit = (values, resetCursor = true) => {
        var criteria = { ...approvalFilter };

        if (!form.isValid()) {
            return;
        }

        logger.log('here we go');
        // criteria.unpinned = false;
        criteria.sessionId = form.getInputProps('sessionId') ? form.getInputProps('sessionId').value.split('|')[1] : null;
        if (criteria.sessionId && criteria.sessionId === 'undefined') {
            criteria.sessionId = null;
        }
        criteria.client = form.getInputProps('client') ? form.getInputProps('client').value : null;
        criteria.star = form.getInputProps('star') ? form.getInputProps('star').value : -1;
        criteria.sak = form.getInputProps('sak') ? form.getInputProps('sak').value : -1;
        criteria.tags = form.getInputProps('tags') ? form.getInputProps('tags').value : null;
        // const startDate = moment().year(2000);

        if (criteria.sessionId === SessionTypes.UNKOWN) {
            criteria.machineId = criteria.sessionId;
            criteria.sessionId = null;
        }

        criteria.machineId = form.getInputProps('machineId') ? form.getInputProps('machineId').value : null;

        // if we have any of the other criteria set and the cataglogue is latest then we clear the catalogue.
        if ((criteria.sessionId && criteria.sessionId.length) || criteria.star > -1 || criteria.sak > -1 || criteria.tags || criteria.machineId) {
            if (criteria.catalogue.label === 'Latest') criteria.catalogue = null;
        }

        buildSearchParams(criteria);

        setLoading(true);
        setIsSearching(true);

        const cursor = resetCursor ? '' : searchCursor;
        debouncedCaptureFetch(criteria, cursor);

        // navigate({
        //   search: `?${str}`,
        // });
        //dispatch(fetchCaptures(criteria,user))
    };

    // const buildSearchString = (criteria) => {
    //     const keys = Object.keys(criteria);
    //     var str = '';
    //     var delim = '';
    //     keys.forEach((key, idx) => {
    //         var val = criteria[key];

    //         if (typeof val === 'object') {
    //             if (key === 'catalogue' && val.label.length) {
    //                 val = JSON.stringify(val);
    //             }
    //         } else if (val) {
    //             val = val.toString();
    //         }

    //         // lowercase the first.
    //         logger.log(key, val);
    //         if (val && val.length) {
    //             str += `${delim}${key}=${val}`;
    //             delim = '&';
    //         }
    //     });
    //     return str;
    // };

    const buildSearchParams = (criteria) => {
        filterKeys.forEach((key) => {
            if (key !== 'catalogue') {
                if (criteria[key] && criteria[key] !== 'undefined') searchParams.set(key, criteria[key]);
                else searchParams.delete(key);
            }
        });

        setSearchParams(searchParams);
    };

    /**
     * Debounced fetch for captures
     */
    // const debouncedCaptureFetch = useCallback(
    //   (criteria) => {
    //       return debounce(dispatch(fetchCaptures(criteria,user)), 3000)
    //   }
    // , []);

    const debouncedCaptureFetch = useCallback(
        _.debounce(function (criteria, cursor) {
            dispatch(fetchCaptures(criteria, user, 1, cursor));

        }, 100),
        [],
    );

    const debouncedLatestCaptureFetch = useCallback(
        _.debounce(function (criteria) {
            dispatch(fetchLatestCaptures());
        }, 100),
        [],
    );

    /**
     * Effect to look after the search params change, this drives the filtering of the captures and lets
     * The route handle the filtering
     */
    useEffect(() => {
        // let urlParms = Object.fromEntries([...searchParams]);
        let urlParms = Object.fromEntries(new URLSearchParams(location.search));

        if (!urlParms.catalogue) {
            //urlParms.catalogue = {label:'',value:{since:null,until:null}};
        } else {
            urlParms.catalogue = JSON.parse(urlParms.catalogue);
        }
        // let client = '';

        // if(urlParms.sessionId){
        //     let splitArr = urlParms.sessionId.split('-');
        //     if(splitArr.length)client = splitArr[0];
        // }
        // pass in the client
        const criteria = new CaptureFilterModel({ ...urlParms });

        // has the filter changed
        var filterChanged = hasFilterChanged(criteria);

        // var filterChanged = true;
        if (filterChanged) {
            logger.log(searchParams);

            // set the form values.
            form.setFieldValue('sessionId', `session|${urlParms.sessionId}`);
            form.setFieldValue('star', urlParms.star ? urlParms.star : '-1');
            form.setFieldValue('sak', urlParms.sak ? urlParms.sak : '-1');
            form.setFieldValue('machineId', urlParms.machineId);
            form.setFieldValue('tags', urlParms.tags);
            // form.setFieldValue('client', urlParms.client ? urlParms.client : '');
            // change to always grab this client
            if(aggData && aggData.clients && aggData.clients.length){
                form.setFieldValue('client',aggData.clients[0].code);
            }

            // Update the filter
            setFilter(criteria);

            // we check that there are other default values else the default is only layoutType.
            if (Object.keys(urlParms).length > 1) {
                setLoading(true);
                setIsSearching(true);

                //dispatch(fetchCaptures(criteria,user));
                debouncedCaptureFetch(criteria, '');
            } else if (Object.keys(urlParms).length === 1) {
                setLoading(true);
                setIsSearching(true);
                // we grab the latest captures
                debouncedLatestCaptureFetch();
            }
        }

        // check for layout change
        if (urlParms.layoutType && urlParms.layoutType !== settings.layoutType) {
            setApprovalSettings({ ...settings, layoutType: urlParms.layoutType });
        }
    }, [location]);

    /**
     * Determine whether the passed in filter has changed from the previous.
     * @param {filterCriteria} obj
     * @returns
     */
    const hasFilterChanged = useCallback(
        (obj) => {
            var hasChanged = false;
            for (let i = 0; i < filterKeys.length; i++) {
                const key = filterKeys[i];
                if (obj[key] && approvalFilter[key] && approvalFilter[key].toString() !== obj[key].toString()) {
                    hasChanged = true;
                    break;
                }
            }

            if (obj.catalogue) {
                const objCat = isPureObject(obj.catalogue) ? obj.catalogue : JSON.parse(obj.catalogue);
                const appCat = isPureObject(approvalFilter.catalogue) ? approvalFilter.catalogue : JSON.parse(approvalFilter.catalogue);
                if (objCat.label !== appCat.label) {
                    hasChanged = true;
                }
            }

            return hasChanged;
        },
        [approvalFilter],
    );

    const onCollectionClick = (val) => {
        logger.log(val);

        // let filter = {...approvalFilter};
        // filter.catalogue = {...val};

        // logger.log(filter)

        //   val = JSON.stringify(val);

        // let params = [...searchParams];

        // we clear the other filters if we have selected latest
        if (val && val.label === 'Latest') {
            onClearFilterClick();
            navigate({
                search: `?catalogue=${encodeURI(JSON.stringify(val))}`,
            });
            // searchParams.set('catalogue',JSON.stringify(val));
        } else {
            searchParams.set('catalogue', JSON.stringify(val));
            setSearchParams(searchParams);
        }

        // const str = buildSearchString(filter);

        // navigate({
        //   search: `?${str}`,
        // });
    };

    const onClearFilterClick = () => {
        logger.log('clearing filter');
        // let vals = {};
        // filter.catalogue = null;
        // vals.star = -1;
        // vals.sak = -1;
        // vals.tags = [];
        // vals.sessionId = '';

        // form.setValues(vals)
        form.reset();
        setSearchParams([]);
    };

    /**
     * Handler for the close click
     */
    const onCloseClick = () => {
        const path = previousRoute ? previousRoute : '/warehouse';
        navigate(path);
    };

    /**
     * Capture Change
     */
    useEffect(() => {
        // if they've selected an image.
        const captureId = searchParams.get('captureId');
        if (captures && captures.length) {
            if ((captureId && selectedItem && captureId !== selectedItem.id) || (captureId && !selectedItem)) {
                const capture = captures.find((val) => val.id === captureId);

                if (capture) {
                    // TODO work out why this is taking so long setting state causes bottom tray to flash
                    logger.log(' SETTING SELECTED CAPTURE');
                    setSelectedItem({ ...capture });
                    setSelectedItems([capture]);
                    searchParams.delete('captureId');
                    // setSearchParams(searchParams);
                }
            }
        }
    }, [captures]);

    const loadNextPage = () => {
        onSearchSubmit(null, false);
    };
    return (
        <AppShell
            padding='md'
            className={[settings.infoOpen ? `${classes.shell} info-open` : classes.shell, settings.filterOpen ? `${classes.shell} filter-open` : '']}
            navbar={
                <Navbar width={{ base: 350 }} height={'100%'} className='filter-panel'>
                    <form onSubmit={form.onSubmit((values) => logger.log(values))}>
                        <Box m={'sm'}>
                            <Input
                                className={classes.keywordSearch}
                                icon={<IconSearch size={16} />}
                                placeholder='Keyword'
                                rightSection={
                                    <div className='unhightable'>
                                        <Text size={'xs'}>
                                            {' '}
                                            <Kbd>
                                                <span>Press</span> ↵
                                            </Kbd>
                                        </Text>
                                    </div>
                                }
                            />
                        </Box>

                        <Divider />

                        <FilterPanel
                            approvalFilter={approvalFilter}
                            aggData={aggData}
                            isSearching={isSearching}
                            form={form}
                            onSubmit={onSearchSubmit}
                            onCollectionClick={onCollectionClick}
                            onClearClick={() => onClearFilterClick()}
                        />
                    </form>
                </Navbar>
            }
            header={
                <Header height={60} p='xs' sx={{ display: 'flex', width: '100%' }}>
                    {/* <Center> */}
                    <Group position='apart' sx={{ width: '100%' }}>
                        {/* <Crumbline /> */}
                        <UnstyledButton
                            onClick={() => {
                                onCloseClick();
                            }}>
                            <Group>
                                <IconArrowLeft />
                                <Title order={5} className='unhightable'>
                                    Capture Management
                                </Title>
                            </Group>
                        </UnstyledButton>
                        <Group>
                            <ActionIcon onClick={() => setInfoShown(true)} variant='default'>
                                <IconInfoCircle size={18} />
                            </ActionIcon>
                            <ActionIcon onClick={toggle} variant='default'>
                                <IconArrowsMaximize size={18} />
                            </ActionIcon>
                            <Button
                                size='xs'
                                variant='outline'
                                onClick={() => {
                                    onCloseClick();
                                }}>
                                Close
                            </Button>
                        </Group>
                    </Group>
                    {/* </Center> */}
                </Header>
            }
            styles={(theme) => ({
                main: {
                    transition: 'padding .1s ease-in-out',
                    minHeight: 'calc(100vh - 60px)',
                    marginLeft: `0 !important`,
                    marginRight: '0 !important',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                },
            })}>
            <ModalsProvider>
                <div style={{ height: 'calc(100%)', width: '100%', flexWrap: 'nowrap', display: 'flex', flexGrow: 0, gap: 0 }}>
                    <Stack className={classes.container} sx={{ height: 'calc(100%)' }}>
                        <Toolbar
                            settingsChange={(type, data) => settingsChange(type, data)}
                            settings={settings}
                            selectedItems={selectedItems}
                            onImageControlClick={onImageControlClick}
                            selectedCompareFrame={selectedCompareFrame}
                            onCaptureChange={onCaptureChange}
                        />

                        <MainPanel
                            settings={settings}
                            onImageEvent={onImageEvent}
                            captures={captures}
                            comparisonItems={comparisonItems}
                            selectedItem={selectedItem}
                            selectedItems={selectedItems}
                            selectedCompareFrame={selectedCompareFrame}
                            imageControlSettings={imageControlSettings}
                            onSettingsChange={settingsChange}
                            onCaptureChange={onCaptureChange}
                            imageLocHash={imageLocHash}
                            isSearching={isSearching}
                            searchCursor={searchCursor}
                            loadNextPage={loadNextPage}
                            showLoading={(captures.length === 0 && isLoading) || (appLoading && captures.length === 0)}
                        />

                        {settings.layoutType !== ApprovalLayoutTypes.GRID ? (
                            <BottomTray
                                captures={captures}
                                onImageEvent={onImageEvent}
                                selectedItems={selectedItems}
                                comparisonItems={comparisonItems}
                                selectedCompareFrame={selectedCompareFrame}
                                imageLocHash={imageLocHash}
                                onCaptureChange={onCaptureChange}
                                showComapirsonSelection={settings.layoutType === ApprovalLayoutTypes.COMPARE}
                                isSearching={isSearching}
                                searchCursor={searchCursor}
                                loadNextPage={loadNextPage}
                                showLoading={captures.length === 0 && isLoading}
                            />
                        ) : null}
                    </Stack>

                    <InfoPanel selectedItem={selectedItem} />
                </div>
            </ModalsProvider>
            <Modal opened={infoShown} centered onClose={() => setInfoShown(false)} title='Capture Management Shortcuts'>
                <Shortcuts />
            </Modal>
            
        </AppShell>
    );
};

////////////////////////////////
//
// SELECTORS
// memoize the selection for sorted captures
//
////////////////////////////////

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
        captures2: state.approvalReducer.present.captures,
        selectedItem: state.approvalReducer.present.selectedItem,
        selectedItems: state.approvalReducer.present.selectedItems,
        comparisonItems: state.approvalReducer.present.comparisonItems,
        selectedCompareFrame: state.approvalReducer.present.selectedCompareFrame,
        approvalFilter: state.approvalReducer.present.approvalFilter,
        settings: state.approvalReducer.present.settings,
        success: state.approvalReducer.present.success,
        error: state.approvalReducer.present.error,
        isLoading: state.approvalReducer.present.loading,
        action: state.approvalReducer.present.action,
        captures: selectSortedCaptures(state.approvalReducer.present),
        aggData: state.appReducer.aggData,
        appLoading: state.appReducer.loading,
        previousRoute: state.appReducer.previousRoute,
        searchCursor: state.approvalReducer.present.searchCursor,
    };
};

const mapDispatch = {
    setApprovalSettings,
    setSelectedItems,
    setSelectedItem,
    setComparisonItems,
    setSelectedCompareFrame,
    setFilter,
    setLoading,
    setUser,
};

export default connect(mapStateToProps, mapDispatch)(ApprovalModule);

export const ApprovalEventTypes = {
    SETTINGS_CHANGE: 'settingsChange',
    COMPARE_FRAME_CLICK: 'compareFrameClick',
};

export const SortFields = {
    JOB_ID: 'sessionId',
    MACHINE_ID: 'machineId',
    FILE_NAME: 'originalFilename',
    RATING: 'star',
    DATE: 'actionSort',
};

export const CaptureSortFields = [
    { label: 'Capture Date', value: SortFields.DATE },
    { label: 'Session', value: SortFields.JOB_ID },
    { label: 'Machine', value: SortFields.MACHINE_ID },
    { label: 'File Name', value: SortFields.FILE_NAME },
    { label: 'Rating', value: SortFields.RATING },
];

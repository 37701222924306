import { v4 as uuidv4 } from 'uuid';

export class GuideModel {

    constructor (obj){
        this.id = "";
        this.name = '';
        this.sort = '';
        this.descr = '';
        this.url = '';
        this.guideCategories = [];
        this.tags = [];

        if(obj){
            Object.assign(this, obj);
        }
        
    }
    
    
    // return {
    //     guideId:guideId?guideId:'',
    //     name:name?name:'',
    //     description:description?description:'',
    //     filePath:filePath?filePath:''
    // }
    
}
export const GuideEventType = {
    GUIDE_EDIT: 'guideEdit',
    GUIDE_ADD: 'guideAdd',
    GUIDE_VIEW: 'guideView',
    GUIDE_DOWNLOAD: 'guideDownload',    
}


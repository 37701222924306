import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Group,
    Input,
    NativeSelect,
    ScrollArea,
    Skeleton,
    Stack,
    Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconSearch, IconX } from '@tabler/icons';
import { CheckboxDropdown } from 'core/components/index';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { userRoles } from 'utils/constants';
import { getUserAvatarColour, getUserInitials, selectItems, useEffectOnce } from 'utils/helper';
import { logger } from 'utils/logger';

const AddTeamMembers = ({ user, sessionForm, session, success, guideSuccess, error, onTeamAction, onCancel, aggData }) => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [isTest,setTest] = useState({status:true});
    const [selectedItems, setSelectedItems] = useState([...session.team]);
    const [keyword, setKeyword] = useState('');
    const [roles, setRoles] = useState([...userRoles]);
    const [selectedDept,setSelectedDept] = useState('All Departments');

    const [members, setMembers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            role: '',
        },

        validate: {
            //   name: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    useEffectOnce(() => {
        const productionTeam = aggData.productionTeam.map((val) => {
            return { ...val, id: val.email, teamLabel: 'Production', teamValue: 'prod' };
        });
        const postTeam = aggData.postProductionTeam.map((val) => {
            return { ...val, id: val.email, teamLabel: 'Post Production', teamValue: 'post' };
        });
        var tmpMembers = postTeam.concat(productionTeam);
        tmpMembers.sort((a, b) => a.name.localeCompare(b.name));

        
        setMembers([...tmpMembers]);
        setFilteredData([...tmpMembers]);
    }, []);

    const selectAllMenu = (
        <div>
            <Button variant='default' onClick={(e) => onSelectAll(e)}>
                Deselect All
            </Button>
        </div>
    );

    const onSubmit = (vals) => {
        onTeamAction(vals);
    };

    const isAssigned = (item) => {
        return selectedItems.find((val) => val.id === item.id);
    };

    // logger.log(aggData)

    const onTeamClickAction = (type, guide) => {
        logger.log(type);

        // if(onGuideAction){
        //     const guides = [...session.guides];
        //     guides.push(guide);

        // dispatch(selectSession({...session,guides}))
        // this won't work with mantine closure
        // onGuideAction({type,data:guide})
        // }else{
        // switch (type){
        //     case SessionActions.ASSIGN_STYLEGUIDE:{
        //         dispatch(assignGuideToSession(guide,session,user))
        //         break;
        //     }
        //     case SessionActions.REMOVE_STYLEGUIDE:{
        //         dispatch(removeGuideFromSession(guide,session,user))
        //         break;
        //     }
        // }
        // }
    };

    useEffect(() => {
        logger.log(keyword);
        const filterItems = () => {
            const data = members.filter((member) => {
                var match = true;

                if (keyword && keyword.length && match) {
                    match = member.role.indexOf(keyword.toLowerCase()) > -1 ? true : false;
                }

                if (keyword && keyword.length && !match) {
                    match = member.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ? true : false;
                }

                if (selectedDept !== 'All Departments') {
                    match = member.teamLabel === selectedDept ? true : false;
                }

                return match;
            });
            setFilteredData(data);
        };

        filterItems();
    }, [keyword, members,selectedDept]);

    const loaderRows = [1, 2, 3].map((val, key) => (
        <Box key={key} className='list-renderer-item' pt={'md'}>
            <Group position='apart'>
                <Group>
                    <Skeleton height={26} radius='xl' width={26} />
                    <div>
                        <Skeleton height={16} radius='xl' width={200} mb={'xs'} />
                        <Skeleton height={10} radius='xl' width={100} />
                    </div>
                </Group>
                <Skeleton height={32} radius='sm' width={85} />
            </Group>
        </Box>
    ));

    const onSelectAll = () => {
        // if there are items selected remove them
        if (selectedItems.length) {
            setSelectedItems([]);
            // dispatch(selectSamples([]));
        } else {
            setSelectedItems([...filteredData]);
            // dispatch(selectSamples([...sampleData]));
        }
    };

    const onItemSelected = (e, item, selected) => {
        var items = [...selectedItems];

        // call general helper to select items
        selectItems(e, item, selected, members, items);

        setSelectedItems(items);
        //setSelectedItems(items);
        //dispatch(selectSeasons(items));
        // selectSeasons(items);
    };

    const onSubmitClick = () => {
        var team = [];
        selectedItems.forEach((item) => {
            const person = members.find((member) => item.id === member.id);
            if (person) team.push(person);
        });
        onTeamAction(team);
    };

    return (
        <Stack>
            <Text>Select team members to assign to the session.</Text>
            <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} py='xs'>
                <CheckboxDropdown selectedItems={selectedItems} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />
                <Input
                    sx={{ flexGrow: 1 }}
                    icon={<IconSearch />}
                    // value={guideFilter.keyword}
                    // onChange={(e)=> setGuideFilter({...guideFilter,keyword:e.currentTarget.value})}
                    rightSection={<IconX size={14} onClick={() => setKeyword('')} />}
                    value={keyword}
                    onChange={(e) => setKeyword(e.currentTarget.value)}
                    placeholder={`Search across ${filteredData.length} Users`}
                    // onClick={(e) => setGuideFilter({...guideFilter,keyword:''})}
                />

                <NativeSelect data={['All Departments', 'Production', 'Post Production']} value={selectedDept} onChange={(event) => setSelectedDept(event.currentTarget.value)} />
            </Group>

            <ScrollArea style={{ height: 450 }}>
                <Stack className='list-stack'>
                    {/* {isLoading.toString()} {isTest.status.toString()}{guideSuccess?guideSuccess.type:'null'} */}
                    {isLoading ? (
                        <>{loaderRows}</>
                    ) : (
                        <>
                            {filteredData.map((item, idx) => {
                                const assigned = isAssigned(item);
                                const userInitials = getUserInitials(item.name.split(' ')[0], item.name.split(' ')[1]);
                                const themeCol = getUserAvatarColour(userInitials);
                                return (
                                    <Box key={idx} className='list-renderer-item' pt={'md'}>
                                        <Group grow position='apart' sx={{ minWidth: '100px' }}>
                                            <Group sx={{ flexShrink: 0 }}>
                                                {/* <Checkbox checked={selectedItems.find(val => val.id === row.original.id)?true:false} onChange={(e) => onItemSelected(row.original,e.currentTarget.checked)}></Checkbox> */}
                                                <Group>
                                                    {/* {assigned?
                                                <ThemeIcon radius={'xl'}><IconCheck size={14} /></ThemeIcon>
                                                :<IconPalette />} */}
                                                    <Checkbox
                                                        checked={selectedItems.find((val) => val.id === item.id) ? true : false}
                                                        onChange={(e) => onItemSelected(e, item, e.currentTarget.checked)}
                                                    />
                                                </Group>

                                                <Group spacing='sm' sx={{ width: 300 }}>
                                                    <Avatar variant='filled' title={`${item.name} - ${item.role}`} color={themeCol} size={30} radius={40}>
                                                        {item.name.charAt(0)}
                                                    </Avatar>
                                                    <div>
                                                        <Text size='sm' weight={500}>
                                                            {item.name}
                                                        </Text>
                                                        <Text size='xs' color='dimmed'>
                                                            {item.email}
                                                        </Text>
                                                    </div>
                                                </Group>

                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Text size='sm' lineClamp={2} sx={{ maxWidth: '190px' }}>
                                                        {item.role}
                                                    </Text>
                                                </Box>

                                                <Box sx={{ flexShrink: 1, width: 150 }}>
                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '250px' }}>
                                                        {item.teamLabel}
                                                    </Text>
                                                </Box>
                                            </Group>
                                            {/* <Button sx={{width:100}} size='sm' variant={assigned?'default':'outline'} onClick={() => onTeamClickAction(assigned?SessionActions.REMOVE_STYLEGUIDE:SessionActions.ASSIGN_STYLEGUIDE,item)}>{assigned?'Unassign':'Assign'}</Button> */}
                                        </Group>
                                    </Box>
                                );
                            })}
                        </>
                    )}

                    {/* <GuideList data={guides} selectedItems={selectedItems} onItemSelected={onItemSelected} onSelectAll={onSelectAll} /> */}
                </Stack>
            </ScrollArea>

            <Group position='right' mt='md'>
                <Button loading={isSaving} onClick={() => onSubmitClick()}>
                    Assign to Team
                </Button>
            </Group>
        </Stack>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
        guides: state.guideReducer.guides,
        // session: state.sessionReducer.selectedSession,
        aggData: state.appReducer.aggData,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(AddTeamMembers);

import { Badge, Group, Text } from "@mantine/core";
import { forwardRef } from "react";

/**
 * Style guide Select Renderer
 */
const StyleGuideItem = forwardRef(({ image, label, descr, shots, guide, guideCategories, guideId, ...others }, ref) => {
    return (
        <div ref={ref} {...others}>
            <Group noWrap position='apart' px={0}>
                <div>
                    <Text size='sm'>{label}</Text>
                    <Text size='xs' opacity={0.65}>
                        {descr}
                    </Text>
                </div>
                {shots ? <Badge>{shots}</Badge> : null}
            </Group>
        </div>
    );
});

export default StyleGuideItem;
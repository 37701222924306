export class SeasonModel{

    constructor (obj){
        this.id = null;
        this.name = '';
        this.description = '';
        this.deadline = null;
        this.seasonPart = '';
        this.seasonSort = '';
        this.source = '';
        this.tags = [];
        this.styleCount = 0;
        // this.team = null;
        this.ts = '';

        if(obj){
            Object.assign(this, obj);
        }

    }
    
}


export const SeasonEventType = {
    CREATE_SESSION:'seasonCreateSession',
    SEASON_EDIT: 'seasonEdit',
    SEASON_ADD: 'seasonAdd',
    SEASON_VIEW: 'seasonView',
    SEASON_DELETE: 'seasonDelete',
    SEASON_DOWNLOAD: 'seasonDownload',   
    SEASON_ASSIGN_CAPTURES: 'seasonAssignCaptures',
    SEASON_REMOVE_STYLES: 'seasonRemoveStyles',    
    SEASON_ASSIGN_STYLES_TO_SESSION: 'seasonAssignStylesToSession',    
}

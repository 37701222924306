import { Group, Loader, Select } from '@mantine/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { getSessions } from 'services/sessionService';
import { SessionTypes } from 'utils/constants';
import { headerDateSort, useEffectOnce } from 'utils/helper';
import { logger } from "utils/logger";

const SessionSelect = ({onChange,value,required=false,showDefaultValues=true}) => {
    const [selectValue, setSelectValue] = useState(value);
    const [searchValue, setSearchValue] = useState('');
    const [data,setData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        setSelectValue(value);
        logger.log(" VALUE CHANGE", value)
    },[value]);
    
    const onKeywordChange = useCallback((val) =>  {        
        // lets fetch the sessions
        // if(!data.length){
            
        //     getData();
        // }
            
    },[data]);

    useEffectOnce(() => {
        setIsLoading(true);
        getData();
    },[])

    const debouncedChangeHandler = useMemo(
        (val) => {
            setSearchValue(val);
            return debounce(onKeywordChange, 3000)
        }
      , [onKeywordChange]);


    const getData = async() => {
        
        const sessions = await getSessions({},'');
        var newData = [];
        if(showDefaultValues){
            newData = [{
                label:'All Sessions', value:''
                },
                {
                    label:'Unassigned', value:`00|${SessionTypes.UNKOWN}`
                }
            ]
        }
        
        const tmpSessions = sessions.sort((a,b) => headerDateSort({values:{created:b.created}},{values:{created:a.created}} ,'created')).map((val) => {
            return {label:val.name?`${val.code} - ${val.name}`:'',value:val.id};
        });


        setData(newData.concat(tmpSessions));
        setIsLoading(false);
    }

    const onSelectChange = (val) => {
        logger.log(val)
        if(val !== selectValue){
            setSelectValue(val);
            onChange(val);
        }        
    }
  return (
    <Group sx={{position:'relative'}}>
    <Select value={selectValue} 
        searchable 
        placeholder='All Sessions'
        className='unhightable'
        onSearchChange={debouncedChangeHandler}
        onChange={onSelectChange}
        searchValue={searchValue}
        nothingFound="No Sessions Found"
        withAsterisk={required?true:false}
        required={required}
        sx={{width:'100%'}}
        data={data} />
        {isLoading?
        <Loader sx={{position:'absolute', left:10}} variant='dots' size={'xs'} />
        :null}
        
    </Group>
  )
  
}

export default SessionSelect;
import { Badge, createStyles, Divider, Group, Stack } from '@mantine/core';
// import { get, set } from 'idb-keyval';
import { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'utils/helper';
import { CaptureEventType } from './CaptureThumb';
// import { ApprovalEventTypes } from '../ApprovalModule';
import SingleLayout from 'modules/Approval/components/SingleLayout';
import { ApprovalEventTypes } from 'modules/Approval/ApprovalModule';
import Toolbar from 'modules/Approval/components/Toolbar';
import { ApprovalLayoutTypes } from 'utils/constants';
import { logger } from 'utils/logger';

const useStyles = createStyles((theme, _params, getRef) => ({
    container: {
        // marginLeft:`-${theme.spacing.lg}px`,
        // marginRight:`-${theme.spacing.lg}px`
        border: `1px solid ${theme.colors.dark[5]}`,
        paddingTop: theme.spacing.sm,
        paddingLeft: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    imageClass: {
        ref: getRef('imageClass'),
        border: '3px solid transparent',
        padding: 2,
        maxWidth: '100%',
        objectFit: 'contain',

        // height:'100%',
        // width:'100%',
    },
    imageBadge: {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: theme.radius.md,
    },
    imageThumb: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        border: `1px solid transparent`,
        img: {
            userSelect: 'none',
        },
        '&.selected': {
            [`.${getRef('imageClass')}`]: {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[3] : theme.colors.gray[3],
            },
        },
        // '&:hover':{
        //     background:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.gray[3],
        // },
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },

    '.approvaltoolbar': {
        root: {
            background: 'red',
        },
    },
    layoutWrapper: {
        flexGrow: '1 !important',
        height: '100%',
        maxWidth: '50%',
        position: 'relative',
        '&.selected': {
            '.layoutImage': {
                border: `1px solid ${theme.colors.brand[2]}`,
            },
        },
    },
    imageBadge: {
        position: 'absolute',
        top: theme.spacing.xs,
        left: theme.spacing.xs,
    },
}));

const CaptureCompare = ({ images, onImageEvent, height = '80vh', selected, badgeLabel, onCaptureChange, showSettings = true, onCloseClick }) => {
    logger.log(images);

    const isAliveRef = useRef(true);
    const comparisonItems = images;

    const onClick = useCallback(
        (event) => {
            onImageEvent(CaptureEventType.IMAGE_CLICK, images, event);
        },
        [images, onImageEvent],
    );

    const onDoubleClick = (event) => {
        onImageEvent(CaptureEventType.IMAGE_DBL_CLICK, images, event);
    };

    const { classes } = useStyles();
    const imgClass = selected ? ' selected' : '';

    useEffectOnce(() => {
        return () => {
            isAliveRef.current = false;
        };
    });

    const handleClick = (e, data) => {
        setSelectedFrame(data.index);
    };

    const [settings, setSettings] = useState({
        layoutType: ApprovalLayoutTypes.COMPARE,
        infoOpen: false,
        thumbnailSize: 150,
        isCommenting: false,
        sortBy: 'actionSort',
        sortDirection: 'asc',
    });
    const [selectedFrame, setSelectedFrame] = useState(0);

    const onImageControlClick = () => {};

    const selectedCompareFrame = () => {};

    const settingsChange = () => {};

    return (
        <div style={{ height: height, width: '100%', flexWrap: 'nowrap', display: 'flex', flexGrow: 0, gap: 0, marginTop: -15 }} className='unhighlightable'>
            <Stack spacing={0} className={classes.container} sx={{ height: 'calc(100%)', width: '100%' }} pr={'sm'}>
                <Group sx={{ width: '100%', marginTop: 0 }} position='center'>
                    {/* <Group>
                <Title className='mantine-Modal-title'>Capture Comparison</Title>
                </Group> */}
                    <div style={{ position: 'absolute', top: 0 }}>
                        <Toolbar
                            settingsChange={(type, data) => settingsChange(type, data)}
                            settings={settings}
                            selectedItems={images}
                            onImageControlClick={onImageControlClick}
                            selectedCompareFrame={selectedFrame}
                            onCaptureChange={onCaptureChange}
                            showSettingControls={false}
                            showLayoutControls={false}
                            showCaptureToolbar={false}
                        />
                    </div>
                    {/* <Group position='right'>
                <CloseButton onClick={onCloseClick} />
                </Group> */}
                </Group>
                <Group sx={{ width: '100%', flexGrow: 1, height: '100%', gap: 0 }} >
                    <div
                        className={`${classes.layoutWrapper} ${selectedFrame === 0 ? 'selected' : ''}`}
                        onClick={() => handleClick(ApprovalEventTypes.COMPARE_FRAME_CLICK, { image: comparisonItems[0], index: 0 })}>
                        <SingleLayout
                            badgeLabel='Production'
                            showSettings={false}
                            image={images[0]}
                            instanceId='openSeaDragon1'
                            settings={settings}
                            onCaptureChange={(type, data, items) => onCaptureChange(type, data, [comparisonItems[0]])}
                        />
                        <Badge size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                            Production
                        </Badge>
                    </div>
                    <Divider orientation='vertical' sx={{ marginTop: -12, marginBottom: -12, flexGrow: 0 }} mx='md' />
                    <div
                        className={`${classes.layoutWrapper} ${selectedFrame === 1 ? 'selected' : ''}`}
                        onClick={() => handleClick(ApprovalEventTypes.COMPARE_FRAME_CLICK, { image: comparisonItems[1], index: 1 })}>
                        <SingleLayout
                            showSettings={false}
                            image={images[1]}
                            instanceId='openSeaDragon2'
                            settings={settings}
                            onCaptureChange={(type, data, items) => onCaptureChange(type, data, [comparisonItems[1]])}
                        />
                        <Badge size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                            Post Production
                        </Badge>
                    </div>
                </Group>
            </Stack>
        </div>
    );
};

export default CaptureCompare;

export class TeamMemberModel{

    constructor (obj){

        this.name = "";
        this.email = "";
        this.role = "";
       
        if (obj) {
            Object.assign(this, obj);
        }
    }
    
}

export const TeamEventType = {
    TEAM_ADD_MEMBER: 'teamMemberAddEdit',
    TEAM_ADD_MEMBERS: 'teamAddMembers',
    TEAM_DELETE_MEMBER: 'teamMemberDeleteEdit',
}



import { Badge, createStyles, Divider, Group } from '@mantine/core';

import React from 'react';
import { ApprovalEventTypes } from '../ApprovalModule';

import SingleLayout from './SingleLayout';

const useStyles = createStyles((theme, _params, getRef) => ({
    '.approvaltoolbar':{
        root: {
            background:'red'
        }
    },   
    layoutWrapper:{
      flexGrow:'1 !important',
      height:'100%',
      maxWidth:'50%',
      position:'relative',
      '&.selected':{
        '.layoutImage':{
            border:`1px solid ${theme.colors.brand[2]}`
        }        
      }
    },
    imageBadge:{
        position:'absolute',
        top:theme.spacing.xs,
        left:theme.spacing.xs,
    }   

  }));

const CompareLayout = ({onImageEvent, comparisonItems, selectedItems, selectedItem, selectedFrame, settings, onCaptureChange}) => {

    const { classes } = useStyles();

    const img1 = comparisonItems && comparisonItems[0]?comparisonItems[0]:null;
    const img2 = comparisonItems && comparisonItems[1]?comparisonItems[1]:null;

    return (
    
        <Group sx={{width:'100%', flexGrow:1, height:'100%', gap:0}} px={'md'}>
            <div className={`${classes.layoutWrapper} ${selectedFrame === 0?'selected':''}`} onClick={() => onImageEvent(ApprovalEventTypes.COMPARE_FRAME_CLICK,{image:comparisonItems[0],index:0})}>
                <SingleLayout image={img1} instanceId='openSeaDragon1' settings={settings} onCaptureChange={(type,data,items) => onCaptureChange(type,data,[comparisonItems[0]])} />
                <Badge size='xs' variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>1</Badge>
            </div>
            <Divider orientation="vertical" sx={{marginTop:-16, marginBottom:-16, flexGrow:0}} mx='md' />
            <div className={`${classes.layoutWrapper} ${selectedFrame === 1?'selected':''}`} onClick={() => onImageEvent(ApprovalEventTypes.COMPARE_FRAME_CLICK,{image:comparisonItems[1],index:1})}>
                <SingleLayout image={img2} instanceId='openSeaDragon2' settings={settings} onCaptureChange={(type,data,items) => onCaptureChange(type,data,[comparisonItems[1]])} />
                <Badge size='xs' variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>2</Badge>
            </div>
        </Group>

    )
}

export default CompareLayout;


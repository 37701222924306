import React from 'react'

export function StarIcon({
  size = 18,
  strokeColor = 'none',
  storkeWidth = 0,
  className = 'star-svg',
  fill,
  style = { display: 'inline' }
}) {

    
  return (
    <svg fill={fill} width={size} height={size} viewBox='0 0 24 24' className={className} style={{ ...style }}>
      <path
        fill={fill}
        stroke={strokeColor}
        strokeMiterlimit='10'
        strokeWidth={storkeWidth}
        d='M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z'
      />
    </svg>
  )
}



import { Group } from '@mantine/core';
import logo2 from '../../assets/images/altitude-brand2.png'
import logo2Light from '../../assets/images/altitude-brand2-light.png'

export function Logo({ colorScheme } ) {
  return (
    
    <Group sx={{position:'relative'}}>
    <img
      src={colorScheme === 'dark'?logo2:logo2Light} height="20"
        alt="Altitude logo"
      />

    </Group>
  );
}
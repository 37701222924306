import { v4 as uuidv4 } from 'uuid';

export class AnnotationModel{
    // captureId,name,description,url,star,sak,clientTags,selected
    constructor (obj){

        this.annPart = "";
        this.annSort = "";
        this.ts = null;
        this.user = null;

        this.payload = "";
        
        if (obj) {
            Object.assign(this, obj);
        }
    }
    
}
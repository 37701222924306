import { Button, createStyles, Grid, Group, Menu, ScrollArea, Tabs, Text, Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logger } from 'utils/logger';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { useForm } from '@mantine/form';
import { createSessionSuccess, fetchSeason, fetchSeasonStyles, selectSeason, updateSeason, uploadCSVToSeason, uploadCSVToSeasonSuccess } from '../../seasonSlice';
import { Crumbline, TabCounterLabel } from '../../../../core/components';
import SeasonTabGeneral from './SeasonTabGeneral';
import SeasonTabStyles from './SeasonTabStyles';

import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';

import { SHELL_TAB_BAR_HEIGHT } from 'utils/constants';
import { SampleStatusTypes } from 'core/models/SampleModel';
import CsvUpload from 'core/components/csvUpload/CsvUpload';
import { useAbortController } from 'core/hooks/useAbortableController';


// import SeasonTabTasks from './SeasonTabTasks';

const useStyles = createStyles((theme) => ({
  tab: {
    // marginLeft:`-${theme.spacing.lg}px`,
    // marginRight:`-${theme.spacing.lg}px`
  },
  col: {
    padding: 0,
  },
  tabContent: {
    // padding: theme.spacing.xs,
    paddingTop: 0,
  },
}));

const Season = ({ user, season, success, error }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const params = useParams();
  const [activeTab, setActiveTab] = useState('styles');
  const navigate = useNavigate();
  const hasInit = useRef(false);
  const abortController = useAbortController();
  
  useEffect(() => {
    logger.log(success)
    if (success) {
      switch (success.type) {
        case uploadCSVToSeasonSuccess.toString(): {
          if (activeTab !== 'styles') {
            openConfirmModal({
              title: 'CSV Successfully uploaded',
              children: <Text size='sm'>Your csv has been successfully uploaded would you like to view the styles now?</Text>,
              labels: {
                confirm: 'Yes, view styles now',
                cancel: 'Cancel',
              },
              onConfirm: () => setActiveTab('styles'),
            });
          }
          break;
        }
        // we fetch the styles again to updpate them
        case createSessionSuccess.toString(): {
          dispatch(fetchSeasonStyles(params.id,null,abortController.current.signal));
          break;
        };
        default: {
          break;
        }
      }
    }
  }, [success]);

  // get the Season from the url
  useEffect(() => {
    if (params && params.id && (!season || (season && season.id !== params.id)) && !hasInit.current) {
      dispatch(fetchSeason(params.id));      
      hasInit.current = true;
      // fetch the styles
      // dispatch(fetchSeasonStyles(params.id))
    }
  }, [params]);

  const form = useForm({
    initialValues: {
      ...season,
      name: '',
      deadline: new Date(2021, 11, 5),
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    const deadline = season && season.deadline ? new Date(season.deadline) : '';
    form.setValues({ ...season, deadline: deadline });
  }, [season]);

  const onFormSubmit = (vals) => {
    logger.log('Submitting form');
    const data = {
      id: season.id,
      code: form.values.code,
      name: form.values.name,
      description: form.values.description,
      deadline: form.values.deadline,
      tags: form.values.tags.join(','),
      // team:form.values.team,
      // ts:form.values.ts,
    };
    dispatch(updateSeason(data, user));
  };

  const onUploadClick = (type) => {
    var title = 'Upload a Event Style speadsheet file';

    if (type === 'otb') {
      title = 'Upload a OTB spreadsheet file';
    }

    openModal({
      title: title,
      size: 'xl',
      children: (
        <>
          <CsvUpload onCancel={closeAllModals} data={season} callback={(data, files) => dispatch(uploadCSVToSeason(season, type, files))} />
        </>
      ),
    });
  };
  // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}

  const crumbItems = [
    { title: 'Events', route: '/seasons', search: '' },
    { title: season?.id, route: `seasons/${season?.id}` },
  ];

  const onStatusClick = (type, data) => {
    switch (type) {
      // if they have clicked on the photography count then we want to filter
      // the media based on the style code
      // case SampleStatusTypes.SAMPLE_PHOTOGRAPHY_COUNT:{
      //     const filter = {...mediaFilter,styles:[data.stylenumber]};
      //     setMediaFilter(filter);

      //     // nav to the media tab
      //     onTabChange('media');
      //     break;
      // }
      // case SampleStatusTypes.SAMPLE_TASK_COUNT:{
      //     const filter = {...taskFilter,styles:[data.stylenumber]};
      //     setTaskFilter(filter);

      //     // nav to the media tab
      //     onTabChange('tasks');
      //     break;
      // }
      case SampleStatusTypes.SAMPLE_SCAN_COUNT: {

        // take them too the scan
        navigate(`/warehouse/scans/${data.scan.gid}`)
        logger.log(data)
        break;
      }
      default: {
        break;
      }
    }
  }


  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}>
      <Group
        className='main-container-header'
        sx={(theme) => ({
          width: '100%',
          top: '0 !important',
          borderBottom: `1px solid ${theme.colors.gray[3]}`,
          borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        })}>
        <Group px='lg' position='apart' sx={{ width: '100%' }}>
          <Crumbline items={crumbItems}></Crumbline>
          {/* <Button>Update Season</Button> */}
        </Group>
      </Group>
      <Group pt={'lg'} px={'lg'} position='apart'>
        <Title order={3}>{season?.name}</Title>
        <Group>
          <Menu width={200} shadow='md'>
            <Menu.Target>
              <Button>Upload Spreadsheet</Button>
            </Menu.Target>

            <Menu.Dropdown>
              {/* <Menu.Item onClick={() => onUploadClick('otb')}>Upload OTB Dates</Menu.Item> */}
              <Menu.Item onClick={() => onUploadClick('seasons')}>Upload Event Styles</Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {/* <Button onClick={onUploadClick}>Upload OTB Dates</Button>
                            <Button onClick={onUploadClick}>Upload Spreadsheet</Button> */}
        </Group>
      </Group>
      <Grid mb='lg' style={{ margin: 0, height: 'auto', flexGrow: 1 }}>
        <Grid.Col span={12} className={`${classes.col} body-panels`} sx={{ height: '100%' }}>
          <div style={{ height: '100%' }}>
            <ReactVirtualizedAutoSizer disableWidth>
              {({ height, width }) => {
                return (
                  <Tabs
                    defaultValue='styles'
                    value={activeTab}
                    sx={(theme) => ({
                      borderRight: `1px solid ${theme.colors.gray[2]}`,
                      height: height,
                      '.mantine-Tabs-tabsList': {
                        paddingLeft: theme.spacing.lg,
                        paddingRight: theme.spacing.lg,
                        paddingTop: 22,
                      },
                      borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                    })}
                    onTabChange={setActiveTab}>
                    <Tabs.List>
                      <Tabs.Tab value='general'>General</Tabs.Tab>
                      <Tabs.Tab value='styles'>
                        <TabCounterLabel label={'Styles'} data={season} accessor={'styles'} />
                      </Tabs.Tab>

                    </Tabs.List>

                    <Tabs.Panel value='general' pt='xs'>
                      <div className={classes.tabContent}>
                        <ScrollArea
                          style={{
                            height: height - SHELL_TAB_BAR_HEIGHT,
                          }}>
                          <SeasonTabGeneral season={season} form={form} onFormSubmit={onFormSubmit} />
                        </ScrollArea>
                      </div>
                    </Tabs.Panel>

                    <Tabs.Panel value='styles' pt='xs'>
                      <div
                        className={`${classes.tabContent}`}
                        style={{
                          height: height - SHELL_TAB_BAR_HEIGHT,
                          overflow: 'hidden',
                        }}>
                        {/* <StyleListView /> */}
                        <SeasonTabStyles onStatusClick={onStatusClick} />
                      </div>
                    </Tabs.Panel>
                  </Tabs>
                );
              }}
            </ReactVirtualizedAutoSizer>
          </div>
        </Grid.Col>

        {/* latest info */}
        {/* <Grid.Col span={3} className={classes.col}>
            <div className='right-detail-panel'>
              <Box sx={(theme)=>({
                  borderBottom:`2px solid ${theme.colors.gray[2]}`,
                  borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                })}>


                <Group py='xs' px='lg'>
                  <Text size='sm' weight='500'>Status</Text>
                  <Select style={{flexGrow:1}}
                    placeholder="Pick one"
                    data={[
                      { value: 'delayed', label: 'Delayed' },
                      { value: 'onTime', label: 'On Time' },
                      { value: 'archived', label: 'Archived' },
                      { value: 'available', label: 'Available' },
                    ]}
                  />
                </Group>
              </Box>

              <Box px='lg' pt='lg'>

                <Title className='main-content-heading' order={5} mb='lg'>Details</Title>

                <Box>
                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'>Name</Text>
                    <div>
                      <Text size='sm'>John Adams</Text>
                    </div>
                  </Group>

                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'> Time</Text>
                    <div>
                      <Text size='sm'>30/05/2022 - 9:00 AM</Text>
                    </div>
                  </Group>

                  <Group my='md'>
                    <Text size='sm' style={{width:'30%'}} weight='500'>Location</Text>
                    <div>
                      <Anchor size='sm'>Wellcom Lorimer Street</Anchor>
                    </div>
                  </Group>
                  <Button variant='light' color="brand" fullWidth style={{ marginTop: 14 }}>
                    View Scan Details
                  </Button>

                </Box>

              </Box>

            </div>

          </Grid.Col> */}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  //logger.log(state,props, ' mapstate')
  return {
    user: state.auth.user,
    season: state.seasonReducer.selectedSeason,
    success: state.seasonReducer.success,
    error: state.seasonReducer.error,
  };
};

const mapDispatch = { selectSeason };

export default connect(mapStateToProps, mapDispatch)(Season);

export class MediaFilterModel {
    constructor(obj) {
        this.keyword = '';
        this.sak = -1;
        this.star = -1;
        this.styles = [];
        this.tags = [];
        this.catalogue = { label: '', value: { since: null, until: null } };
        this.groupBy = '';
        this.psdLoaded = '';
        this.status = {taskStatus:''}
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

import { DateRangePicker } from '@mantine/dates';
import { FormInputWrapper, TagInput } from 'core/components/index';
import { StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';
import { logger } from 'utils/logger';

const { Box, MultiSelect, Group, Anchor, Button, Select } = require('@mantine/core');
const { useForm } = require('@mantine/form');
const { useState, useEffect } = require('react');
const { getCaptureCataglogues } = require('utils/helper');
// const { default: FormInputWrapper } = require('../formInputWrapper/FormInputWrapper');
const { FlagRating } = require('utils/constants');
const { IconFlag2, IconFlag2Off } = require('@tabler/icons');

const AssetFilterPanel = ({ onCancel, onApply, filter, filterItems, styleCodes, showCaptureDate = true, showStyleCodes = true, showTags = true }) => {
    const LABEL_WIDTH = '120px';
    const [tags, setTags] = useState([]);

    const [dateRange, setDateRange] = useState([null, null]);

    const form = useForm({
        initialValues: {
            ...filter,
        },
    });

    logger.log(filterItems);
    useEffect(() => {
        setDateRange([...filter.completed]);
    }, []);
    const setDates = (val) => {
        setDateRange(val);
        form.setFieldValue('completed', val);
    };
    useEffect(() => {
        if (filter && form) {
            form.setValues({ ...filter });
            const tmpTags = filter.tags.map((val) => {
                return { label: val, value: val };
            });
            setTags([...tmpTags]);
        }

        logger.log(filter);
    }, [filter]);

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push({ ...filterItems[key][val], dataKey: val }));
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Guides' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('guides').map((val) => ({ label: val.name, value: val.id }))} placeholder='All' {...form.getInputProps('guides')} />
                </FormInputWrapper>
                <FormInputWrapper label='Guide Categories' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('guideCategories').map((val) => ({ label: val.name, value: `${val.dataKey}` }))} placeholder='All' {...form.getInputProps('guideCategories')} />
                </FormInputWrapper>

                <FormInputWrapper label='Completed' labelWidth={LABEL_WIDTH}>
                    <DateRangePicker
                        placeholder='Pick dates range'
                        value={dateRange}
                        // {...form.getInputProps('deadline')}
                        onChange={(val) => {
                            setDates(val);
                        }}
                    />
                </FormInputWrapper>

                {/* <FormInputWrapper label='Rating' id='input-rating' labelWidth={LABEL_WIDTH}>
                    <StarRating onChange={(val) => form.setFieldValue('star', val)} rating={form.values.star} />
                </FormInputWrapper> */}

                {/* 
                        <FormInputWrapper label='Machines' id='input-machines' labelWidth={LABEL_WIDTH}>
                          <MultiSelect
                              data={aggData?.machines?aggData.machines:[]}
                              placeholder="All"
                              {...form.getInputProps('machines')}
                          />
                        </FormInputWrapper> */}

                {/* <FormInputWrapper label='Status' id='input-status' labelWidth={LABEL_WIDTH}>
                    <Select data={[{ label: 'Active', value: StyleAssetStatusTypes.ACTIVE },{ label: 'Archived', value: `${StyleAssetStatusTypes.ARCHIVED}` }]} placeholder='All' {...form.getInputProps('statuses')} />
                </FormInputWrapper> */}

                {showTags ? (
                    <FormInputWrapper label='Tags' id='input-tags' labelWidth={LABEL_WIDTH}>
                        <TagInput
                            {...form.getInputProps('tags')}
                            onChange={(val) => {
                                setTags([...val.map((item) => ({ value: item, label: item }))]);
                                form.setFieldValue('tags', val);
                            }}
                        />
                        {/* <MultiSelect
                        data={tags}
                        searchable
                        creatable
                        // value={form.getInputProps('tags')}
                        getCreateLabel={(query) => `+ Create ${query}`}
                        onCreate={(query) => {
                            const item = { value: query, label: query };

                            setTags((current) => [...current, item]);

                            return item;
                        }}
                        placeholder='Type to enter Tags'
                        {...form.getInputProps('tags')}
                    /> */}
                    </FormInputWrapper>
                ) : null}

                <Group position='apart' mt='lg'>
                    <Anchor component='button' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Anchor>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default AssetFilterPanel;

import { cacheToken, getToken } from "utils/helper";
import { logger } from "utils/logger";

export const authUser = async(email,password) => {
 
    const url = `${process.env.REACT_APP_API_URL}/users/auth`;
   
    const formData = JSON.stringify({
       email:email,
       password:password
    })
      
    return fetch(url, {
        headers: {
         //'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'            
         'Content-Type': 'application/json'       
        },
    method: 'POST',
    body: formData,
    }).then((response) => {
        
        const token = response.headers.get('hgio');
        cacheToken(token);

        return response.json();     
    }).then((data)=>{
        logger.log(data)
        // const user = {email:email}
        return data;
    })
    .catch((err) => {
       logger.error('ERROR logging in',err);
        throw(err);
    })
}

export const forgotPassword = async(email) => {
    const url = `${process.env.REACT_APP_API_URL}/users/forgotpw`;

    const formData = JSON.stringify({
        email:email
     })
   
     // const body = {
     //   username:email,
     //   password:password
     // }
   
     //const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&'); 
   
     logger.log(" logging in: " , formData);
     return fetch(url, {
         headers: {
           'Content-Type': 'application/json'            
         },
     method: 'POST',
     body: formData,
     }).then((response) => {
         return response.json();     
     }).then((data)=>{
         logger.log(data)
         return data;
     })
     .catch((err) => {
        console.error('ERROR forgotPassword in',err);
         throw(err);
     })
}

export const initUser = async(password,setupPhrase) => {
    const url = `${process.env.REACT_APP_API_URL}/users/init`;
   
    const formData = JSON.stringify({
       setupPhrase:setupPhrase,
       password:password
    })
   
    return fetch(url, {
        headers: {
         'Content-Type': 'application/json'            
        },
        method: 'POST',
        body: formData,
    }).then((response) => {
        return response.json();     
    }).then((data)=>{
        logger.log(data)
        return data;
    })
    .catch((err) => {
       console.error('ERROR logging in',err);
        throw(err);
    })
}

import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, createStyles, Popover, Text } from '@mantine/core';


const useStyles = createStyles((theme) => ({
    container: {
        // display:'flex',
        // position:'relative',
        // padding:`0px ${theme.spacing.sm}px`,
        // border: `1px solid ${theme.colors.gray[4]}`,
        // borderRadius: `${theme.radius.sm}px`,
        // minHeight:'34px',
        // alignItems:'center'
    },
    checkbox:{
        marginRight:`${theme.spacing.sm/2}px`,
    },
    button:{
        paddingRight:`${theme.spacing.sm/2}px`,
        paddingLeft:`${theme.spacing.sm/2}px`,
        '&.active':{
            backgroundColor:theme.colors.brand[6],
            color:theme.white
        }
    },
    menuButton:{
        border:'none', 
        width:'100%', 
    },
    label:{
        fontWeight:'normal',
        paddingRight:`${theme.spacing.sm/2}px`,
    },
    popbody:{
        // padding:'3px',
        button:{
            border:'none', 
            width:'100%', 
            fontWeight:'normal',
            '.mantine-Button-inner': { justifyContent:'start' },
        }
    }


  }));

  


export default function CheckboxDropdown({value,onCheckboxClick,onMenuSelect,selectedItems, selectMenu}) {
  
  const [type, setValue] = useState(value);
  const { classes } = useStyles();  
  const [opened, setOpened] = useState(false);
  
  useEffect(() => {
    if(type !== value){
      setValue(value)
    }
  },[value,type])

//   const changerHandler = (val) => {
//     onChange(val);
//   }

/*
  const onMenuItemClick = (e) => {
      setOpened(false);
      onMenuSelect(e);
  }
*/
  const checkboxHandler = (e) => {
    e.stopPropagation();
    onCheckboxClick();

  }
  const label = selectedItems.length?<Text className={classes.label} size='sm'>{selectedItems.length} Items Selected</Text>:'';
  const btnClasses = selectedItems.length?[classes.button,'active']:[classes.button];

  return (
      
    <div>
        <Box className={classes.container}>
        <Popover
            spacing={0}
            opened={opened}
            onClose={() => setOpened(false)}            
            width={200}
            position="bottom-start"
            >

            <Popover.Target>
                
                <Button className={btnClasses} variant="default" onClick={() => setOpened((o) => !o)}>
                    <Checkbox checked={selectedItems.length} onChange={checkboxHandler} onClick={(e) => e.stopPropagation()} className={classes.checkbox}></Checkbox>
                    {label}
                    <div>
                        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" style={{color: 'rgb(134, 142, 150)'}}>
                            <path d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                        </svg>
                    </div>
                </Button>
            
            </Popover.Target>

            <Popover.Dropdown p={'xs'}>

            <div className={classes.popbody} onClick={(e) => setOpened(false)}>
                {/* {selectMenu.props.children.map((elem,idx) => {
                    
                    return (
                        <Button key={idx} {...elem.props} onClick={(e) => {setOpened(false); elem.props.onClick()}}>{elem.props.children}</Button>
                    )
                })
                } */}
                {selectedItems.length?selectMenu: <div><Button variant='default' onClick={(e) => {checkboxHandler(e); setOpened(false)}}>Select All</Button></div>}
                
            </div>
            </Popover.Dropdown>
            
        </Popover>
            {/* <Checkbox className={classes.checkbox}></Checkbox> */}
            
            
        </Box>
    </div>    
    
  );
}

import { Badge, Group, Loader } from '@mantine/core';

const TabCounterLabel = ({ label, data, accessor, loading }) => {
    const tabLabel =
        data && data[accessor] ? (
            <>
                <Group spacing={'xs'}>
                    {label}
                    {loading ? (
                        <Loader size='xs' variant='dots' />
                    ) : (
                        <>
                            <Badge>{data[accessor].length}</Badge>
                        </>
                    )}
                </Group>
            </>
        ) : (
            <>
                <Group>
                    {label}
                    <Loader size='xs' variant='dots' />
                </Group>
            </>
        );
    return <>{tabLabel}</>;
};

export default TabCounterLabel;

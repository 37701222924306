import { logger } from 'utils/logger';

export class InstructionModel {
    constructor(obj) {
        this.dept = '';
        this.department = '';
        // this.name = "";
        // this.oDBMSAPN = "";
        // this.parentkeycode = "";
        // this.webPhotographyCode = "";
        this['Web Photography Notes'] = "";
        this['Web Photography Code'] = "";
        this['ODBMS APN (GTIN)'] = "";
        this['Parent keycode'] = "";
        this['Web Photography Sample Size'] = "";
        this['Web Photography Style'] = "";
        this['Web Photography Treatment'] = "";
        this['Web Stock OTB Date'] = "";
        this['guideCode'] = ""
        this['guidename'] = "";
        // this.webPhotographySampleSize = "";
        // this.webPhotographyStyle = "";
        // this.webPhotographyTreatment = "";
        // this.webStockOTBDate = "";
        this.departmentStr = '';
        // if (obj) {
        //     Object.assign(this, obj);
        // }
    }
}

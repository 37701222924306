import { Box, Center, Skeleton, Text, Title } from '@mantine/core';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { areEqual, FixedSizeGrid as Grid } from 'react-window';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { IconCamera } from '@tabler/icons';
import { CaptureThumb } from 'core/components/index';
import InfiniteLoader from 'react-window-infinite-loader';
import { logger } from 'utils/logger';

const GridLayout = ({ onImageEvent, thumbnailSize = 200, captures = [], selectedItems, onCaptureChange, imageLocHash, showLoading, isSearching, searchCursor, loadNextPage }) => {
    // const imageHash = useRef({})
    const [scrollState, setScrollState] = useState({ rowCount: 0, colCount: 0, scrolledRow: 0 });
    const infiniteLoaderRef = useRef(null);

    const isSelected = useCallback((val, selectedItems) => {
        let selected = false;
        if (selectedItems && val) {
            selected = selectedItems.find((item) => val.id === item.id) ? true : false;
        }
        return selected;
    }, []);

    /**
     * Handler to scroll to the top of the grid if we have returned new search results.
     * Using the change on captures would trigger a scroll on any capture update such as setting
     * a rating
     */
    useEffect(() => {
        // if (gridRef.current && !isSearching)
        //     gridRef.current.scrollToItem({
        //         rowIndex: 0,
        //     });
        logger.log('searchCursor', searchCursor)
        if(searchCursor && captures.length <= 100){
            setScrollState({...scrollState,scrolledRow:0})
            if (infiniteLoaderRef.current && infiniteLoaderRef.current._listRef){
                infiniteLoaderRef.current._listRef.scrollToItem({
                    rowIndex: 0,
                });
            }            
        }
        
    }, [searchCursor]);

    const ProxyCell = memo(
        (props) => {
            const { columnIndex, rowIndex, style } = props;

            const ThumbCallback = useCallback(({ columnIndex, data, rowIndex, style }) => {
                return (
                    <div style={{ height: '100%', width: '100%', padding: 5 }}>
                        <Box sx={{ width: '100%', height: '100%' }}>
                            <Skeleton width={'100%'} height={'80%'} />
                            <Skeleton height={8} mt={10} radius='xl' />
                            <Skeleton height={8} mt={5} width='70%' radius='xl' />
                        </Box>
                    </div>
                );
            }, []);

            const tmpStyle = { ...style };
            return (
                <div key={`${columnIndex}-${rowIndex}`} style={tmpStyle}>
                    <ThumbCallback {...props} />
                </div>
            );
        },

        areEqual,
    );

    const Cell = memo(
        (props) => {
            const { columnIndex, data, rowIndex, style } = props;
            const idx = columnIndex + rowIndex * data.colCount;
            const val = captures[idx];
            const ThumbCallback = useCallback(
                ({ columnIndex, data, rowIndex, style }) => {
                    // return await new Promise((resolve) => {

                    //     getImage(data).then((response) => {
                    //         logger.log(' GOT REP{OSINE');
                    //         if(response && response.length)imageHash.current[data.id] = response;
                    //         resolve(response)
                    //     })

                    // });

                    /*const getImage = async() => {

                        try{
                            const res = await fetchImagePath(`${data.url}`);
        
                            logger.log(res, " ____PATH")
                            imageLocHash.current[data.id] = res;
                            return res;
                        }catch(e){
                            logger.log(e,'failed to fetch path')
                        }        
                    }*/

                    // if(data && !imageHash.current[data.id]){
                    //     getImage(data).then((response) => {
                    //         logger.log(' GOT REP{OSINE');
                    //         if(response && response.length)imageHash.current[data.id] = response;
                    //     })

                    // }

                    // //const imgData = data?{...data,url:imageHash.current[data.id]}:null;
                    // // logger.log('THumbclallback', imgData,imageHash.current)
                    var imageURL = data && data.previewLo ? data.previewLo : '';
                    let imageRefKey = thumbnailSize > 300 ? 'previewLo' : 'previewLo';
                    if (data && imageLocHash.current[data.id] && data[imageRefKey] === imageLocHash.current[data.id]) {
                        imageURL = imageLocHash.current[data.id];
                    } else if (data) {
                        //getImage();
                        imageLocHash.current[data.id] = thumbnailSize > 200 ? data.previewLo : data.previewLo;
                        imageURL = imageLocHash.current[data.id];
                    }

                    return (
                        <div style={{ height: '100%', width: '100%' }}>
                            {data ? (
                                <CaptureThumb
                                    showSettings={true}
                                    src={imageURL}
                                    image={data}
                                    onImageEvent={onImageEvent}
                                    size={thumbnailSize}
                                    selected={isSelected(val, selectedItems)}
                                    onCaptureChange={(type, val) => onCaptureChange(type, val, [data])}
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                    );
                },
                [val],
            );

            // if(!captures[idx]){
            //     return (<div></div>);
            // }

            const tmpStyle = { ...style };
            return (
                <div key={`${columnIndex}-${rowIndex}`} style={tmpStyle}>
                    {data ? <ThumbCallback {...props} data={val} imageHash={imageLocHash} /> : null}
                </div>
            );
        },

        areEqual,
    );

    const getLayout = () => {
        return (
            <ReactVirtualizedAutoSizer>
                {({ height, width }) => {
                    const colWidth = thumbnailSize < 180 ? 190 : thumbnailSize + 10;
                    const rowHeight = thumbnailSize < 180 ? 260 : thumbnailSize + 110;

                    let colCount = Math.floor(width / colWidth);
                    let rowCount = showLoading ? 4 : Math.floor(captures.length / colCount);

                    if ((captures.length / colCount) % 2) {
                        ++rowCount;
                        // ++colCount;
                    }

                    // Only load 1 page of items at a time.
                    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
                    const loadMoreItems = (startIndex, endIndex) => {
                        if (!isSearching && searchCursor) {
                            loadNextPage();
                        }
                    };

                    // Every row is loaded except for our loading indicator row.
                    // const isItemLoaded = (index) => !searchCursor || index < rowCount;

                    const isRowLoaded = (index) => {
                        return index < rowCount - 1;
                    };

                    // Render an item or a loading indicator.
                    // const Item = ({ index, style }) => {
                    //     let content;
                    //     if (!isItemLoaded(index)) {
                    //         content = 'Loading...';
                    //     } else {
                    //         content = captures[index].name;
                    //     }

                    //     return <div style={style}>{content}</div>;
                    // };

                    return (
                        <>
                            {showLoading ? (
                                <Grid columnCount={colCount} columnWidth={width / colCount} height={height} rowCount={rowCount} rowHeight={rowHeight} width={width} itemData={{ colCount, rowCount }}>
                                    {ProxyCell}
                                </Grid>
                            ) : (
                                <InfiniteLoader 
                                    ref={infiniteLoaderRef}
                                    isItemLoaded={isRowLoaded} 
                                    itemCount={scrollState.rowCount} 
                                    loadMoreItems={loadMoreItems}>

                                    {({ onItemsRendered, ref }) => {
                                        
                                        return (
                                            <Grid
                                                columnCount={colCount}
                                                columnWidth={width / colCount}
                                                height={height}
                                                rowCount={rowCount}
                                                rowHeight={rowHeight}
                                                width={width}
                                                // initialScrollTop={rowHeight * scrollState.scrolledRow}
                                                onItemsRendered={({ visibleRowStartIndex, visibleColumnStartIndex, visibleRowStopIndex, overscanRowStopIndex, overscanRowStartIndex }) => {
                                                    logger.log('on items rendered');
                                                    if (scrollState.colCount !== colCount || scrollState.rowCount !== rowCount) {
                                                        setScrollState({ rowCount, colCount, scrolledRow: visibleRowStartIndex });
                                                    }
                                                    // props.setScrollRowAndColumn(visibleRowStartIndex, visibleColumnStartIndex)
                                                    onItemsRendered({
                                                        overscanStartIndex: overscanRowStartIndex,
                                                        overscanStopIndex: overscanRowStopIndex,
                                                        visibleStartIndex: visibleRowStartIndex,
                                                        visibleStopIndex: visibleRowStopIndex,
                                                    });
                                                }}
                                                ref={ref}
                                                itemData={{ colCount, rowCount }}>
                                                {Cell}
                                            </Grid>
                                        );
                                    }}
                                </InfiniteLoader>
                            )}
                        </>
                    );
                }}
            </ReactVirtualizedAutoSizer>
        );
    };

    const content = !showLoading && captures.length === 0 ? <EmptyState /> : getLayout();
    return (
        // <Group spacing={'lg'}>

        //     {captures.map((val,idx) => (
        //         <ImageThumb key={idx} image={val} onImageEvent={onImageEvent} size={thumbnailSize} selected={isSelected(val,selectedItems)} />
        //     ))}

        // </Group>
        <Box px='sm' sx={{ width: '100%', height: '100%' }}>
            {content}
        </Box>
    );
};

export default GridLayout;

const EmptyState = () => {
    return (
        <Center sx={{ height: '100%' }}>
            <div style={{ textAlign: 'center' }}>
                <IconCamera size={48} stroke={1} />
                <Title color={'dimmed'} order={3}>
                    No Captures Available
                </Title>
                <Text color={'dimmed'}>Try changing the Catalogue or Filter</Text>
            </div>
        </Center>
    );
};

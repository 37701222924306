import { useRef } from 'react';
import { useEffectOnce } from 'utils/helper';

export const useAbortController = () => {
    const controllerRef = useRef(null);

    useEffectOnce(() => {
        // Initialize the AbortController only once
        controllerRef.current = new AbortController();

        return () => {
            // Abort on cleanup (when component unmounts)
            if (controllerRef.current) {
                controllerRef.current.abort();
            }
        };
    }, []);

    return controllerRef;
};

import { v4 as uuidv4 } from 'uuid';

export class SampleModel {
    constructor(obj) {
        this.photoCode = obj?.photoCode ?? '';
        this.styleCode = obj?.styleCode ?? '';
        this.name = obj?.name ?? '';
        this.notes = obj?.notes ?? '';
        this.size = obj?.size ?? '';
        this.department = obj?.department ?? '';
        this.otbDate = obj?.otbDate ?? '';
        this.country = obj?.country ?? '';
        this.itemType = obj?.itemType ?? '';
        this.orderDate = obj?.orderDate ?? '';
        this.orderPriority = obj?.orderPriority ?? '';
        this.region = obj?.region ?? '';
        this.salesChannel = obj?.salesChannel ?? '';
        this.shipDate = obj?.shipDate ?? '';
        this.totalCost = obj?.totalCost ?? 0;
        this.totalProfit = obj?.totalProfit ?? 0;
        this.totalRevenue = obj?.totalRevenue ?? '';
        this.unitCost = obj?.unitCost ?? 0;
        this.unitPrice = obj?.unitPrice ?? 0;
        this.unitsSold = obj?.unitsSold ?? 0;
        this.sampleId = obj?.stylenumber ?? uuidv4();
        this.barCode = obj?.barCode ?? '';
        this.colourCount = obj?.colourCount ?? 0;
        this.colourItems = obj?.colourItems ?? [];
        this.colours = obj?.colours ?? [];
        this.desc = obj?.desc ?? '';
        this.modified = obj?.modified ?? '';
        this.sizeCount = obj?.sizeCount ?? 0;
        this.styleCount = obj?.styleCount ?? 0;
        this.targetId = obj?.targetId ?? '';
        this.targetSort = obj?.targetSort ?? '';
        this.version = obj?.version ?? '';
        this.departmentStr = obj?.departmentStr ?? '';
        this.Dept = obj?.Dept ?? '';
        this.issuer = obj?.issuer?? '';
        this.distributionDate = obj?.distributionDate??'';
        
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export const SampleEventType = {
    SAMPLE_BUILD_OUTFIT: 'sampleBuildOutfit',
    SAMPLE_EDIT: 'sampleEdit',
    SAMPLE_ADD: 'sampleAdd',
    SAMPLE_VIEW: 'sampleView',
    SAMPLE_DELETE: 'sampleDelete',
    SAMPLE_DOWNLOAD: 'sampleDownload',
    SAMPLE_ASSIGN_CAPTURES: 'sampleAssignCaptures',
    SAMPLE_SHOW_SESSION: 'sampleShowSession',
    SAMPLE_DISTRIBUTE: 'sampleDistribute',
};

export const SampleStatusTypes = {
    SAMPLE_PHOTOGRAPHY_COUNT: 'photographyCount',
    SAMPLE_TASK_COUNT: 'taskCount',
    SAMPLE_SCAN_COUNT: 'scanCount',
    SAMPLE_DISTRIBUTED:'DISTRIBUTED',
    SAMPLE_READY:'READY FOR DISTRIBUTION',
    SAMPLE_ACTIVE:'ACTIVE'
};
export const SessionStatusTypes = {
    ARCHIVE: 'inactive',
    ACTIVE: 'active',
};

// this model is for the session samples
export const SampleFilterModel = {
    keyword: '',
    brands: [],
    departments: [],
    location: [],
    status: {
        sampleCount: '',
        photographyCount: '',
        taskCount: '',
        taskCompletedCount: '',
        hasSession:'',
    },
    colour: '',
    sessions: [],
    pageIndex:0,
};

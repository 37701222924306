import { Accordion, Box, Button, Center, createStyles, Group, MultiSelect, SegmentedControl, Select, Text, Title, UnstyledButton } from '@mantine/core';
import { IconCapture, IconChevronRight, IconFilter, IconFlag2, IconFlag2Off } from '@tabler/icons';
import { FormInputWrapper, StarRating, TagInput } from 'core/components/index';

import { logger } from 'utils/logger';
import moment from 'moment';
import { FlagRating } from 'utils/constants';
import { useState } from 'react';
import SessionSelect from 'core/components/sessionSelect/SessionSelect';

const useStyles = createStyles((theme, _params, getRef) => ({
    keywordSearch: {
        input: {
            paddingRight: 67,
        },
        span: {
            fontSize: 10,
        },
        '.mantine-Input-rightSection': {
            width: 67,
        },
    },
    captureMenuItem: {
        marginLeft: theme.spacing.xl * 1.2,
        flexGrow: '1 !important',
        borderRadius: theme.radius.xs,
        paddingTop: theme.spacing.xs / 3,
        paddingBottom: theme.spacing.xs / 3,
        paddingLeft: theme.spacing.xs,
        // width:'100%',
        '&:hover': {
            backgroundColor: theme.colors.dark[4],
        },
        '&.selected': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
            color: theme.colors.brand[4],
        },
    },
    accordians: {
        '.row': {},
    },
}));

const FilterPanel = ({ approvalFilter, onCollectionClick, form, aggData, onSubmit, onClearClick, isSearching }) => {
    const { classes } = useStyles();
    const LABEL_WIDTH = '80px';
    const [tags, setTags] = useState([]);

    const clearFilter = () => {
        logger.log('clear click');
        onClearClick();
    };

    return (
        <Accordion multiple className='unhightable' defaultValue={['filters']}>
            {/* <Accordion.Item value='captures'>
                <Accordion.Control>
                    <Group>
                        <IconCamera />
                        <Title order={6} className='unhightable'>
                            Catalogues
                        </Title>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <CollectionMenu classes={classes} onItemClick={(e) => onCollectionClick(e)} selectedItem={approvalFilter.catalogue} />
                </Accordion.Panel>
            </Accordion.Item> */}

            <Accordion.Item value='filters'>
                <Accordion.Control>
                    <Group>
                        <IconFilter></IconFilter>
                        <Title order={6} className='unhightable'>
                            Filters
                        </Title>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel pl={'xs'} className={classes.accordians}>
                    
                    {/* <FormInputWrapper label='Client' labelWidth={LABEL_WIDTH} required>
                        {aggData && aggData.clients?<Select required placeholder='All Clients' {...form.getInputProps('client')} data={aggData?.clients?.map((val) => ({ label: val.name, value: val.code }))} />:null}
                    </FormInputWrapper> */}

                    <FormInputWrapper label='Session' id='input-session' labelWidth={LABEL_WIDTH}>
                        <SessionSelect onChange={(val) => form.setFieldValue('sessionId', val)} value={form.values.sessionId} />
                    </FormInputWrapper>

                    <FormInputWrapper label='Flag' id='input-status' labelWidth={LABEL_WIDTH}>
                        <SegmentedControl
                            {...form.getInputProps('sak')}
                            data={[
                                {
                                    value: FlagRating.FLAG_SELECT,
                                    label: (
                                        <Center>
                                            <IconFlag2 size={16} />
                                            {/* <Box ml={10}>Preview</Box> */}
                                        </Center>
                                    ),
                                },
                                {
                                    value: FlagRating.FLAG_KILL,
                                    label: (
                                        <Center>
                                            <IconFlag2Off size={16} />
                                            {/* <Box ml={10}>Code</Box> */}
                                        </Center>
                                    ),
                                },
                                {
                                    value: '-1',
                                    label: (
                                        <Center>
                                            <Box ml={0}>
                                                <Text size={'sm'}>All</Text>
                                            </Box>
                                        </Center>
                                    ),
                                },
                            ]}
                        />
                        {/* <FlagToggle /> */}
                    </FormInputWrapper>

                    <FormInputWrapper label='Rating' id='input-rating' labelWidth={LABEL_WIDTH}>
                        <StarRating onChange={(val) => form.setFieldValue('star', val)} rating={form.values.star} />
                        <Group>
                            {/* <NativeSelect
                  data={['=', '<', '>', '<=', '>=']}
                /> */}
                            {/* <Rating defaultValue={0} color={'lime'} /> */}
                            {/* <StarRating /> */}
                        </Group>
                    </FormInputWrapper>

                    {/* <FormInputWrapper label='Season' id='input-location' labelWidth={LABEL_WIDTH}>
              <MultiSelect
                data={['React', 'Angular']}
                placeholder="All"
                
              />
              </FormInputWrapper> */}

                    {/* <FormInputWrapper label='Colours' id='input-colours' labelWidth={LABEL_WIDTH}>
                <MultiSelect
                    data={aggData?.colours?aggData.colours:[]}
                    placeholder="All"
                    
                />
              </FormInputWrapper> */}

                    <FormInputWrapper label='Machines' id='input-machines' labelWidth={LABEL_WIDTH}>
                        <MultiSelect
                            data={
                                aggData?.machines
                                    ? aggData.machines.map((val) => {
                                          return { label: val.split(':')[1], value: val };
                                      })
                                    : []
                            }
                            placeholder='All Machines'
                            {...form.getInputProps('machines')}
                        />
                    </FormInputWrapper>

                    {/* <FormInputWrapper label='Sizes' id='input-sizes' labelWidth={LABEL_WIDTH}>
                <MultiSelect
                    data={aggData?.sizes?aggData.sizes:[]}
                    placeholder="All"
                    
                />
              </FormInputWrapper> */}

                    {/* <FormInputWrapper label='Location' id='input-location' labelWidth={LABEL_WIDTH}>
              <MultiSelect
                data={['React', 'Angular', 'Svelte', 'Vue']}
                placeholder="All"
                
              />
              </FormInputWrapper> */}

                    {/* <FormInputWrapper label='SKU' id='input-sku' labelWidth={LABEL_WIDTH}>
                <MultiSelect
                  data={['React', 'Angular', 'Svelte', 'Vue']}
                  placeholder="All"
                />
              </FormInputWrapper> */}

                    <FormInputWrapper label='Tags' id='input-tags' labelWidth={LABEL_WIDTH}>
                        <TagInput
                            {...form.getInputProps('tags')}
                            onChange={(val) => {
                                setTags([...val.map((item) => ({ value: item, label: item }))]);
                                form.setFieldValue('tags', val);
                            }}
                        />
                        {/* <MultiSelect
                            data={tags}
                            searchable
                            creatable
                            // value={form.getInputProps('tags')}
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = { value: query, label: query };

                                setTags((current) => [...current, item]);

                                return item;
                            }}
                            placeholder='Type to enter Tags'
                            {...form.getInputProps('tags')}
                        /> */}
                    </FormInputWrapper>

                    {/* <FormInputWrapper label='Unassigned' id='input-rating' labelWidth={LABEL_WIDTH}>
                <Checkbox />
              </FormInputWrapper> */}
                    <Group mt={'xl'} sx={{ width: '100%' }}>
                        <Button variant='subtle' onClick={() => clearFilter()}>
                            Clear Filters
                        </Button>
                        <Button sx={{ flexGrow: 1 }} variant='filled' type='submit' onClick={onSubmit} loading={isSearching}>
                            {isSearching ? 'Please wait...' : 'Search'}
                        </Button>
                    </Group>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};

export default FilterPanel;

const CollectionMenu = ({ classes, selectedItem, onItemClick }) => {
    const collections = [
        { label: 'Latest', value: { since: null, until: null } },
        { label: 'Today', value: { since: moment().hour(0).minute(0).format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Week', value: { since: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Month', value: { since: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Year', value: { since: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        {
            label: 'Last Week',
            value: { since: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS') },
        },
        {
            label: 'Last Month',
            value: { since: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS') },
        },
        {
            label: 'Last Year',
            value: { since: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS') },
        },
    ];

    const getClassName = (val = '') => {
        var cls = val.label === selectedItem.label ? `${classes.captureMenuItem} selected` : classes.captureMenuItem;
        return cls;
    };

    return (
        <>
            <Group sx={{ flexDirection: 'column', alignContent: 'start', flexWrap: 'nowrap', alignItems: 'stretch', gap: 0 }} ml='lg'>
                <UnstyledButton className={getClassName('')} sx={{ marginLeft: 0 }} onClick={() => onItemClick(null)}>
                    <Group spacing={'xs'}>
                        <IconCapture size={14} />
                        <Text size={'sm'}>All Captures</Text>
                    </Group>
                </UnstyledButton>
            </Group>
            <Group sx={{ flexDirection: 'column', alignContent: 'start', flexWrap: 'nowrap', alignItems: 'stretch', gap: 0 }} ml='lg'>
                {collections.map((val, idx) => {
                    return (
                        <UnstyledButton key={idx} className={getClassName(val)} onClick={() => onItemClick(val)}>
                            <Group spacing={'xs'}>
                                <IconChevronRight size={14} />
                                <Text size={'sm'}>{val.label}</Text>
                            </Group>
                        </UnstyledButton>
                    );
                })}
            </Group>
        </>
    );
};

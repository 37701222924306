import { DateRangePicker } from '@mantine/dates';
import { FormInputWrapper, TagInput } from 'core/components/index';
import { logger } from 'utils/logger';

const { Box, Select, SegmentedControl, Center, MultiSelect, Group, Anchor, Button, Text } = require('@mantine/core');
const { useForm } = require('@mantine/form');
const { useState, useEffect } = require('react');
const { getCaptureCataglogues } = require('utils/helper');
// const { default: FormInputWrapper } = require('../formInputWrapper/FormInputWrapper');
const { FlagRating } = require('utils/constants');
const { IconFlag2, IconFlag2Off } = require('@tabler/icons');

const ScanFilterPanel = ({ onCancel, onApply, filter, filterItems, styleCodes, showCaptureDate = true, showStyleCodes = true, showTags = true }) => {
    const LABEL_WIDTH = '120px';
    const [tags, setTags] = useState([]);

    const [styleCode, setStylecode] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);

    const form = useForm({
        initialValues: {
            ...filter,
        },
    });

    logger.log(filterItems);
    useEffect(() => {
        setDateRange([...filter.completed]);
    }, []);
    const setDates = (val) => {
        setDateRange(val);
        form.setFieldValue('completed', val);
    };
    useEffect(() => {
        if (filter && form) {
            form.setValues({ ...filter });
            const tmpTags = filter.tags.map((val) => {
                return { label: val, value: val };
            });
            setTags([...tmpTags]);
        }

        logger.log(filter);
    }, [filter]);

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => {
            logger.log(val)
            res.push({name:val,value:val})
        });
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Department' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('department').map((val) => ({ label: val.name, value: val.id }))} placeholder='All' {...form.getInputProps('department')} />
                </FormInputWrapper>
                <FormInputWrapper label='Colour' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('colour').map((val) => ({ label: val.name, value: `${val.id}` }))} placeholder='All' {...form.getInputProps('colour')} />
                </FormInputWrapper>
                <FormInputWrapper label='Size' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('size').map((val) => ({ label: val.name, value: `${val.id}` }))} placeholder='All' {...form.getInputProps('size')} />
                </FormInputWrapper>
                <FormInputWrapper label='Event' id='input-captureDate' labelWidth={LABEL_WIDTH}>
                    <MultiSelect data={getFilterVars('season').map((val) => ({ label: val.name, value: `${val.id}` }))} placeholder='All' {...form.getInputProps('season')} />
                </FormInputWrapper>

                <FormInputWrapper label='Scan Date' labelWidth={LABEL_WIDTH}>
                    <DateRangePicker
                        placeholder='Pick dates range'
                        value={dateRange}
                        // {...form.getInputProps('deadline')}
                        onChange={(val) => {
                            setDates(val);
                        }}
                    />
                </FormInputWrapper>
              

                {showTags ? (
                    <FormInputWrapper label='Tags' id='input-tags' labelWidth={LABEL_WIDTH}>
                        <TagInput
                            {...form.getInputProps('tags')}
                            onChange={(val) => {
                                setTags([...val.map((item) => ({ value: item, label: item }))]);
                                form.setFieldValue('tags', val);
                            }}
                        />
                    </FormInputWrapper>
                ) : null}

                <Group position='apart' mt='lg'>
                    <Anchor component='button' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Anchor>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default ScanFilterPanel;

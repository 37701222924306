import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Checkbox,
    createStyles,
    Group,
    Menu,
    Modal,
    NativeSelect,
    Pagination,
    Paper,
    Popover,
    ScrollArea,
    Select,
    Skeleton,
    Stack,
    Table,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CheckboxDropdown, FilterButton, ListToggleControl } from '../../../../core/components';
import { batchUpdateStyleAsset, getStyleAssetsSuccess, getStyleSuccess, setDisplayPrefs } from 'modules/Styles/styleSlice';
import { setPreviousRoute } from 'app/appSlice';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { IconArchive, IconArchiveOff, IconChevronDown, IconChevronRight, IconChevronUp, IconClearAll, IconDots, IconSearch, IconX } from '@tabler/icons';
import Moment from 'react-moment';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';

import { CaptureEventType } from 'core/components/capture/CaptureThumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApprovalLayoutTypes, PaginationValues } from 'utils/constants';
import { handleZipDownload, selectItems } from 'utils/helper';
import * as utils from 'utils/helper';

import CaptureDetail from 'core/components/capture/CaptureDetail';
import { logger } from 'utils/logger';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import AssetFilterPanel from './AssetFilterPanel';
import { StyleAssetFilterModel, StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';
import StyleMediaGridLayout from './StyleMediaGridLayout';
import { openConfirmModal } from '@mantine/modals';

var _ = require('lodash');

const useStyles = createStyles((theme, _params, getRef) => ({}));

const StyleTabMedia = ({ user, style, success, guides, error, form, setPreviousRoute, onMediaAction, displayPrefs, mediaFilter, setMediaFilter }) => {
    const [data, setData] = useState([]);
    // const [mediaFilter, setMediaFilter] = useState(new MediaFilterModel());
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [filterItems, setFilterItems] = useState(null);

    const [imageModalOpen, setImageModalOpen] = useState(false);

    const [listView, setListView] = useState(displayPrefs.mediaDisplayType);

    const [hasFetched, setHasFetched] = useState(false);
    const [showLoading, setShowLoading] = useState(style && style.assets && style.assets.length?false:true);

    const [styleCodes, setStyleCodes] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { cx } = useStyles();

    const [stylesPageIndex, setSessionsPageIndex] = useState(0); // keep this state so we can use it when data is refreshed
    const [scrolled, setScrolled] = useState(false);

    // const [sorting, setSorting] = useState([{ id: 'tags', desc: true }]);
    useEffect(() => {
        filterMedia();
    }, [style]);

    useEffect(() => {
        //setSampleData(samples);
        // set the brands/season/category
        var guidesMap = {};
        var guideCategories = {};
        var statuses = {};

        if (style && style.assets && guides && !filterItems) {
            // grab the guides and categories for the assets
            style.assets.forEach((val) => {
                let style = { ...val };
                if (val.guideID && guides) {
                    style.guide = guides.find((guide) => guide.id === val.guideID);
                    if (style.guide && style.guide.guideCategories) {
                        style.guideCategory = style.guide.guideCategories.find((cat) => cat.id === style.catId);
                    }
                }

                guidesMap[`${val.guideID}`] = val.guide;
                if (style.guideCategory) guideCategories[`${style.guide.id}|${style.catId}`] = style.guideCategory;
                if (style.viewstatus) {
                    statuses[style.viewstatus] = style;
                } else {
                    statuses[StyleAssetStatusTypes.ACTIVE] = style;
                }
            });
            setFilterItems({ guides, guideCategories, statuses });
        }

        // filterSamples();
    }, [guides, style, filterItems]);

    // Table columns and setup
    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <>
                        {mediaFilter && mediaFilter.groupBy && mediaFilter.groupBy.length ? (
                            <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? <IconChevronDown /> : <IconChevronRight />}</span>
                        ) : null}
                    </>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    {
                        let isChecked = false;
                        let indeterminate = false;
                        if (row.subRows) {
                            // if all of the subrows are included in the selected items.`
                            const commonItems = _.intersectionBy(row.originalSubRows, selectedItems, 'id');
                            isChecked = commonItems.length === row.subRows.length;
                            indeterminate = commonItems.length && commonItems.length < row.subRows.length ? true : false;
                        }
                        return row.canExpand ? (
                            <Group>
                                {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> */}

                                <div
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            // paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}>
                                    {row.isExpanded ? <IconChevronDown /> : <IconChevronRight />}
                                </div>
                                <Checkbox checked={isChecked} indeterminate={indeterminate} onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox>
                            </Group>
                        ) : null;
                    },
                width: 70,
            },

            {
                Header: 'Asset',
                accessor: 'styleCode',
            },
            {
                Header: 'Tags',
                accessor: 'tags',
                width: 200,
            },
            {
                Header: 'Guide',
                accessor: 'guideID',
                width: 100,
            },
            {
                Header: 'Guide Category',
                accessor: 'catId',
                width: 200,
            },
            {
                Header: 'Event',
                accessor: 'seasonCode',
            },
            {
                Header: 'Session',
                accessor: 'sessionCode',
                width: 50,
            },
            {
                Header: 'Completed',
                accessor: 'completed',
                width: 50,
            },

            {
                Header: 'Status',
                accessor: 'viewstatus',
                width: 80,
            },
            {
                Header: '',
                accessor: 'action',
                width: 40,
            },
        ],
        [mediaFilter.groupBy, selectedItems],
    );

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        pageOptions,
        // pageCount,
        gotoPage,
        rows,
        // nextPage,
        // previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            // globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: stylesPageIndex,
                pageSize: 100,
                globalFilter: null,
                expanded: false,
            },
            sortTypes: {
                alphanumeric: (row1, row2, columnName) => {
                    const rowOneColumn = row1.values[columnName];
                    const rowTwoColumn = row2.values[columnName];
                    if (isNaN(rowOneColumn) && rowTwoColumn) {
                        return rowOneColumn?.toUpperCase() > rowTwoColumn?.toUpperCase() ? 1 : -1;
                    }
                    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                },
            },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded,
        usePagination,
    );

    // function changeSorting(columnName, isDescending) {
    //     logger.log(columnName, isDescending);
    //     const newSorting = [{ id: columnName, desc: isDescending }];
    //     setSorting(newSorting);
    // }

    /**
     * Effect to handle the success state change
     */
    useEffect(() => {
        if (success) {
            logger.log(success, ' SUCCESS');
            switch (success.type) {
                // if we have fetched the style media set the state.

                case getStyleSuccess.toString(): {
                    if (!style) {
                        setShowLoading(false);
                        setData([]);
                    }
                    break;
                }
                case getStyleAssetsSuccess.toString(): {
                    setHasFetched(true);
                    setShowLoading(false);
                    break;
                }
                default: {
                }
            }
        }
    }, [success]);

    /**
     * Method to filter the style media
     */
    const filterMedia = useCallback(() => {
        logger.log('FILTERING MEDIA');

        if (style && style.assets) {
            let newData = utils.filterStyleAssets(style.assets, mediaFilter);

            // // grab the guides and categories for the assets
            newData = newData.map((val) => {
                let style = { ...val };
                if (val.guideID) {
                    style.guide = guides.find((guide) => guide.id === val.guideID);
                    if (style.guide && style.guide.guideCategories) {
                        style.guideCategory = style.guide.guideCategories.find((cat) => cat.id === style.catId);
                    }
                }
                return style;
            });

            if (mediaFilter.groupBy) {
                var uniq = [
                    ...new Set(
                        Object.keys(newData).map((e) => {
                            // if(newData[e] && newData[e].hasOwnProperty(mediaFilter.groupBy) && newData[e][mediaFilter.groupBy])
                            return newData[e][mediaFilter.groupBy];
                        }),
                    ),
                ];
                // create the group by items
                var groupedData = uniq.map((group) => {
                    var subRows = newData.filter((val) => val[mediaFilter.groupBy] === group);
                    let name = group;
                    logger.log(group);
                    if (mediaFilter.groupBy === 'catId' && subRows.length && subRows[0].guideCategory) {
                        name = subRows[0].guideCategory.name;
                    }
                    return { type: 'group', name: name, subRows, id: group };
                });
                newData = groupedData;
            }

            setData([...newData]);
        }
    }, [mediaFilter, setData, style]);

    /**
     * Effect to handle the filter changes. When this happens we refilter
     * the style media.
     */
    useEffect(() => {
        const count = utils.getStyleAssetFilterCount(mediaFilter);
        setFilterCount(count);
        filterMedia();
        setSessionsPageIndex(0);
    }, [mediaFilter, filterMedia]);

    /**
     * Debounce fetch so it isn't called multiple times.
     * We now fetch media in the main style component
     */
    const fetchMedia = useCallback(
        _.debounce(function (criteria) {
            logger.log(criteria);
            // dispatch(fetchStyleAssets(criteria.styleId));
        }, 1000),
        [],
    );

    /**
     * Effect to fetch media when the style changes
     * We also need to check to see if the media doesn't already exist otherwise we don't
     * need to fetch.
     */
    useEffect(() => {
        // only fetch if media hasn't been fetched
        if (style && style.id && !hasFetched && !style.assets) {
            var criteria = {};

            logger.log('search submit');
            criteria.styleId = style.id;

            fetchMedia(criteria);
            setHasFetched(true);
        }

        if (style && style.assets) {
            logger.log(style.assets);

            // setHasFetched(true);
            filterMedia();
        }
    }, [style, fetchMedia, filterMedia, hasFetched]);

    /**
     * Handler to archive the assets, we strip out unwanted
     * attributes and set the status.
     * @param {*} assets
     * @param {*} status
     */
    const archiveAssets = (assets, status) => {
        const items = assets.map((val) => {
            return {
                id: val.styleSort,
                actionPart: val.actionPart,
                actionSort: val.actionSort,
                viewstatus: status.toLowerCase(),
            };
        });
        dispatch(batchUpdateStyleAsset(style, items));
    };

    /**
     * Handler for the Capture menu events.
     * @param {*} e
     * @param {*} data
     * @param {*} items
     */
    const onMenuItemClick = (e, data, items) => {
        logger.log(e, data, items);
        let captures = [];
        switch (e) {
            case CaptureEventType.DOWNLOAD: {
                // find all the items
                const arr = items.map((val) => {
                    const item = style.assets.find((style) => val.id === style.id);
                    return item;
                });
                handleZipDownload(arr, 'originalFilename', style.id, 'previewHi');
                break;
            }
            case CaptureEventType.RATING_CHANGE:
            case CaptureEventType.STATUS_CHANGE: {
                captures = items.map((val) => {
                    return style.assets.find((obj) => obj.id === val.id);
                });

                onCaptureChange(e, data, captures);
                break;
            }
            case CaptureEventType.APPLY_STYLE_INFO:
            case CaptureEventType.CREATE_TASK: {
                captures = items.map((val) => {
                    return style.assets.find((obj) => obj.id === val.id);
                });

                onMediaAction(e, data, captures);
                break;
            }
            case CaptureEventType.UNARCHIVE:
            case CaptureEventType.ARCHIVE: {
                const arr = items.map((val) => {
                    const item = style.assets.find((style) => val.id === style.id);
                    return item;
                });
                var action = `Archive`;
                var viewStatus = StyleAssetStatusTypes.ARCHIVED;

                if (e === CaptureEventType.UNARCHIVE) {
                    action = `Restore`;
                    viewStatus = StyleAssetStatusTypes.ACTIVE;
                }

                openConfirmModal({
                    title: `${action} ${arr.length} ${utils.pluralize('Asset', arr.length)}`,
                    centered: false,
                    children: (
                        <Stack pb={'md'}>
                            <Text size='sm'>
                                Are you sure you wish to {action.toLowerCase()} the following {utils.pluralize('asset', items.length)}?
                            </Text>
                            <Group spacing={'xs'}>
                                {arr.map((val) => {
                                    return <ResolverImage src={val.thumb} width={50} height={50} />;
                                })}
                            </Group>
                        </Stack>
                    ),
                    labels: { confirm: `Yes ${action}`, cancel: 'No thanks' },
                    confirmProps: { color: 'theme' },
                    onCancel: () => console.log('Cancel'),
                    onConfirm: () => archiveAssets(arr, viewStatus),
                });
                break;
            }

            default: {
                break;
            }
        }
    };

    // Set the stylecodes
    useEffect(() => {
        if (style && style.samples) {
            const arr = style.samples.map((val) => {
                return {
                    // label: `${val.stylenumber} - ${val.name}`,
                    label: `${val.stylenumber}`,
                    value: val.stylenumber,
                };
            });
            setStyleCodes([...arr]);
        }
    }, [style?.samples?.length, style]);

    /**
     * Handles the list toggle display
     * @param {*} val
     */
    const listToggleHandler = (val) => {
        navigate({ search: `?tab=media&displayType=${val}` });
        // setListView(val);
        const prefs = { ...displayPrefs, mediaDisplayType: val };
        dispatch(setDisplayPrefs(prefs));
    };

    useEffect(() => {
        if (displayPrefs && displayPrefs.mediaDisplayType && displayPrefs.mediaDisplayType !== listView) {
            setListView(displayPrefs.mediaDisplayType);
        }
    }, [displayPrefs, listView]);

    /**
     * The select all menu component part of the checkbox dropdown
     */
    const selectAllMenu = (
        <div>
            {/* <Button variant='default' leftIcon={<IconDownload size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.DOWNLOAD, { data: null }, selectedItems)}>
                Download
            </Button> */}
            <Button variant='default' leftIcon={<IconArchive size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.ARCHIVE, { data: null }, selectedItems)}>
                Archive Assets
            </Button>
            <Button variant='default' leftIcon={<IconArchiveOff size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.UNARCHIVE, { data: null }, selectedItems)}>
                Unarchive Assets
            </Button>
            <Button variant='default' leftIcon={<IconClearAll size={14} />} onClick={(e) => setSelectedItems([])}>
                Deselect All
            </Button>
            {/* <Button variant='default' leftIcon={<IconSubtask size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.CREATE_TASK, { data: null }, selectedItems)}>
                Create Task
            </Button> */}
            {/* <Button variant='default' leftIcon={<IconColorSwatch size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.APPLY_STYLE_INFO, { data: null }, selectedItems)}>
                Apply Styles
            </Button> */}

            {/* <Divider mt={'sm'} /> */}
            {/* <Group>
      <Title order={6} color={'dimmed'}>Rating</Title>
      <Divider />
    </Group> */}
            {/* <Group px={'xs'}>
                <FormInputWrapper label='Rate' id='input-rating' labelWidth={60} style={{ marginTop: 12, marginBottom: 0 }}>
                    <StarRating size={'sm'} onChange={(val) => onMenuItemClick(CaptureEventType.RATING_CHANGE, { data: val }, selectedItems)} />
                </FormInputWrapper>
            </Group>

            <Group px={'xs'}>
                <FormInputWrapper label='Flag' id='input-rating' labelWidth={60} style={{ marginTop: 12, marginBottom: 0 }}>
                    <FlagToggle size={'sm'} onChange={(val) => onMenuItemClick(CaptureEventType.STATUS_CHANGE, { data: val }, selectedItems)} />
                </FormInputWrapper>
            </Group> */}
        </div>
    );

    const onItemClick = (val) => {
        logger.log('ON ITEM CLICK');
        showImageModal(val);
    };

    /**
     * Handler for when a capture is selected.
     * @param {*} item
     * @param {*} selected
     */
    const onItemSelected = (e, item, selected) => {
        var items = [...selectedItems];

        const pageItems = page.map((val) => {
            return { ...val.original };
        });

        if (item.type && item.type === 'group') {
            // select all the sub items.
            let subItems = [...item.subRows];
            if (selected) {
                subItems.forEach((val) => {
                    selectItems(e, val, selected, pageItems, items);
                });
            } else {
                items = _.differenceBy(items, subItems, 'id');
            }

            // if(selected){
            //     items = items.concat(subItems);
            // }else{
            //     items = items.filter(val => !subItems.find(item => item.id === val.id))
            // }
        } else {
            // call general helper to select items
            selectItems(e, item, selected, pageItems, items);
        }

        setSelectedItems(items);
        //dispatch(selectTasks(items));
        //selectTask(items);
    };

    const onSelectAll = () => {
        // table.getToggleAllPageRowsSelectedHandler()
        // if there are items selected remove them
        if (selectedItems.length) {
            setSelectedItems([]);
            // toggleAllRowsSelected(false);
            //setSelectedItems([])
            //dispatch(selectTasks([]));
        } else {
            var elems = [...data];

            // if there is a group by we need to grab the subrows
            if (mediaFilter.groupBy && mediaFilter.groupBy.length) {
                elems = _.flatMap(data, 'subRows');
            }

            setSelectedItems([...elems]);
            // toggleAllRowsSelected(true);
            //setSelectedItems([...elements])
            //dispatch(selectTasks([...data]));
        }
    };

    /**
     * Handler for the Capture event types
     * @param {*} type
     * @param {*} mediaData
     * @param {*} originalEvent
     */
    const onImageEvent = (type, mediaData, originalEvent) => {
        logger.log(type, mediaData);
        var items;

        switch (type) {
            case CaptureEventType.IMAGE_CLICK: {
                // if we already have one item
                // if control or shift is not held we set it to a single item
                if (originalEvent.metaKey) {
                    // if command held down we add the item to the array
                    // if it's not yet selected.
                    const obj = selectedItems.find((val) => val.id === mediaData.id);
                    // if the object is already selected then we unselect it
                    if (obj) {
                        //items  = [...selectedItems];
                        items = _.remove([...selectedItems], (val) => {
                            return val.id !== mediaData.id;
                        });
                    } else {
                        items = [...selectedItems, mediaData];
                    }
                } else if (originalEvent.shiftKey) {
                    // if shift key we push all items from the last selected item
                    // get the index of the last element selected.
                    let lastObj = selectedItems ? selectedItems[selectedItems.length - 1] : null;
                    if (lastObj) {
                        let lastIdx = _.findIndex(style.assets, lastObj);
                        let currIdx = _.findIndex(style.assets, mediaData);
                        let newItems = [...data.slice(lastIdx + 1, currIdx + 1)];
                        items = _.concat(selectedItems, newItems);
                    }
                } else {
                    items = [mediaData];
                    setSelectedItem({ ...mediaData });

                    // searchParams.set('captureId',data.captureId);
                    // setSearchParams(searchParams);
                }

                setSelectedItems(items);

                break;
            }
            case CaptureEventType.IMAGE_DBL_CLICK: {
                // take user to captures
                setSelectedItem(mediaData);

                onItemClick(mediaData);
                // navigate(`/approval?styleId=${data.styleId}&captureId=${data.id}&layoutType=${ApprovalLayoutTypes.SINGLE}`);

                break;
            }

            default: {
                break;
            }
        }
    };

    const setFilterVars = (vals) => {
        logger.log(vals, ' FILTER VALS');
        setMediaFilter(vals);
    };

    const filterButtonHandler = (e) => {
        setFilterOpen((o) => !o);
    };

    const onCaptureChange = (type, data, items) => {
        logger.log(type, data, items);
        switch (type) {
            // case CaptureEventType.RATING_CHANGE: {
            //     const tmpItems = items.map((val) => {
            //         return {
            //             star: data.data,
            //             actionPart: val.actionPart,
            //             actionSort: val.actionSort,
            //         };
            //     });
            //     // dispatch(setSessionCaptures(tmpItems, user));
            //     break;
            // }
            // case CaptureEventType.STATUS_CHANGE: {
            //     const tmpItems = items.map((val) => {
            //         return {
            //             sak: data.data,
            //             actionPart: val.actionPart,
            //             actionSort: val.actionSort,
            //         };
            //     });
            //     // dispatch(setSessionCaptures(tmpItems, user));
            //     break;
            // }
            case CaptureEventType.DOWNLOAD: {
                // find all the items
                handleZipDownload(items, 'originalFilename', style.id, 'previewHi');
                break;
            }
            default: {
                break;
            }
        }
    };

    /**
     * Handler for the clear filter click.
     * Clears the media filter
     * @param {*} e
     */
    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();

        setMediaFilter({ ...new StyleAssetFilterModel() });
    };

    /**
     * Media capture menu handler
     * @param {*} e
     * @param {*} data
     */
    const onMenuClick = (e, data) => {
        // we want to store the previous nav state so we can return if we need to.
        const tmpLoc = `${location.pathname}/${location.search}`;
        setPreviousRoute(tmpLoc);

        switch (e.type) {
            case CaptureEventType.SHOW_IN_CAPTURE_COMMENTS: {
                navigate(`/approval?styleId=${data.styleId}&captureId=${data.id}&layoutType=${ApprovalLayoutTypes.COMMENT}`);
                break;
            }
            case CaptureEventType.SHOW_IN_CAPTURE: {
                navigate(`/approval?styleId=${style.id}&layoutType=${ApprovalLayoutTypes.GRID}`);
                break;
            }
            case CaptureEventType.DOWNLOAD: {
                handleZipDownload([data], 'originalFilename', data.originalFilename, 'previewHi');
                break;
            }
            case CaptureEventType.CREATE_TASK: {
                onMenuItemClick(CaptureEventType.CREATE_TASK, { data: null }, [data]);
                break;
            }
            case CaptureEventType.APPLY_STYLE_INFO: {
                onMenuItemClick(CaptureEventType.APPLY_STYLE_INFO, { data: null }, [data]);
                break;
            }
            case CaptureEventType.UNARCHIVE:
            case CaptureEventType.ARCHIVE: {
                onMenuItemClick(e.type, { data: null }, [data]);
                break;
            }
            default: {
                break;
            }
        }
    };

    const showImageModal = (data) => {
        setSelectedItem(data);

        setImageModalOpen(true);

        logger.log(data, 'show modal');
    };

    const onPageChange = (idx) => {
        gotoPage(idx);
        setSessionsPageIndex(idx);
    };

    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        <div style={{ height: '100%' }}>
            <Box px='xl' sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} pt='xl' pb={'sm'} mb={'sm'}>
                    <Group>
                        <CheckboxDropdown selectedItems={selectedItems} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />

                        <TextInput
                            icon={<IconSearch />}
                            sx={{ width: 250 }}
                            value={mediaFilter.keyword}
                            onChange={(e) =>
                                setMediaFilter({
                                    ...mediaFilter,
                                    keyword: e.currentTarget.value,
                                })
                            }
                            placeholder={`Search across ${data.length} Media`}
                            rightSection={<ActionIcon onClick={() => setMediaFilter({ ...mediaFilter, keyword: '' })}>{mediaFilter.keyword.length ? <IconX size={14} /> : null}</ActionIcon>}
                        />

                        <Text size='sm'>
                            Total{' '}
                            <strong>
                                {data.length} of {style?.assets?.length}
                            </strong>{' '}
                        </Text>
                    </Group>

                    <Group>
                        <Checkbox
                            label='Include Archived'
                            value={mediaFilter.viewstatus}
                            onChange={(event) => {
                                setFilterVars({ ...mediaFilter, viewstatus: event.target.checked ? [] : [StyleAssetStatusTypes.ACTIVE, undefined, ''] });
                            }}
                        />
                        <Popover shadow='sm' opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} position='bottom' withArrow closeOnClickOutside={true}>
                            <Popover.Target>
                                <div>
                                    <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div style={{ display: 'flex' }}>
                                    <AssetFilterPanel
                                        filter={mediaFilter}
                                        onCancel={() => setFilterOpen(false)}
                                        onApply={(vals) => {
                                            setFilterVars(vals);
                                            setFilterOpen(false);
                                        }}
                                        filterItems={filterItems}
                                        styleCodes={styleCodes}
                                    />
                                </div>
                            </Popover.Dropdown>
                        </Popover>
                        <Select
                            placeholder='Group By'
                            value={mediaFilter.groupBy}
                            onChange={(val) =>
                                setMediaFilter({
                                    ...mediaFilter,
                                    groupBy: val,
                                })
                            }
                            data={[
                                { value: '', label: 'No Group' },
                                {
                                    value: 'guideID',
                                    label: 'Group by Guide',
                                },
                                {
                                    value: 'catId',
                                    label: 'Group by Guide Category',
                                },
                                {
                                    value: 'seasonCode',
                                    label: 'Group by Season',
                                },
                                {
                                    value: 'sessionCode',
                                    label: 'Group by Session',
                                },
                                {
                                    value: 'viewstatus',
                                    label: 'Group by View Status',
                                },
                            ]}
                        />
                        {/* <Select
              placeholder="Filter By All"
              data={[
                { value: '', label: 'Show All' },
                { value: 'sku', label: 'Filter by SKU' },
                { value: 'location', label: 'Filter by Location' },
                { value: 'status', label: 'Filter by Status' },
              ]}
            /> */}

                        {/* <Select
              placeholder="Sort By"
              value={sorting}
              onChange={(e) => changeSorting(e,true)}
              data={
                columns.map(val => {
                  return { value: val.accessor, label: val.Header }
                })
                }/> */}

                        {/* <Button variant='light' onClick={() => onMenuClick({ type: CaptureEventType.SHOW_IN_CAPTURE }, { styleId: style.id })}>
                            Open Session in Captures
                        </Button> */}

                        <ListToggleControl value={listView} onChange={(val) => listToggleHandler(val)} />

                        {/* <ListToggleControl value={listView} onChange={(val) => listToggleHandler(val)}/> */}
                    </Group>
                </Group>

                {listView === ListViewOption.LIST ? (
                    <ScrollArea
                        sx={(theme) => ({
                            height: '100%',
                            marginRight: -theme.spacing.xl,
                            paddingRight: theme.spacing.xl,
                        })}
                        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
                        <Table size='sm' {...getTableProps()}>
                            <thead className={cx('scrollTableHeader', { ['scrolled']: scrolled })}>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => {
                                            let colwidth = 'auto';
                                            let padding = '7px 10px';
                                            if ((mediaFilter?.groupBy?.length === 0 && column.id === 'expander') || (!mediaFilter?.groupBy && column.id === 'expander')) {
                                                colwidth = 0;
                                                padding = 0;
                                            } else if (mediaFilter?.groupBy?.length && column.id === 'expander') {
                                                colwidth = column.width;
                                            } else if (column.width) {
                                                colwidth = column.width;
                                            }

                                            return (
                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: colwidth, maxWidth: colwidth, padding: padding }}>
                                                    <Group spacing={'xs'} position='apart'>
                                                        <Text size='sm'>{column.render('Header')}</Text>
                                                        {/* Add a sort direction indicator */}
                                                        {column.id !== 'expander' ? (
                                                            <Group spacing={0}>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                            <IconChevronDown size={16} />
                                                                        </ActionIcon>
                                                                    ) : (
                                                                        <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                            <IconChevronUp size={16} />
                                                                        </ActionIcon>
                                                                    )
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Group>
                                                        ) : null}
                                                    </Group>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>

                            {showLoading ? (
                                <tbody>
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <tr key={i}>
                                            {/* {columns.map((val, idx) => <td key={idx} colSpan={idx === 0 ? 2 : 1}>
                        <Skeleton height={8} my='lg' />
                      </td>)} */}
                                            <td colSpan={2}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                            <td colSpan={1}>
                                                <Skeleton height={8} my='lg' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    {page.map((row, i) => {
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    let renderCell;
                                                    let checked = selectedItems.find((val) => val.id === row.original.id) ? true : false;
                                                    let guideInfo = utils.getGuideInfo(row.original.guideID, row.original.catId, guides);

                                                    let padding = '7px 10px';
                                                    if ((mediaFilter?.groupBy?.length === 0 && cell.column.id === 'expander') || (!mediaFilter?.groupBy && cell.column.id === 'expander')) {
                                                        padding = 0;
                                                    }

                                                    switch (cell.column.id) {
                                                        case 'expander': {
                                                            renderCell = <div>{cell.render('Cell')}</div>;
                                                            break;
                                                        }
                                                        case 'styleCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: '250px' }}>
                                                                    {row.canExpand ? (
                                                                        <Group>
                                                                            <Text size='sm' lineClamp={1}>
                                                                                {row.original.name}
                                                                            </Text>
                                                                            <Badge>{row.original.subRows.length}</Badge>
                                                                        </Group>
                                                                    ) : (
                                                                        <Group sx={{ flexShrink: 0 }}>
                                                                            <Checkbox checked={checked} onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox>
                                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}
                                                                            <Group onClick={() => onItemClick(row.original, rows)}>
                                                                                <ResolverImage src={row.original.thumb} width={50} height={50} />
                                                                                {/* <Image radius='sm' width={50} height={50} src={row.original.thumb} /> */}
                                                                            </Group>
                                                                            <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original, rows)}>
                                                                                <Anchor size='sm'>
                                                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '150px' }}>
                                                                                        {row.original.originalFileName}
                                                                                    </Text>
                                                                                </Anchor>
                                                                                <Text size='xs' color='dimmed'>
                                                                                    {row.original.actionSort}
                                                                                </Text>
                                                                            </Box>
                                                                        </Group>
                                                                    )}
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'tags': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: 200 }} spacing={'xs'}>
                                                                    {!row.canExpand ? <Text color={'dimmed'}>{row.original.tags}</Text> : null}
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'status': {
                                                            renderCell = !row.canExpand ? <Badge color='teal'>{cell.render('Cell')}</Badge> : null;
                                                            break;
                                                        }

                                                        case 'viewstatus': {
                                                            if (!row.canExpand) {
                                                                renderCell = (
                                                                    <Badge color={row.original.viewstatus === StyleAssetStatusTypes.ARCHIVED ? 'gray' : 'teal'}>
                                                                        {row.original.viewstatus && row.original.viewstatus.length ? cell.render('Cell') : 'ACTIVE'}
                                                                    </Badge>
                                                                );
                                                            } else {
                                                                renderCell = null;
                                                            }
                                                            // renderCell = !row.canExpand ? <Badge color='teal'>{cell.render('Cell')}</Badge> : null;
                                                            break;
                                                        }

                                                        case 'guideID': {
                                                            renderCell = (
                                                                <div style={{ maxWidth: 100 }}>
                                                                    <Tooltip label={`${guideInfo?.guide?.name}`}>
                                                                        <Anchor
                                                                            size='sm'
                                                                            onClick={() => {
                                                                                const tmpLoc = `${location.pathname}/${location.search}`;
                                                                                setPreviousRoute(tmpLoc);
                                                                                navigate(`/styleguides/${row.original.guideID}`);
                                                                            }}>
                                                                            <Text size='sm' sx={{ minWidth: 150 }}>
                                                                                {row.original.guideID}
                                                                            </Text>
                                                                        </Anchor>
                                                                    </Tooltip>
                                                                </div>
                                                            );
                                                            break;
                                                        }

                                                        case 'catId': {
                                                            renderCell = <Group sx={{ width: 200 }}>{guideInfo.category ? guideInfo.category.name : ''}</Group>;
                                                            break;
                                                        }

                                                        case 'seasonCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: 100 }}>
                                                                    <Anchor
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            const tmpLoc = `${location.pathname}/${location.search}`;
                                                                            setPreviousRoute(tmpLoc);
                                                                            navigate(`/seasons/${cell.value}`);
                                                                        }}>
                                                                        {cell.render('Cell')}
                                                                    </Anchor>
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'sessionCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: 100 }}>
                                                                    <Anchor
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            const tmpLoc = `${location.pathname}/${location.search}`;
                                                                            setPreviousRoute(tmpLoc);
                                                                            navigate(`/sessions/${cell.value}`);
                                                                        }}>
                                                                        {cell.render('Cell')}
                                                                    </Anchor>
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'completed': {
                                                            renderCell = (
                                                                <Group sx={{ width: 120 }}>
                                                                    {!row.canExpand ? (
                                                                        <Text size='sm'>
                                                                            <Moment date={row.original.completed} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY' trim />
                                                                        </Text>
                                                                    ) : null}
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'action': {
                                                            if (!row.canExpand) {
                                                                renderCell = (
                                                                    <Group
                                                                        sx={{
                                                                            width: 40,
                                                                            alignContent: 'center',
                                                                            justifyContent: 'center',
                                                                        }}>
                                                                        <MediaItemMenu item={row.original} onMenuItemClick={(e) => onMenuClick(e, row.original)} />
                                                                    </Group>
                                                                );
                                                            }

                                                            break;
                                                        }

                                                        default: {
                                                            renderCell = (
                                                                <>
                                                                    <Text lineClamp={2} size='sm'>
                                                                        {cell.render('Cell')}
                                                                    </Text>
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <td {...cell.getCellProps()} className={checked ? 'cellChecked' : ''} style={{ padding }}>
                                                            {renderCell}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Table>

                        {!showLoading && page.length === 0 ? <EmptyState /> : null}
                    </ScrollArea>
                ) : (
                    <Group sx={{ height: '100%', marginLeft: -20, marginRight: -20 }}>
                        <StyleMediaGridLayout
                            guides={guides}
                            filter={mediaFilter}
                            captures={page}
                            selectedItems={selectedItems}
                            onImageEvent={onImageEvent}
                            onCaptureChange={onCaptureChange}
                            onGroupSelect={onItemSelected}
                            onItemMenuClick={(e) => {
                                logger.log(e, ' MENU ITEM CLICK');
                                onMenuItemClick(e.type, { data: null }, [e.asset]);
                            }}
                        />
                    </Group>
                )}

                {/* Footer */}
                <Paper shadow='sm'>
                    <Group className={'displayFooter'}>
                        <Pagination size={'sm'} total={pageOptions.length} withEdges page={pageIndex + 1} onChange={(idx) => onPageChange(idx - 1)} />
                        <Group>
                            <Text size='sm'>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </Text>

                            <NativeSelect
                                size='sm'
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                data={PaginationValues}></NativeSelect>
                        </Group>
                    </Group>
                </Paper>
            </Box>

            <Modal opened={imageModalOpen} size={'lg'} title={selectedItem.originalFileName} trapFocus={true} fullScreen onClose={() => setImageModalOpen(false)}>
                <CaptureDetail
                    showSettings={false}
                    resoloveImage={true}
                    image={selectedItem}
                    src={selectedItem.preview}
                    onImageEvent={() => logger.log('On image event')}
                    onCaptureChange={onCaptureChange}
                />
            </Modal>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        style: state.styleReducer.selectedStyle,
        guides: state.guideReducer.guides,
        displayPrefs: state.styleReducer.displayPrefs,
        // mediaFilter: state.styleReducer.mediaFilter,
        success: state.styleReducer.success,
        error: state.styleReducer.error,
    };
};

const mapDispatch = { setPreviousRoute };

export default connect(mapStateToProps, mapDispatch)(StyleTabMedia);

function MediaItemMenu(props) {
    return (
        <Menu size='lg' position='bottom-end'>
            <Menu.Target>
                <ActionIcon>
                    <IconDots />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                {props.item.viewstatus !== StyleAssetStatusTypes.ARCHIVED ? (
                    <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.ARCHIVE })} icon={<IconArchive size={14} />}>
                        Archive
                    </Menu.Item>
                ) : (
                    <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.UNARCHIVE })} icon={<IconArchiveOff size={14} />}>
                        Unarchive
                    </Menu.Item>
                )}

                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.DOWNLOAD })} icon={<IconDownload size={14} />}>
                    Download
                </Menu.Item> */}
                {/* <Menu.Item
                    onClick={() =>
                        props.onMenuItemClick({
                            type: CaptureEventType.SHOW_IN_CAPTURE_COMMENTS,
                        })
                    }
                    icon={<IconCamera size={14} />}>
                    View in Captures
                </Menu.Item> */}
                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.CREATE_TASK })} icon={<IconSubtask size={14} />}>
                    Create Task
                </Menu.Item> */}
                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.APPLY_STYLE_INFO })} icon={<IconColorSwatch size={14} />}>
                    Apply Styles
                </Menu.Item> */}
            </Menu.Dropdown>
        </Menu>
    );
}

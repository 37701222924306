// import BudgetProgress, { ProgressType } from 'components/budgetProgress/budgetProgress';
import { Box, Group, Text } from '@mantine/core';
import React from 'react';

const FormInputWrapper = ({children, required, label, description, id,  labelWidth, style={marginTop:12,marginBottom:12}}) => {
   
    const lw = labelWidth?labelWidth:'20%';
    return (
        <Box style={style}>
            <div className="form-group row" style={{    
                display:'flex',
                gap:'20px'
            }}>
                <label className="col-form-label" style={{width:lw,flexShrink:0}}>
                    <Group sx={{gap:4, marginTop:4}}>
                        <Text size='sm' mb='xs' weight='500'>{label}</Text>
                        {required?<Text size='sm' color={'red'} sx={{marginBottom:12}}>*</Text>:null}
                    </Group>
                    <Text size='xs' color="dimmed">{description}</Text>
                </label>
                <div style={{flexGrow:1}}>
                    {children}
                </div>
            </div>
        </Box>
    )
}

export default FormInputWrapper;

import { createStyles, TextInput } from '@mantine/core';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  container: {
  },
  inlineEdit:{
    backgroundColor: 'transparent',
    border: 0,
    padding: '0px',
    div:{
      backgroundColor:'transparent',
    },
    input:{
      backgroundColor:'transparent',
      border:`1px solid transparent`,
      '&:focus':{  
        backgroundColor:theme.colorScheme === 'dark'?theme.colors.dark[9]:'white',
      }
    },
    '&:hover':{
      div:{
        backgroundColor:theme.colorScheme === 'dark'?theme.colors.dark[9]:'white',
      },
      input:{
        border:`1px solid ${theme.colorScheme === 'dark'?theme.colors.dark[4]:theme.colors.gray[3]}`
      }
    },
    
  }
  ,proxy:{
    display:'inline-block',
    marginLeft:`${theme.spacing.sm}px`,
    marginRight:`${theme.spacing.sm}px`,
    height:'1px',
    overflow:'hidden',
    borderBottom:`1px dashed ${theme.colors.brand[3]}`,
    position:'absolute',
    bottom:'0px',
  }
}));


const InlineTextEdit = ({ value, setValue }) => {
  const [editingValue, setEditingValue] = useState(value);
  const { classes } = useStyles();

  
  const onChange = (event) => setEditingValue(event.target.value);
  
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  }
  
  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value)
    }
  }

  return (
    <div style={{position:'relative'}}>
    <TextInput className={classes.inlineEdit}
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
    <span className={classes.proxy}>{editingValue}</span>
    </div>
  );
};

export default InlineTextEdit;
import { v4 as uuidv4 } from 'uuid';

export class SessionModel{

    constructor (obj){

        this.actionPart = "";
        this.actionSort = "";
        this.id = uuidv4();
        this.sessionId = "";
        this.name = "";
        this.code = "";
        this.description = "";
        this.channel = "";
        this.client = "";
        this.shootDate = null;
        this.deadline = null;
        this.tags = [];
        this.guides = [];
        this.samples = [];
        this.team = [];
        if (obj) {
            Object.assign(this, obj);
        }
    }
    
}

export const SessionEventType = {
    SESSION_EDIT: 'sessionEdit',
    SESSION_ADD: 'sessionAdd',
    SESSION_VIEW: 'sessionView',
}



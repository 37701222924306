import { Button, Divider, Group, Input, NumberInput, Select, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { FormInputWrapper } from 'core/components/';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppConstants, SampleGrades } from 'utils/constants';

import { logger } from 'utils/logger';

const SampleEdit = ({ sample, onCancel, onSubmit }) => {
    const [active, handlers] = useDisclosure(false);
    const [isSaving, setIsSaving] = useState(false);
    // const theme = useMantineTheme();
    // const [files, setFiles] = useState([]);
    // const dispatch = useDispatch();

    const [seasonData, setSeasonData] = useState([
        { value: 'winter', label: 'Winter' },
        { value: 'spring', label: 'Spring' },
    ]);
    /*
    const previews = files.map((file, index) => {
      return (
        <Group key={index} position='apart' mt={'lg'} mb={'sm'} >
            <Group spacing={'xs'}>
                <ThemeIcon radius="xl">
                    <IconFile size={16} />
                </ThemeIcon>

                <Text size={'sm'}>{file.name}</Text>
            </Group>
            <Button variant='subtle' onClick={() => setFiles([])}>Remove</Button>
        </Group>
      );
    }); */

    const form = useForm({
        initialValues: {
            ...sample,
            sampleCount: sample.sampleCount && sample.sampleCount.length?parseInt(sample.sampleCount):0,
            photoCode: sample.instructions['Web Photography Code'],
            otbDate: moment(sample.instructions['Web Stock OTB Date']).toDate(),
            sampleGrade:sample.sampleGrade??SampleGrades[0].value,
        },

        validate: {
            photoCode: (value) => (value.length ? null : 'Invalid code'),
        },
    });

    useEffect(() => {
        logger.log(active, form);
        if (!active) {
            logger.log('toggling focus ');
            setTimeout(() => {
                handlers.toggle();
            }, 1000);
        }
    }, [active, handlers]);

    //   useEffect(() => {

    //     // Specify how to clean up after this effect:
    //     return function cleanup() {
    //       logger.log('cleanup');
    //       if(active)handlers.toggle();
    //     };
    //   });

    /**
     * Function to submit values to create a new sample
     * @param {*} values
     */
    const formHandler = (values) => {
        // dispatch(updateSampl(values))
        setIsSaving(true);
        onSubmit(values);
    };

    return (
        <>
            <form onSubmit={form.onSubmit((values) => formHandler(values))}>
                <FormInputWrapper label='Photo Code' required labelWidth={180}>
                    <Input id='photo-code' required placeholder='Enter Photo Code' variant={AppConstants.inputVariant} {...form.getInputProps('photoCode')} />
                </FormInputWrapper>
                <FormInputWrapper label='Style' labelWidth={180}>
                    <Input id='input-style' disabled variant={AppConstants.inputVariant} {...form.getInputProps('seasonSort')} />
                </FormInputWrapper>
                <FormInputWrapper label='Name' labelWidth={180}>
                    <Input id='input-name' disabled placeholder='Enter Name' variant={AppConstants.inputVariant} {...form.getInputProps('instructions.Name')} />
                </FormInputWrapper>
                <Divider />
                <FormInputWrapper label='Sample Count' labelWidth={180}>
                    <NumberInput defaultValue={0} sx={{ width: 200 }} id='input-count' placeholder='Enter Sample Count' variant={AppConstants.inputVariant} {...form.getInputProps('sampleCount')} />
                </FormInputWrapper>
                <FormInputWrapper label='Sample Grade' id='input-grade' labelWidth={180}>
                <Select sx={{ width: 200 }}
                    data={SampleGrades}
                    placeholder="Select Grade"
                    nothingFound="Nothing found"
                    {...form.getInputProps('sampleGrade')}
                  />
                </FormInputWrapper>  
                <FormInputWrapper label='Sample Notes' labelWidth={180}>
                    <Textarea id='input-sampleNotes' placeholder='' variant={AppConstants.inputVariant} {...form.getInputProps('sampleNotes')} />
                </FormInputWrapper>
                
                <Divider />
                <FormInputWrapper label='Notes' description="Enter any Web Photography Notes" labelWidth={180}>
                    <Textarea id='input-name' disabled placeholder='' variant={AppConstants.inputVariant} {...form.getInputProps('instructions.Web Photography Notes')} />
                </FormInputWrapper>
                <FormInputWrapper label='Sample Size' labelWidth={180}>
                    <Input
                        id='input-name'
                        sx={{ width: 200 }}
                        disabled
                        placeholder='Enter Sample Size'
                        variant={AppConstants.inputVariant}
                        {...form.getInputProps('instructions.Web Photography Sample Size')}
                    />
                </FormInputWrapper>
                <FormInputWrapper label='OTB Date' labelWidth={180}>
                    {/* <Input id='input-name' sx={{ width: 100 }} disabled placeholder='Enter OTB Date' variant={AppConstants.inputVariant} {...form.getInputProps('instructions.Web Stock OTB Date')} /> */}
                    <Group>
                        <CustomDatePicker disabled sx={{ width: 200 }} placeholder='OTB Date' {...form.getInputProps('otbDate')} />
                    </Group>
                </FormInputWrapper>
                <FormInputWrapper label='Style' labelWidth={180}>
                    <Input id='input-style' disabled placeholder='Enter Style' variant={AppConstants.inputVariant} {...form.getInputProps('instructions.Web Photography Style')} />
                </FormInputWrapper>
                <FormInputWrapper label='Department' labelWidth={180}>
                    <Input id='input-department' disabled placeholder='Enter Department' variant={AppConstants.inputVariant} {...form.getInputProps('instructions.departmentStr')} />
                </FormInputWrapper>

                {/* <FocusTrap active={true}> */}
                {/* <FormInputWrapper label='SKU' id='input-sku'>
                <Input id="input-sku" required placeholder="Enter SKU" data-autofocus variant={AppConstants.inputVariant} />
                </FormInputWrapper>

                <FormInputWrapper label='Name' id='input-sku'>
                <Input id="input-name" required placeholder="Enter Name" variant={AppConstants.inputVariant} />
                </FormInputWrapper>

                <FormInputWrapper label='Description' id='input-desc'>
                <Textarea id='input-desc'
                    placeholder="Description" variant={AppConstants.inputVariant}
                />
                </FormInputWrapper>               

                <FormInputWrapper label='Event' id='input-season'>
                <Select
                    data={seasonData}
                    placeholder="Select Season"
                    nothingFound="Nothing found"
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setSeasonData((current) => [...current, item]);
                      return item;
                    }}
                  />
                </FormInputWrapper>        


                <FormInputWrapper label='Tags' id='input-tags'>
                <Input id='input-tags'
                    placeholder="Tags" variant={AppConstants.inputVariant}
                />
                </FormInputWrapper>          */}
                {/* <FormInputWrapper label='Hero CAD' id='input-desc' description='Upload the CAD file for this sample. CAD file should be of type SVG.'>
                <SampleDropzone></SampleDropzone>
                </FormInputWrapper>       */}
                <Group position='right' mt='md'>
                    <Button type='submit' loading={isSaving}>
                        Update
                    </Button>
                </Group>
                {/* </FocusTrap> */}
            </form>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        // sample: state.warehouse.selectedSample,
        success: state.warehouse.success,
        error: state.warehouse.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(SampleEdit);

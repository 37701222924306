import { v4 as uuidv4 } from 'uuid';

export class CaptureFilterModel{
    // captureId;name;description;url;star;sak;clientTags;selected
    constructor (obj){
        this.keyword = '';
        this.completed = [null,null];
        this.sessionId = '';
        this.machineId = '';
        // this.ratingComparison = '=';
        this.star = -1;
        this.sak = -1;
        this.actionPart = '';
        this.tags = [];
        // this.status = null;
        this.season = [];
        this.sku = [];
        this.location = [];
        // this.unpinned = true;
        this.catalogue = {label : '', value : {since : null,until : null}}

        if (obj) {
            Object.assign(this,obj);
        }
    }
    
}
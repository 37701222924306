import {  Button, Center, createStyles, Group, SegmentedControl, Tooltip } from '@mantine/core';
import { IconMarquee, IconPointer, IconZoomIn, IconZoomOut, IconZoomOutArea } from '@tabler/icons';
import React from 'react';
import { ApprovalLayoutTypes } from 'utils/constants';
import { ApprovalEventTypes } from '../ApprovalModule';
import { logger } from "utils/logger";

const useStyles = createStyles((theme) => ({
    container:{
        padding:'3px 10px',
        // paddingRight:0,
        // background:theme.colorScheme === 'dark'?theme.colors.dark[9]:theme.white,
        // borderRadius:theme.radius.sm,
    },
    col:{
      padding:0
    },
    tabContent:{
      padding:theme.spacing.xs,
      paddingTop:0,
    }
  }));


const ImageControlToolbar = ({settings, settingsChange, onControlsClick, size='md', instanceId='openseadragon'}) =>{

    const { classes } = useStyles();
    // const theme = useMantineTheme();

    logger.log("+++++++++++++++CHANGE",settings)
    
    /*const onLayoutChange = (val) => {
      const tmpSettings = {...settings,layoutType:val};
      settingsChange(ApprovalEventTypes.SETTINGS_CHANGE,tmpSettings);
    }

    const onSliderChange = useCallback((val,settings) => {
      logger.log(val)
      if(val !== settings.thumbnailSize){
        const tmpSettings = {...settings,thumbnailSize:val};
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE,tmpSettings);
      }
      
    },[settings]);*/

    const onCommentClick = (val) => {
      logger.log(val)
      const tmpSettings = {...settings,isCommenting:val==='yes'?true:false};
      settingsChange(ApprovalEventTypes.SETTINGS_CHANGE,tmpSettings);
    }

    return (
        <Group sx={{width:'auto'}} className={classes.container} spacing='xs'>
            <div id='toolbarDiv'></div>
            
            <Button.Group >
              <Tooltip label="Zoom In (+ key)" position='bottom' size={'sm'}>
                <Button id={`${instanceId}-zoom-in`} size='xs' variant="default" ><IconZoomIn size={16} /></Button>
              </Tooltip>
              <Tooltip label="Zoom Out (- key)" position='bottom'>
                <Button id={`${instanceId}-zoom-out`} size='xs' variant="default"> <IconZoomOut size={16} /></Button>
              </Tooltip>
              <Tooltip label="Zoom All (0 key)" position='bottom'>
              <Button id={`${instanceId}-home`} size='xs' variant="default" title='Zoom All'><IconZoomOutArea size={16} /></Button>
              </Tooltip>
              {/* <Tooltip label="Add Comment (hold shift + drag)" position='bottom' onClick={() => onCommentClick()}>
                <Button size='xs' variant="default"> <IconMarquee size={16} /></Button>
              </Tooltip> */}
            </Button.Group>
            
            {settings.layoutType === ApprovalLayoutTypes.COMMENT?
            <SegmentedControl
              value={settings.isCommenting?'yes':'no'}
              onChange={onCommentClick}
              data={[
                  {
                    value: 'no',
                    label: (
                      
                      <Center title='Select mode'>
                        <IconPointer size={16} />
                        {/* <Box ml={10}>Preview</Box> */}
                      </Center>
                      
                    ),
                  },
                  {
                    value: 'yes',
                    label: (
                      <Center title='Add Comment (hold shift + drag)'>  
                        <IconMarquee size={16} />                       
                      </Center>                     
                    ),
                  },
                ]}
              />
          :null}
            
            {/* onClick={() => {logger.log('clicked'); onControlsClick(ApprovalImageToolbarModes.ZOOM_RESET)}}  */}

        </Group>
    )
}

export default ImageControlToolbar;
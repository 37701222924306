import { getToken } from 'utils/helper';
import { logger } from 'utils/logger';

export const logError = async (stack, token) => {
    const url = `${process.env.REACT_APP_API_URL}/audit`;

    const formData = JSON.stringify(stack);

    logger.log(' GETTING SCAN DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('ERROR logging error');
        })
        .then((data) => {
            logger.log(data);
           
            return data;
        })
        .catch((err) => {
            console.error('ERROR logging error', err);
            throw err;
        });
};
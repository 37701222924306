import { useEffect, useRef, useState } from 'react';
import { Group, Text, UnstyledButton, TextInput, Box, ActionIcon, Badge, ThemeIcon, useMantineTheme, createStyles, Code, Indicator, Skeleton, Loader } from '@mantine/core';
import { IconArrowUpRight, IconCalendarEvent, IconRocket, IconSearch, IconX } from '@tabler/icons';
import { closeAllModals } from '@mantine/modals';
import { useForm } from '@mantine/form';
import { logger } from 'utils/logger';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { fetchStyle, getStyleSuccess, setFilter, setPreviousRoute } from 'app/appSlice';
import ResolverImage from '../resolverImage/ResolverImage';
import { getStatusColor, getStyleStatusColor } from 'utils/helper';
import { EmptyState } from '../emptyState/EmptyState';
import StylePopover from '../popovers/StylePopover';
import SeasonPopover from '../popovers/SeasonPopover';
import SessionPopover from '../popovers/SessionPopover';
import Moment from 'react-moment';
import TaskPopover from '../popovers/TaskPopover';
// import type { SpotlightAction } from '@mantine/spotlight';

const useStyles = createStyles((theme, _params, getRef) => ({
    spotlightItem: {
        width: '100%',
        '&:hover': {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
        },
        borderRadius: theme.radius.sm,
    },
}));

const SpotlightForm = ({ style, filter, success, error }) => {
    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState();
    const theme = useMantineTheme();
    const { classes } = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const keywordRef = useRef();

    useEffect(() => {
        if (success && success.type === getStyleSuccess.toString()) {
            setIsLoading(false);
        }

        if (error) {
            setIsLoading(false);
        }
    }, [success, error]);

    useEffect(() => {
        setTimeout(() => {
            keywordRef.current?.select();
        }, 200);
    }, []);

    const form = useForm({
        initialValues: {
            keyword: filter.keyword,
        },

        validate: {
            keyword: (value) => (value.length ? null : 'Enter style'),
        },
    });

    const onFormSubmit = async (values) => {
        // fetch the timeline.
        logger.log(values);

        setIsLoading(true);

        dispatch(setFilter({ keyword: values.keyword }));

        // const timeline = await getStyleTimeline({ id: values.keyword });
        dispatch(fetchStyle({ keyword: values.keyword }));
        // setData(timeline.Timeline);
    };

    const onItemClick = (route) => {
        navigate(route);
        closeAllModals();
    };

    // const styleItem = style && style.styles && style.styles.length ? [style.styles[0]] : null;
    const styleItem = style && style.baseStyle && style.baseStyle.length ? [style.baseStyle[0]] : null;

    const loaderRows = [1, 2].map((val, key) => (
        <Box key={key} className='' pt={'md'}>
            <ItemRenderer />
        </Box>
    ));

    return (
        <div style={{ background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white }}>
            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                <Group
                    noWrap
                    sx={(theme) => ({
                        width: '100%',
                        // subscribe to color scheme changes
                        // height: '34px',
                        // borderRadiusTop: `${theme.radius.md}px`,
                        color: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[6],
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
                        borderBottom: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[4]}`,
                        // borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4],
                        padding: `0px ${theme.spacing.sm / 2}px`,
                        paddingLeft: theme.spacing.md,
                    })}
                    spacing={0}>
                    <IconSearch size={20} color='grey' />
                    <TextInput
                        p={0}
                        sx={(theme) => ({
                            input: {
                                border: 0,
                                background: 'transparent',
                                width: '100%',
                            },
                            background: 'transparent',
                            flex: 1,
                            flexGrow: `1 !important`,
                        })}
                        size='lg'
                        placeholder='Enter Stylenumber'
                        data-autofocus
                        ref={keywordRef}
                        {...form.getInputProps('keyword')}
                    />

                    {isLoading ? (
                        <Loader size={'sm'} variant='dots' mr={'lg'} />
                    ) : (
                        <Code
                            sx={(theme) => ({
                                // subscribe to color scheme changes
                                border: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[3]}` : `1px solid ${theme.colors.gray[2]}`,
                                marginRight: theme.spacing.sm,
                            })}>
                            ↵ Enter
                        </Code>
                    )}

                    {form.isValid() ? (
                        <ActionIcon
                            onClick={(e) => {
                                form.setFieldValue('keyword', '');
                                keywordRef.current?.focus();
                                dispatch(setFilter({ keyword: '' }));
                            }}>
                            <IconX />
                        </ActionIcon>
                    ) : null}
                </Group>
            </form>
            <Box sx={{ maxHeight: style ? '100%' : 0, overflow: 'hidden' }}>
                <Box
                    p={0}
                    pt={'xs'}
                    sx={(theme) => ({
                        borderBottom: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[4]}`,
                    })}>
                    {/* <Table highlightOnHover> */}
                    <Box px={'sm'} pb={'sm'}>
                        {isLoading ? <ItemRenderer /> : null}
                        {!isLoading && style && style.styles && style.styles.length ? (
                            <div>
                                {' '}
                                {styleItem.map((val, idx) => {
                                    return (
                                        <StylePopover key={idx} data={{ ...val, preview:style.styles && style.styles.length?style.styles[0].preview:'', stylePart: val.styleNumber,styleSort:val.styleNumber, assets: style.styles.map((val) => ({ ...val })) }} onShowDetails={closeAllModals}>
                                            <UnstyledButton onClick={() => onItemClick(`warehouse/styles/${val.styleNumber}`)} className={classes.spotlightItem} px={'sm'} py={'md'} key={idx}>
                                                <Group position='apart' noWrap>
                                                    <Group spacing='sm' align='start' noWrap>
                                                        <div style={{ position: 'relative' }}>
                                                            <Indicator inline label={style.styles.length} sx={{ '.mantine-MachineNumber-currentNumber': { lineHeight: `16px !important` } }} size={16}>
                                                                <div style={{ position: 'relative' }}>
                                                                    <ResolverImage width={30} height={30} src={style.styles && style.styles.length ? style.styles[0].thumb : ''} />
                                                                </div>
                                                            </Indicator>
                                                        </div>

                                                        <div>
                                                            <Text size='sm' weight={500}>
                                                                {val.styleNumber}
                                                            </Text>
                                                            <Group>
                                                                <Text size='xs' color='dimmed' truncate style={{maxWidth:280}}>
                                                                    {val.name}
                                                                </Text>
                                                            </Group>
                                                            {val.distributionDate && val.distributionDate.length ? (
                                                                <Group>
                                                                    <Text size='xs' color='dimmed'>
                                                                        Distributed → <Moment date={val.distributionDate} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY' trim /> - {val.issuer}
                                                                    </Text>
                                                                </Group>
                                                            ) : null}

                                                            {/* <Group>
                                                            <Badge color={getStyleStatusColor(val.status)}>{val.status}</Badge>
                                                                <Text size='sm'>
                                                                    <Moment date={val.distributionDate} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY' trim />
                                                                </Text>
                                                            </Group> */}
                                                        </div>
                                                    </Group>
                                                    <Group noWrap>
                                                        <Badge color={getStyleStatusColor(val.status)}>{val.status}</Badge>
                                                        <ThemeIcon variant='light'>
                                                            <IconArrowUpRight size={18} />
                                                        </ThemeIcon>
                                                    </Group>
                                                </Group>
                                            </UnstyledButton>
                                        </StylePopover>
                                    );
                                })}{' '}
                            </div>
                        ) : null}
                        {/* </Table> */}
                        {!isLoading && style && style.styles && style.styles.length === 0 ? (
                            <EmptyState style={{ paddingTop: 10, paddingBottom: 10 }} showImage={false} caption='No style found' />
                        ) : null}
                    </Box>
                </Box>

                <Box p={0} pt={'lg'}>
                    <Group px={'lg'} pb={'xs'}>
                        <Text color='dimmed' transform='uppercase' weight={700} size='xs' className={''}>
                            Events
                        </Text>
                        {/* {isLoading ? null : <Badge>{style?.seasons?.length}</Badge>} */}
                    </Group>
                    {/* <Table highlightOnHover> */}
                    <Box px={'sm'} pb={'sm'}>
                        {isLoading ? loaderRows : null}
                        {!isLoading && style && style.seasons && style.seasons.length ? (
                            <div>
                                {' '}
                                {style.seasons.map((season, idx) => {
                                    return (
                                        <SeasonPopover key={idx} data={{ ...season }} onShowDetails={closeAllModals}>
                                            <UnstyledButton onClick={() => onItemClick(`seasons/${season.seasonPart}`)} className={classes.spotlightItem} px={'sm'} py={'md'} key={idx}>
                                                {/* <td> */}
                                                <Group position='apart'>
                                                    <Group spacing='sm'>
                                                        <ThemeIcon color='blue' size='lg' variant='light'>
                                                            <IconCalendarEvent size={18} strokeWidth={2} />
                                                        </ThemeIcon>

                                                        <div>
                                                            <Text size='sm' weight={500}>
                                                                {season.seasonPart}
                                                            </Text>
                                                            <Text size='xs' color='dimmed'>
                                                                {season.seasonName}
                                                            </Text>
                                                        </div>
                                                    </Group>
                                                    {/* <ThemeIcon variant='light'>
                                                    <IconArrowUpRight size={18} />
                                                </ThemeIcon> */}
                                                </Group>
                                            </UnstyledButton>
                                        </SeasonPopover>
                                    );
                                })}{' '}
                            </div>
                        ) : null}
                        {!isLoading && style && style.seasons && style.seasons.length === 0 ? (
                            <EmptyState style={{ paddingTop: 10, paddingBottom: 10 }} showImage={false} caption='No events found' />
                        ) : null}
                        {/* </Table> */}
                    </Box>
                </Box>

                <Box p={0} pt={'sm'}>
                    <Group px={'lg'} pb={'xs'}>
                        <Text color='dimmed' transform='uppercase' weight={700} size='xs' className={''}>
                            Sessions
                        </Text>
                        {/* {isLoading ? null : <Badge>{style?.sessions?.length}</Badge>} */}
                    </Group>
                    {/* <Table highlightOnHover> */}
                    <Box px={'sm'} pb={'sm'}>
                        {isLoading ? loaderRows : null}
                        {!isLoading && style && style.sessions && style.sessions.length ? (
                            <div>
                                {' '}
                                {style.sessions.map((session, idx) => {
                                    return (
                                        <SessionPopover onShowDetails={closeAllModals} key={idx} data={{ ...session }}>
                                            <UnstyledButton onClick={() => onItemClick(`sessions/${session.ssPart}`)} className={classes.spotlightItem} px={'sm'} py={'md'} key={idx}>
                                                {/* <td> */}
                                                <Group spacing='sm'>
                                                    <ThemeIcon color='grape' size='lg' variant='light'>
                                                        <IconCalendarEvent size={18} strokeWidth={2} />
                                                    </ThemeIcon>
                                                    {/* <Avatar variant='filled' title={`${member.name} - ${member.role}`} color={themeCol} size={30} radius={40}>
                                              {season.}
                                          </Avatar> */}
                                                    <div>
                                                        <Text size='sm' weight={500}>
                                                            {session.ssPart}
                                                        </Text>
                                                        <Text size='xs' color='dimmed'>
                                                            {session.sessionName}
                                                        </Text>
                                                    </div>
                                                </Group>

                                                {/* <Group noWrap spacing={'xs'} sx={{ height: '100%', flexShrink: 1 }}>
                                            <Group noWrap spacing={'xs'}>
                                                {CountDisplay({
                                                    val: parseInt(session['sampleCount']),
                                                    icon: <IconShirt size={18} strokeWidth={1} />,
                                                    tooltip: 'Sample Acquired',
                                                    theme,
                                                    onClick: () => {},
                                                })}
                                                {CountDisplay({
                                                    val: session['photographyCount'],
                                                    icon: <IconCamera size={18} strokeWidth={1} />,
                                                    tooltip: 'Photography Count',
                                                    onClick: () => {},
                                                    theme,
                                                })}
                                            </Group>
                                            <Group noWrap spacing={'xs'}>
                                                {CountDisplay({
                                                    val: session['taskCount'],
                                                    icon: <IconSubtask size={18} strokeWidth={1} />,
                                                    tooltip: 'Total Tasks',
                                                    onClick: () => {},
                                                    theme,
                                                })}
                                                {CountDisplay({ val: session['taskCompleteCount'], icon: <IconChecklist size={18} strokeWidth={1} />, tooltip: 'Tasks Completed', theme })}
                                            </Group>
                                        </Group> */}

                                                {/* </td> */}
                                                {/* <td
                                            style={{
                                                textTransform: 'capitalize',
                                            }}>
                                            {member.role}
                                        </td> */}
                                                {/* <td align='right'><TeamItemMenu onMenuItemClick={(event, data) => onTeamAction(event, member)} /></td> */}
                                            </UnstyledButton>
                                        </SessionPopover>
                                    );
                                })}{' '}
                            </div>
                        ) : null}
                        {!isLoading && style && style.sessions && style.sessions.length === 0 ? (
                            <EmptyState style={{ paddingTop: 10, paddingBottom: 10 }} showImage={false} caption='No sessions found' />
                        ) : null}
                        {/* </Table> */}
                    </Box>
                </Box>


                
                <Box p={0} py={'sm'}>
                    <Group px={'lg'} pb={'xs'}>
                        <Text color='dimmed' transform='uppercase' weight={700} size='xs' className={''}>
                            Tasks
                        </Text>
                        {/* {isLoading ? null : <Badge>{style?.sessions?.length}</Badge>} */}
                    </Group>
                    {/* <Table highlightOnHover> */}
                    <Box px={'sm'} pb={'sm'}>
                        {isLoading ? loaderRows : null}
                        {!isLoading && style && style.tasks && style.tasks.length ? (
                            <div>
                                {' '}
                                {style.tasks.map((task, idx) => {
                                    return (
                                        <TaskPopover onShowDetails={closeAllModals} key={idx} data={{ ...task }}>

                                            <UnstyledButton onClick={() => onItemClick(`/sessions/tasks/${encodeURIComponent(task.taskPart)}_${task.taskSort}`)} className={classes.spotlightItem} px={'sm'} py={'md'} key={idx}>
                                                {/* <td> */}
                                                <Group spacing='sm'>
                                                    <ThemeIcon color='lime' size='lg' variant='light'>
                                                        <IconCalendarEvent size={18} strokeWidth={2} />
                                                    </ThemeIcon>
                                                    {/* <Avatar variant='filled' title={`${member.name} - ${member.role}`} color={themeCol} size={30} radius={40}>
                                              {season.}
                                          </Avatar> */}
                                                    <div>
                                                        <Text size='sm' weight={500}>
                                                            {task.description}
                                                        </Text>
                                                        <Text size='xs' color='dimmed'>
                                                            OTB Date: {task.otbDate}
                                                        </Text>
                                                    </div>
                                                </Group>

                                                {/* <Group noWrap spacing={'xs'} sx={{ height: '100%', flexShrink: 1 }}>
                                            <Group noWrap spacing={'xs'}>
                                                {CountDisplay({
                                                    val: parseInt(session['sampleCount']),
                                                    icon: <IconShirt size={18} strokeWidth={1} />,
                                                    tooltip: 'Sample Acquired',
                                                    theme,
                                                    onClick: () => {},
                                                })}
                                                {CountDisplay({
                                                    val: session['photographyCount'],
                                                    icon: <IconCamera size={18} strokeWidth={1} />,
                                                    tooltip: 'Photography Count',
                                                    onClick: () => {},
                                                    theme,
                                                })}
                                            </Group>
                                            <Group noWrap spacing={'xs'}>
                                                {CountDisplay({
                                                    val: session['taskCount'],
                                                    icon: <IconSubtask size={18} strokeWidth={1} />,
                                                    tooltip: 'Total Tasks',
                                                    onClick: () => {},
                                                    theme,
                                                })}
                                                {CountDisplay({ val: session['taskCompleteCount'], icon: <IconChecklist size={18} strokeWidth={1} />, tooltip: 'Tasks Completed', theme })}
                                            </Group>
                                        </Group> */}

                                                {/* </td> */}
                                                {/* <td
                                            style={{
                                                textTransform: 'capitalize',
                                            }}>
                                            {member.role}
                                        </td> */}
                                                {/* <td align='right'><TeamItemMenu onMenuItemClick={(event, data) => onTeamAction(event, member)} /></td> */}
                                            </UnstyledButton>
                                        </TaskPopover>
                                    );
                                })}{' '}
                            </div>
                        ) : null}
                        {!isLoading && style && style.sessions && style.sessions.length === 0 ? (
                            <EmptyState style={{ paddingTop: 10, paddingBottom: 10 }} showImage={false} caption='No sessions found' />
                        ) : null}
                        {/* </Table> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isLoading: state.appReducer.loading,
    style: state.appReducer.style,
    filter: state.appReducer.filter,
    success: state.appReducer.success,
    error: state.appReducer.error,
});

const mapDispatchToProps = { setPreviousRoute };

export default connect(mapStateToProps, mapDispatchToProps)(SpotlightForm);

const ItemRenderer = () => {
    return (
        <Box py={3} px={'sm'}>
            <Group position='apart'>
                <Group>
                    <Skeleton height={30} radius='sm' width={30} />
                    <Skeleton height={16} radius='xl' width={200} />
                    <Skeleton height={16} radius='sm' width={100} />
                    {/* <Skeleton height={10} radius="xl" width={100} /> */}
                </Group>
            </Group>
        </Box>
    );
};

import { v4 as uuidv4 } from 'uuid';

export class TaskModel{

    constructor (obj){
        // this.id = uuidv4();
        this.id = obj?.taskSort || uuidv4();
        this.deadline = null;
        this.description = '';
        this.type = '';
        this.items = '';
        this.taskPart = "";
        this.taskSort = "";
        this.original='';
        this.user=null;
        this.tags=[];
        this.guideId = obj?.guideId || '';
        this.categoryId = obj?.categoryId || '';
        this.assignee = obj?.assignee || '';
        
        if(obj){
            Object.assign(this, obj);
        }
    }    
}

export const TaskEventType = {
    TASK_EDIT: 'taskEdit',
    TASK_ADD: 'taskAdd',
    TASK_VIEW: 'taskView',
    TASK_DOWNLOAD_EIP: 'taskDownloadEip',   
    FILE_DOWNLOAD_SUCCESS:'taskDownloadFileSuccess',
    TASK_BATCH_EDIT:'taskBatchEdit',
    TASK_ARCHIVE:'taskArchive',
}
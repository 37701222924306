import { GuideEventType } from "core/models/GuideModel";
import { SessionActions } from "utils/constants";

const { Menu, ActionIcon } = require("@mantine/core");
const { IconDots, IconEye, IconEdit, IconDownload, IconTrash } = require("@tabler/icons");

const GuideItemMenu = (props) => {
    return (
      <Menu size='lg' position="bottom-end">
        <Menu.Target>
          <ActionIcon><IconDots /></ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => props.onMenuItemClick({type:GuideEventType.GUIDE_VIEW})} icon={<IconEye size={14} />}>View</Menu.Item>
          <Menu.Item onClick={() => props.onMenuItemClick({type:SessionActions.REMOVE_STYLEGUIDE})} icon={<IconTrash size={14} />}>Unassign</Menu.Item>
          <Menu.Item onClick={() => props.onMenuItemClick({type:GuideEventType.GUIDE_EDIT})} icon={<IconEdit size={14} />}>Edit</Menu.Item>
          <Menu.Item onClick={() => props.onMenuItemClick({type:GuideEventType.GUIDE_DOWNLOAD})} icon={<IconDownload size={14} />}>Download Guide</Menu.Item>
          {/* <Menu.Item onClick={() => props.onMenuItemClick({type:CaptureEventType.SHOW_IN_CAPTURE_COMMENTS})} icon={<IconCamera size={14} />}>View in Captures</Menu.Item> */}
        </Menu.Dropdown>
      </Menu>
    );
  }
  export default GuideItemMenu;
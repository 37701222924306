import { ActionIcon, Anchor, Avatar, Badge, Box, Checkbox, createStyles, Group, NativeSelect, Pagination, Paper, ScrollArea, Skeleton, Table, Text, TextInput } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { logger } from 'utils/logger';
// import { SortableTable } from '../../../../components';
// import CurrencyFormat from 'react-currency-format';
// import { connect } from 'react-redux';

import { useSortBy, useTable, usePagination, useAsyncDebounce, useFilters, useGlobalFilter } from 'react-table';
import { getUserInitials, headerDateSort, sampleAlphanumericFilter } from 'utils/helper';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import moment from 'moment';
import { AppConstants, DataFormats } from 'utils/constants';
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme, _params, getRef) => ({
    displayFooter: {
        position: 'fixed',
        bottom: 0,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        right: theme.spacing.lg,
        left: 240,
        borderTop: '1px solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
    },
}));

const SeasonList = ({ data, onItemClick, onItemSelected, selectedItems, isLoading, manualSortBy, sortChange }) => {
    // const [uirows,setRows] = useState([]);
    const [items, setItems] = useState(selectedItems);

    // const imgURL = 'https://media.istockphoto.com/vectors/vector-illustration-of-jacket-isolated-on-white-background-clothing-vector-id1319338185?b=1&k=20&m=1319338185&s=170667a&w=0&h=6ZYN2RP-2NjtQq3CdkzUtFkj4fK9BLW2lV9PyRClEDw=';

    const columns = React.useMemo(
        () => [
            // {
            //   Header: 'Item',
            //   columns: [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Tags',
                accessor: 'tags',
            },
            {
                Header: 'Style Count',
                accessor: 'styleCount',
            },

            {
                Header: 'Created',
                accessor: 'created',
                sortType: (a, b) => headerDateSort(a, b, 'created'),
            },
            {
                Header: 'Deadline',
                accessor: 'deadline',
            },
            //   ],
            // },
            // {
            // Header: 'Info',
            // columns: [
            // {
            //   Header: 'Team',
            //   accessor: 'team',
            // },

            //   ],
            // },
        ],
        [],
    );

    useEffect(() => {
        logger.log(' ITEMS SELECTED');
        setItems(selectedItems);
    }, [selectedItems, setItems]);

    return (
        <CustomTable
            columns={columns}
            data={data}
            selectedItems={selectedItems}
            onItemClick={onItemClick}
            isLoading={isLoading}
            onItemSelected={onItemSelected}
            manualSortBy={manualSortBy}
            sortChange={sortChange}>
            {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} /> */}
        </CustomTable>
    );
};

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows ? preGlobalFilteredRows.length : 0;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 50);

    return (
        <span>
            <TextInput
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    );
};

const CustomTable = ({ columns, data, selectedItems, onItemClick, onItemSelected, isLoading, manualSortBy, sortChange }) => {
    // Use the state and functions returned from useTable to build your UI

    // const [pagination, setPagination] = useState({
    //   pageIndex: 0,
    //   pageSize: 50,
    // })
    const [scrolled, setScrolled] = useState(false);
    const { classes, cx } = useStyles();

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,

        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        rows,
        // The rest of these things are super handy, too ;)
        // canPreviousPage,
        // canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        setSortBy,
        // preGlobalFilteredRows,
        // setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: AppConstants.defaultPaginationSize,
                globalFilter: null,
                sortBy: [
                    {
                        id: columns[4].accessor,
                        desc: true,
                    },
                ],
            },
            sortTypes: {
                alphanumeric: React.useCallback((row1, row2, columnName) => sampleAlphanumericFilter(row1, row2, columnName, true), []),
            },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    );

    // Render the UI for your table
    // useEffect(() => {
    //     if (manualSortBy && manualSortBy.id !== sortBy.id) {
    //         setSortBy([
    //             {
    //                 id: manualSortBy.id, // Specify the column ID or accessor for sorting
    //                 desc: true, // Set to true for descending order, false for ascending
    //             },
    //         ]);
    //     }
    // }, [manualSortBy]);

    // useEffect(() => {
    //     sortChange(sortBy);
    // }, [sortBy]);

    //   setSortBy(newSortBy);
    return (
        <ScrollArea
            sx={(theme) => ({
                height: '100%',
                // marginRight: -theme.spacing.xl,
                paddingRight: theme.spacing.xl,
                paddingBottom: 20,
            })}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
            {/* <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}/> */}

            <Table size='sm' {...getTableProps()} sx={{ marginBottom: 50 }}>
                <thead className={cx('scrollTableHeader', { ['scrolled']: scrolled })}>
                    {headerGroups.map((headerGroup) => {
                        const { key, ...groupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <tr key={key} {...groupProps}>
                                {headerGroup.headers.map((column, colIdx) => {
                                    const {key, ...colProps} = column.getHeaderProps(column.getSortByToggleProps());
                                    return (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th key={colIdx} {...colProps}>
                                            <Group position='apart'>
                                                <Text size='sm'>{column.render('Header')}</Text>
                                                {/* Add a sort direction indicator */}
                                                <Group>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                <IconChevronDown size={16} />
                                                            </ActionIcon>
                                                        ) : (
                                                            <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                <IconChevronUp size={16} />
                                                            </ActionIcon>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Group>
                                            </Group>
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>

                {isLoading ? (
                    <tbody>
                        {[1, 2, 3, 4, 5].map((i) => (
                            <tr key={i}>
                                <td>
                                    <Skeleton height={8} my='xs' />
                                </td>
                                {/* <td onClick={() => onItemClick(element)}>{element.sampleId}</td> */}
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>

                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                {/* <td> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td> */}
                                {/* <td align='right'> <Skeleton height={8} radius="xl" /></td> */}
                                {/* <td align='center'> <Skeleton height={8} radius="xl" /></td> */}
                                {/* <td align='right'> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td> */}

                                {/* <td></td> */}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const {key, ...rowProps} = row.getRowProps();
                            return (
                                <tr key={i} {...rowProps}>
                                    {row.cells.map((cell) => {
                                        let renderCell;
                                        let checked = selectedItems.find((val) => val.id === row.original.id) ? true : false;
                                        switch (cell.column.id) {
                                            case 'name': {
                                                renderCell = (
                                                    <Group
                                                        sx={{
                                                            minWidth: '200px',
                                                        }}>
                                                        <Group
                                                            sx={{
                                                                flexShrink: 0,
                                                            }}>
                                                            <Checkbox
                                                                checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                                                onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked, rows)}></Checkbox>
                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}
                                                            <Group onClick={() => onItemClick(row.original)}>
                                                                {/* <Image radius='sm'
                        width={50}
                        height={50}
                        src={imgURL}

                      /> */}
                                                            </Group>
                                                            <Box
                                                                sx={{
                                                                    flexShrink: 1,
                                                                }}
                                                                // onClick={() => onItemClick(row.original)}
                                                            >
                                                                <Link className='router-link' size='sm' to={`${row.original.seasonSort}`}>
                                                                    <Text
                                                                        size='sm'
                                                                        lineClamp={1}
                                                                        sx={{
                                                                            maxWidth: '150px',
                                                                        }}>
                                                                        {row.original.name}
                                                                    </Text>
                                                                </Link>
                                                                <Text size='xs' color='dimmed'>
                                                                    {row.original.seasonSort}
                                                                </Text>
                                                            </Box>
                                                        </Group>
                                                    </Group>
                                                );
                                                break;
                                            }
                                            case 'description': {
                                                renderCell = (
                                                    <Text sx={{ minWidth: 100 }} lineClamp={2} size='sm'>
                                                        {cell.render('Cell')}
                                                    </Text>
                                                );
                                                break;
                                            }

                                            case 'team': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        <Group>
                                                            {row.original.team &&
                                                                row.original.team.map((val, idx) => (
                                                                    <Avatar key={idx} size={30} radius={40} color='teal'>
                                                                        {getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1])}
                                                                    </Avatar>
                                                                ))}
                                                        </Group>
                                                    </div>
                                                );
                                                break;
                                            }

                                            case 'deadline': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        <Text
                                                            sx={{
                                                                maxWidth: 500,
                                                            }}
                                                            lineClamp={2}
                                                            size='sm'>
                                                            {moment(row.original[cell.column.id]).format(DataFormats.DATE_FORMAT)}
                                                        </Text>
                                                    </div>
                                                );
                                                break;
                                            }

                                            case 'created': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        <Text
                                                            sx={{
                                                                maxWidth: 500,
                                                            }}
                                                            lineClamp={2}
                                                            size='sm'>
                                                            {moment(row.original[cell.column.id], DataFormats.DATE_FORMAT_CREATE_PARSE).format(DataFormats.DATE_FORMAT)}
                                                        </Text>
                                                    </div>
                                                );
                                                break;
                                            }

                                            case 'tags': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        {row.original.tags.length ? (
                                                            <>
                                                                {row.original.tags.map((val, idx) => (
                                                                    <Badge
                                                                        key={idx}
                                                                        color='violet'
                                                                        sx={{
                                                                            maxWidth: 150,
                                                                        }}>
                                                                        {val}
                                                                    </Badge>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                );
                                                break;
                                            }

                                            default: {
                                                renderCell = (
                                                    <>
                                                        <Text lineClamp={2} size='sm'>
                                                            {cell.render('Cell')}
                                                        </Text>
                                                    </>
                                                );
                                            }
                                        }
                                        const {key, ...cellProps} = cell.getCellProps();
                                        return (
                                            <td key={key} {...cellProps} className={checked ? 'cellChecked' : ''}>
                                                {renderCell}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </Table>

            {!isLoading && data.length === 0 ? <EmptyState /> : null}

            <Paper shadow='sm'>
                <Group className={[classes.displayFooter, 'pagination-footer']} p='sm'>
                    <Pagination size={'sm'} total={pageOptions.length} withEdges page={pageIndex + 1} onChange={(idx) => gotoPage(idx - 1)} />
                    <Group>
                        <Text size='sm'>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </Text>

                        <NativeSelect
                            size='sm'
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                            data={[
                                { value: 20, label: 'Show 20' },
                                { value: 50, label: 'Show 50' },
                                { value: 80, label: 'Show 80' },
                                { value: 100, label: 'Show 100' },
                            ]}></NativeSelect>
                    </Group>
                </Group>
            </Paper>
        </ScrollArea>
    );
};

export default SeasonList;

// function SessionItemMenu() {
//     return (
//       <Menu size='lg'>
//         <Menu.Item icon={<IconEdit size={14} />}>Edit Session</Menu.Item>
//         <Menu.Item icon={<IconShare size={14} />}>Share Session</Menu.Item>
//         <Menu.Item color="red" icon={<IconTrash size={14} />} size={14}>Archive Session</Menu.Item>
//       </Menu>
//     );
//   }

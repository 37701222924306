import { InstructionModel, SeasonModel } from 'core/models';
import { doThrowOnResponse, getToken, parseValue } from 'utils/helper';
import { logger } from 'utils/logger';

///////////////
// SEASONS
///////////////

export const getSeasons = async (criteria) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/`;
    logger.log(' GETTING Captures DATA: ', url);

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                const keys = Object.keys(item);
                var newItem = {};
                keys.forEach((key, idx) => {
                    let tmpKey = key.replace(' ', '');
                    tmpKey = key.replace(':', '_');
                    // lowercase the first.
                    let char = tmpKey.charAt(0).toLowerCase();
                    let subStr = tmpKey.substring(1, tmpKey.length);
                    const newKey = `${char}${subStr}`;

                    const itemKey = Object.keys(item[key]);
                    logger.log(key);
                    newItem[newKey] = item[key][itemKey];
                    // parse team
                });
                //newItem.id = `${newItem.sessionId}|${newItem.sessionSort}`;
                newItem.tags = newItem.tags.length ? newItem.tags.split(',') : [];

                // newItem.team = newItem.team.replace(/[{}]/g,'');
                // newItem.team = newItem.team.split(',').map((val,idx) => {
                //     let arr = val.split('=');
                //     let teamMember = {role:arr[0].trim(),name:arr[1]};
                //     return teamMember;
                // });
                newItem.id = newItem.seasonSort;
                return new SeasonModel(newItem);
            });

            // sort by created date
            return items.sort(function (a, b) {
                var dateA = new Date(a.created);
                var dateB = new Date(b.created);
                return dateB - dateA;
            });
        })
        .catch((err) => {
            logger.error('ERROR getting markups', err);
            throw err;
        });
};

export const getSeason = async (id, criteria) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/${id}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.Items && data.Items.length) {
                const season = createSeasonModel(data.Items[0]);
                return season;
            } else {
                throw new Error("Event doesn't exist");
            }
        })
        .catch((err) => {
            logger.error('ERROR getting markups', err);
            throw err;
        });
};

export const udpateSeason = async (season) => {
    let url = season.id ? `${process.env.REACT_APP_API_URL}/seasons/${season.id}` : `${process.env.REACT_APP_API_URL}/seasons`;
    let tmpData = { ...season };
    delete tmpData.id;

    let formData = JSON.stringify({
        ...tmpData,
    });

    logger.log(' UPDATING SEASON DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            return data;
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR updating event', err);
            throw err;
        });
};

export const removeSeason = async (id, markup, token) => {
    let url = `${process.env.REACT_APP_API_URL}/season/${id}`;
    const formData = JSON.stringify({
        ...markup,
    });

    logger.log(' DELETING NOTE DATA: ', formData);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR updating markup', err);
            throw err;
        });
};

export const getSeasonStyles = async (id, style, signal) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/${id}/records`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
        signal:signal??null,
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((val, idx) => {
                var newItem = { ...val };
                // HGIO-511 - we no longer fetch from dynamo
                // return createSeasonStyleModel(item);
                newItem.id = `${newItem.seasonPart}__${newItem.seasonSort}`;
                newItem.stylenumber = newItem.seasonSort;

                if (newItem.instructions) {
                   newItem.instructions.departmentStr = `${newItem.instructions.Dept} - ${newItem.instructions.department}`;
                }else{
                    newItem.instructions = InstructionModel();
                }

                return newItem;
            });

            return items;
        })
        .catch((err) => {
            if(doThrowOnResponse(err)){
                throw err
            }else{
                // return empty values back to the caller
                return []
            }
        });
};

export const addStyleToSeason = async (season, style) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/${season.id}/style/${style.code}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'PUT',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logger.error('ERROR adding style to event', err);
            throw err;
        });
};

export const deleteStyleFromSeason = async (season, style) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/${season.id}/style/${style.seasonSort}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'DELETE',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logger.error('ERROR remove style', err);
            throw err;
        });
};

export const uploadCSVtoSeason = async (season, type, file) => {
    let url = `${process.env.REACT_APP_API_URL}/seasons/${season.id}/upload`;
    if (type === 'otb') {
        url = `${process.env.REACT_APP_API_URL}/seasons/${season.id}/updates`;
    }

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'hgio-filename': `${file.name}`,
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            if (response.ok) {
                return true;
            }
            throw new Error('ERROR uploading CSV style');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            logger.error('ERROR uploading CSV style', err);
            throw err;
        });
};

/**
 * Create an instance of the Season Model
 * @param {*} item
 * @returns
 */
const createSeasonModel = (item) => {
    const keys = Object.keys(item);
    var newItem = {};
    keys.forEach((key, idx) => {
        let tmpKey = key.replace(' ', '');
        tmpKey = key.replace(':', '_');
        // lowercase the first.
        let char = tmpKey.charAt(0).toLowerCase();
        let subStr = tmpKey.substring(1, tmpKey.length);
        const newKey = `${char}${subStr}`;
        newItem[newKey] = item[key]['S'];
        // parse team
    });

    newItem = parseValue(item);

    //newItem.id = `${newItem.sessionId}|${newItem.sessionSort}`;
    newItem.tags = newItem.tags.length ? newItem.tags.split(',') : [];

    // newItem.team = newItem.team.replace(/[{}]/g,'');
    // newItem.team = newItem.team.split(',').map((val,idx) => {
    //     let arr = val.split('=');
    //     let teamMember = {role:arr[0].trim(),name:arr[1]};
    //     return teamMember;
    // });
    newItem.id = newItem.seasonSort;

    return newItem;
};

const createSeasonStyleModel = (item) => {
    const keys = Object.keys(item);
    var newItem = {};
    if (item.scan) {
        // logger.log(item);
    }

    keys.forEach((key, idx) => {
        let tmpKey = key.replace(' ', '');
        tmpKey = key.replace(':', '_');
        // lowercase the first.
        let char = tmpKey.charAt(0).toLowerCase();
        let subStr = tmpKey.substring(1, tmpKey.length);
        const newKey = `${char}${subStr}`;
        newItem[newKey] = item[key]['S'];

        newItem.id = `${newItem.seasonPart}__${newItem.seasonSort}`;
        newItem.stylenumber = newItem.seasonSort;

        if (newKey === 'instructions') {
            var instructions = new InstructionModel();
            var instObj = item[newKey]['M'];
            const instructKeys = Object.keys(item[newKey]['M']);
            instructKeys.forEach((key2, idx) => {
                // let tmpKey = key2.replace(' ','');
                tmpKey = key2.replace(':', '_');
                // const normalizedKey = key2.replace(/\s/g, "").replace(/^(.)(.*)$/, (match, firstLetter, restOfString) => firstLetter.toLowerCase() + restOfString);
                instructions[key2] = instObj[key2]['S'];
            });
            instructions.departmentStr = `${instructions.Dept} - ${instructions.department}`;
            newItem.instructions = instructions;
        } else if (newKey === 'sampleCount') {
            newItem[newKey] = item[key]['N'];
        } else if (newKey === 'styleCount') {
            newItem[newKey] = item[key]['N'];
        } else if (newKey === 'studioSessionCodes') {
            newItem[newKey] = item[newKey]['L'].map((val) => val.S);
        } else if (newKey === 'scan' && item.scan) {
            newItem[newKey] = parseValue(item[newKey]['M']);
        }
    });
    if (newItem.scan) {
        newItem['styleCount'] = '1';
    }

    return newItem;
};

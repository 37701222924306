/**
 * @file warehouseSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit';
import { getSession } from '../../services/sessionService';
import * as sampleService from '../../services/samplesService';
import * as scanService from '../../services/scanService';
import { logger } from 'utils/logger';
import { dateSortFilter } from 'utils/helper';
import { ScanStatuses } from 'utils/constants';
import { SortByModel } from 'core/models';
/*
let nextSampleId = 0;

const filterSamples = (samples,filter) => {
  return samples.filter(sample => {
    var match = false;
    // filter by brand
    if(filter.brands){
      match = filter.brands.indexOf(sample.facet_brand) > -1?true:false;
    }else{
      match = true;
    }
    logger.log(match, ' MATCH')
    return match;
  })
}
*/

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState: {
    samples: [],
    selectedSample: null,
    selectedSamples: [],

    scans: [],
    selectedScan: null,
    selectedScans: [],

    sampleFilter: { keyword: '', brands: [], categories: [], department: [], location: [], status: [], colour: '', groupBy: '' },
    scanFilter: { keyword: ''
      , brands: []
      , categories: []
      , department: []
      , location: []
      , status: []
      , colour: ''
      , groupBy: ''
      , sortBy:null
      , pageIndex:0 },

    // OUTFITS
    error: null,
    success: null,
    loading: false,
    action: null,
  },
  reducers: {
    clearNotifications(state, action) {
      state.error = null;
      state.success = null;
    },
    selectSample(state, action) {
      logger.log('Setting Sample: ', action.payload);
      state.selectedSample = action.payload ? { ...action.payload } : null;
    },
    selectSamples(state, action) {
      logger.log('setting selected samples', action.payload);
      state.selectedSamples = action.payload ? [...action.payload] : [];
    },

    selectScans(state, action) {
      logger.log('setting selected samples');
      state.selectedScans = action.payload ? [...action.payload] : [];
    },

    setSampleFilter(state, action) {
      logger.log('Setting Sample: ', action.payload);
      state.sampleFilter = action.payload ? { ...action.payload } : null;

      // filter the samples.
      // const samples = filterSamples(state.samples, state.sampleFilter);
    },

    setScanFilter(state, action) {
      logger.log('Setting Scan Filter: ', action.payload);
      state.scanFilter = action.payload ? { ...action.payload } : null;
    },

    //////////////////////
    //
    // SINGLE SAMPLE
    //
    //////////////////////
    getSampleStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getSampleSuccess(state, action) {
      logger.log('getSampleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Sample Success' };
      // if(action.payload.urls){
      //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
      // }
      state.selectedSample = { ...action.payload };
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getSampleFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    updateSampleSuccess(state, action) {
      logger.log('udpateSampleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Update Sample Success' };
      // if(action.payload.urls){
      //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
      // }
      state.selectedSample = { ...action.payload };
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },


    //////////////////////
    //
    // GET SAMPLES
    //
    //////////////////////
    getSamplesStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getSamplesSuccess(state, action) {
      logger.log('getSampleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Samples Success' };

      state.samples = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getSamplesFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    //////////////////////
    //
    // SINGLE SCAN
    //
    //////////////////////
    selectScan(state, action) {
      logger.log('Setting Scan: ', action.payload);
      state.selectedScan = { ...action.payload };
    },
    getScanSuccess(state, action) {
      logger.log('getScanSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Scan Success' };
      state.selectedScan = { ...action.payload };
      // if(action.payload.urls){
      //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
      // }

      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getScanFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    updateScanStart(state, action) {
      state.success = null;
      state.error = null;
    },
    updateScanSuccess(state, action) {
      logger.log('updateScanSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Scan successfully updated' };

      //state.seasons = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    uploadScanCSVSuccess(state, action) {
      logger.log('updateScanSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Scans successully uploaded' };

      //state.seasons = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    updateScanFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    //////////////////////
    //
    // GET SCANS
    //
    //////////////////////

    getScansStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getScansSuccess(state, action) {
      logger.log('getScansSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Scans Success' };

      state.scans = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getScansFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    onServiceStart(state, action) {
      state.success = null;
      state.loading = true;
      state.error = null;
      // state.action = action.type;
    },
    onServiceFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// SAMPLE
export const fetchSample = (id, user) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getSampleStart());
    const data = await sampleService.getSample(id, user);
    dispatch(getSampleSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getSampleFailure(err));
  }
};

// SAMPLES
export const fetchSamples = (criteria, user) => async (dispatch) => {
  try {
    dispatch(getSamplesStart());
    // const data = await getSamples(criteria,user?user.rawIdToken:'')
    const data = await sampleService.getFeed(criteria, user ? user.rawIdToken : '');
    dispatch(getSamplesSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getSamplesFailure(err));
  }
};

// SAMPLE
export const fetchScan = (id, user) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getSampleStart());
    const data = await scanService.getScan(id);
    dispatch(getScanSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getSampleFailure(err));
  }
};

// SAMPLES
export const fetchScans = (criteria, user) => async (dispatch) => {
  try {
    dispatch(getSamplesStart());
    const data = await scanService.getScans(criteria, user ? user.rawIdToken : '');
    dispatch(getScansSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getScansFailure(err));
  }
};

// SCAN UPDATE
export const updateScan = (scan, user) => async (dispatch) => {
  try {
    dispatch(updateScanStart());

    const data = await scanService.udpateScan(scan, user ? user.rawIdToken : '');
    // const uploadurl = await getUploadUrl(data);
    // const uploadData = await uploadSeasonFile(season);

    dispatch(updateScanSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(updateScanFailure(err));
  }
};

// SAMPLE UPDATE
export const updateSample = (season, sample) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    const data = await sampleService.updateSample(season, sample);
    dispatch(updateSampleSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(updateScanFailure(err));
  }
};


export const fetchAllScans = (criteria, user) => async (dispatch) => {
  try {
    dispatch(getSamplesStart());
    const pending = await scanService.getScans({ status: 'pending' }, user ? user.rawIdToken : '');
    const success = await scanService.getScans({ status: 'succeeded' }, user ? user.rawIdToken : '');
    const failed = await scanService.getScans({ status: 'failed' }, user ? user.rawIdToken : '');
    const created = await scanService.getScans({ status: ScanStatuses.CREATED.value }, user ? user.rawIdToken : '');
    const parsed = await scanService.getScans({ status: ScanStatuses.PARSED.value }, user ? user.rawIdToken : '');
    const masterNotFound = await scanService.getScans({ status: ScanStatuses.MASTER_NOT_FOUND.value }, user ? user.rawIdToken : '');
    const altNotFound = await scanService.getScans({ status: ScanStatuses.LOCAL_NOT_FOUND.value }, user ? user.rawIdToken : '');

    const data = [...pending, ...success, ...failed, ...created, ...parsed, ...masterNotFound, ...altNotFound];
    const sortedData = data.sort((a,b) => dateSortFilter(a,b,'created',false));
    dispatch(getScansSuccess(sortedData));
  } catch (err) {
    logger.log('err', err);
    dispatch(getScansFailure(err));
  }
};

// SCAN UPLOAD
export const uploadScanCSV = (files) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    const data = await scanService.uploadCSV( files[0]);
    dispatch(uploadScanCSVSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(onServiceFailure(err));
  }
};

// JOB
export const fetchSession = (id, user) => async (dispatch) => {
  try {
    dispatch(getSessionStart());
    const data = await getSession(id, user ? user.rawIdToken : '');
    dispatch(getSessionSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getSessionFailure(err));
  }
};

export const {
  clearNotifications,

  selectSample,
  selectSamples,
  setSampleFilter,
  setScanFilter,

  getSampleStart,
  getSampleFailure,
  getSampleSuccess,

  getSamplesStart,
  getSamplesFailure,
  getSamplesSuccess,

  updateSampleSuccess,

  selectScan,
  selectScans,
  getScanStart,
  getScanFailure,
  getScanSuccess,

  getScansStart,
  getScansFailure,
  getScansSuccess,

  updateScanStart,
  updateScanFailure,
  updateScanSuccess,

  getSessionStart,
  getSessionFailure,
  getSessionSuccess,

  uploadScanCSVSuccess,

  onServiceStart,
  onServiceFailure,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;

import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as ListWindow } from 'react-window';

import {
    Accordion,
    ActionIcon,
    Alert,
    Anchor,
    Avatar,
    Badge,
    Box,
    Button,
    Center,
    Container,
    createStyles,
    Divider,
    Group,
    Image,
    Indicator,
    Loader,
    Menu,
    NativeSelect,
    Pagination,
    Select,
    Skeleton,
    Stack,
    Table,
    Text,
    Textarea,
    ThemeIcon,
    Title,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormInputWrapper } from 'core/components/';
import { TaskEventType, TaskModel } from 'core/models/TaskModel';
import { updateTask, selectTask, setSuccess, getSampleGuideCategoriesSuccess, fetchSampleGuideCategories, updateTaskSuccess } from 'modules/Sessions/sessionSlice';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { logger } from 'utils/logger';
import { IconAlertCircle, IconCheck, IconChevronDown, IconDownload, IconFile, IconNote, IconUpload, IconX } from '@tabler/icons';
import { bytesToMB, createMembers, getColumnColor, getUserAvatarColour, getUserInitials, handleZipDownload } from 'utils/helper';
import { useMemo } from 'react';
import { DataFormats, statuss, status_backlog, ViewModes, status_progress, TreatmentTypes, status_unassigned } from 'utils/constants';
import { Link } from 'react-router-dom';
import { getSession } from 'services/sessionService';
import { Dropzone } from '@mantine/dropzone';
import Moment from 'react-moment';
import { openModal } from '@mantine/modals';
import CaptureCompare from 'core/components/capture/CaptureCompare';
import CaptureDetail from 'core/components/capture/CaptureDetail';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import { DownloadProgress, FileDownloader } from 'core/components/fileDownloader/FileDownloader';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import _ from 'lodash';
import { StatusSelectRenderer } from 'core/components/taskStatusSelect/TaskStatusSelectRenderer';
import SelectItem from 'core/components/teamSelect/SelectItem';
import { showNotification } from '@mantine/notifications';
import { StyleGuideItem } from 'core/components/index';
import { EmptyState } from 'core/components/emptyState/EmptyState';

const useStyles = createStyles((theme, _params, getRef) => ({
    row: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.dark[3], 0.2) : theme.colors[theme.primaryColor][0],
    },
    taskCaptureItem: {
        [`:hover .${getRef('imgWrapper')}`]: {
            borderColor: theme.colors.brand[5],
        },
    },
    imgWrapper: {
        ref: getRef('imgWrapper'),
        border: `3px solid transparent`,
    },
    captureCompare: {
        '.approvaltoolbar': {
            border: 'none',
            background: 'transparent',
        },
    },
}));

const PAGE_SIZE = 10;
const thumbSize = 92;

const TaskEdit = ({ selectedTask, onCancel, onTaskSave, mode = 'edit', user, aggData, success, stateSession }) => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [session, setSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingGuides, setIsLoadingGuides] = useState(true);
    const theme = useMantineTheme();
    const [files, setFiles] = useState([]);
    const { classes } = useStyles();

    const [dropZoneActive, setDropZoneActive] = useState(true);
    const [itemMap, setItemMap] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [members, setMembers] = useState([]);

    const [guideCats, setGuideCats] = useState([]);
    const [selectedGuide, setSelectedGuide] = useState();
    const [selectedGuideCategory, setSelectedGuideCategory] = useState();

    const [isDownloadingProdZip, setDownloadingProdZip] = useState(false);
    const [isDownloadingPostZip, setDownloadingPostZip] = useState(false);

    const [downloadProdPercent, setDownloadProdPercent] = useState(0);
    const [downloadPostPercent, setDownloadPostPercent] = useState(0);

    // const MAX_THUMB_COUNT = 11;
    const LABEL_WIDTH = 150;

    const showItems = selectedTask?.guideId?.length ? true : false;

    const form = useForm({
        initialValues: {
            ...selectedTask,
            status: selectedTask?.status ? selectedTask.status.toLowerCase() : status_backlog.value,
            deadline: selectedTask?.deadline ? new Date(selectedTask.deadline) : '',
        },

        validate: {
            deadline: (value) => (value ? null : 'Invalid deadline'),
            // description: (value) => (value?.length ? null : 'Invalid description'),
            status: (value) =>
                (value === status_progress.value && files.length) || value !== status_progress.value || selectedTask?.status === status_progress.value ? null : 'You must attach a file',
            //   type: (value) => (value.length ? null : 'Invalid type'),
            //   item: (value) => (value.length ? null : 'Invalid item'),
        },
    });

    // const previews = files.map((file, index) => {
    //     return (
    //         <div key={index}>
    //             <FilePreview file={file} index={index} onClick={(file) => setFiles([...files.filter((val, idx) => idx !== index)])} />
    //         </div>
    // <Group key={index} position='apart' mt={'lg'} mb={'sm'}>
    //     <Group spacing={'xs'}>
    //         <ThemeIcon radius='xl'>
    //             <IconFile size={16} />
    //         </ThemeIcon>

    //         <Text size={'sm'}>{file.name}</Text>
    //     </Group>
    //     <Button variant='subtle' onClick={() => setFiles([])}>
    //         Remove
    //     </Button>
    // </Group>
    //     );
    // });

    useEffect(() => {
        logger.log(selectedTask);
        async function getCurrentSession(id) {
            var tmpSession;
            if ((!session && selectedTask) || (session && id !== session.code)) {
                if (!stateSession || (stateSession && stateSession.sessionId !== id)) {
                    tmpSession = await getSession(id);
                } else {
                    tmpSession = { ...stateSession };
                }
            }
            setSession(tmpSession);

            // dispatch(selectSession(tmpSession));

            setIsLoading(false);

            const fetchGuides = _.debounce(function (val) {
                setIsLoadingGuides(true);
                dispatch(fetchSampleGuideCategories(val));
            }, 500);

            // if we don't have a guide added to the task yet we fetch them
            // so the user can select
            // if (!selectedTask?.guideId.length) {
            if (tmpSession) {
                fetchGuides(tmpSession);
                setIsLoadingGuides(true);
            }
        }
        const taskSessionId = selectedTask ? selectedTask?.taskPart?.split('#')[1] : null;

        if ((!session && selectedTask) || (session && taskSessionId !== session.code)) {
            // const sessionId = selectedTask?.taskPart?.split('#')[1];
            getCurrentSession(taskSessionId);
        }

        return () => {
            // setIsLoadingGuides(true);
        };
    }, [selectedTask, session]);

    const updateForm = useCallback(() => {
        form.setValues({
            ...selectedTask,
            status: selectedTask?.status ? selectedTask.status.toLowerCase() : status_backlog.value,
            deadline: selectedTask?.deadline ? new Date(selectedTask.deadline) : '',
        });
    }, [selectedTask, form]);

    useEffect(() => {
        if (form.values.id !== selectedTask?.id) {
            updateForm();
        }

        // create the taskMap
        if (selectedTask && !itemMap) {
            var tmpMap = new Map();
            selectedTask.items?.forEach((item) => tmpMap.set(item.actionSort, null));
            setItemMap(tmpMap);
        }

        // if (selectedTask?.guideId?.length && selectedGuide !== selectedTask.guideId) setSelectedGuide(selectedTask.guideId);
        // if (selectedTask?.categoryId?.length && selectedGuideCategory !== selectedTask.categoryId) setSelectedGuideCategory(selectedTask.categoryId);
    }, [selectedTask, form.values.id, itemMap, updateForm]);

    const onFileUploadProgress = (e) => {
        const { file, percentComplete } = e;

        const mapItemArr = _.find(Array.from(itemMap), ([key, value]) => {
            return (value && value.jpg.name === file.name) || (value && value.psd.name === file.name);
        });
        var mapItem;
        if (mapItemArr && mapItemArr.length) {
            mapItem = itemMap.get(mapItemArr[0]);
        }

        if (mapItem) {
            var newValue;
            let tmpMap = new Map([...itemMap]);

            if (file.name.toLowerCase().endsWith('.psd')) {
                newValue = { ...mapItem, psdUploadProgress: percentComplete, jpgUploadProgress: 0 };
            } else {
                newValue = { ...mapItem, jpgUploadProgress: percentComplete, psdUploadProgress: 100 };
            }

            tmpMap.set(mapItemArr[0], newValue);
            setItemMap(tmpMap);
        }
        // find the line item from the item map based on the file
        logger.log(e, ' : FILE UPLOAD PROGRESS');
    };

    const onFormSubmit = (values) => {
        setIsSaving(true);
        // we need to update a selectedTask.
        const deadline = moment(values.deadline).format('YYYY-MM-DD');
        // const type = values.type?'treatment':'';
        const type = TreatmentTypes.RETOUCHING.label;
        // const items = selectedTask.items.map((val) => val.id);
        const items = selectedTask.items.map((val) => {
            return { actionPart: val.actionPart, actionSort: val.actionSort };
        });

        // get assignee.
        const assignee = members.find((val) => val.value === values.assignee);
        var payload = new TaskModel({
            ...values,
            deadline: deadline,
            type: type,
            items: items,
            assignee: assignee ? assignee.value : '',
            assigneeEmail: assignee ? assignee.email : '',
            guideId: selectedGuide,
            categoryId: selectedGuideCategory,
            // guideId: selectedTask.guideId.length ? selectedTask.guideId : selectedGuide,
            // categoryId: selectedTask.categoryId.length ? selectedTask.categoryId : selectedGuideCategory,
        });

        if (mode === ViewModes.ADD) {
            delete payload.id;
            delete payload.user;
            delete payload.tags;
            delete payload.taskPart;
            delete payload.taskSort;
        }

        const tmpFiles = Array.from(itemMap.values()).filter((value) => value !== undefined && value !== null);
        const splitFiles = [];
        tmpFiles.forEach((fm) => {
            if (fm.psd) {
                splitFiles.push(fm.psd);
            }
            if (fm.jpg) {
                splitFiles.push(fm.jpg);
            }
        });

        payload.files = values.status === status_progress.value ? splitFiles : null;

        dispatch(updateTask(session, payload, user, true, (e) => onFileUploadProgress(e)));
    };

    /**
     * Works out if the file is a jpg or psd and maps it to the task line item
     * @param {*} item - Task line Item
     * @param {*} files - files to be mapped
     * @param {*} fileMap - passed in file map
     * @returns {jpg,psd}
     */
    const getItemMapFiles = (item, files, fileMap) => {
        const itemFiles = fileMap.get(item.actionSort);

        const psdFile = files.find((file) => file.name.toLowerCase().endsWith('.psd'));
        const jpgFile = files.find((file) => file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg'));
        var prodFiles = { psd: (itemFiles && itemFiles.psd) ?? null, jpg: (itemFiles && itemFiles.jpg) ?? null };

        if (psdFile) {
            prodFiles.psd = psdFile;
        }
        if (jpgFile) {
            prodFiles.jpg = jpgFile;
        }
        return prodFiles;
    };

    // useEffect(() => {

    //     switch(success.type){
    //         case updateTaskSuccess.toString():{
    //             if(onTaskSave)onTaskSave();
    //             break;
    //         }
    //     }
    // },[success])
    // const onItemClick = (e, val) => {
    //     if (e === 'delete') {
    //         var data = _.remove(selectedTask.items, function (item) {
    //             return item === val;
    //         });

    //         // setItems([...data])
    //     }
    // };

    const thumbs = useMemo(() => {
        const onFileDownload = () => {
            dispatch(setSuccess({ type: TaskEventType.FILE_DOWNLOAD_SUCCESS, message: 'File downloaded successfully' }));
        };

        const onImageClick = (image) => {
            if (selectedTask) {
                const img1 = { previewHi: image?.productionPreview };
                const img2 = { previewHi: image?.postProductionPreview };
                const isPostProduction = image.postProductionThumb?.length ? true : false;

                var content;

                if (isPostProduction) {
                    // content = <CaptureCompare images={[{first:img1, second:img2}]} onImageEvent={() => logger.log('On image event')} />;
                    content = <CaptureCompare images={[img1, img2]} onImageEvent={() => logger.log('On image event')} />;
                } else {
                    content = <CaptureDetail showSettings={false} image={img1} src={img1.previewHi} onImageEvent={() => logger.log('On image event')} />;
                }

                openModal({
                    title: isPostProduction ? 'Compare' : 'Preview',
                    size: '100%',
                    children: <>{content}</>,
                });
            }
        };

        var thumbs = [];
        // const maxCount = selectedTask && selectedTask.items ? Math.min(selectedTask?.items.length, MAX_THUMB_COUNT) : 0;

        for (let i = 0; i < selectedTask?.items?.length; i++) {
            const isPostProduction = selectedTask.items[i]?.postProductionThumb?.length ? true : false;
            // let ofn = getOfn(selectedTask.items[i].productionArtifact);
            let ofn = selectedTask.items[i].originalFilename ? selectedTask.items[i].originalFilename.replace(/eip/g, 'psd') : '';
            thumbs.push(
                // <ThumbPreview key={i} onClick={() => onImageClick(selectedTask.items[i])} item={selectedTask.items[i]} isPostProduction={isPostProduction} />
                <div style={{ position: 'relative' }} key={i}>
                    {isPostProduction ? (
                        <Box onClick={() => onImageClick(selectedTask.items[i])} sx={{ position: 'relative', cursor: 'zoom-in' }}>
                            <Image radius={'sm'} width={thumbSize} height={thumbSize} src={selectedTask.items[i]?.postProductionThumb} />
                            <Badge sx={{ position: 'absolute', top: 3, right: 3 }} size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                Post
                            </Badge>
                        </Box>
                    ) : (
                        <Image
                            radius={'sm'}
                            width={thumbSize}
                            height={thumbSize}
                            src={selectedTask.items[i].productionThumb}
                            onClick={() => onImageClick(selectedTask.items[i])}
                            sx={{ cursor: 'zoom-in' }}
                        />
                    )}
                    <Tooltip label='Download Production PSD'>
                        {/* <ActionIcon onClick={() => downloadFile(selectedTask.items[i].productionArtifact, ofn,)} variant='filled' sx={{ position: 'absolute', top: 3, left: 3 }}>
                            <IconFileDownload />
                        </ActionIcon> */}
                        <div>
                            <FileDownloader onComplete={() => onFileDownload()} url={selectedTask.items[i].productionArtifact} filename={ofn} />
                        </div>
                    </Tooltip>
                </div>,
            );
        }
        //if we've passed the max bumber then display + more;
        // if (selectedTask?.items.length > MAX_THUMB_COUNT) {
        //     thumbs.push(
        //         <div key={MAX_THUMB_COUNT} style={{ position: 'relative' }}  onClick={() => onImageClick(selectedTask.items[MAX_THUMB_COUNT])}>
        //             <Image radius={'sm'} width={80} height={80} src={selectedTask.items[MAX_THUMB_COUNT].productionThumb} />
        //             <Overlay opacity={0.6} color='#000'>
        //                 <Title order={2} sx={{ lineHeight: 3 }} align='center'>
        //                     +{selectedTask.items.length - MAX_THUMB_COUNT}
        //                 </Title>
        //             </Overlay>
        //         </div>,
        //     );
        // }

        return thumbs;
    }, [selectedTask, dispatch]);

    useEffect(() => {
        if (aggData && aggData.productionTeam) {
            var newMembers = createMembers(aggData, true);
            setMembers([...newMembers]);
        }
    }, [aggData]);

    // each item can now be a 2 files a PSD and a JPEG
    const onSingleFileDrop = useCallback(
        (files, item) => {
            // const itemFiles = itemMap.get(item.actionSort);

            // const psdFile = files.find((file) => file.name.toLowerCase().endsWith('.psd'));
            // const jpgFile = files.find((file) => file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg'));
            // var prodFiles = { psd: (itemFiles && itemFiles.psd) ?? null, jpg: (itemFiles && itemFiles.jpg) ?? null };

            // if (psdFile) {
            //     prodFiles.psd = psdFile;
            // }
            // if (jpgFile) {
            //     prodFiles.jpg = jpgFile;
            // }

            const tmpFn = files[0].name.split('.')[0];
            // const fn = tmpFn.split(' ')[0];
            const fn = tmpFn;

            logger.log(item);

            const ofn = item.originalFilename.split('.')[0];

            if (ofn === fn) {
                const prodFiles = getItemMapFiles(item, files, itemMap);
                logger.log(prodFiles);
                setItemMap(new Map(itemMap.set(item.actionSort, prodFiles)));
            } else {
                showNotification({
                    title: 'No file match',
                    message: 'Unfortunatly no filenames match',
                    position: 'top-center',
                    color: 'red',
                });
            }
        },
        [itemMap],
    );

    const getPsdCount = useCallback(() => {
        var psdCount = 0;
        if (selectedTask && selectedTask.items) {
            selectedTask.items.forEach((val) => {
                if (val.psdLoaded && val.psdLoaded === 'Y') {
                    ++psdCount;
                }
            });
        }
        return psdCount;
    }, [selectedTask]);

    var psdCount = getPsdCount();

    const postThumbs_old = useMemo(() => {
        const onFileDownload = () => {
            dispatch(setSuccess({ type: TaskEventType.FILE_DOWNLOAD_SUCCESS, message: 'File downloaded successfully' }));
        };

        const onImageClick = (image) => {
            function findIndexByValue(map, value) {
                let index = 0;
                for (let [key, val] of map.entries()) {
                    if (key === value) {
                        return index;
                    }
                    index++;
                }
                // Value not found
                return -1;
            }

            const index = findIndexByValue(itemMap, image.actionSort);

            if (selectedTask) {
                var images = [];
                const isPostProduction = image.postProductionThumb?.length ? true : false;

                selectedTask.items.forEach((image) => {
                    const img1 = { previewHi: image?.productionPreview };
                    const img2 = { previewHi: image?.postProductionPreview };

                    if (isPostProduction) {
                        images.push({ first: img1, second: img2 });
                    } else {
                        images.push(img1);
                    }
                });
                const img1 = { previewHi: image?.productionPreview };
                const img2 = { previewHi: image?.postProductionPreview };

                var content;

                if (isPostProduction) {
                    content = <TaskCaptureCompare selectedIndex={index} images={images} onImageEvent={() => logger.log('On image event')} />;
                } else {
                    content = <CaptureDetail showSettings={false} image={img1} src={img1.previewHi} onImageEvent={() => logger.log('On image event')} />;
                }

                openModal({
                    title: isPostProduction ? 'Compare' : 'Preview',
                    size: '100%',
                    children: <>{content}</>,
                });
            }
        };

        var thumbs = [];
        // const maxCount = selectedTask && selectedTask.items ? Math.min(selectedTask?.items.length, MAX_THUMB_COUNT) : 0;

        for (let i = 0; i < selectedTask?.items?.length; i++) {
            // const isPostProduction = selectedTask.items[i]?.postProductionThumb?.length ? true : false;
            let ofn = selectedTask.items[i].originalFilename ? selectedTask.items[i].originalFilename.replace(/eip/g, 'psd') : '';

            thumbs.push(
                <Box
                    sx={(theme) => ({
                        borderBottom: `0px solid gray`,
                        borderTop: showItems ? '1px solid gray' : 0,
                        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
                    })}>
                    <Group key={i} noWrap spacing={'xs'} py={7}>
                        {showItems ? (
                            <div style={{ position: 'relative', display: 'flex', height: 100, alignItems: 'center', position: 'relative' }}>
                                {/* <Image radius={'sm'} width={80} height={80} src={selectedTask.items[i].referenceThumb} withPlaceholder /> */}
                                <ResolverImage radius={'sm'} width={thumbSize} height={thumbSize} src={selectedTask.items[i].referenceThumb} />
                            </div>
                        ) : null}

                        {selectedTask.items[i].productionThumb ? (
                            <div style={{ position: 'relative', display: 'flex', height: 100, alignItems: 'center' }} onClick={() => onImageClick(selectedTask.items[i])}>
                                <Image radius={'sm'} width={thumbSize} height={thumbSize} src={selectedTask.items[i].productionThumb} sx={{ cursor: 'zoom-in' }} />

                                {!selectedTask.items[i].psdLoaded && mode === ViewModes.ADD ? (
                                    <Tooltip label='This item has no PSD'>
                                        <div style={{ marginTop: 10, position: 'absolute', top: 0, right: 10 }}>
                                            <ThemeIcon size={'xs'} color='yellow'>
                                                <IconAlertCircle />
                                            </ThemeIcon>
                                        </div>
                                    </Tooltip>
                                ) : null}

                                <Tooltip label='Download Production PSD'>
                                    {/* <ActionIcon onClick={() => downloadFile(selectedTask.items[i].productionArtifact, ofn,)} variant='filled' sx={{ position: 'absolute', top: 3, left: 3 }}>
                        <IconFileDownload />
                    </ActionIcon> */}
                                    <div style={{ marginTop: 5, position: 'absolute', top: 0 }}>
                                        <FileDownloader onComplete={() => onFileDownload()} url={selectedTask.items[i].productionArtifact} filename={ofn} />
                                    </div>

                                    {/* show warning if no psdLoaded */}
                                </Tooltip>
                            </div>
                        ) : (
                            <EmptyShot />
                        )}

                        {showItems ? (
                            <Group key={i} noWrap style={{ width: '100%' }}>
                                {selectedTask.items[i]?.postProductionThumb ? (
                                    <Box sx={{ position: 'relative' }} onClick={() => onImageClick(selectedTask.items[i])} style={{ width: thumbSize, cursor: 'zoom-in' }}>
                                        <div style={{ height: 100, display: 'flex', alignItems: 'center', position: 'relative' }}>
                                            <Image radius={'sm'} width={thumbSize} height={thumbSize} src={selectedTask.items[i]?.postProductionThumb} />
                                            <Tooltip label='Download Post Production PSD'>
                                                <div style={{ marginTop: 5, position: 'absolute', top: 0 }}>
                                                    <FileDownloader onComplete={() => onFileDownload()} url={selectedTask.items[i].postProductionArtifact} filename={ofn} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {/* <Badge sx={{ position: 'absolute', top: 3, right: 3 }} size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                Post
                            </Badge> */}
                                    </Box>
                                ) : (
                                    <Group noWrap>
                                        <EmptyShot thumbSize={thumbSize} />

                                        {/* <Image radius={'sm'} width={thumbSize} height={thumbSize} withPlaceholder  /> */}
                                    </Group>
                                )}

                                {mode === ViewModes.VIEW || (mode !== ViewModes.VIEW && showItems === false) ? null : (
                                    <>
                                        {itemMap && itemMap.get(selectedTask.items[i].actionSort) ? (
                                            <Box sx={{ width: '100%' }}>
                                                <Stack spacing={'xs'}>
                                                    {itemMap.get(selectedTask.items[i].actionSort).psd ? (
                                                        <FilePreview
                                                            fileType='PSD'
                                                            progress={itemMap.get(selectedTask.items[i].actionSort).psdUploadProgress}
                                                            file={itemMap.get(selectedTask.items[i].actionSort).psd}
                                                            index={0}
                                                            onClick={() => {
                                                                // remove the item from the map
                                                                let tmpMap = new Map([...itemMap]);
                                                                var fileItem = tmpMap.get(selectedTask.items[i].actionSort);

                                                                if (fileItem) {
                                                                    fileItem.psd = null;
                                                                    if (!fileItem.jpg) {
                                                                        tmpMap.delete(selectedTask.items[i].actionSort);
                                                                    }
                                                                }

                                                                // tmpMap.delete(selectedTask.items[i].actionSort.psd);
                                                                setItemMap(tmpMap);
                                                            }}
                                                        />
                                                    ) : (
                                                        <FileDropZone
                                                            maxFiles={1}
                                                            icon={
                                                                <ThemeIcon variant='light' color='dark'>
                                                                    <Text size={10} weight={800}>
                                                                        PSD
                                                                    </Text>
                                                                </ThemeIcon>
                                                            }
                                                            size='sm'
                                                            onFileDrop={(files) => onSingleFileDrop(files, selectedTask.items[i])}
                                                        />
                                                    )}

                                                    <div>
                                                        {itemMap.get(selectedTask.items[i].actionSort).jpg ? (
                                                            <FilePreview
                                                                file={itemMap.get(selectedTask.items[i].actionSort).jpg}
                                                                index={0}
                                                                fileType='JPG'
                                                                progress={itemMap.get(selectedTask.items[i].actionSort).jpgUploadProgress}
                                                                onClick={() => {
                                                                    // remove the item from the map
                                                                    // let tmpMap = new Map([...itemMap]);
                                                                    // tmpMap.delete(selectedTask.items[i].actionSort.jpg);
                                                                    // setItemMap(tmpMap);
                                                                    // remove the item from the map
                                                                    let tmpMap = new Map([...itemMap]);
                                                                    var fileItem = tmpMap.get(selectedTask.items[i].actionSort);

                                                                    if (fileItem) {
                                                                        fileItem.jpg = null;
                                                                        if (!fileItem.psd) {
                                                                            tmpMap.delete(selectedTask.items[i].actionSort);
                                                                        }
                                                                    }

                                                                    // tmpMap.delete(selectedTask.items[i].actionSort.psd);
                                                                    setItemMap(tmpMap);
                                                                }}
                                                            />
                                                        ) : (
                                                            <FileDropZone
                                                                maxFiles={1}
                                                                icon={
                                                                    <ThemeIcon variant='light' color='dark'>
                                                                        <Text size={10} weight={800}>
                                                                            JPG
                                                                        </Text>
                                                                    </ThemeIcon>
                                                                }
                                                                size='sm'
                                                                onFileDrop={(files) => onSingleFileDrop(files, selectedTask.items[i])}
                                                            />
                                                        )}
                                                    </div>
                                                </Stack>
                                            </Box>
                                        ) : (
                                            <Group sx={{ width: '100%' }} grow>
                                                <FileDropZone onFileDrop={(files) => onSingleFileDrop(files, selectedTask.items[i])} />
                                                {/* <Dropzone
                                                maxFiles={1}
                                                sx={{ flexGrow: 1 }}
                                                onReject={(files) => logger.log('rejected files', files)}
                                                // maxSize={3 * 1024 ** 2}
                                                accept={['.psd']}
                                                onDrop={(files) => onSingleFileDrop(files, selectedTask.items[i])}>
                                                <Group position='center' spacing='sm' style={{ minHeight: 45, pointerEvents: 'none' }}>
                                                    <Dropzone.Accept>
                                                        <IconUpload size={18} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                                                    </Dropzone.Accept>
                                                    <Dropzone.Reject>
                                                        <IconX size={18} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                                                    </Dropzone.Reject>
                                                    <Dropzone.Idle>
                                                        <IconFile size={18} stroke={1.5} />
                                                    </Dropzone.Idle>

                                                    <div style={{ maxWidth: '100%', lineHeight: 1 }}>
                                                        <Text size='md' inline style={{ lineHeight: 1 }}>
                                                            Upload post files
                                                        </Text>
                                                        <Text size='xs' color='dimmed' inline mt={7}>
                                                            Drop file or click to attach file
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </Dropzone> */}

                                                {/* <Box sx={{ width: 28 }}>
                                                <ThemeIcon>
                                                  
                                                    <Text p={0} m={0} weight={800} size={10}>
                                                        PDF
                                                    </Text>
                                                </ThemeIcon>
                                                <ThemeIcon mt={3}>
                                                  
                                                    <Text p={0} m={0} weight={800} size={10}>
                                                        JPG
                                                    </Text>
                                                </ThemeIcon>
                                            </Box> */}
                                            </Group>
                                        )}
                                    </>
                                )}
                            </Group>
                        ) : (
                            <></>
                        )}
                    </Group>

                    {/* show the photoNotes if we have them */}
                    {selectedTask.items[i].photoNotes ? (
                        <Box mb={'sm'} style={{ marginLeft: 100, position: 'relative', width: 470 }}>
                            <Alert
                                sx={(theme) => ({
                                    marginTop: theme.spacing.sm,
                                    position: 'relative',
                                    overflow: 'visible',
                                    ':after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: -13,
                                        left: 25,
                                        transform: 'translateX(-50%)',
                                        width: 0,
                                        opacity: 0.2,
                                        height: 0,
                                        borderLeft: `12px solid transparent`,
                                        borderRight: `12px solid transparent`,
                                        borderBottom: `12px solid ${theme.colors.brand[4]}`,
                                        zIndex: 9,
                                    },
                                })}
                                icon={
                                    // <ThemeIcon variant='light' color='brand'>
                                    <IconNote size={18} />
                                    // </ThemeIcon>
                                }>
                                <Text color='brand' size={'sm'}>
                                    {selectedTask.items[i].photoNotes}
                                </Text>
                            </Alert>
                        </Box>
                    ) : null}
                </Box>,
            );
        }

        return thumbs;
    }, [selectedTask, itemMap, theme, dispatch, onSingleFileDrop]);

    const postThumbs = useMemo(() => {
        const guideCat = guideCats.find((cat) => cat.value === selectedGuideCategory && cat.guideId === selectedGuide);
        logger.log(selectedGuide, guideCat);
        const onFileDownload = () => {
            dispatch(setSuccess({ type: TaskEventType.FILE_DOWNLOAD_SUCCESS, message: 'File downloaded successfully' }));
        };

        const onImageClick = (image) => {
            function findIndexByValue(map, value) {
                let index = 0;
                for (let [key, val] of map.entries()) {
                    if (key === value) {
                        return index;
                    }
                    index++;
                }
                // Value not found
                return -1;
            }

            const index = findIndexByValue(itemMap, image.actionSort);

            if (selectedTask) {
                var images = [];
                const isPostProduction = image.postProductionThumb?.length ? true : false;

                selectedTask.items.forEach((image) => {
                    const img1 = { previewHi: image?.productionPreview };
                    const img2 = { previewHi: image?.postProductionPreview };

                    if (isPostProduction) {
                        images.push({ first: img1, second: img2 });
                    } else {
                        images.push(img1);
                    }
                });
                const img1 = { previewHi: image?.productionPreview };
                const img2 = { previewHi: image?.postProductionPreview };

                var content;

                if (isPostProduction) {
                    content = <TaskCaptureCompare selectedIndex={index} images={images} onImageEvent={() => logger.log('On image event')} />;
                } else {
                    content = <CaptureDetail showSettings={false} image={img1} src={img1.previewHi} onImageEvent={() => logger.log('On image event')} />;
                }

                openModal({
                    title: isPostProduction ? 'Compare' : 'Preview',
                    size: '100%',
                    children: <>{content}</>,
                });
            }
        };

        var thumbs = [];
        // const maxCount = selectedTask && selectedTask.items ? Math.min(selectedTask?.items.length, MAX_THUMB_COUNT) : 0;
        logger.log(selectedTask, ' : SELECTED TASK');
        var taskItems = selectedTask && selectedTask.items ? [...selectedTask?.items] : [];
        taskItems.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));

        var guideShots = guideCat && guideCat.shotItems ? [...guideCat.shotItems] : [];
        guideShots.sort((a, b) => parseInt(a.seq) - parseInt(b.seq));

        const maxShots = Math.max(taskItems.length, guideShots.length);

        for (let i = 0; i < maxShots; i++) {
            // const isPostProduction = selectedTask.items[i]?.postProductionThumb?.length ? true : false;
            let ofn = taskItems[i]?.originalFilename ? taskItems[i].originalFilename.replace(/eip/g, 'psd') : '';
            const refShot = guideShots[i];

            logger.log(refShot);

            thumbs.push(
                <Box
                    sx={(theme) => ({
                        borderBottom: `0px solid gray`,
                        borderTop: showItems ? '1px solid gray' : 0,
                        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
                    })}>
                    <Group key={i} noWrap spacing={'xs'} py={7}>
                        {showItems ? (
                            <>
                                {refShot && refShot.referenceUrl.length ? (
                                    <div style={{ position: 'relative', display: 'flex', height: 100, alignItems: 'center', position: 'relative' }}>
                                        {/* <Image radius={'sm'} width={80} height={80} src={taskItems[i].referenceThumb} withPlaceholder /> */}
                                        <ResolverImage radius={'sm'} width={thumbSize} height={thumbSize} src={refShot.referenceUrl} />
                                    </div>
                                ) : (
                                    <div>{isLoadingGuides ? <Skeleton height={thumbSize} width={thumbSize} /> : <EmptyShot thumbSize={thumbSize} label='No Ref' />}</div>
                                )}
                            </>
                        ) : null}

                        {taskItems[i]?.productionThumb ? (
                            <div style={{ position: 'relative', display: 'flex', height: 100, alignItems: 'center' }} onClick={() => onImageClick(taskItems[i])}>
                                <Image radius={'sm'} width={thumbSize} height={thumbSize} src={taskItems[i]?.productionThumb ?? ''} sx={{ cursor: 'zoom-in' }} />

                                {!taskItems[i]?.psdLoaded && mode === ViewModes.ADD ? (
                                    <Tooltip label='This item has no PSD'>
                                        <div style={{ marginTop: 10, position: 'absolute', top: 0, right: 10 }}>
                                            <ThemeIcon size={'xs'} color='yellow'>
                                                <IconAlertCircle />
                                            </ThemeIcon>
                                        </div>
                                    </Tooltip>
                                ) : null}

                                <Tooltip label='Download Production PSD'>
                                    {/* <ActionIcon onClick={() => downloadFile(taskItems[i].productionArtifact, ofn,)} variant='filled' sx={{ position: 'absolute', top: 3, left: 3 }}>
                        <IconFileDownload />
                    </ActionIcon> */}
                                    <div style={{ marginTop: 5, position: 'absolute', top: 0 }}>
                                        <FileDownloader onComplete={() => onFileDownload()} url={taskItems[i]?.productionArtifact ?? ''} filename={ofn} />
                                    </div>

                                    {/* show warning if no psdLoaded */}
                                </Tooltip>
                            </div>
                        ) : (
                            <EmptyShot thumbSize={thumbSize} label={'No Prod'} />
                        )}

                        {showItems ? (
                            <Group key={i} noWrap style={{ width: '100%' }}>
                                {taskItems[i]?.postProductionThumb ? (
                                    <Box sx={{ position: 'relative' }} onClick={() => onImageClick(taskItems[i])} style={{ width: thumbSize, cursor: 'zoom-in' }}>
                                        <div style={{ height: 100, display: 'flex', alignItems: 'center', position: 'relative' }}>
                                            <Image radius={'sm'} width={thumbSize} height={thumbSize} src={taskItems[i]?.postProductionThumb} />
                                            <Tooltip label='Download Post Production PSD'>
                                                <div style={{ marginTop: 5, position: 'absolute', top: 0 }}>
                                                    <FileDownloader onComplete={() => onFileDownload()} url={taskItems[i].postProductionArtifact} filename={ofn} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {/* <Badge sx={{ position: 'absolute', top: 3, right: 3 }} size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                Post
                            </Badge> */}
                                    </Box>
                                ) : (
                                    <Group noWrap>
                                        <EmptyShot thumbSize={thumbSize} />

                                        {/* <Image radius={'sm'} width={thumbSize} height={thumbSize} withPlaceholder  /> */}
                                    </Group>
                                )}

                                {mode === ViewModes.VIEW || (mode !== ViewModes.VIEW && showItems === false) ? null : (
                                    <>
                                        {itemMap && itemMap.get(taskItems[i]?.actionSort) ? (
                                            <Box sx={{ width: '100%' }}>
                                                <Stack spacing={'xs'}>
                                                    {itemMap.get(taskItems[i].actionSort).psd ? (
                                                        <FilePreview
                                                            fileType='PSD'
                                                            progress={itemMap.get(taskItems[i].actionSort).psdUploadProgress}
                                                            file={itemMap.get(taskItems[i].actionSort).psd}
                                                            index={0}
                                                            onClick={() => {
                                                                // remove the item from the map
                                                                let tmpMap = new Map([...itemMap]);
                                                                var fileItem = tmpMap.get(taskItems[i].actionSort);

                                                                if (fileItem) {
                                                                    fileItem.psd = null;
                                                                    if (!fileItem.jpg) {
                                                                        tmpMap.delete(taskItems[i].actionSort);
                                                                    }
                                                                }

                                                                // tmpMap.delete(taskItems[i].actionSort.psd);
                                                                setItemMap(tmpMap);
                                                            }}
                                                        />
                                                    ) : (
                                                        <FileDropZone
                                                            maxFiles={1}
                                                            icon={
                                                                <ThemeIcon variant='light' color='dark'>
                                                                    <Text size={10} weight={800}>
                                                                        PSD
                                                                    </Text>
                                                                </ThemeIcon>
                                                            }
                                                            size='sm'
                                                            onFileDrop={(files) => onSingleFileDrop(files, taskItems[i])}
                                                        />
                                                    )}

                                                    <div>
                                                        {itemMap.get(taskItems[i].actionSort).jpg ? (
                                                            <FilePreview
                                                                file={itemMap.get(taskItems[i].actionSort).jpg}
                                                                index={0}
                                                                fileType='JPG'
                                                                progress={itemMap.get(taskItems[i].actionSort).jpgUploadProgress}
                                                                onClick={() => {
                                                                    // remove the item from the map
                                                                    // let tmpMap = new Map([...itemMap]);
                                                                    // tmpMap.delete(taskItems[i].actionSort.jpg);
                                                                    // setItemMap(tmpMap);
                                                                    // remove the item from the map
                                                                    let tmpMap = new Map([...itemMap]);
                                                                    var fileItem = tmpMap.get(taskItems[i].actionSort);

                                                                    if (fileItem) {
                                                                        fileItem.jpg = null;
                                                                        if (!fileItem.psd) {
                                                                            tmpMap.delete(taskItems[i].actionSort);
                                                                        }
                                                                    }

                                                                    // tmpMap.delete(taskItems[i].actionSort.psd);
                                                                    setItemMap(tmpMap);
                                                                }}
                                                            />
                                                        ) : (
                                                            <FileDropZone
                                                                maxFiles={1}
                                                                icon={
                                                                    <ThemeIcon variant='light' color='dark'>
                                                                        <Text size={10} weight={800}>
                                                                            JPG
                                                                        </Text>
                                                                    </ThemeIcon>
                                                                }
                                                                size='sm'
                                                                onFileDrop={(files) => onSingleFileDrop(files, taskItems[i])}
                                                            />
                                                        )}
                                                    </div>
                                                </Stack>
                                            </Box>
                                        ) : (
                                            <Group sx={{ width: '100%' }} grow>
                                                <FileDropZone onFileDrop={(files) => onSingleFileDrop(files, taskItems[i])} />
                                            </Group>
                                        )}
                                    </>
                                )}
                            </Group>
                        ) : (
                            <></>
                        )}
                    </Group>

                    {/* show the photoNotes if we have them */}
                    {taskItems[i]?.photoNotes ? (
                        <Box mb={'sm'} style={{ marginLeft: 100, position: 'relative', width: 470 }}>
                            <Alert
                                sx={(theme) => ({
                                    marginTop: theme.spacing.sm,
                                    position: 'relative',
                                    overflow: 'visible',
                                    ':after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: -13,
                                        left: 25,
                                        transform: 'translateX(-50%)',
                                        width: 0,
                                        opacity: 0.2,
                                        height: 0,
                                        borderLeft: `12px solid transparent`,
                                        borderRight: `12px solid transparent`,
                                        borderBottom: `12px solid ${theme.colors.brand[4]}`,
                                        zIndex: 9,
                                    },
                                })}
                                icon={
                                    // <ThemeIcon variant='light' color='brand'>
                                    <IconNote size={18} />
                                    // </ThemeIcon>
                                }>
                                <Text color='brand' size={'sm'}>
                                    {taskItems[i].photoNotes}
                                </Text>
                            </Alert>
                        </Box>
                    ) : null}
                </Box>,
            );
        }

        return thumbs;
    }, [selectedTask, itemMap, theme, dispatch, onSingleFileDrop, guideCats, selectedGuideCategory]);

    var submitLabel = isSaving ? `Creating Task` : `Create Task`;
    if (mode === ViewModes.EDIT) {
        submitLabel = 'Update Task';
    }

    const nameArr = selectedTask?.user ? selectedTask?.user.split(' ') : [];

    const startIdx = (currentPage - 1) * PAGE_SIZE;
    const endIdx = startIdx + PAGE_SIZE;

    const rows = selectedTask?.events?.slice(startIdx, endIdx).map((item, idx) => {
        // const selected = selection.includes(item.id);
        const userInitials = getUserInitials(item.user.split(' ')[0], item.user.split(' ')[1]);
        const themeCol = getUserAvatarColour(userInitials);
        return (
            <tr key={idx} className={classes.row}>
                <td>
                    <Text sx={{ maxWidth: 250 }}>{item.descr}</Text>
                </td>
                <td>
                    <Group spacing='sm'>
                        <Avatar size={30} radius={40} variant='filled' color={themeCol} title={item.user}>
                            {item.user.charAt(0)}
                        </Avatar>
                        <Text size='sm'>{item.user}</Text>
                    </Group>
                </td>
                <td align='right'>
                    <Moment date={item.datetime} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY hh:mm:ss' trim />
                </td>
            </tr>
        );
    });

    // const isPostProduction = selectedTask?.postProductionThumb?.length ? true : false;
    const userInitials = getUserInitials(nameArr[0], nameArr[1]);
    const userThemeCol = getUserAvatarColour(userInitials);

    const createCategoryList = () => {
        var cats = [];

        stateSession.guides.forEach((val, idx) => {
            if (val.guideCategories) {
                return val.guideCategories.forEach((cat, idx) => {
                    var catObj = {
                        value: cat.id,
                        label: cat.name,
                        descr: cat.descr,
                        guide: val.name,
                        guideId: val.code,
                        // shots: cat.shots ? cat.shots : [],
                    };

                    cats.push({
                        ...catObj,
                        shots: cat.shots ? cat.shots.length : 0,
                        shotItems: cat.shots ? cat.shots : [],
                    });
                });
            }
        });

        setGuideCats(cats);
        if (cats.length && selectedTask && !selectedTask.categoryId) setSelectedGuideCategory(cats[0].value);
    };

    useEffect(() => {
        if (stateSession && stateSession.guides) {
            createCategoryList();

            // HGIO-387
            // if there is no currently selected guide then select the first one.
            if (selectedTask && selectedTask.guideId && selectedTask.guides && selectedTask.guideId.length === 0 && stateSession.guides.length) {
                setSelectedGuide(stateSession.guides[0].id);
            }
        }
    }, [stateSession?.guides]);

    useEffect(() => {
        if (success) {
            switch (success.type) {
                case getSampleGuideCategoriesSuccess.toString(): {
                    setIsLoading(false);
                    setIsLoadingGuides(false);

                    if (selectedTask?.guideId?.length && selectedGuide !== selectedTask.guideId) setSelectedGuide(selectedTask.guideId);
                    if (selectedTask?.categoryId?.length && selectedGuideCategory !== selectedTask.categoryId) setSelectedGuideCategory(selectedTask.categoryId);

                    // createCategoryList();
                    break;
                }
                case updateTaskSuccess.toString(): {
                    setIsSaving(false);
                    var tmpMap = new Map([...itemMap]);
                    tmpMap.clear();
                    setItemMap(tmpMap);

                    break;
                }
                default: {
                    break;
                }
            }
        }
    }, [success]);

    // useEffect(() => {
    //     const fetchGuides = _.debounce(function (val) {
    //         dispatch(fetchSampleGuideCategories(val));
    //     }, 1000);

    //     // if we don't have a guide added to the task yet we fetch them
    //     // so the user can select
    //     // if (!selectedTask?.guideId.length) {
    //         fetchGuides(stateSession);
    //     // }
    // }, []);

    const downloadAllProdFiles = (type) => {
        logger.log(type);
        const arr = selectedTask.items.map((val, idx) => {
            const item = val;
            if (item) return item;
        });

        const isPost = type.toLowerCase().indexOf('post') > -1;

        var extension = '';
        var zipName = selectedTask.style;

        switch (type) {
            case 'eipURL': {
                extension = 'eip';
                zipName += '_eip';
                break;
            }
            case 'postProductionWebReady': {
                extension = 'jpg';
                zipName += '_webready';
                break;
            }
            default: {
                extension = 'psd';
                zipName += '_psd';
                break;
            }
        }

        if (isPost) {
            zipName += '_post';
            setDownloadingPostZip(true);
        } else {
            setDownloadingProdZip(true);
        }

        handleZipDownload(arr, 'originalFilename', zipName, type, extension, (progress) => {
            const isPost = type.toLowerCase().indexOf('post') > -1;
            if (isPost) {
                setDownloadPostPercent(progress * 100);
            } else {
                setDownloadProdPercent(progress * 100);
            }

            if (progress === 1) {
                setTimeout(() => {
                    if (isPost) {
                        setDownloadingPostZip(false);
                        setDownloadPostPercent(0);
                    } else {
                        setDownloadingProdZip(false);
                        setDownloadProdPercent(0);
                    }
                }, 2000);
            }
        });
    };

    useEffect(() => {
        if (form.values.assignee === 'unassigned') {
            form.setFieldValue('status', status_backlog.value);
        }
    }, [form.values.assignee]);

    const isUnassigned = form.values.assignee && form.values.assignee.toLowerCase() === status_unassigned.value ? true : false;

    return (
        <>
            {/* <Box>
            <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 300, height: 300 }}
                />
            <Group>
                <Text>{tasks.length} Tasks Created</Text>
            </Group>
        </Box> */}

            {mode === ViewModes.VIEW ? (
                <Box>
                    <Box>
                        <FormInputWrapper label='Session:' labelWidth={LABEL_WIDTH}>
                            {isLoading ? (
                                <Stack spacing={'xs'}>
                                    <Skeleton height={21} width={200} />
                                    <Skeleton height={15} width={400} />
                                </Stack>
                            ) : (
                                <>
                                    {session ? (
                                        <Box>
                                            <Group>
                                                <Anchor component={Link} className='dark-anchor' to={`/sessions/${session.sessionId}`} size={'md'}>
                                                    {session.name}
                                                </Anchor>
                                                <Badge>{session.sessionId}</Badge>
                                            </Group>
                                            <Group>
                                                <Text size={'sm'} color={'dimmed'}>
                                                    Due : {moment(session.deadline).format(DataFormats.DATE_FORMAT)}
                                                </Text>
                                            </Group>
                                        </Box>
                                    ) : (
                                        <Text color='dimmed' pt={4} size={'sm'}>
                                            No Session assigned
                                        </Text>
                                    )}
                                </>
                            )}
                        </FormInputWrapper>
                    </Box>

                    <FormInputWrapper label='Photo Code / Style' labelWidth={LABEL_WIDTH}>
                        <Text size={'sm'} weight={500} mt={5}>
                            {selectedTask.photoCode} / {selectedTask.style}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper label='OTB Date' labelWidth={LABEL_WIDTH}>
                        <Text weight={500} size={'sm'}>
                            {selectedTask.otbDate ? moment(selectedTask.otbDate).format(DataFormats.DATE_FORMAT) : '-'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper label='Items:' labelWidth={LABEL_WIDTH}>
                        <Group mt={5}>
                            <Text size={'sm'} weight={500} sx={{ width: thumbSize }}>
                                Reference
                            </Text>
                            <Text size={'sm'} weight={500} sx={{ width: thumbSize }}>
                                Production
                            </Text>
                            <Text size={'sm'} weight={500} sx={{ width: thumbSize }}>
                                Post
                            </Text>
                            {/* <Text size={'sm'} weight={500}></Text> */}
                        </Group>

                        <Stack spacing={0} pt={'sm'} mb={'md'}>
                            {postThumbs}
                        </Stack>
                    </FormInputWrapper>

                    <FormInputWrapper label='Description:' id='input-desc' labelWidth={LABEL_WIDTH}>
                        <Text pt={4} size={'sm'} color={form.values.description ? '' : 'dimmed'}>
                            {form.values.description ? form.values.description : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <Box>
                        <FormInputWrapper label='Status:' id='input-status' labelWidth={LABEL_WIDTH}>
                            {/* <Text pt={4} size={'sm'} color={form.values.status ? '' : 'dimmed'}>
                            {form.values.status ? form.values.status : 'No Value'}
                        </Text> */}
                            <Box pt={4}>
                                <Indicator position='middle-start' color={getColumnColor(form.values.status, 'value')}>
                                    <Text size={'sm'} px={'md'} sx={{ textTransform: 'capitalize' }}>
                                        {form.values.status}
                                    </Text>
                                </Indicator>
                            </Box>
                        </FormInputWrapper>
                    </Box>

                    {selectedTask?.tags ? (
                        <Box>
                            <FormInputWrapper label='Tags:' labelWidth={LABEL_WIDTH}>
                                {selectTask.tags && selectedTask.tags.length ? (
                                    <Group spacing={'xs'} py={'xs'}>
                                        {selectedTask?.tags?.length && selectedTask?.tags.map((val, idx) => <Badge key={idx}>{val}</Badge>)}

                                        {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>Winter</Badge> */}
                                    </Group>
                                ) : (
                                    <Text pt={4} size={'sm'} color={'dimmed'}>
                                        Not tags
                                    </Text>
                                )}
                            </FormInputWrapper>
                        </Box>
                    ) : null}

                    <Box>
                        <FormInputWrapper label='Assigned:' labelWidth={LABEL_WIDTH}>
                            <Group>
                                {selectedTask?.assignee ? (
                                    <Group>
                                        <Avatar size={30} src={null} alt={selectedTask.assignee} radius={'xl'} color={userThemeCol} variant='filled'>
                                            {userInitials.charAt(0)}
                                        </Avatar>
                                        <Text size={'sm'}>{selectedTask.assignee}</Text>
                                    </Group>
                                ) : (
                                    <Text pt={4} size={'sm'} color={'dimmed'}>
                                        Not yet assigned
                                    </Text>
                                )}
                            </Group>
                        </FormInputWrapper>
                    </Box>

                    <Box>
                        <FormInputWrapper label='Created:' labelWidth={LABEL_WIDTH}>
                            <Group>
                                {selectedTask?.user ? (
                                    <Group>
                                        <Avatar size={'md'} src={null} alt={selectedTask.user} radius={'xl'} color={userThemeCol} variant='filled'>
                                            {userInitials.charAt(0)}
                                        </Avatar>
                                        <Text size={'sm'}>{selectedTask.user}</Text>
                                    </Group>
                                ) : null}
                            </Group>
                        </FormInputWrapper>
                    </Box>

                    <FormInputWrapper label='Deadline:' id='input-dates' labelWidth={LABEL_WIDTH}>
                        <Group pt={4}>
                            <Badge>
                                <Moment fromNow>{form.values.deadline}</Moment>
                            </Badge>
                            <Text size={'sm'} color={form.values.deadline ? '' : 'dimmed'}>
                                {form.values.deadline ? <Moment format={DataFormats.DATE_FORMAT}>{form.values.deadline}</Moment> : 'No Value'}
                            </Text>
                        </Group>
                    </FormInputWrapper>

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Type:' id='input-type' labelWidth={LABEL_WIDTH}>
                            {form.values.type ? (
                                <Badge>{form.values.type}</Badge>
                            ) : (
                                <Text pt={4} size={'sm'} color={form.values.type ? '' : 'dimmed'}>
                                    {form.values.type ? form.values.type : 'No Value'}
                                </Text>
                            )}
                        </FormInputWrapper>
                    </Box>

                    {/* <FormInputWrapper label='Items:' id='input-item' labelWidth={LABEL_WIDTH}> */}
                    {/* <Group cols={2} mb={'xs'}>
                        <Stack>
                            <Title order={6}>Production</Title>
                        </Stack>
                        <Stack>
                            <Title order={6}>Post Production</Title>
                        </Stack>
                    </Group>
                     */}

                    {/* <Tooltip position='bottom-start' label={'Click to view'}> */}
                    {/* <Group style={{ cursor: 'pointer' }}>{thumbs}</Group> */}
                    {/* </Tooltip> */}
                    {/* <Group>
                    {tasks?.map((val,idx)=>(
                    <div key={idx} style={{position:'relative'}}>
                       <Image width={80} height={80} src={val.previewLo} />  
                       <ThemeIcon variant='default' onClick={onItemClick('delete',val)} sx={{position:'absolute', top:0, right:0, borderTopRightRadius:0}}>
                            <IconTrash size={14} />
                       </ThemeIcon>
                    </div>
                    ))}
                </Group> */}
                    {/* </FormInputWrapper> */}
                    {/* <Divider />
                <Box my={'lg'}>
                    <Title order={5}>Items</Title>
                    <Stack mt={'md'}>{postThumbs}</Stack>
                </Box> */}

                    <Divider mt={'lg'} />
                    {rows ? (
                        <Box mb={'lg'}>
                            <Accordion defaultValue=''>
                                <Accordion.Item value='history'>
                                    <Accordion.Control>
                                        <Group>
                                            <Title order={5}>Task History</Title>
                                            <Badge>{selectedTask?.events.length}</Badge>
                                        </Group>
                                    </Accordion.Control>
                                    <Accordion.Panel>
                                        <div>
                                            {selectedTask.events.length ? (
                                                <div>
                                                    <Table mt={'sm'} withBorder striped>
                                                        <tbody>{rows}</tbody>
                                                    </Table>

                                                    <Pagination mt={'xs'} page={currentPage} onChange={setCurrentPage} total={selectedTask.events ? Math.ceil(selectedTask.events.length / 10) : 0} />
                                                </div>
                                            ) : (
                                                <Box>
                                                    <Container className={classes.root}>
                                                        <Center>
                                                            <Stack align={'center'}>
                                                                <div>
                                                                    <Text color='dimmed' size='sm'>
                                                                        There are no records available
                                                                    </Text>
                                                                </div>
                                                            </Stack>
                                                        </Center>
                                                    </Container>
                                                </Box>
                                            )}
                                        </div>
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                        </Box>
                    ) : null}
                </Box>
            ) : (
                <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                    {/* <FocusTrap active={true}> */}
                    {/* <FormInputWrapper label='Description' id='input-desc'>
                <Input id="input-desc" required  data-autofocus placeholder="Enter Description" />
            </FormInputWrapper> */}
                    <Box>
                        <FormInputWrapper label='Session' labelWidth={LABEL_WIDTH}>
                            {isLoading ? (
                                <Stack spacing={'xs'}>
                                    <Skeleton height={21} width={200} />
                                    <Skeleton height={15} width={400} />
                                </Stack>
                            ) : (
                                <>
                                    {session ? (
                                        <Box>
                                            <Group>
                                                <Anchor component={Link} className='dark-anchor' to={`/sessions/${session.sessionId}`} size={'md'}>
                                                    {session.name}
                                                </Anchor>
                                                <Badge>{session.sessionId}</Badge>
                                            </Group>
                                            <Group>
                                                <Text size={'sm'} color={'dimmed'}>
                                                    Due : {moment(session.deadline).format(DataFormats.DATE_FORMAT)}
                                                </Text>
                                            </Group>
                                        </Box>
                                    ) : (
                                        <Text color='dimmed'>No session assigned</Text>
                                    )}
                                </>
                            )}
                        </FormInputWrapper>
                    </Box>

                    <FormInputWrapper label='Photo Code / Style' labelWidth={LABEL_WIDTH}>
                        {isLoading ? (
                            <Stack spacing={'xs'} mt={5}>
                                <Skeleton height={21} width={200} />
                            </Stack>
                        ) : (
                            <Text size={'sm'} weight={500} mt={5}>
                                {selectedTask?.photoCode} / {selectedTask?.style}
                            </Text>
                        )}
                    </FormInputWrapper>

                    <FormInputWrapper label='OTB Date' labelWidth={LABEL_WIDTH}>
                        {isLoading ? (
                            <Stack spacing={'xs'} mt={5}>
                                <Skeleton height={21} width={200} />
                            </Stack>
                        ) : (
                            <Text weight={500} size={'sm'} mt={5}>
                                {selectedTask?.otbDate ? moment(selectedTask.otbDate).format(DataFormats.DATE_FORMAT) : '-'}
                            </Text>
                        )}
                    </FormInputWrapper>

                    {/* {showItems?
                    <></>
                    :
                    <Box pb={'xs'}>
                        <FormInputWrapper label='Style Guide' labelWidth={LABEL_WIDTH}>
                            {session && session.guides ? (
                                <Select
                                    placeholder='Pick one'
                                    itemComponent={StyleGuideItem}
                                    required
                                    // data={guides}
                                    data={[
                                        ...stateSession.guides?.map((val) => {
                                            return { label: val.name, value: val.code, shots: val.guideCategories ? val.guideCategories.length : 0 };
                                        }),
                                    ]}
                                    value={selectedGuide}
                                    onChange={(val) => {
                                        logger.log(val, 'changed');
                                        setSelectedGuide(val);
                                        setSelectedGuideCategory('');
                                    }}
                                    // searchable
                                    maxDropdownHeight={400}
                                    nothingFound='No Items'
                                />
                            ) : null}
                        </FormInputWrapper>

                        <FormInputWrapper label='Guide Category' labelWidth={LABEL_WIDTH}>
                            {session && session.guides ? (
                                <Select
                                    required
                                    placeholder='Pick one'
                                    itemComponent={StyleGuideItem}
                                    data={[{ label: 'All Categories', value: '', descr: '' }, ...guideCats.filter((val) => val.guideId === selectedGuide)]}
                                    value={selectedGuideCategory}
                                    onChange={(val) => {
                                        logger.log(val, 'changed');
                                        setSelectedGuideCategory(val);
                                    }}
                                    // searchable
                                    maxDropdownHeight={400}
                                    nothingFound='No Items'
                                    // filter={(value, item) =>
                                    //   item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                    //   item.descr.toLowerCase().includes(value.toLowerCase().trim())
                                    // }
                                />
                            ) : null}
                        </FormInputWrapper>
                    </Box>
                    } */}

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Style Guide' labelWidth={LABEL_WIDTH}>
                            {stateSession && stateSession.guides && !isLoadingGuides ? (
                                <Select
                                    placeholder='Pick one'
                                    itemComponent={StyleGuideItem}
                                    required
                                    // data={guides}
                                    data={[
                                        ...stateSession?.guides?.map((val) => {
                                            return { label: val.name, value: val.code, shots: val.guideCategories ? val.guideCategories.length : 0 };
                                        }),
                                    ]}
                                    value={selectedGuide}
                                    onChange={(val) => {
                                        logger.log(val, 'changed');
                                        setSelectedGuide(val);
                                        setSelectedGuideCategory('');
                                    }}
                                    searchable
                                    maxDropdownHeight={400}
                                    nothingFound='No Items'
                                />
                            ) : (
                                <Skeleton height={36} width={'100%'} />
                            )}
                        </FormInputWrapper>

                        <FormInputWrapper label='Guide Category' labelWidth={LABEL_WIDTH}>
                            {session && session.guides && !isLoadingGuides ? (
                                <Select
                                    required
                                    placeholder='Pick one'
                                    itemComponent={StyleGuideItem}
                                    data={[{ label: 'All Categories', value: '', descr: '' }, ...guideCats.filter((val) => val.guideId === selectedGuide)]}
                                    value={selectedGuideCategory}
                                    onChange={(val) => {
                                        logger.log(val, 'changed');
                                        setSelectedGuideCategory(val);
                                    }}
                                    searchable
                                    maxDropdownHeight={400}
                                    nothingFound='No Items'
                                    // filter={(value, item) =>
                                    //   item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                    //   item.descr.toLowerCase().includes(value.toLowerCase().trim())
                                    // }
                                />
                            ) : (
                                <Skeleton height={36} width={'100%'} />
                            )}
                        </FormInputWrapper>
                    </Box>

                    {showItems ? (
                        <FormInputWrapper
                            label='Items'
                            labelWidth={LABEL_WIDTH}
                            description={`You can upload multiple post production files at once by dragging them onto the form. You can also just assign them individually by clicking on each item's drop zone.`}>
                            <Group mt={5} sx={{ height: 30 }} spacing={0}>
                                <Text size={'sm'} weight={500} sx={{ width: thumbSize + 10 }}>
                                    Reference
                                </Text>

                                <Group sx={{ width: thumbSize + 10 }} noWrap>
                                    <Text size={'sm'} weight={500} sx={{ width: 35 }}>
                                        Prod
                                    </Text>
                                    {isDownloadingProdZip ? (
                                        <DownloadProgress isLoading={true} percent={downloadProdPercent} />
                                    ) : (
                                        <Menu shadow='md' position='bottom-end'>
                                            <Menu.Target>
                                                <ActionIcon loading={isDownloadingProdZip} variant='subtle'>
                                                    <IconChevronDown size={14} />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                {/* <Menu.Label>Application</Menu.Label> */}
                                                <Menu.Item onClick={() => downloadAllProdFiles('productionArtifact')} icon={<IconDownload size={14} />}>
                                                    Download All PSD
                                                </Menu.Item>
                                                <Menu.Item onClick={() => downloadAllProdFiles('eipURL')} icon={<IconDownload size={14} />}>
                                                    Download All EIP
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    )}
                                </Group>
                                <Group sx={{ width: thumbSize }} noWrap>
                                    <Text size={'sm'} weight={500} sx={{ width: 35 }}>
                                        Post
                                    </Text>
                                    {isDownloadingPostZip ? (
                                        <DownloadProgress isLoading={true} percent={downloadPostPercent} />
                                    ) : (
                                        <Menu shadow='md' position='bottom-end'>
                                            <Menu.Target>
                                                <ActionIcon loading={isDownloadingPostZip} variant='subtle'>
                                                    <IconChevronDown size={14} />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                {/* <Menu.Label>Application</Menu.Label> */}
                                                <Menu.Item onClick={() => downloadAllProdFiles('postProductionArtifact')} icon={<IconDownload size={14} />}>
                                                    Download All PSD
                                                </Menu.Item>
                                                <Menu.Item onClick={() => downloadAllProdFiles('postProductionWebReady')} icon={<IconDownload size={14} />}>
                                                    Download All JPG
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    )}
                                </Group>

                                <Text size={'sm'} weight={500}></Text>
                            </Group>
                            {selectedGuideCategory?.length && !isLoadingGuides ? (
                                <Stack spacing={0} pt={'sm'} mb={'md'}>
                                    {postThumbs}
                                </Stack>
                            ) : (
                                <>
                                    {isLoadingGuides ? (
                                        <Group noWrap spacing={'xs'} py={7}>
                                            <Skeleton height={thumbSize} width={thumbSize} />
                                            <Skeleton height={thumbSize} width={thumbSize} />
                                            <Skeleton height={thumbSize} width={thumbSize} />
                                        </Group>
                                    ) : (
                                        <EmptyState caption='No Guide Category Selected' />
                                    )}
                                </>
                            )}
                        </FormInputWrapper>
                    ) : (
                        <FormInputWrapper label='Items'>
                            <Group spacing={'md'} pt={'sm'} mb={'md'}>
                                {postThumbs}
                            </Group>

                            {psdCount !== selectedTask?.items?.length && mode === ViewModes.ADD ? (
                                <Alert color='yellow' icon={<IconAlertCircle />} my={'md'}>
                                    <div style={{ lineHeight: 2 }}>
                                        <Text size='sm' color='yellow' inline style={{ lineHeight: 1.4 }}>
                                            Some items are missing their associated PSDs
                                        </Text>
                                    </div>
                                </Alert>
                            ) : null}
                        </FormInputWrapper>
                    )}

                    <FormInputWrapper label='Notes' id='input-desc' labelWidth={LABEL_WIDTH} required={false}>
                        <Textarea id='input-desc' data-autofocus placeholder='Enter notes' {...form.getInputProps('description')} />
                    </FormInputWrapper>

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Status' id='input-status' labelWidth={LABEL_WIDTH}>
                            {/* <Checkbox label='Treatment' {...form.getInputProps('type', { type: 'checkbox' })} /> */}
                            {/* <NativeSelect data={StatusTypeArray} sx={{width:200}} /> */}
                            <Select
                                itemComponent={StatusSelectRenderer}
                                data={statuss.map((val) => {
                                    const color = getColumnColor(val.value, 'value');
                                    return { label: val.label, value: val.value, color: color };
                                })}
                                {...form.getInputProps('status')}
                                disabled={isUnassigned}
                            />
                            {form.values.status === status_progress.value && getFileCount(itemMap) === 0 ? (
                                <Alert color='yellow' icon={<IconAlertCircle />} mt={'md'}>
                                    <div style={{ lineHeight: 2 }}>
                                        <Text size='sm' inline style={{ lineHeight: 1.4 }}>
                                            When updating the status to <Badge color={'blue'}>In Approval</Badge> you must attach a file to your Items above.
                                        </Text>
                                    </div>
                                </Alert>
                            ) : null}
                        </FormInputWrapper>

                        {/* {form.values.status === status_progress.value ? (
                        <FormInputWrapper label='Files' id='input-files'>
                            <div>
                                {files.length === 0 ? (
                                    <Dropzone
                                        onReject={(files) => logger.log('rejected files', files)}
                                        // maxSize={3 * 1024 ** 2}
                                        accept={PHOTOSHOP_FILE_TYPES}
                                        onDrop={setFiles}>
                                        <Group position='center' spacing='sm' style={{ minHeight: 150, pointerEvents: 'none' }}>
                                            <Dropzone.Accept>
                                                <IconUpload size={50} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                                            </Dropzone.Accept>
                                            <Dropzone.Reject>
                                                <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                                            </Dropzone.Reject>
                                            <Dropzone.Idle>
                                                <IconFile size={50} stroke={1.5} />
                                            </Dropzone.Idle>

                                            <div style={{ maxWidth: 300, lineHeight: 2 }}>
                                                <Text size='xl' inline style={{ lineHeight: 1.4 }}>
                                                    When updating status to <Badge color={'blue'}>In Approval</Badge> you must attach a file.
                                                </Text>
                                                <Text size='sm' color='dimmed' inline mt={7}>
                                                    Drop file or click to attach file
                                                </Text>
                                            </div>
                                        </Group>
                                    </Dropzone>
                                ) : null}

                                <Stack>{previews}</Stack>
                            </div>
                        </FormInputWrapper>
                    ) : null} */}
                    </Box>

                    {/* {selectedTask?.tags ? (
                    <Box>
                        <FormInputWrapper label='Tags' labelWidth={LABEL_WIDTH}>
                            <Group spacing={'xs'} py={'xs'}>
                                {selectedTask?.tags?.length && selectedTask?.tags.map((val, idx) => <Badge key={idx}>{val}</Badge>)}

                               
                            </Group>
                        </FormInputWrapper>
                    </Box>
                ) : null} */}

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Assigned' labelWidth={LABEL_WIDTH}>
                            <Group grow>
                                <Select data={[...members]} itemComponent={SelectItem} searchable placeholder='Select Assignees' {...form.getInputProps('assignee')} />
                            </Group>
                        </FormInputWrapper>
                    </Box>

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Created' labelWidth={LABEL_WIDTH}>
                            <Group grow>
                                {selectedTask?.user ? (
                                    <Group spacing={'sm'}>
                                        <Avatar size={30} src={null} alt={selectedTask.user} radius={'xl'} color={userThemeCol} variant='filled'>
                                            {userInitials.charAt(0)}
                                        </Avatar>
                                        <Text size={'sm'}>{selectedTask.user}</Text>
                                    </Group>
                                ) : null}
                            </Group>
                        </FormInputWrapper>
                    </Box>

                    <FormInputWrapper label='Deadline' id='input-dates' labelWidth={LABEL_WIDTH} required={true}>
                        <Group>
                            <CustomDatePicker placeholder='Deadline' required {...form.getInputProps('deadline')} sx={{ width: 200 }} />
                        </Group>
                    </FormInputWrapper>

                    {/* <Box pt={'xs'}>
                <FormInputWrapper label='Type' id='input-type'>
                    <Checkbox label='Treatment' {...form.getInputProps('type', { type: 'checkbox' })} />
                </FormInputWrapper>
            </Box> */}

                    <Box pb={'xs'}>
                        <FormInputWrapper label='Type' id='input-type' labelWidth={LABEL_WIDTH}>
                            {/* <Checkbox label='Treatment' {...form.getInputProps('type', { type: 'checkbox' })} /> */}
                            <NativeSelect data={['Retouching']} sx={{ width: 200 }} />
                        </FormInputWrapper>
                    </Box>

                    {/* <FormInputWrapper label='Items' id='input-item' labelWidth={LABEL_WIDTH}>
                    <Tooltip position='bottom-start' label={'Click to view'}>
                    <Group style={{ cursor: 'pointer' }}>{thumbs}</Group>
                    </Tooltip>
                    <Group>
                    {tasks?.map((val,idx)=>(
                    <div key={idx} style={{position:'relative'}}>
                       <Image width={80} height={80} src={val.previewLo} />  
                       <ThemeIcon variant='default' onClick={onItemClick('delete',val)} sx={{position:'absolute', top:0, right:0, borderTopRightRadius:0}}>
                            <IconTrash size={14} />
                       </ThemeIcon>
                    </div>
                    ))}
                </Group>
                </FormInputWrapper> */}

                    {/* <Divider mt={'lg'} /> */}

                    {/* <Box my={'lg'}>
                    <Group>
                        <Title order={5}>Items</Title>
                        <Badge>{selectedTask?.items?.length}</Badge>
                    </Group>
                    <Text size={'sm'} color='dimmed' pt={'sm'}>
                        You can upload multiple post production files at once by dragging them onto the form. You can also just assign them individually by clicking on each item's drop zone.
                    </Text>
                    <Group mt={'xl'}>
                        <Text size={'sm'} weight={500} sx={{ width: 80 }}>
                            Reference
                        </Text>
                        <Text size={'sm'} weight={500} sx={{ width: 80 }}>
                            Production
                        </Text>
                        <Text size={'sm'} weight={500} sx={{ width: 80 }}>
                            Post
                        </Text>
                        <Text size={'sm'} weight={500}></Text>
                    </Group>

                    <Stack spacing={0} pt={'sm'}>
                        {postThumbs}
                    </Stack>
                </Box> */}

                    <Dropzone.FullScreen
                        onDragEnter={(e) => {
                            setDropZoneActive(true);
                        }}
                        active={dropZoneActive}
                        onReject={(files) => logger.log('rejected files', files)}
                        // maxSize={3 * 1024 ** 2}
                        accept={['image/jpg', 'image/jpeg', 'image/vnd.adobe.photoshop']}
                        onDrop={(e) => {
                            setDropZoneActive(true);
                            setFiles(e);
                            var tmpMap = new Map([...itemMap]);

                            // loop over the dropped files and try and match to the task items
                            e.forEach((file) => {
                                const tmpFn = file.name.split('.')[0];
                                // const fn = tmpFn.split(' ')[0];
                                // Fix for spacing in the filenames we no longer need to split
                                const fn = tmpFn;

                                // find the item with a matching name
                                const item = selectedTask.items.find((taskItem) => {
                                    const ofn = taskItem.originalFilename.split('.')[0];
                                    return ofn === fn ? true : false;
                                });
                                logger.log(item);
                                if (item) {
                                    const prodFiles = getItemMapFiles(item, [file], tmpMap);
                                    tmpMap.set(item.actionSort, prodFiles);
                                }
                            });

                            setItemMap(tmpMap);

                            form.setValues({
                                status: status_progress.value,
                            });
                        }}>
                        <Group position='center' align='center' spacing='sm' style={{ minHeight: '100vh', pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload size={50} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                            </Dropzone.Accept>
                            <Dropzone.Reject>
                                <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                            </Dropzone.Reject>
                            <Dropzone.Idle>
                                <IconFile size={50} stroke={1.5} />
                            </Dropzone.Idle>

                            <div style={{ maxWidth: 300, lineHeight: 2 }}>
                                <Text size='xl' inline style={{ lineHeight: 1.4 }}>
                                    When updating status to <Badge color={'blue'}>In Approval</Badge> you must attach a file.
                                </Text>
                                <Text size='sm' color='dimmed' inline mt={7}>
                                    Drop file or click to attach file
                                </Text>
                            </div>
                        </Group>
                    </Dropzone.FullScreen>

                    {/* <Box my={'lg'}>
                    <Title order={5}>Uploads</Title>
                    {previews}
                </Box> */}

                    <Divider mt={'lg'} />
                    {rows ? (
                        <Box mb={'lg'}>
                            <Accordion defaultValue=''>
                                <Accordion.Item value='history'>
                                    <Accordion.Control>
                                        <Group>
                                            <Title order={5}>Task History</Title>
                                            <Badge>{selectedTask?.events.length}</Badge>
                                        </Group>
                                    </Accordion.Control>
                                    <Accordion.Panel>
                                        {selectedTask.events.length ? (
                                            <div>
                                                <Table mt={'sm'} withBorder>
                                                    <tbody>{rows}</tbody>
                                                </Table>
                                                <Pagination mt={'xs'} page={currentPage} onChange={setCurrentPage} total={selectedTask.events ? Math.ceil(selectedTask.events.length / 10) : 0} />
                                            </div>
                                        ) : (
                                            <Box>
                                                <Container className={classes.root}>
                                                    <Center>
                                                        <Stack align={'center'}>
                                                            <div>
                                                                <Text color='dimmed' size='sm'>
                                                                    There are no records available
                                                                </Text>
                                                            </div>
                                                        </Stack>
                                                    </Center>
                                                </Container>
                                            </Box>
                                        )}
                                    </Accordion.Panel>
                                </Accordion.Item>
                            </Accordion>
                        </Box>
                    ) : null}

                    {/* <Divider mt={'lg'} /> */}
                    <Group position='apart' mt='lg'>
                        <Button variant='default' onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={!form.isValid()} loading={isSaving}>
                            {submitLabel}
                        </Button>
                    </Group>
                    {/* </FocusTrap> */}
                </form>
            )}
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        stateSession: state.sessionReducer.selectedSession,
        tasks: state.sessionReducer.tasks,
        selectedTask: state.sessionReducer.selectedTask,
        success: state.sessionReducer.success,
        error: state.sessionReducer.error,
        aggData: state.appReducer.aggData,
    };
};

const mapDispatch = { selectTask };

export default connect(mapStateToProps, mapDispatch)(TaskEdit);

const FilePreview = forwardRef((props, ref) => {
    // const imageUrl = URL.createObjectURL(props.file);
    const isUploading = props && props.progress !== undefined;
    logger.log(props.progress);
    return (
        <Group ref={ref} noWrap key={props.index} position='apart' sx={(theme) => ({ width: '100%', borderBottom: `0px solid ${theme.colors.dark[4]}` })} pb={0}>
            <Group spacing={'xs'} noWrap>
                <ThemeIcon radius='sm' variant='light' sx={{ position: 'relative', overflow: 'hidden' }}>
                    {/* <IconFile size={16} /> */}
                    <Box sx={(theme) => ({ position: 'absolute', left: -3, right: -3, bottom: 0, height: `${props.progress}%`, background: theme.colors.brand[4] })}></Box>
                    <Text size={10} weight={500} sx={{ position: 'relative' }}>
                        {props.fileType}
                    </Text>
                </ThemeIcon>
                {/* <Image height={60} width={60} radius="sm" src={imageUrl} imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }} /> */}
                <div>
                    <Text size={'sm'} truncate sx={{ maxWidth: 130 }}>
                        {props.file.name}
                    </Text>
                    <Text size={'xs'} color='dimmed' truncate sx={{ maxWidth: 130 }}>
                        Size: {bytesToMB(props.file.size)}
                    </Text>
                </div>
            </Group>
            {isUploading ? (
                <>
                    {props.progress === 100 ? (
                        <ThemeIcon size={'sm'} variant='filled' color='teal' radius={'xl'}>
                            <IconCheck />
                        </ThemeIcon>
                    ) : (
                        <Loader size={'xs'} />
                    )}
                </>
            ) : (
                <ActionIcon variant='outline' radius={'xl'} size={'sm'} onClick={() => props.onClick(props.file)}>
                    <IconX size={16} />
                </ActionIcon>
            )}

            {/* <Button variant='subtle' size='xs' onClick={() => props.onClick(props.file)}>
                Remove
            </Button> */}
        </Group>
    );
});

// const ThumbPreview = forwardRef((props, ref) => {
//     const theme = useMantineTheme();
//     const {isPostProduction,index,item} = props;
//     // const imageUrl = URL.createObjectURL(props.file);
//     return (
//         <Group ref={ref} noWrap key={index} position='apart' sx={(theme) => ({ width: '100%', borderBottom: `1px solid ${theme.colors.dark[4]}` })} pb={'xs'}>
//             <Group spacing={'xs'}>
//             {isPostProduction ? (
//                         <Box sx={{ position: 'relative' }}>
//                             <Image radius={'sm'} width={80} height={80} src={item.postProductionThumb} />
//                             <Badge sx={{ position: 'absolute', top: 0, right: 0 }} size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
//                                 Post
//                             </Badge>
//                         </Box>
//                     ) : (
//                         <Image radius={'sm'} width={80} height={80} src={item.productionThumb} />
//                     )}
//                 {/* <Image height={60} width={60} radius="sm" src={imageUrl} imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }} /> */}
//                 <Group>
//                     <Text size={'sm'} truncate sx={{ minWidth: 240 }}>
//                         {props.name}
//                     </Text>
//                     {/* <Text size={'xs'} color='dimmed' truncate sx={{ maxWidth: 130 }}>
//                         Size: {bytesToMB(props.file.size)}
//                     </Text> */}
//                 </Group>
//             </Group>
//             <Button variant='subtle' onClick={() => props.onClick(props.file)}>
//                 Remove
//             </Button>
//         </Group>
//     );
// });

const getFileCount = (mapVal) => {
    if (!mapVal) {
        return 0;
    }

    const arr = Array.from(mapVal?.values()).filter((value) => value !== undefined && value !== null);
    return arr ? arr.length : 0;
};

export const TaskCaptureCompare = ({ images, onImageEvent, selectedIndex = 0 }) => {
    logger.log(images);
    const [currentIdx, setCurrentIndex] = useState(selectedIndex);
    const ref = useRef();
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const Column = ({ index, style }) => {
        const val = images[index];
        // const obj = comparisonItems.find((capture) => capture.id === val.id);
        // const captureIdx = comparisonItems.indexOf(obj);
        const isSelected = currentIdx === index ? true : false;
        var cls = '';
        var cls = isSelected ? `${classes.taskCaptureItem} capture-selected` : `${classes.taskCaptureItem}`;

        const onImageEvent = () => {};
        const onCaptureChange = () => {};
        return (
            <Group
                px={'5'}
                align='center'
                className={cls}
                style={{ ...style, cursor: 'pointer' }}
                onClick={() => {
                    setCurrentIndex(index);
                }}>
                <Group
                    className={classes.imgWrapper}
                    key={index}
                    p={2}
                    sx={{
                        width: 'auto',
                        height: '100%',
                        textAlign: 'center',
                        position: 'relative',
                        border: isSelected ? `3px solid ${theme.colors.brand[4]}` : `3px solid ${theme.colors.dark[3]}`,
                        justifyContent: 'center',
                    }}
                    spacing={0}>
                    <Image src={val.first.previewHi} height={150} width={133} fit='contain' />
                    <Image src={val.second.previewHi} height={150} width={133} fit='contain' />
                </Group>
            </Group>
        );
    };

    return (
        <>
            <Stack sx={{ height: '80vh' }} className={classes.captureCompare}>
                <div style={{ paddingTop: theme.spacing.sm, height: '100%' }}>
                    <CaptureCompare height='100%' images={[images[currentIdx].first, images[currentIdx].second]} onImageEvent={() => logger.log('On image event')} />
                </div>
                <Accordion sx={{ border: `1px solid ${theme.colors.dark[4]}` }} defaultValue='images'>
                    <Accordion.Item value='images' sx={{ border: 0 }}>
                        <Accordion.Control>
                            <Group>
                                Task Items{' '}
                                <Text color={'dimmed'} size='sm'>
                                    Displaying {images.length} Captures
                                </Text>
                            </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <div style={{ height: 160 }}>
                                <ReactVirtualizedAutoSizer>
                                    {({ height, width }) => {
                                        const colWidth = 180;

                                        var colCount = images.length * 2;
                                        var rowCount = 1;

                                        // Every row is loaded except for our loading indicator row.
                                        // const isItemLoaded = (index) => !searchCursor || index < rowCount;
                                        const isColLoaded = (index) => {
                                            return index < colCount - 1;
                                        };

                                        return (
                                            <>
                                                <ListWindow height={height} itemCount={images.length} itemSize={300} layout='horizontal' width={width} ref={ref}>
                                                    {Column}
                                                </ListWindow>
                                            </>
                                        );
                                    }}
                                </ReactVirtualizedAutoSizer>
                            </div>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Stack>
        </>
    );
};

const FileDropZone = ({ onFileDrop, maxFiles = 2, size = 'md', icon = <IconFile size={28} stroke={1.5} /> }) => {
    const theme = useMantineTheme();

    return (
        <Dropzone
            maxFiles={maxFiles}
            sx={{ flexGrow: 1 }}
            onReject={(files) => logger.log('rejected files', files)}
            // maxSize={3 * 1024 ** 2}
            accept={['.psd', '.jpg', '.jpeg']}
            padding={size === 'md' ? 'md' : 0}
            onDrop={(files) => onFileDrop(files)}>
            <Group position='center' spacing='sm' style={{ minHeight: size === 'md' ? 56 : 38, pointerEvents: 'none' }}>
                <Dropzone.Accept>
                    <IconUpload size={18} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <IconX size={18} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                </Dropzone.Reject>
                <Dropzone.Idle>{icon}</Dropzone.Idle>
                {size === 'md' ? (
                    <div style={{ maxWidth: '100%', lineHeight: 1 }}>
                        <Text size='md' inline style={{ lineHeight: 1 }}>
                            Upload post files
                        </Text>
                        <Text size='xs' color='dimmed' inline mt={7}>
                            Drop file or click to attach file
                        </Text>
                    </div>
                ) : (
                    <Text size='xs' color='dimmed' inline>
                        Drop file or click to attach file
                    </Text>
                )}
            </Group>
        </Dropzone>
    );
};

const EmptyShot = ({ thumbSize, label = 'No Post' }) => {
    const theme = useMantineTheme();
    return (
        <Group
            align='center'
            position='center'
            sx={(theme) => ({
                flexShrink: 0,
                position: 'relative',
                borderRadius: theme.radius.sm,
                flexGrow: 0,
                height: thumbSize,
                width: thumbSize,
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2],
            })}>
            <Text size={'xs'} color='dimmed' align='center'>
                {label}
            </Text>
            {/* <Badge sx={{ position: 'absolute', top: 3, right: 3 }} size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                    Post
                                </Badge> */}
        </Group>
    );
};

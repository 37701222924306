import React from 'react';
import { logger } from 'utils/logger';
import { ErrorView } from './ErrorView';
import { logError } from 'services/auditService';


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      logger.log('error boundary')
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      logger.log('derived')
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // log error
      const erroObj = {message:error.message}
      const payload = {error:erroObj,user:this.props.user.email,date:new Date()}
      logError(payload).then((val) => {
        console.warn('Error logged')
      })
      logger.log('ERROR')
    }
  
    render() {
      
      if (this.state.hasError) {
        logger.log('render', this.state)
        // You can render any custom fallback UI
        return <ErrorView />;
      }
      
      return this.props.children; 
    }
  }

  export default ErrorBoundary;

export class ScanModel{

    constructor({
        extractStatus = "",
        id = "",
        styleCode = "",
        department = "",
        ext = "",
        size = "",
        upBucket = "",
        location = "",
        season = "",
        descr = "",
        created = "",
        lon = "",
        colour = "",
        url = "",
        thumbUrl = "",
        gtin = "",
        concat = "",
        filename = "",
        salePrice = "",
        notes = "",
        filepath = "",
        lat = "",
        scanId = "",
        sku = "",
        did = "",
        sessionMatches=[],
        eventMatches=[],
        feedReference="",
    }) {
        this.extractStatus = extractStatus??"";
        this.id = id;
        this.styleCode = styleCode??"";
        this.department = department??"";
        this.ext = ext??"";
        this.size = size??"";
        this.upBucket = upBucket??"";
        this.location = location??"";
        this.season = season??"";
        this.descr = descr??"";
        this.created = created??"";
        this.lon = lon??"";
        this.colour = colour??"";
        this.url = url??"";
        this.thumbUrl = thumbUrl??"";
        this.gtin = gtin??"";
        this.concat = concat??"";
        this.filename = filename??"";
        this.salePrice = salePrice??"";
        this.notes = notes??"";
        this.filepath = filepath??"";
        this.lat = lat??"";
        this.scanId = scanId??"";
        this.sku = sku??"";
        this.did = did??"";
        this.sessionMatches=sessionMatches??[];
        this.eventMatches=eventMatches??[];
        this.feedReference=feedReference??"";
    }
} 
// import { v4 as uuidv4 } from 'uuid';

export class StyleGuideModel{

    constructor (obj){

        this.actionPart = "";
        this.actionSort = "";
        this.id = "";
        this.name = "";
        this.description = "";
        
        if (obj) {
            Object.assign(this, obj);
        }
    }
    
}

export const StyleGuideEventType = {
    GUIDE_EDIT: 'guideEdit',
    GUIDE_ADD: 'guideAdd',
    GUIDE_VIEW: 'guideView',
    GUIDE_DOWNLOAD: 'guideDownload',    
}

import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme, Menu } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconLogout } from '@tabler/icons';
import { getUserAvatarColour, getUserInitials } from 'utils/helper';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'modules/Auth/authSlice';

export function User({ data }) {
    // const navigate = useNavigate();
    const theme = useMantineTheme();
    const dispatch = useDispatch();

    const onUserClick = () => {
        dispatch(logoutUser(data));
    };

    const userInitials = getUserInitials(data.first, data.last);
    const themeCol = getUserAvatarColour(userInitials);

    return (
        <Group position='center'>
            <Menu width={200} withArrow placement='center'>
                <Menu.Target>
                    <UnstyledButton
                        sx={{
                            display: 'block',
                            width: '100%',
                            padding: theme.spacing.xs,
                            borderRadius: theme.radius.sm,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                            '&:hover': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            },
                        }}>
                        {data ? (
                            <Group>
                                <Avatar
                                    src={null}
                                    radius='xl'
                                    size='sm'
                                    color={themeCol}
                                    variant='filled'
                                    alt={`${data.first} ${data.last}`}>
                                    {userInitials.charAt(0)}
                                </Avatar>
                                <Box sx={{ flex: 1 }}>
                                    <Text size='sm' weight={500} style={{ textTransform: 'capitalize' }}>
                                        {data.first} {data.last}
                                    </Text>
                                </Box>

                                {/* {theme.dir === 'ltr' ? <ChevronRight size={18} /> : <ChevronLeft size={18} />} */}
                            </Group>
                        ) : null}
                    </UnstyledButton>
                </Menu.Target>

                {/* <Menu.Label></Menu.Label> */}

                {/* <Menu.Item
          icon={<UserCircle size={16} />}
          onClick={() => onUserClick()}
          // rightSection={
          //   <Text size="sm" color="gray">
          //     Lougout
          //   </Text>
          // }
        >
          Your Account
      </Menu.Item> */}
                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IconLogout size={16} />}
                        onClick={() => onUserClick()}
                        // rightSection={
                        //   <Text size="sm" color="gray">
                        //     Lougout
                        //   </Text>
                        // }
                    >
                        Sign Out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group>
    );
}

import { Box, Button, Group, Input, ScrollArea, Skeleton, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCheck, IconPalette, IconSearch, IconX } from "@tabler/icons";
import _ from "lodash";
import { clearNotifications, fetchGuides, getGuidesSuccess } from "modules/Guides/guideSlice";
import { assignGuideToSession, removeGuideFromSession, selectSession } from "modules/Sessions/sessionSlice";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SessionActions } from "utils/constants";
import { useEffectOnce } from "utils/helper";
import { logger } from "utils/logger";

const AddGuide = ({user,guides,session,success,guideSuccess,error,onGuideAction,onCancel}) => {
    
    const dispatch = useDispatch();
    const [isSaving,setIsSaving] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    // const [isTest,setTest] = useState({status:true});
    const [selectedItems,setSelectedItems] = useState([]);
    const [keyword,setKeyword] = useState('');

    const [filteredGuides, setFilteredGuides] = useState(guides);
    

    /**
     * Effect to fetch the guides
     */
    useEffectOnce(() => {
        logger.log('setting is loading')
        setIsLoading(true);
        setSelectedItems([...session.guides])
        // setTest({...isTest,status:true});
        setTimeout(() => {dispatch(fetchGuides())},100);

        return (() => {
            setIsLoading(true);
            dispatch(clearNotifications());
        })
    },[])

    useEffect(() => {

        if(guideSuccess){
            switch (guideSuccess.type){
                case getGuidesSuccess.toString():{
                    setIsLoading(false);
                    // setTest({...isTest,status:false});
                    break;
                }
            }
        }
    },[error,guideSuccess])

    const onItemSelected = (item,selected) => {
        logger.log(item, selectedItems)
        var items = [...selectedItems];
        if(selected){
          items.push({id:item.id});
        }else{
          var tmpItem = items.find((val) => val.id === item.id);
          var index = items.indexOf(tmpItem);
          if (index > -1) {
            items.splice(index, 1);
          }
        }
  
        //setSelectedItems(items);
        //dispatch(selectGuides(items));
        setSelectedItems(items);
        // selectGuides(items);
      }
  
      const onSelectAll = () => {
        // if there are items selected remove them
        if(selectedItems.length){
          setSelectedItems([])
          //dispatch(selectGuides([]));
        }else{
          setSelectedItems([...guides])
          //dispatch(selectGuides([...guideData]));
        }
      }
    
    const onGuideClickAction = (type,guide) => {
        logger.log(type)
        const guides = [...session.guides];
        var si = [...selectedItems];
        if(onGuideAction){
            
            if(type === SessionActions.REMOVE_STYLEGUIDE){
                si = _.reject(si, { id: guide.id });
            }else{
                guides.push(guide);
                si.push(guide);
            }
           
            setSelectedItems(si)
            // dispatch(selectSession({...session,guides}))
            // this won't work with mantine closure
            onGuideAction({type,data:guide})
        }else{
            switch (type){
                case SessionActions.ASSIGN_STYLEGUIDE:{
                    dispatch(assignGuideToSession(guide,session,user));
                    guides.push(guide);
                    si.push(guide);
                    
                    break;
                }
                case SessionActions.REMOVE_STYLEGUIDE:{
                    dispatch(removeGuideFromSession(guide,session,user));
                    si = _.reject(si, { id: guide.id });
                    break;
                }
            }
            setSelectedItems(si)
        }
        
    }

    const isAssigned = (item) => {
        // return session.guides.find(val => val.code === item.code);
        return selectedItems.find(val => val.code === item.code);
    }

    useEffect(() => {

        if (guides) {

            const newData =guides.filter(guide => {
                var match = true;
                if (guide && keyword && keyword.length && match) {
                    match = guide.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ? true : false;
                }
                return match;
    
            })
            setFilteredGuides([...newData]);
        }

    },[keyword])

    return (

        <Stack>
            <Text>Select a Style Guide to attach it to the session.</Text>
            <Group className='container-action-bar' sx={{width:'100%'}} position="apart" height={'100%'} py="xs">
                <Input
                    sx={{flexGrow:1}}
                    icon={<IconSearch />}
                    placeholder="Search Guides"
                    value={keyword}
                    onChange={(e)=> setKeyword(e.currentTarget.value)}
                    rightSection={<IconX size={14} 
                    value={keyword}
                    onChange={setKeyword}
                    placeholder={`Search across ${guides.length} Guides`}
                    // onClick={(e) => setGuideFilter({...guideFilter,keyword:''})} 
                    />}
                />
                
            </Group>
            
            <ScrollArea style={{ height: 450 }}>
                <Stack>
                    {/* {isLoading.toString()} {isTest.status.toString()}{guideSuccess?guideSuccess.type:'null'} */}
                    {isLoading?
                        <>
                        {loaderRows}
                        </>
                    :
                        <>
                        {filteredGuides.map((item,idx) =>
                            { 
                                const assigned = isAssigned(item);
                                return (
                                <Box key={idx} className="list-renderer-item" pt={'md'}>
                                    <Group position='apart' sx={{minWidth:'200px'}}>
                                        <Group  sx={{flexShrink:0}}>
                                        {/* <Checkbox checked={selectedItems.find(val => val.id === row.original.id)?true:false} onChange={(e) => onItemSelected(row.original,e.currentTarget.checked)}></Checkbox> */}
                                            <Group>
                                                {assigned?
                                                <ThemeIcon radius={'xl'}><IconCheck size={14} /></ThemeIcon>
                                                :<IconPalette />}
                                                
                                            </Group>
                                            <Box sx={{flexShrink:1}}>
                                                <Text size='sm' lineClamp={1} sx={{maxWidth:'250px'}}>{item.name}</Text>
                                                <Text size='xs' color="dimmed">{item.descr}</Text>
                                            </Box>
                                        </Group>
                                        <Button sx={{width:100}} size='sm' variant={assigned?'default':'outline'} onClick={() => onGuideClickAction(assigned?SessionActions.REMOVE_STYLEGUIDE:SessionActions.ASSIGN_STYLEGUIDE,item)}>{assigned?'Unassign':'Assign'}</Button>
                                </Group>
                            </Box>
                            )}
                        )}
                        </>
                    }
                    
                {/* <GuideList data={guides} selectedItems={selectedItems} onItemSelected={onItemSelected} onSelectAll={onSelectAll} /> */}
                
                </Stack>
            </ScrollArea>

            <Group position="right" mt="md">
                <Button loading={isSaving} onClick={() => onCancel()}>Done</Button>
            </Group>

        </Stack>
    )
}

const loaderRows = [1,2,3].map((val,key) => (
    <Box key={key} className="list-renderer-item" pt={'md'}>
    <Group position='apart'>
      <Group>
        <Skeleton height={26} radius="xl" width={26} />
        <div>
            <Skeleton height={16} radius="xl" width={200} mb={'xs'} />
            <Skeleton height={10} radius="xl" width={100} />
        </div>
      </Group>
      <Skeleton height={32} radius="sm" width={85} />
    </Group>
    </Box>
  )
)


const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
       user: state.auth.user,
       guides: state.guideReducer.guides,
    //    session: state.sessionReducer.selectedSession,
       guideSuccess: state.guideReducer.success,
       success: state.sessionReducer.success,
       error: state.sessionReducer.error,
    })
  }

const mapDispatch = {  }

export default connect(
    mapStateToProps,
    mapDispatch
  )(AddGuide)

import { v4 as uuidv4 } from 'uuid';

export class GuideCategoryModel {

    constructor (obj){
        this.id = uuidv4();
        this.guidePart = '';
        this.guideSort = '';
        this.descr = '';
        this.name = '';
        this.sort = 0;
        this.url = '';
        this.uploadUrl = '';
        this.shots = [];
        if(obj){
            Object.assign(this, obj);
            this.shots = obj.shots.map(shot => ({...shot,id:uuidv4()}))
        }
        
    }
    
    
    // return {
    //     guideId:guideId?guideId:'',
    //     name:name?name:'',
    //     description:description?description:'',
    //     filePath:filePath?filePath:''
    // }
    
}
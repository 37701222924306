const { useMantineTheme } = require('@mantine/core');
const { useState } = require('react');

const DivButton = (props) => {
    const [btnHover, setBtnHover] = useState(false);
    const theme = useMantineTheme();
    let baseColor = props.variant && props.variant === 'light' ? theme.fn.rgba(theme.colors[props.color][7], 0.2) : theme.colors[props.color][9];

    let fontColor = props.variant && props.variant === 'light' ? theme.fn.rgba(theme.colors[props.color][1], 0.9) : theme.fn.rgba(theme.white, 0.9);

    fontColor = theme.colorScheme === 'light' && props.variant === 'light' ? theme.white : fontColor;
    baseColor = theme.colorScheme === 'light' ? theme.colors[props.color][6] : baseColor;
    const hoverColor = theme.colorScheme === 'dark' ? theme.fn.rgba(baseColor, 0.25) : theme.fn.rgba(theme.colors[props.color][6],.08);

    let border = 'none';
    if(props.variant && props.variant === 'outline'){
        border = theme.colorScheme === 'dark'?`1px solid ${theme.colors[props.color][5]}`:`1px solid ${theme.colors[props.color][4]}`;
        baseColor = 'transparent';
        fontColor = theme.colorScheme === 'dark'?theme.colors[props.color][5]:theme.colors[props.color][4];
    }
    
    return (
        <>
            <div
                style={{
                    display: 'inline-block',
                    padding: '10px 20px',
                    fontWeight: 600,
                    backgroundColor: btnHover ? hoverColor : baseColor,
                    color: fontColor,
                    border: border,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontSize: '14px',
                    lineHeight:'normal',
                    ':hover': {
                        backgroundColor: theme.colors[props.color][0],
                        color: theme.colorScheme === 'dark'?fontColor:theme.colors.white,
                    },
                }}
                onMouseOver={() => setBtnHover(true)}
                onMouseOut={() => setBtnHover(false)}
                onClick={(e) => props.onClick(e)}>
                {props.children}
            </div>
        </>
    );
};

export default DivButton;

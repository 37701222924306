import OpenSeaDragon from 'openseadragon';
import { Box, Center, createStyles, Group, Text, Title } from '@mantine/core';
import { IconCamera } from '@tabler/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'utils/helper';
import ImageControlToolbar from './ImageControlToolbar';
import { CaptureToolbar } from 'core/components/index';
import { logger } from 'utils/logger';
import TagTalent from 'core/components/capture/TagTalent';
import { CaptureEventType } from 'core/components/capture/CaptureThumb';

const useStyles = createStyles((theme) => ({
    container: {
        '.layoutImage': {
            border: '1px solid transparent',
        },
    },
}));

const SingleLayout = ({ image, settings, onImageClick, imageControlSettings, instanceId = 'openSeaDragon', imageLocHash, onCaptureChange, showSettings = true, showToolbar = true }) => {
    const { classes } = useStyles();
    // const [imageHeight, setImageHeight ] = useState(200)
    const containerRef = useRef();
    const imageRef = useRef();

    const [viewer, setViewer] = useState(null);

    const zoomWrapper = useRef(null);
    const controlRef = useRef(null);
    const [showTagTalent, setShowTagTalent] = useState(false);

    var init = useRef(false);

    // const tmpHeight = containerRef.current?containerRef.current.offsetHeight - 90:200;

    // useEffect(() => {
    //     if(viewer && viewer.viewport){

    //         if(imageControlSetting.zoomDirection === ApprovalImageToolbarModes.ZOOM_RESET){
    //             viewer.viewport.goHome();
    //         }else{
    //             let level = imageControlSettings.zoomDirection === ApprovalImageToolbarModes.ZOOM_IN?viewer.viewport.getZoom() + ZOOM_FACTOR:viewer.viewport.getZoom() - ZOOM_FACTOR;
    //             if(level < .5){
    //                 viewer.viewport.goHome();
    //             }else{
    //                 viewer.viewport.zoomTo(level,null,false)
    //             }
    //         }

    //     }

    // },[imageControlSettings])

    useEffect(() => {
        logger.log('IMAGE CHANGE');
        const imageMatch = (img1, img2) => {
            let str1 = img1.split('?')[0];
            let str2 = img2.split('?')[0];
            logger.log(str1, str2);
            return str1 === str2;
        };
        if (image && viewer) {
            var imageUrl = image.previewHi;
            let sourceUrl = viewer.source ? viewer.source.url : '';
            if (!imageMatch(sourceUrl, imageUrl)) {
                logger.log(viewer.tileSources.url, imageUrl);
                // if(imageLocHash && imageLocHash.current && imageLocHash.current[image.id] && imageLocHash.current[image.id] === image.previewMid ){
                //     imageUrl = imageLocHash.current[image.id];
                // }

                viewer.open({
                    type: 'image',
                    url: imageUrl,
                });
            }
        }
    }, [image, viewer]);

    const InitOpenseadragon = useCallback(() => {
        if (viewer) {
            viewer.zoomInButton.tracker.destroy();
            viewer.zoomOutButton.tracker.destroy();
            viewer && viewer.destroy();
            controlRef.current.remove();
        }

        if (viewer && viewer.zoomInButton) {
            viewer.zoomInButton.destroy();
        }

        if (!viewer) {
            const initViewer = OpenSeaDragon({
                id: `${instanceId}`,
                prefixUrl: 'openseadragon/images/',
                animationTime: 0.2,
                blendTime: 0.1,
                constrainDuringPan: false,
                maxZoomPixelRatio: 5,
                // defaultZoomLevel:imageControlSettings.zoomLevel,
                // minZoomLevel: .5,
                visibilityRatio: 1,
                zoomPerScroll: 1.1,
                gestureSettingsMouse: {
                    clickToZoom: false,
                },
                tileSources: {
                    type: 'image',
                    url: image ? image.previewHi : '',
                },
                // toolbar:       "toolbarDiv",
                zoomInButton: `${instanceId}-zoom-in`,
                zoomOutButton: `${instanceId}-zoom-out`,
                homeButton: `${instanceId}-home`,
            });

            setViewer(initViewer);

            // move the controls to the top nav
            var elem = document.getElementById('controllerCol');
            if (elem) elem.append(controlRef.current);
        }
    }, [image, instanceId, viewer]);

    useEffect(() => {
        if (!init.current && image) {
            InitOpenseadragon();
            // setIsInit(true);
            init.current = true;
        }
    }, [image, InitOpenseadragon]);

    useEffectOnce(() => {
        if (!init.current && image) {
            InitOpenseadragon();
            // setIsInit(true);
            init.current = true;
        }

        return () => {
            viewer && viewer.zoomInButton.destroy();
            viewer && viewer.zoomOutButton.destroy();
            viewer && viewer.destroy();
            //controlRef.current && document.getElementById('openseacontroller').remove();

            var elem = document.getElementById(`${instanceId}-controller`);
            if (elem) {
                elem.remove();
            }

            if (viewer && viewer.zoomInButton) {
                viewer.zoomInButton.destroy();
            }

            logger.log('CLEANUP');
        };
    }, []);

    return (
        <Box className={classes.container} spacing={'lg'} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} ref={containerRef}>
            {image ? (
                <div
                    ref={zoomWrapper}
                    className='layoutImage'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}>
                    {showToolbar ? (
                        <div ref={controlRef} id={`${instanceId}-controller`} className='openseacontrols'>
                            <ImageControlToolbar instanceId={instanceId} settings={settings} />
                        </div>
                    ) : null}
                    <div
                        className={classes.container}
                        id={`${instanceId}`}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}></div>
                </div>
            ) : (
                <Center sx={{ height: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <IconCamera size={48} stroke={1} />
                        <Title color={'dimmed'} order={3}>
                            Select an Image
                        </Title>
                    </div>
                </Center>
            )}

            {/* <ImageThumb onClick={onImageClick} /> */}
            {/* <div sx={{flexGrow:1, flexDirection:'column'}}> */}

            {/* <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', flexGrow:1,  }}>
                
                {image?
                    <BackgroundImage className='layoutImage' sx={{height:'100%',backgroundSize:'contain', backgroundRepeat:'no-repeat', border:'1px solid transparent'}}
                    src={image?image.filePath:''}
                    radius="sm" />
                :
                    <Center sx={{height:'100%'}}>
                        <div style={{textAlign:'center'}}>
                        <IconCamera size={48} stroke={1} />
                        <Title color={'dimmed'} order={3}>Select an Image</Title>
                        </div>
                    </Center>
                } 
                

            </div> */}

            {/* <Image src={tmpURL} ref={imageRef}
                // width={'100%'}
                // style={{flexGrow:0}}
                height="200"
                width="200"
                fit="contain"
                /> */}

            {/* </div> */}
            {image && showSettings ? (
                <Group position='center' px={'xs'} py={'xs'} sx={{ width: imageRef.current?.outerWidth }}>
                    <Group position='apart'>
                        <Text size={'xs'}>{image.name}</Text>
                        <TagTalent opened={showTagTalent} onChange={setShowTagTalent} captures={[image]}>
                            <div>
                                <CaptureToolbar
                                    onCaptureChange={(type, data) => {
                                        onCaptureChange(type, data, [image]);
                                        if (type === CaptureEventType.TAG_TALENT) {
                                            setShowTagTalent(true);
                                        }
                                    }}
                                    rating={image.star}
                                    status={image.sak}
                                />
                            </div>
                        </TagTalent>
                    </Group>
                </Group>
            ) : null}
        </Box>
    );
};

export default SingleLayout;

import { createSlice } from '@reduxjs/toolkit'
import { getAggData, getDepartments } from 'services/approvalService';
import { getStyleTimeline } from 'services/styleService';
import { logger } from 'utils/logger';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading:false,
    previousRoute:'',
    aggData:null,
    style:null,
    filter:{
      keyword:''
    }
    // history:{
    //   styles:[],
    //   sessions:[],
    //   seasons:[],
    //   tasks:[],
    // }
  },
  reducers: {
    setLoading(state, action) {
      logger.log('setting loading', action.payload)
      state.loading = action.payload;
    },
    setPreviousRoute(state,action){
      logger.log('setting prev route', action)
      state.previousRoute = action.payload;
    },
    setFilter(state,action){
      state.filter = action.payload
      if(action.payload && !action.payload.keyword.trim().length){
        state.style = null;
      }
    },
    
    // setHistory(state,action){
    //   state.history = action.payload;
    // },

    //////////////////////
    //
    // GET STYLES
    //
    //////////////////////
    getStyleStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getStyleSuccess(state, action) {
      logger.log('getStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Styles Success' };

      state.style = {...action.payload.Timeline};
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getStyleFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    onServiceStart(state,action){
      state.success = null;
      state.loading = true;
      state.error = null;
      // state.action = action.type;
    },
    onServiceFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload;
      state.loading = false;
    },

    //////////////////////
    //
    // AGG DATA
    //
    //////////////////////
    getAggDataSuccess(state,action) {
      state.loading = false;
      state.success = {type:action.type,message:'Aggregate data recieved'}
      state.aggData = {...action.payload}
      state.error = null
    },

    //////////////////////
    //
    // DEPARTMENT DATA
    //
    //////////////////////
    getDepartmentsSuccess(state,action) {
      state.loading = false;
      state.success = {type:action.type,message:'Departments recieved'}
      state.departments = [...action.payload]
      state.error = null
    }


  }
})


//////////////////
// AGG DATA
//////////////////
export const fetchAggData = ( id, markup, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await getAggData()
    dispatch(getAggDataSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}

//////////////////
// DEPARTMENTS
//////////////////
export const fetchDepartments = () => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await getDepartments()
    dispatch(getDepartmentsSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}


export const fetchStyle = (criteria) => async (dispatch) => {
  try {
    dispatch(getStyleStart());
    const data = await getStyleTimeline({ id: criteria.keyword });
    logger.log(data);
    dispatch(getStyleSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};

export const { 
  setLoading
  ,setPreviousRoute
  , getAggDataSuccess
  , getDepartmentsSuccess
  , setFilter
  // , setHistory
  , getStyleStart
  , getStyleSuccess
  , getStyleFailure

  , onServiceStart
  , onServiceFailure
} = appSlice.actions

export default appSlice.reducer

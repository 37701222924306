import { createStyles, Image, Container, Title, Text, Button, SimpleGrid, Center } from '@mantine/core';
import image from './../../../assets/images/error.svg';
import { clearLocalStorage } from "app/store";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
    height:'100vh',
    display:'flex'
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

export function ErrorView() {
  const { classes } = useStyles();

  const onClick = () => {
    clearLocalStorage();
    window.location.reload();
  }

  return (
    <Container className={classes.root}>

        <Center>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Whoops something isn't right...</Title>
          <Text color="dimmed" size="lg">
            Unfortunately there's been an error. This has been logged and the Altitude team has been notified.
          </Text>
          <Button variant="outline" size="md" mt="xl" className={classes.control} onClick={() => onClick()}>
            Reload
          </Button>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
      </Center>
    </Container>
  );
}
import { createStyles, Image, Container, Text, Center, Stack, useMantineTheme } from '@mantine/core';
import image from '../../../assets/images/empty.svg';
import imageLight from '../../../assets/images/empty-light.svg';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
    display:'flex',
    justifyContent:'center'
  },
  emptyImage:{
    width:100,
  }

}));

export function EmptyState({style,showImage=true,caption="There are no records available"}) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <Container className={classes.root} style={style}>
        <Center>
        
        <Stack align={'center'}>
            {showImage?
            <div className={classes.emptyImage}>
            <Image src={theme.colorScheme === 'dark'?image:imageLight} />
            </div>
            :null}
            
            <div>          
            <Text color="dimmed" size="sm" align="center">
                {caption}
            </Text>         
            </div>
        </Stack>
      </Center>
    </Container>
  );
}
import { Group, Indicator, Text } from "@mantine/core";

const { forwardRef } = require("react");

export const StatusSelectRenderer = forwardRef(({ image, label, color, description, ...others }, ref) => (
    <div ref={ref} {...others}>
        <Group noWrap>
            <Indicator position='middle-start' color={color}>
                <Text sx={{ paddingLeft: 12 }} size='sm'>
                    {label}
                </Text>
            </Indicator>
        </Group>
    </div>
));
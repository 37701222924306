import { Anchor, Avatar, Badge, Box, Button, Divider, Group, Input, Modal, MultiSelect, Select, Table, Text, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons';
import { FormInputWrapper } from 'core/components/';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import StyleGuideTableRow from './StyleGuideTableRow';
import { GuideEventType, GuideModel } from 'core/models/GuideModel';
import { closeModal, openModal } from '@mantine/modals';
import AddGuide from './AddGuide';
import { connect, useDispatch } from 'react-redux';
import { selectSession } from 'modules/Sessions/sessionSlice';
import { SessionModel } from 'core/models';
import { DataFormats, SessionActions, ViewModes, shotChannels } from 'utils/constants';
import { downloadFile, getUserAvatarColour, getUserInitials, pluralize } from 'utils/helper';
import { selectGuide } from 'modules/Guides/guideSlice';
import GuideEdit from 'modules/Guides/features/guide/GuideEdit';
import _ from 'lodash';
import TeamItemMenu from '../teams/TeamItemMenu';
import { TeamEventType } from 'core/models/TeamMemberModel';
import Moment from 'react-moment';
import AddTeamMembers from './AddTeamMembers';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import { TagInput } from 'core/components/index';

const SessionEdit = ({ session, sessionData, guides, onCancel, onSubmit, styles, availableStyles, season, aggData, mode = ViewModes.ADD }) => {
    const [active, handlers] = useDisclosure(false);
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState(sessionData ?? session);

    const [isTeamModalOpened, teamModalDisclosure] = useDisclosure(false);
    const [isGuideModalOpened, guideModalDisclosure] = useDisclosure(false);

    const [styleData] = useState(
        availableStyles
            ? [
                  ...availableStyles.map((val) => {
                      return { label: val.targetSort, value: val.id };
                  }),
              ]
            : [],
    );

    const [styleGuides, setStyleGuides] = useState([]);
    const dispatch = useDispatch();

    const [tags, setTags] = useState([
        ...data?.tags.map((val) => {
            return { label: val, value: val };
        }),
    ]);

    logger.log(data);

    useEffect(() => {
        if (data && data.guides) {
            setStyleGuides([...data.guides]);
        }
    }, [data, data.guides]);

    useEffect(() => {
        if (guides && data) {
            if (data && data.guides) {
                // const guideList = session.guides.map(val => val.id);

                // dispatch(fetchSessionGuides(guideList,user));
                var sessionGuides = [...data.guides];
                for (let i = 0; i < sessionGuides.length; i++) {
                    const guide = guides.find((val) => val.code === sessionGuides[i].code);
                    if (guide) {
                        sessionGuides[i] = { ...guide };
                    }
                }

                const tmpSession = { ...data, guides: sessionGuides };
                logger.log(JSON.stringify(sessionGuides), data.guides.toString());
                if (JSON.stringify(sessionGuides) !== JSON.stringify(data.guides)) {
                    // dispatch(selectSession(tmpSession));
                    setData(tmpSession);
                }
            }
        }
    }, [guides, dispatch, data?.guides]);

    const form = useForm({
        initialValues: {
            name: data.name,
            code: data.code,
            description: data.description,
            season: season ? season.name : '',
            client: data.client,
            seasonCode: season ? season.seasonSort : '',
            shootDate: data.shootDate,
            channel: data.channel,
            deadline: season ? new Date(season.deadline) : data.deadline,
            tags: data.tags,

            styles: styles ? [...styles.map((val) => val.id)] : [],
            guides: [],
        },

        validate: {
            // client: (value) => (value.length ? null : 'Client is required'),
            channel: (value) => (value.length ? null : 'Channel is required'),
            shootDate: (value) => (value ? null : 'Shoot Date is required'),
            //   name: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    useEffect(() => {
        logger.log(active);
        if (!active) {
            logger.log('toggling focus ');
            setTimeout(() => {
                handlers.toggle();
            }, 1000);
        }
    }, [handlers, active]);

    useEffect(() => {
        // Specify how to clean up after this effect:
        return function cleanup() {
            logger.log('cleanup');
            if (active) handlers.toggle();
        };
    });

    //   useEffect(() => {
    //     if(!session){
    //         dispatch(selectSession(new SessionModel()))
    //     }
    //   },[])

    const onGuideAction = useCallback(
        (event) => {
            const openGuideViewModal = () => {
                openModal({
                    title: 'Guide View',
                    size: 'xl',
                    modalId: 'guideView',
                    children: (
                        <>
                            <GuideEdit session={data} mode={ViewModes.VIEW} />
                        </>
                    ),
                });
            };

            const openAddGuideModal = () => {
                const tmpSession = { ...form.values, team: [...data.team] };
                // dispatch(selectSession(tmpSession));
                // setData(tmpSession);
                guideModalDisclosure.open();
                // openModal({
                //     title: 'Assign Style Guide',
                //     size: 'lg',
                //     modalId: 'guideAdd',
                //     children: (
                //         <>
                //             <AddGuide
                //                 session={form.values}
                //                 onGuideAction={(event) => onGuideAction(event)}
                //                 // user={user}
                //                 // task={selectedTask}
                //                 onCancel={() => {
                //                     closeCurrentModal('guideAdd');
                //                 }}
                //                 //   onMenuClick={onTaskMenuClick}
                //             />
                //         </>
                //     ),
                //     // onClose:closeGuideModal,
                // });
            };

            switch (event.type) {
                case GuideEventType.GUIDE_ADD: {
                    logger.log(event);
                    // const guide = new GuideModel(data);
                    // logger.log(guide);
                    const tmpSession = new SessionModel();
                    // dispatch(selectSession(tmpSession));
                    // setData(tmpSession);
                    // dispatch(selectGuide(guide));
                    openAddGuideModal();
                    break;
                }

                //  this is now handled in the AddGuide.js
                case SessionActions.ASSIGN_STYLEGUIDE: {
                    // add the guide to the session.

                    // var localGuides = [...session.guides];
                    var localGuides = [...styleGuides];
                    localGuides.push(event.data);

                    setData({...data,guides:localGuides})
                    // setStyleGuides(localGuides);

                    // const tmpSession = {...session,guides:localGuides};

                    // dispatch(selectSession(tmpSession));

                    break;
                }
                case GuideEventType.GUIDE_VIEW: {
                    const guide = new GuideModel(event.data);

                    dispatch(selectGuide(guide));
                    openGuideViewModal();
                    break;
                }
                case GuideEventType.GUIDE_DOWNLOAD: {
                    logger.log(event);
                    downloadFile(event.data.url, event.data.name);
                    break;
                }
                case SessionActions.REMOVE_STYLEGUIDE: {
                    // we need to remove the guide from the session.styles
                    var guides = [...data.guides];
                    var newGuides = _.reject(guides, function (obj) {
                        return obj.id === event.data.id;
                    });
                    const tmpSession = { ...data, guides: newGuides };
                    // dispatch(selectSession(tmpSession));
                    setData(tmpSession);
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [dispatch, data, styleGuides, form.values],
    );

    const styleGuideRows = useMemo(() => {
        const rows = styleGuides.map((element, idx) => {
            return (
                <React.Fragment key={idx}>
                    <StyleGuideTableRow mode={mode} element={element} index={idx} onGuideAction={(event, data) => onGuideAction({ ...event, data })} />
                </React.Fragment>
            );
        });

        return rows ? rows : [];
    }, [styleGuides, mode, onGuideAction]);

    const closeCurrentModal = (id) => {
        closeModal(id);
    };

    const onAddTeamClick = () => {
        openTeamModal();
    };

    const openTeamModal = () => {
        // const tmpSession = {...data, ...form.values, team: [...data.team], tags: [...tags.map((val) => val.value)] };
        // dispatch(selectSession(tmpSession));
        // setData(tmpSession);
        teamModalDisclosure.open();

        // openModal({
        //     title: 'Add Team Members',
        //     size: 'xl',
        //     modalId: 'teamView',
        //     children: (
        //         <>
        //             <AddTeamMembers
        //                 sessionForm={{ ...form.values }}
        //                 session={data}
        //                 mode={ViewModes.VIEW}
        //                 onTeamAction={(teamMembers) => onTeamAction({ type: TeamEventType.TEAM_ADD_MEMBERS }, teamMembers)}
        //             />
        //         </>
        //     ),
        // });
    };

    const onTeamAction = (event, teamData) => {
        var tmpTeam = data.team ? [...data.team] : [];
        switch (event.type) {
            case TeamEventType.TEAM_ADD_MEMBERS: {
                tmpTeam = [...teamData];
                const tmpSession = { ...data, ...form.values, team: tmpTeam, guides:styleGuides };
                //dispatch(selectSession(tmpSession));
                setData({ ...tmpSession });

                closeModal('teamView');
                teamModalDisclosure.close();
                break;
            }
            case TeamEventType.TEAM_ADD_MEMBER: {
                tmpTeam.push({ ...teamData });
                const tmpSession = { ...data, ...form.values, team: tmpTeam, guides:styleGuides };
                //dispatch(selectSession(tmpSession));
                setData(tmpSession);

                closeModal('teamView');
                teamModalDisclosure.close();
                break;
            }
            case TeamEventType.TEAM_DELETE_MEMBER: {
                tmpTeam = _.reject(data.team, (obj) => obj.name === teamData.name);

                const tmpSession = { ...data, ...form.values, team: tmpTeam, guides:styleGuides };
                //dispatch(selectSession(tmpSession));
                setData(tmpSession);

                break;
            }
            default: {
                break;
            }
        }
    };

    return (
        <>
            {mode === ViewModes.VIEW ? (
                <Box>
                    {/* <FocusTrap active={true}> */}
                    <FormInputWrapper labelWidth={100} label='Name:' id='input-name'>
                        <Text pt={4} size={'sm'} className='value-readonly'>
                            {form.values.name}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Code:' id='input-code'>
                        <Text pt={4} size={'sm'} className='value-readonly'>
                            {form.values.code}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Description:' id='input-desc'>
                        <Text pt={4} size={'sm'} className='value-readonly'>
                            {form.values.description}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Client:'>
                        <Text pt={4} size={'sm'} color={form.values.client ? '' : 'dimmed'}>
                            {form.values.client ? form.values.client : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Season:' id='input-season'>
                        {form.values.seasonCode ? (
                            <Anchor href={`/seasons/${form.values.seasonCode}`} size='sm'>
                                <Text pt={4} size='sm' lineClamp={1}>
                                    {form.values.season}
                                </Text>
                            </Anchor>
                        ) : (
                            <Text pt={4} size={'sm'} color={'dimmed'}>
                                'No Value'
                            </Text>
                        )}
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Channel:'>
                        <Text pt={4} size={'sm'} color={form.values.channel ? '' : 'dimmed'}>
                            {form.values.channel ? form.values.channel : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Shoot Date:' id='input-dates'>
                        <Text pt={4} size={'sm'} color={form.values.shootDate ? '' : 'dimmed'}>
                            {form.values.shootDate ? <Moment format={DataFormats.DATE_FORMAT}>{form.values.shootDate}</Moment> : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Deadline:' id='input-dates'>
                        <Group pt={4}>
                            <Badge>
                                <Moment fromNow>{form.values.deadline}</Moment>
                            </Badge>
                            <Text size={'sm'}>
                                <Moment format={DataFormats.DATE_FORMAT}>{form.values.deadline}</Moment>
                            </Text>
                        </Group>
                    </FormInputWrapper>

                    {/* <FormInputWrapper labelWidth={100} label='Department:' id='input-department'>
                        <Text pt={4} size={'sm'} className='value-readonly'>
                            {form.values.department}
                        </Text>
                    </FormInputWrapper> */}

                    <FormInputWrapper labelWidth={100} label='Tags:' id='input-tags'>
                        {tags.map((val, idx) => (
                            <Badge key={idx} color='violet'>
                                {val.label}
                            </Badge>
                        ))}

                        {/* <Text pt={4} size={'sm'} color={form.values.tags.length ? '' : 'dimmed'}>
                            {form.values.tags.length ? form.values.tags.length : 'No Value'}
                        </Text> */}
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Styles:' id='input-styles'>
                        <Text pt={4} size={'sm'} color={form.values.styles.length ? '' : 'dimmed'}>
                            {form.values.styles.length ? form.values.styles.length : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <Divider mt={'lg'} />

                    <Box py='lg'>
                        <Group position='apart' pt={'xl'}>
                            <Group position='center' mb='lg'>
                                <Title className='main-content-heading' order={5}>
                                    Team
                                </Title>
                                <Badge>
                                    {data.team?.length} {pluralize('Member', data?.team?.length)}
                                </Badge>
                            </Group>
                            {mode === ViewModes.VIEW ? null : (
                                <Button sx={{ width: 120 }} onClick={() => onAddTeamClick()} leftIcon={<IconPlus size={14} />} variant={'light'}>
                                    Add
                                </Button>
                            )}
                        </Group>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Person</th>
                                    <th width={200}>Role</th>
                                    <th width={100}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.team?.map((member, idx) => {
                                    const userInitials = getUserInitials(member.name.split(' ')[0], member.name.split(' ')[1]);
                                    const themeCol = getUserAvatarColour(userInitials);
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Group spacing='sm' noWrap>
                                                    <Avatar variant='filled' title={`${member.name} - ${member.role}`} color={themeCol} size={30} radius={40}>
                                                        {member.name.charAt(0)}
                                                    </Avatar>
                                                    <div>
                                                        <Text size='sm' weight={500}>
                                                            {member.name}
                                                        </Text>
                                                        <Text size='xs' color='dimmed' truncate>
                                                            {member.email}
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </td>
                                            <td
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}>
                                                {member.role}
                                            </td>

                                            <td align='right'>{mode === ViewModes.VIEW ? null : <TeamItemMenu onMenuItemClick={(event, data) => onTeamAction(event, member)} />}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {data?.team && data.team.length ? null : <EmptyState caption='There are no team members assigned' />}
                    </Box>

                    <Divider mt={'lg'} />

                    <Box py='lg'>
                        <Group position='apart' align='center'>
                            <Group position='center' my='lg'>
                                <Title className='main-content-heading' order={5}>
                                    Assigned Style Guides
                                </Title>
                                <Badge>
                                    {data?.guides?.length} {pluralize('Style Guide', data?.guides?.length)}
                                </Badge>
                            </Group>
                            {mode === ViewModes.VIEW ? null : (
                                <Button sx={{ width: 120 }} onClick={() => onGuideAction({ type: GuideEventType.GUIDE_ADD })} leftIcon={<IconPlus size={14} />} variant={'light'}>
                                    Assign
                                </Button>
                            )}
                        </Group>

                        <Table verticalSpacing={'sm'}>
                            <thead>
                                <tr>
                                    <th>Guide</th>
                                    <th>Description</th>

                                    <th width={100}></th>
                                </tr>
                            </thead>
                            <tbody>{styleGuideRows}</tbody>
                        </Table>

                        {styleGuideRows.length ? null : <EmptyState caption='There are no styleguides assigned' />}
                    </Box>
                </Box>
            ) : (
                <form
                    onSubmit={form.onSubmit((values) => {
                        onSubmit({ ...values, guides: data.guides, team: [...data.team] });
                        setIsLoading(true);
                    })}>
                    {/* <FocusTrap active={true}> */}
                    <FormInputWrapper label='Name' id='input-name' required>
                        <Input id='input-name' required data-autofocus placeholder='Enter Name' {...form.getInputProps('name')} />
                    </FormInputWrapper>

                    <FormInputWrapper label='Description' id='input-desc'>
                        <Textarea id='input-desc' placeholder='Description' {...form.getInputProps('description')} />
                    </FormInputWrapper>

                    {/* <FormInputWrapper label='Season' id='input-season'>
                        <Input id='input-season' placeholder='Enter Season' {...form.getInputProps('season')} />
                    </FormInputWrapper> */}
                    {/* <FormInputWrapper label='Client' required>
                        <Select
                            required
                            placeholder='Select client'
                            sx={{ width: 200 }}
                            {...form.getInputProps('client')}
                            data={aggData.clients.map((val) => ({ label: val.name, value: val.code }))}
                        />
                    </FormInputWrapper> */}

                    <FormInputWrapper label='Event' id='input-season'>
                        <Text pt={4} size={'sm'} color={form.values.season ? '' : 'dimmed'}>
                            {form.values.season ? form.values.season : 'No Value'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper label='Channel' required>
                        <Select required placeholder='Select channel' sx={{ width: 200 }} {...form.getInputProps('channel')} data={shotChannels} />
                    </FormInputWrapper>

                    <FormInputWrapper label='Shoot Date' id='input-dates' required>
                        <Group>
                            <CustomDatePicker sx={{ width: 200 }} placeholder='Shoot Date' required {...form.getInputProps('shootDate')} />
                        </Group>
                    </FormInputWrapper>

                    <FormInputWrapper label='Deadline' id='input-dates' required>
                        <Group>
                            <CustomDatePicker sx={{ width: 200 }} placeholder='Deadline' required {...form.getInputProps('deadline')} />
                        </Group>
                    </FormInputWrapper>
                    {/* <FormInputWrapper label='Department' id='input-department'>
                        <Input id='input-name' required data-autofocus placeholder='Enter Department' {...form.getInputProps('department')} />
                    </FormInputWrapper> */}

                    <FormInputWrapper label='Tags' id='input-tags'>
                        <TagInput
                            {...form.getInputProps('tags')}
                            onChange={(val) => {
                                setTags([...val.map((item) => ({ value: item, label: item }))]);
                                form.setFieldValue('tags', val);
                            }}
                        />

                        {/* <MultiSelect
                            size='sm'
                            data={tags}
                            placeholder='Enter Tags'
                            searchable
                            creatable
                            {...form.getInputProps('tags')}
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = {
                                    value: query,
                                    label: query,
                                };
                                setTags((current) => [...current, item]);
                                return item;
                            }}
                        /> */}
                    </FormInputWrapper>

                    <FormInputWrapper label='Styles' id='input-tags'>
                        <MultiSelect data={styleData} searchable placeholder='Type to enter Styles' {...form.getInputProps('styles')} />
                    </FormInputWrapper>

                    <Divider mt={'lg'} />

                    <Box py='lg'>
                        <Group position='apart' pt={'xl'}>
                            <Group position='center' mb='lg'>
                                <Title className='main-content-heading' order={5}>
                                    Team
                                </Title>
                                <Badge>
                                    {data.team?.length} {pluralize('Member', data?.team?.length)}
                                </Badge>
                            </Group>
                            <Button sx={{ width: 120 }} onClick={() => onAddTeamClick()} leftIcon={<IconPlus size={14} />} variant={'light'}>
                                Add
                            </Button>
                        </Group>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Person</th>
                                    <th width={200}>Role</th>
                                    <th width={100}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.team?.map((member, idx) => {
                                    const userInitials = getUserInitials(member.name.split(' ')[0], member.name.split(' ')[1]);
                                    const themeCol = getUserAvatarColour(userInitials);
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <Group spacing='sm'>
                                                    <Avatar variant='filled' title={`${member.name} - ${member.role}`} color={themeCol} size={30} radius={40}>
                                                        {member.name.charAt(0)}
                                                    </Avatar>
                                                    <div>
                                                        <Text size='sm' weight={500}>
                                                            {member.name}
                                                        </Text>
                                                        <Text size='xs' color='dimmed'>
                                                            {member.email}
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </td>
                                            <td
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}>
                                                {member.role}
                                            </td>
                                            <td align='right'>{mode === ViewModes.VIEW ? null : <TeamItemMenu onMenuItemClick={(event, data) => onTeamAction(event, member)} />}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {data?.team && data.team.length ? null : <EmptyState caption='There are no team members assigned' />}
                    </Box>

                    <Divider mt={'lg'} />

                    <Box py='lg'>
                        <Group position='apart' align='center'>
                            <Group position='center' my='lg'>
                                <Title className='main-content-heading' order={5}>
                                    Assigned Style Guides
                                </Title>
                                <Badge>
                                    {data?.guides?.length} {pluralize('Style Guide', data?.guides?.length)}
                                </Badge>
                            </Group>
                            <Button sx={{ width: 120 }} onClick={() => onGuideAction({ type: GuideEventType.GUIDE_ADD })} leftIcon={<IconPlus size={14} />} variant={'light'}>
                                Assign
                            </Button>
                        </Group>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Guide</th>
                                    <th>Description</th>
                                    {/* <th>Created Date</th>
                    <th>Status</th> */}
                                    <th width={100}></th>
                                </tr>
                            </thead>
                            <tbody>{styleGuideRows}</tbody>
                        </Table>

                        {styleGuideRows.length ? null : <EmptyState caption='There are no styleguides assigned' />}
                    </Box>

                    {/* <Divider mt={'lg'} />

            <Stack my={'lg'} spacing={0}>
                <Title order={5}>Selected Style</Title>
                <Group>
                {styles.map((val,idx) => <Badge key={idx}>{val.seasonSort}</Badge>)}

                <Box key={idx} className="list-renderer-item" pt={'md'}>
                                <Group position='apart' sx={{minWidth:'200px'}}>
                                    <Group  sx={{flexShrink:0}}>
                                    
                                        <Group>
                                            {assigned?
                                            <ThemeIcon radius={'xl'}><IconCheck size={14} /></ThemeIcon>
                                            :<IconPalette />}
                                            
                                        </Group>
                                        <Box sx={{flexShrink:1}}>
                                            <Text size='sm' lineClamp={1} sx={{maxWidth:'250px'}}>{item.name}</Text>
                                            <Text size='xs' color="dimmed">{item.descr}</Text>
                                        </Box>
                                    </Group>
                                  
                                    <Button variant='light'>Remove</Button>
                            </Group>
                        </Box>

                </Group>

                
            </Stack> */}

                    <Group position='right' mt='md'>
                        <Button type='submit' loading={isLoading}>
                            Create
                        </Button>
                    </Group>
                    {/* </FocusTrap> */}
                </form>
            )}

            <Modal zIndex={10001} opened={isTeamModalOpened} onClose={teamModalDisclosure.close} size='auto' title='Add Team Members'>
                <AddTeamMembers
                    sessionForm={{ ...form.values }}
                    session={data}
                    mode={ViewModes.VIEW}
                    onTeamAction={(teamMembers) => onTeamAction({ type: TeamEventType.TEAM_ADD_MEMBERS }, teamMembers)}
                />
            </Modal>

            <Modal zIndex={10001} opened={isGuideModalOpened} onClose={guideModalDisclosure.close} size='auto' title='Assign Style Guides'>
                <AddGuide
                    session={{...data,...form.values, guides:styleGuides}}
                    onGuideAction={(event) => onGuideAction(event)}
                    // user={user}
                    // task={selectedTask}
                    onCancel={() => {
                        closeCurrentModal('guideAdd');
                        guideModalDisclosure.close();
                    }}
                    //   onMenuClick={onTaskMenuClick}
                />
            </Modal>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        session: state.sessionReducer.selectedSession,
        guides: state.guideReducer.guides,
        aggData: state.appReducer.aggData,
        success: state.sessionReducer.success,
        error: state.sessionReducer.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(SessionEdit);

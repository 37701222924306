import { Box, Button, ColorInput, Group, Input, MultiSelect, Popover, Switch, Text, Title } from '@mantine/core';

import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { fetchGuides, getGuidesSuccess, selectGuide, selectGuides, setGuideFilter } from 'modules/Guides/guideSlice';
import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CheckboxDropdown, FilterButton, FormInputWrapper } from 'core/components';

// import GuideList from './GuideList';
import { GuideActions, ViewModes } from 'utils/constants';

import { useForm } from '@mantine/form';
import GuideList from './GuideList';
import GuideEdit from '../guide/GuideEdit';
import { IconSearch, IconX } from '@tabler/icons';
import { GuideModel } from 'core/models/GuideModel';
import { selectItems, useEffectOnce } from 'utils/helper';
import { logger } from 'utils/logger';
import { openModal } from '@mantine/modals';

// import Dashboard from './features/components/Dashboard';

const Guides = ({ user, guide, guides, selectedGuides, success, error, setGuideFilter, guideFilter, selectGuides }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterOpen, setFilterOpen] = useState(false);
    const [listView, setListView] = useState(ListViewOption.LIST);
    const navigate = useNavigate();
    const location = useLocation();

    const [filterCount, setFilterCount] = useState(0);
    const [guideData, setGuideData] = useState(guides);
    const [filterItems, setFilterItems] = useState(guideFilter);
    const [guideModalOpen, setGuideModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const selectAllMenu = (
        <div>
            {/* <Button variant='default' onClick={(e) => onMenuItemClick(e)}>Create new Guide</Button>
      <Button variant='default' onClick={(e) => onMenuItemClick(e)}>Add to Guide</Button>
      <Button variant='default' onClick={(e) => onMenuItemClick(GuideActions.BUILD_OUTFIT)}>Build Outfit</Button> */}
            <Button variant='default' onClick={(e) => onSelectAll(e)}>
                Deselect All
            </Button>
        </div>
    );

    // let items = [
    //   { title: 'Warehouse', href: '/warehouse' },
    //   { title: 'Item Name', href: '#' },
    // ];

    logger.log(guideFilter, ' JOB FILTER');

    /**
     * Create the crumbline items - dev only
     */
    /*const crumbs = items.map((item, index) => {
      const weight = (index+1) < items.length?'400':'700';
      return (      
        <Anchor href={item.href} key={index}>
          <Text weight={weight} >{item.title}</Text>
        </Anchor>
      )
    });

    useEffect(() => {
      logger.log('---------- SELECTED SAMPLES CHANGE : ', selectedGuides)
    },[selectedGuides])

    /**
     * Handles the list toggle display
     * @param {*} val 
     */
    const listToggleHandler = (val) => {
        navigate({ search: `?displayType=${val}` });
    };

    const filterGuides = useCallback(() => {
        const data = guides.filter((item) => {
            var match = true;

            // filter by brand
            if (guideFilter.brands && guideFilter.brands.length) {
                match = guideFilter.brands.indexOf(item.facet_brand) > -1 ? true : false;
            }

            if (guideFilter.categories && guideFilter.categories.length && match) {
                match = guideFilter.categories.indexOf(item.metadata_toplevelcategory) > -1 ? true : false;
            }

            if (guideFilter.keyword && guideFilter.keyword.length && match) {
                match = item.descr.toLowerCase().indexOf(guideFilter.keyword.toLowerCase()) > -1 ? true : false;
                if (!match) {
                    match = item.name.toLowerCase().indexOf(guideFilter.keyword.toLowerCase()) > -1 ? true : false;
                }
            }

            return match;
        });

        setGuideData(data);
    }, [guideFilter.brands, guideFilter.categories, guideFilter.keyword, guides]);

    /**
     * Effect to fetch the guides
     */
    useEffectOnce(() => {
        setTimeout(() => {
            dispatch(fetchGuides());
            setIsLoading(true);
        }, 100);
    }, [dispatch]);

    useEffect(() => {
        logger.log('SUCCESS', success);
        if (success && success.type === getGuidesSuccess.toString()) {
            setIsLoading(false);
        }
    }, [success]);

    useEffect(() => {
        //setGuideData(guides);

        // set the brands/season/category
        var brands = {};
        var categories = {};
        var seasons = {};

        guides.forEach((item, idx) => {
            brands[item.facet_brand] = item;
            categories[item.metadata_toplevelcategory] = item;
            seasons[item.facet_season] = item;
        });

        setFilterItems({ brands, categories, seasons });

        filterGuides();
    }, [guide, filterGuides, guides]);
    /**
     * Effect to handle location change
     */
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let displayType = params.get('displayType');
        logger.log('location change ', location, ' DISPLAY: ', displayType, params);
        // if display type changes
        if (displayType && displayType !== listView) setListView(displayType);
    }, [location, listView]);

    const onItemClick = (item) => {
        logger.log(item)
        // we need to select the current guide
        dispatch(selectGuide(item));

        // update the route
        navigate(`/styleguides/${item.guideSort}`);
        // openGuideModal();
    };

    const onItemSelected = (e, item, selected, rows) => {
        logger.log(item, selectedGuides);
        var items = [...selectedGuides];
        // call general helper to select items
        selectItems(e, item, selected, rows.map(val => val.original), items);
        //setSelectedItems(items);
        //dispatch(selectGuides(items));
        selectGuides(items);
    };

    const onSelectAll = () => {
        // if there are items selected remove them
        if (selectedGuides.length) {
            //setSelectedItems([])
            dispatch(selectGuides([]));
        } else {
            //setSelectedItems([...elements])
            dispatch(selectGuides([...guideData]));
        }
    };

    const onMenuItemClick = (e) => {
        switch (e) {
            case GuideActions.BUILD_OUTFIT: {
                navigate(`/guides/create`);
                break;
            }
            default: {
                break;
            }
        }
    };

    const setFilterVars = (vals) => {
        logger.log(vals, ' FILTER VALS');
        setGuideFilter(vals);
    };

    useEffect(() => {
        // filter the data.
        filterGuides();
    }, [guideFilter, filterGuides]);

    const getFilterCount = (val) => {
        var count = 0;
        Object.entries(val).forEach(([key, value]) => {
            logger.log(`${key}: ${value}`);
            if (value.length) {
                ++count;
            }
        });

        return count;
    };

    useEffect(() => {
        const count = getFilterCount(guideFilter);
        setFilterCount(count);
    }, [guideFilter]);

    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();

        setGuideFilter({ brands: [], department: [], location: [], status: [], colour: '', keyword: '' });

        logger.log('CLEAR CLICK', e);
    };

    const filterButtonHandler = (e) => {
        logger.log('BUTTON CLICK', e, e.nativeEvent);
        setFilterOpen((o) => !o);
    };

    const onCreateClick = () => {
        // create a new guide
        var tmpGuide = new GuideModel();

        logger.log(tmpGuide);
        dispatch(selectGuide(tmpGuide));

        openGuideModal();
        // setGuideModalOpen(true);
    };

    const openGuideModal = () => {
        logger.log(guide);
        openModal({
            title: 'Edit Guide',
            size: 'xl',
            children: (
                <>
                    <GuideEdit
                        mode={ViewModes.EDIT}
                        // guide={guide}
                        // session={session}
                        // user={user}
                        // task={selectedTask}
                        onCancel={() => {
                            closeGuideModal();
                        }}
                        // onMenuClick={onMenuClick}
                    />
                </>
            ),
            onClose: closeGuideModal,
        });
    };

    const closeGuideModal = () => {
        // closeAllModals();
        // var params = {...searchParams};
        //searchParams.delete('guide');
        //setSearchParams(searchParams);

        setTimeout(() => {
            dispatch(selectGuide(null));
        }, 500);
    };

    return (
        <Box px='lg' sx={(theme) => ({background:theme.colorScheme === 'dark'?theme.colors.dark[8]:theme.white,  display:'flex', flexDirection:'column', height:'100%', overflow:'hidden'})}>
            <Group
                className='main-container-header'
                position='apart'
                sx={(theme) => ({
                    borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                })}>
                {/* <Crumbline></Crumbline> */}
                <Title order={3}>Style Guides</Title>
                <Button onClick={() => onCreateClick()}>Create Guide</Button>
            </Group>

            <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} py='xs' mt='sm'>
                <Group>
                    <CheckboxDropdown selectedItems={selectedGuides} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />

                    <Input
                        icon={<IconSearch />}
                        placeholder='Search Guides'
                        value={guideFilter.keyword}
                        onChange={(e) => setGuideFilter({ ...guideFilter, keyword: e.currentTarget.value })}
                        rightSection={<IconX size={14} onClick={(e) => setGuideFilter({ ...guideFilter, keyword: '' })} />}
                    />

                    <Text size='sm'>
                        Total{' '}
                        <strong>
                            {guideData.length} of {guides?.length}
                        </strong>{' '}
                    </Text>
                </Group>

                <Group>
                    <Popover shadow='sm' opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} position='bottom' withArrow closeOnClickOutside={true}>
                        <Popover.Target>
                            <div>
                                <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div style={{ display: 'flex' }}>
                                <FilterPanel
                                    filter={guideFilter}
                                    onCancel={() => setFilterOpen(false)}
                                    onApply={(vals) => {
                                        setFilterVars(vals);
                                        setFilterOpen(false);
                                    }}
                                    filterItems={filterItems}
                                />
                            </div>
                        </Popover.Dropdown>
                    </Popover>

                    {/* <Select
                placeholder="Filter By All"
                data={[
                  { value: '', label: 'Show All' },
                  { value: 'sku', label: 'Filter by SKU' },
                  { value: 'location', label: 'Filter by Location' },
                  { value: 'status', label: 'Filter by Status' },
                ]}
              /> */}

                    {/* <Select
                placeholder="Sort By"
                data={[
                  { value: 'sku', label: 'Sort by SKU' },
                  { value: 'desc', label: 'Sort by Description' },
                  { value: 'location', label: 'Sort by Location' },
                  { value: 'status', label: 'Sort by Status' },
                ]}
              /> */}

                    {/* <ListToggleControl value={listView} onChange={(val) => listToggleHandler(val)}/> */}
                </Group>
            </Group>

            {/* <ScrollArea style={{height:500}} > */}
            <Box className='main-container-content' mt='lg' sx={{flex:1, overflow:'hidden', marginRight:listView === ListViewOption.LIST?-20:0}}>
                {listView === ListViewOption.LIST ? (
                    <GuideList isLoading={isLoading} data={guideData} onItemClick={onItemClick} onItemSelected={onItemSelected} selectedItems={selectedGuides} />
                ) : (
                    <div data={guideData} onItemClick={onItemClick} onItemSelected={onItemSelected} selectedItems={selectedGuides} />
                )}
            </Box>
            {/* </ScrollArea> */}

            {filterOpen ? <Box style={{ backgroundColor: '#000', opacity: '0.05', position: 'absolute', top: '155px', right: 0, bottom: 0, left: '0px' }}></Box> : null}

            {/* 
        <Modal 
          withCloseButton={true} 
          size={'lg'} 
          title='Create a new Guide' 
          opened={guideModalOpen} 
          trapFocus={true} 
          onClose={() => setGuideModalOpen(false)}>
          <GuideEdit />
        </Modal> */}
        </Box>
    );
};

const FilterPanel = ({ onCancel, onApply, filter, filterItems }) => {
    logger.log(filter);

    const form = useForm({
        initialValues: {
            ...filter,
        },
    });

    useEffect(() => {
        if (filter && form) {
            form.setValues({ ...filter });
        }

        logger.log(form.getInputProps('status'));
    }, [filter, form]);

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Brands' id='input-brands' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('brands')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('brands')} />
                </FormInputWrapper>

                <FormInputWrapper label='Category' id='input-category' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('categories')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('categories')} />
                </FormInputWrapper>

                <FormInputWrapper label='Department' id='input-department' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('department')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Location' id='input-location' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('location')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect
                        data={[
                            { label: 'United States', value: 'US' },
                            { label: 'Great Britain', value: 'GB' },
                            { label: 'Finland', value: 'FI' },
                            { label: 'France', value: 'FR' },
                            { label: 'Russia', value: 'RU' },
                        ]}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('status')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Colour' id='input-colour' labelWidth={'120px'}>
                    <ColorInput placeholder='Pick color' {...form.getInputProps('colour')} />
                </FormInputWrapper>

                <FormInputWrapper label='Assigned Product' id='input-colour' labelWidth={'120px'}>
                    <Switch />
                </FormInputWrapper>

                <Group position='apart' mt='lg'>
                    <Button variant='subtle' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        guides: state.guideReducer.guides,
        guideFilter: state.guideReducer.guideFilter,
        selectedGuides: state.guideReducer.selectedGuides,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatchToProps = { setGuideFilter, selectGuides, selectGuide };

export default connect(mapStateToProps, mapDispatchToProps)(Guides);

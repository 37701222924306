// import BudgetProgress, { ProgressType } from 'components/budgetProgress/budgetProgress';
import {
  Anchor,
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Paper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from '@mantine/form';
import { Link, useNavigate } from "react-router-dom";
import { forgotPW, loginUser } from '../../authSlice';
import { logger } from 'utils/logger';


// import Dashboard from './features/components/Dashboard';

const useStyles = createStyles((theme) => ({
  container: {
      // backgroundColor:'rgb(174 182 255 / 7%)',
      backgroundColor:theme.white,
      padding:`${theme.spacing.md *2}px`,
  },
  title:{
      color:'#000000',
  }
  ,instructions:{
    // background: theme.colors.grape[2],
  }
}));


const mapDispatch = { loginUser }

const ForgotPassword = (location) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [ hasSent, setHasSent ] = useState(false)

  const navigate = useNavigate();

    const form = useForm({
        initialValues: {
          username:'',          
        },
    
        // validate: {
        //   confirmPassword: (value, values) =>
        //     value !== values.password ? 'Passwords did not match' : null,
        // },
      });

      const onSubmit = (values) => {
        logger.log('here', values);
        setHasSent(true);
        dispatch(forgotPW(values.username))
      }

      const loginClick = () => {
        navigate('/auth/login');        
      }
    return (
        
          <Center sx={{width:'100%'}}>
           <Box sx={{ width: '100%' }} mr='xl'>
            <Paper className={classes.container}>
            <Title order={2} mb='xl' sx={{ textAlign: 'center' }} className={classes.title}>{hasSent?'Email Sent':'Forgot Password'}</Title>
            {!hasSent?<>
              <Text mb="xl" size='sm' sx={(theme) => ({color:theme.colors.dark[8]})}>Enter the email address associated with your account and we’ll send you a link to reset your password.</Text>
              <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                  
                  <TextInput label="Email" placeholder='Enter email address' className={classes.title} required
                  {...form.getInputProps('username')} />            

                  <Group position="right" mt="xl" mb="lg">
                    <Button sx={{ width: '100%' }} type="submit">Reset Password</Button>
                  </Group>
                  
              </form>
            </>:
              <Box className={classes.instructions}>
                <Text size='sm' sx={(theme) => ({color:theme.colors.dark[8]})}>
                We've sent you an email with instructions to reset your password.
                <br></br>
                <br></br>
                Please make sure it didn't wind up in your Junk Mail. If you aren't receiving our password reset emails, please contact us.
                </Text>
                <Group mt={'xl'} mb="lg">
                  <Button onClick={() => loginClick()} fullWidth>Login</Button>
                </Group>
              </Box>
            }
            </Paper>
            <Center mt={'xl'}>
              <Anchor component={Link} className='dark-anchor' to="/auth/login" size={'sm'}>
                Remember your password? Log In.
              </Anchor>
              
            </Center>
            </Box>
            </Center>
                    
        
    )
    
};

const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
        user: state.auth.user,
    })
  }

  

export default connect(
    mapStateToProps,
    mapDispatch
  )(ForgotPassword)

  
// export default Auth;
// export default {
//     routeProps: {
//         path: '/auth',
//         component: Auth,
//     },
//     name: 'Auth',
// };


import { Box } from '@mantine/core';
import { clearNotifications, selectGuides, setGuideFilter, createGuideSuccess, updateGuideSuccess, fetchGuides } from 'modules/Guides/guideSlice';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Guides from './features/guides/Guides';
import Guide from './features/guide/Guide';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';

import { logger } from 'utils/logger';
import { closeAllModals } from '@mantine/modals';

const GuidesModule = ({user,guide, guides, selectedSamples,success,error,setSampleFilter,guideFilter, selectSamples, setGuideFilter}) => {
    
    const dispatch = useDispatch();

    // SUCCESS AND ERROR HANDLERS
    useEffect(() => {      
      logger.log(success)
      if(error){        
        showNotification({
          title: 'Whoops something went wrong.',
          message: error.message,
          position:'top-center',
          color:'red'
        });

        dispatch(clearNotifications())
      };

      if(success){
        switch(success.type){

          // case createGuideSuccess.toString():{
          //   // push the user to the session detail page.
          //   if(session){

          //     showNotification({
          //       title: 'Success',
          //       message: success.message,
          //       position:'top-center',
          //       color:'teal'
          //     });

          //     navigate(`/sessions/${session.code}`);              
          //     dispatch(clearNotifications())

          //   }
          //   break; 
          // };
          case updateGuideSuccess.toString():{
            
              showNotification({
                title: 'Success',
                message: success.message,
                position:'top-center',
                color:'teal'
              });
              closeAllModals();
              dispatch(clearNotifications());

              // refetch the guides.
              dispatch(fetchGuides());
              
              break;
          }
          default:{
            break;
          }
        }
      }

      // if the session has been created then we load the session detail view
      
    },[error,success,dispatch,clearNotifications])

    return (
      <Box id="guidesModule" sx={(theme)=>({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white, 
        width:'100%',
        height:'100%'
      })}>        
        
        <Box sx={{height:'100%'}}>
          <Routes>
              <Route path="/" element={<Guides />}></Route>
              <Route path="/:id" element={<Guide />}></Route>
          </Routes>
        
        </Box>
      </Box>    
    )
    
};

const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
       user: state.auth.user,
       guide: state.guideReducer.selectedGuide,
       guides: state.guideReducer.guides,
       guideFilter:state.guideReducer.guideFilter,
       selectedGuides: state.guideReducer.selectedGuides,
       success: state.guideReducer.success,
       error: state.guideReducer.error,
       
    })
  }

const mapDispatchToProps = { setGuideFilter, selectGuides }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(GuidesModule)


  

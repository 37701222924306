import { useRef, useEffect } from "react";
import { logger } from "utils/logger";
import {
  Timeline as Vis,
  TimelineEventPropertiesResult,
  DataSet,
} from "vis-timeline/standalone";
// import createOptions from "./createOptions";
// import MusicMetaData from "@types/MusicMetaData";
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';


function VisTimeline({ data, onChangePosition=()=>{}, className='', options }) {
    const containerRef = useRef(null);
    const timelineRef = useRef(null);
    
    logger.log(options)
    const initTimeline = () => {
      if (!containerRef.current) return;
      timelineRef.current = new Vis(
        containerRef.current
        // ,items
        // ,groups
        ,data.items
        ,data.groups
        ,options
        // createOptions({ data })
      );
      const timeline = timelineRef.current;
    //   timeline.addCustomTime(new Date(1920, 0, 1));
    //   timeline.setCustomTimeTitle("0:0");
    //   timeline.on("timechange", onTimeChange);
    //   timeline.on("click", onTimeChange);
    };
  
    const onTimeChange = (event) => {
      const time =
        event.time.getMinutes() * 60 +
        event.time.getSeconds() +
        event.time.getMilliseconds() / 1000;
      onChangePosition((time / data.duration) * 100);
    };
  
    useEffect(() => {
      if (!timelineRef.current && data.items) initTimeline();
    }, [containerRef,data]);
  
    return <div ref={containerRef} className={className} />;
  }
  export default VisTimeline
/**
 * @file approvalSlice.js
 * @description Sets initial state for the Approval module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice, current } from '@reduxjs/toolkit'
import { CaptureFilterModel } from 'core/models/CaptureFilterModel';

import { findIndex, forOwn } from 'lodash';
// import { CaptureModel } from 'core/models/CaptureModel';
import undoable from 'redux-undo'
import { getAggData, getCaptures, getLatestCaptures, getMarkups, removeMarkup, udpateMarkup, updateCapture } from 'services/approvalService';
import { ApprovalLayoutTypes } from 'utils/constants';
import { findCaptureIndex, setObjectFromProps, updateCaptureItems } from 'utils/helper';
import { logger } from 'utils/logger';

const approvalSlice = createSlice({
  name: 'approval',
  initialState: { 
    selectedBrief:null,
    selectedItem:null,
    selectedItems:[],
    comparisonItems:[],
    selectedCompareFrame:0,
    searchCursor:null,
    approvalFilter:{
      client:'',
      sessionId:'',
      machineId:'',
      ratingComparison:'=',
      star:'-1',
      sak:'-31',
      actionPart:'',
      tags:[],
      status:null,
      season:[],
      sku:[],
      location:[],
      unpinned:true,
      catalogue:{label:'Latest',value:{since:null,until:null}}
    },
    captures:[],
    settings:{
      layoutType:ApprovalLayoutTypes.GRID,
      infoOpen:false,
      filterOpen:false,
      thumbnailSize:150,
      isCommenting:false,
      sortBy:'actionSort',
      sortDirection:'asc'
    },
    aggData:null,
    error:null, 
    success:null, 
    loading:false, 
    action:null,
  },
  reducers: {

    setFilter(state,action){
      logger.log('+++ SETTING FILTER +++', action.payload)
      state.approvalFilter = action.payload;
    },
    setSelectedItem(state,action){
      logger.log(" SETTING IMAGE")
      state.selectedItem = action.payload;
    },
    setSelectedItems(state,action){
      logger.log(' setting selected items:', action.payload)
      state.selectedItems = action.payload;
    },
    setComparisonItems(state,action){
      logger.log(' setting selected items:', action.payload)
      state.comparisonItems = action.payload;
    },
    setSelectedCompareFrame(state,action){
      state.selectedCompareFrame = action.payload;
    },
    setApprovalSettings(state,action){
      logger.log(' SETTINGs CHANGE', action.payload)
      state.settings = action.payload;
    },
    clearNotifications(state,action){
      state.error = null;
      state.success = null;
    },
    refreshState(state,action){
      state.captures = [];
      state.selectedItem = null;
      state.selectedCompareFrame = 0;
      state.comparisonItems = [];
      state.loading = false;
      state.approvalFilter = new CaptureFilterModel();
    },
    //////////////////////
    //
    // UPDATE CAPTURES
    //
    //////////////////////

    onServiceStart(state,action){
      state.success = null;
      state.loading = true;
      state.error = null;
      // state.action = action.type;
    },
    
    setCapturesSuccess(state, action) {
      logger.log('updateCapturesSuccess : SUCCESS')
      
      state.loading = false;
      state.success = {type:action.type,message:'Captures Updated'}      

      // update the captures
      state.captures = [...updateCaptureItems(state.captures,action.payload)];

      // update the selected items
      if(state.selectedItems.length){
        state.selectedItems = [...updateCaptureItems(state.selectedItems,action.payload)];
      }
      

      // update the comparison items
      if(state.settings.layoutType === ApprovalLayoutTypes.COMPARE){
        state.comparisonItems = [...updateCaptureItems(state.comparisonItems,action.payload)];
      }
      

      // update the selected item
     
        const selectedCaptureIdx = findCaptureIndex(action.payload,state.selectedItem);
        if(state.selectedItem && selectedCaptureIdx > -1){
          state.selectedItem = setObjectFromProps(state.selectedItem,action.payload[selectedCaptureIdx]);
        }
      
      
      state.error = null
    },

    getCapturesSuccess(state, action) {
      logger.log('updateCapturesSuccess : SUCCESS')
      
      state.loading = false;
      if(action.payload.complete){
        state.success = {type:action.type,message:'Captures Updated'}
      }

      const items = action.payload.concatItems?[...state.captures].concat(...action.payload.items):[...action.payload.items];
      state.captures = [...items];
      state.searchCursor = action.payload.cursor;

      state.error = null
    },

    onServiceFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload;
      state.loading = false;
    },

    //////////////////////
    //
    // MARKUPS
    //
    //////////////////////
    getMarkupsSuccess(state, action) {
      logger.log('getMarkupsSuccess : SUCCESS')
      
      state.loading = false;
      state.success = {type:action.type,message:'Markups Fetched'}

      // find the capture.
      var capture = state.captures.find(val => val.id === action.payload.id);
      const captureIdx = state.captures.indexOf(capture);

      if(capture){
        capture.annotations = [...action.payload.data];
      }
      const tmpCaptures = [...state.captures];
      state.captures = [...updateCaptureItems(tmpCaptures,[capture])];
      
      // update the selected item
      if(state.selectedItem && captureIdx > -1){
        state.selectedItem = setObjectFromProps(state.selectedItem,capture);
      }

      // state.scans = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null
    },

    setMarkupSuccess(state, action) {
      logger.log('setMarkupSuccess : SUCCESS')
      
      state.loading = false;

      // find the capture and add the markups to it.
      // const markup = action.payload;      
      state.success = {type:action.type,message:'Markups Updated'}
      
      state.error = null
    },

    deleteMarkupSuccess(state, action) {
      logger.log('deleteMarkupSuccess : SUCCESS')
      
      state.loading = false;
      state.success = {type:action.type,message:'Markup Removed'}
      
      state.error = null
    },

    //////////////////////
    //
    // AGG DATA
    //
    //////////////////////
    getAggDataSuccess(state,action) {
      state.loading = false;
      state.success = {type:action.type,message:'Aggregate data recieved'}
      state.aggData = {...action.payload}
      state.error = null
    }
    
  }
})

//////////////////
// AGG DATA
//////////////////
export const fetchAggData = ( id, markup, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await getAggData()
    dispatch(getAggDataSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}

// CAPTURES
export const setCaptures = ( captures, user ) => async dispatch => {
  try {
    logger.log(captures, " SETTING CAPTURES")
    dispatch(onServiceStart())
    dispatch(setCapturesSuccess(captures))
    for(let i = 0; i<captures.length; i++){
      const capture = captures[i];
      await updateCapture(capture);
    }
    //dispatch(setCapturesSuccess(captures))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}

export const fetchCaptures = ( criteria, user, maxRequestCount, cursor="" ) => async dispatch => {
  try {

    dispatch(onServiceStart());
    var origCursor = cursor;
    var items = [];
    var index = 0;
    var resetCursor = true;
    var tmpCursor;
    var concatItems = true;

    do{
      const data = await getCaptures(criteria,cursor);
      cursor = data.nextCursor;
      items.push(...data.items);
      
      if(!maxRequestCount)
        dispatch(getCapturesSuccess({items:[...items],complete:false}));
      
      if(maxRequestCount && (maxRequestCount - 1) === index){
        tmpCursor = cursor;
        cursor = null;
      };

      ++index;

    }while(cursor)

    if(tmpCursor){
      const decodedString = decodeURIComponent(tmpCursor);
      // const obj = JSON.parse(decodedString);
      logger.log(decodedString)
    }
    
    // if(origCursor && origCursor.length && maxRequestCount){
    //   concatItems = true;
    // }

    // if(tmpCursor){
    //   concatItems = true;
    // }

    if(origCursor === '' && maxRequestCount){
      concatItems = false;
    }else if(!maxRequestCount){
      concatItems = false;
    }

    dispatch(getCapturesSuccess({items:[...items], complete:true, cursor:tmpCursor, concatItems:concatItems}));

  } catch (err) {
    logger.error('err',err)
    dispatch(onServiceFailure(err))
  }
}

export const fetchLatestCaptures = ( criteria, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await getLatestCaptures(criteria)
    dispatch(getCapturesSuccess(data))
  } catch (err) {
    logger.error('err',err)
    dispatch(onServiceFailure(err))
  }
}

//////////////////
// MARKUPS
//////////////////
export const fetchMarkups = ( id, markup, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await getMarkups(id,user?user.rawIdToken:'')
    dispatch(getMarkupsSuccess({id,data}))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}
export const setMarkup = ( id, markup, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await udpateMarkup(id,markup,user?user.rawIdToken:'')
    dispatch(setMarkupSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}

export const deleteMarkup = ( id, markup, user ) => async dispatch => {
  try {

    dispatch(onServiceStart())
    const data = await removeMarkup(id,markup,user?user.rawIdToken:'')
    dispatch(deleteMarkupSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(onServiceFailure(err))
  }
}

export const { selectSample
  , setFilter
  , setSelectedItem
  , setSelectedItems
  , setComparisonItems
  , setApprovalSettings
  , setSelectedCompareFrame
  , clearNotifications
  , refreshState
  
  , setCapturesSuccess
  , getCapturesSuccess

  , getAggDataSuccess
  
  , getMarkupsSuccess
  , setMarkupSuccess
  , deleteMarkupSuccess

  , onServiceStart
  , onServiceFailure
} = approvalSlice.actions

// export default approvalSlice.reducer
export default undoable(approvalSlice.reducer,{
  limit: 20 // set a limit for the size of the history
})
import { SampleModel } from 'core/models';
import { SessionModel } from 'core/models/SessionModel';
import { StyleGuideModel } from 'core/models/StyleGuideModel';
import { TaskModel } from 'core/models/TaskModel';
import moment from 'moment';
import { DataFormats } from 'utils/constants';
import { doThrowOnResponse, getToken, parseValue } from 'utils/helper';
import { logger } from 'utils/logger';

export const getSession = async (id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${id}`;

    const formData = JSON.stringify({});

    logger.log(' GETTING JOB DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            //  'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error getting the session');
        })
        .then((data) => {
            logger.log(data);
            var session;
            if (data.Items && data.Items.length) {
                session = createSessionModel(data.Items[0]);
                session.tags = session.tags.length ? session.tags.split(',') : [];
            }

            return session;
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR getting session', err);
            throw err;
        });
};

export const getSessions = async (criteria, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session`;
    logger.log(process.env.REACT_APP_API_URL);
    const formData = JSON.stringify({});

    logger.log(' GETTING Sessions DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error getting the sessions');
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                const newItem = createSessionModel(item);
                newItem.tags = newItem.tags.length ? newItem.tags.split(',') : [];
                if (newItem.scan) {
                    newItem.styleCount = '1';
                }
                return newItem;
            });

            //items = items.splice(0,50)
            logger.log(items, ' getsamples');
            return items.sort(function (a, b) {
                var dateA = new Date(a.created);
                var dateB = new Date(b.created);
                return dateB - dateA;
            });
            //return imgUploadUrl;
        })
        .catch((err) => {
            throw err;
        });
};

export const udpateSession = async (session, token) => {
    const url = session.sessionId ? `${process.env.REACT_APP_API_URL}/session/${session.sessionId}` : `${process.env.REACT_APP_API_URL}/session`;
    var team = {};

    if (session.team) {
        session.team.forEach((val) => {
            team[val.role] = val.name;
        });
    }

    const data = {
        name: session.name,
        description: session.description,
        season: session.season,
        client: session.client,
        seasonCode: session.seasonCode,
        deadline: moment(session.deadline).format(DataFormats.DATE_FORMAT_REST),
        shootDate: moment(session.shootDate).format(DataFormats.DATE_FORMAT_REST),
        channel: session.channel,
        status: session.status,
        tags: session.tags.toString(),
        team: session.team,
    };

    const formData = JSON.stringify(data);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error updating the session');
        })
        .then((data) => {
            const session = data.Attributes ? new SessionModel(parseValue(data.Attributes)) : new SessionModel({ ...data, sessionId: data.ssSort });
            return session;
        })
        .catch((err) => {
            console.error('ERROR udpating session', err);
            throw err;
        });
};

///////////////////////
//
// STYLES
//
///////////////////////
export const getSessionStyles = async (criteria, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${criteria.sessionId}/style`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error fetching session styles');
        })
        .then((data) => {
            return data;
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR getting styles', err);
            throw err;
        });
};
export const updateSessionStyle = async (style, session) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${session.code}/style/${style.targetSort}`;
    const payload = { ...style };

    const formData = JSON.stringify(payload);
    logger.log(payload, url);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error adding style to session');
        })
        .then((data) => {
            var newItem = {};
            newItem = new SampleModel(parseValue(data.Attributes));
            newItem.id = newItem.targetSort;
            return newItem;
        })
        .catch((err) => {
            console.error('ERROR adding style to session', err);
            throw err;
        });
};

export const addStyleToSession = async (style, session) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${session.code}/style/${style.targetSort}`;
    const payload = {
        stylenumber: style.targetSort,
        name: style.name,
        descr: style.descr,
        targetSort: style.targetSort,
        department: style.instructions['department'],
        Dept: style.instructions['Dept'],
        types: '',
        photographyStatus: '',
        photoCode: style.instructions['Web Photography Code'],
        size: style.instructions['Web Photography Sample Size'],
        otbDate: style.instructions['Web Stock OTB Date'],
        sampleStatus: '',
    };

    const formData = JSON.stringify(payload);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error adding style to session');
        })
        .then((data) => {
            var newItem = {};
            newItem = new SampleModel(parseValue(data.Attributes));
            newItem.id = newItem.targetSort;
            return newItem;
        })
        .catch((err) => {
            console.error('ERROR adding style to session', err);
            throw err;
        });
};

export const deleteStyleFromSession = async (style, session, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${session.sessionId}/style/${style.stylenumber}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'DELETE',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error deleting style');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error('ERROR adding style to session', err);
            throw err;
        });
};

///////////////////////
//
// GUIDES
//
///////////////////////

export const addGuideToSession = async (guide, session, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${session.sessionId}/guides/${guide.code}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'PUT',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error adding style to session');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error('ERROR adding style to session', err);
            throw err;
        });
};

export const deleteGuideFromSession = async (guide, session, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${session.sessionId}/guides/${guide.code}`;

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'DELETE',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error deleting guide');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error('ERROR remove guide from session', err);
            throw err;
        });
};

///////////////////////
//
// TASKS
//
///////////////////////

// export const getSessionSamples = async(criteria) => {
//     let url = `${process.env.REACT_APP_API_URL}/session`;

//     if(criteria.sessionId && criteria.sessionId.length){
//         url += `/${criteria.sessionId}/tasks`;
//     }
//     if(criteria.type && criteria.type.length){
//         url += `type=${criteria.type}&`;
//     }

//     if(criteria.status && criteria.status.length){
//         url += `status=${criteria.status}&`;
//     }
//     if(criteria.assignmentStatus && criteria.assignmentStatus.length){
//         url += `assignmentStatus=${criteria.assignmentStatus}&`;
//     }

//     if(criteria.since && criteria.since.length){
//         url += `since=${criteria.since}&`;
//     }

//     if(criteria.until && criteria.until.length){
//         url += `until=${criteria.until}&`;
//     }
//     return fetch(url, {
//         headers: {
//         'Content-Type': 'application/json',
//         'hgio':getToken(),
//         },
//     method: 'GET',
//     // body: formData,
//     }).then((response) => {
//         if (response.ok) {
//             return response.json();
//         }
//         logger.log(response)
//         throw new Error('Error getting tasks');
//     }).then((data)=>{
//         logger.log(data)
//         var items = data.items.map((item,idx) => {
//             // return createTaskModel(item);

//             return new TaskModel({...item,items:[{id:item.item}]});
//         })
//         // var items = data.Items.map((item,idx) => {
//         //     var newItem = {...item};
//         //     newItem.id = `${newItem.taskPart}__${newItem.taskSort}`;
//         //     return newItem;
//         // });

//         return items;
//     })
//     .catch((err) => {
//        console.error('ERROR getting captures',err);
//        throw(err);
//     })
// }

///////////////////////
//
// TASKS
//
///////////////////////

export const getSessionTasks = async (criteria,signal) => {
    let url = `${process.env.REACT_APP_API_URL}/session`;

    if (criteria.sessionId && criteria.sessionId.length) {
        url += `/${criteria.sessionId}/tasks`;
    }
    if (criteria.type && criteria.type.length) {
        url += `type=${criteria.type}&`;
    }

    if (criteria.status && criteria.status.length) {
        url += `status=${criteria.status}&`;
    }
    if (criteria.assignmentStatus && criteria.assignmentStatus.length) {
        url += `assignmentStatus=${criteria.assignmentStatus}&`;
    }

    if (criteria.since && criteria.since.length) {
        url += `since=${criteria.since}&`;
    }

    if (criteria.until && criteria.until.length) {
        url += `until=${criteria.until}&`;
    }

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        signal:signal??null,
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            logger.log(response);
            throw new Error('Error getting tasks');
        })
        .then((data) => {
            logger.log(data);
            var items = data.items.map((item, idx) => {
                // return createTaskModel(item);

                var task = new TaskModel({ ...item });
                task.tags = task.tags.length ? task.tags.split(',') : [];
                return task;
            });
            // var items = data.Items.map((item,idx) => {
            //     var newItem = {...item};
            //     newItem.id = `${newItem.taskPart}__${newItem.taskSort}`;
            //     return newItem;
            // });
            return items;
        })
        .catch((err) => {
            if(doThrowOnResponse(err)){
                throw err;
            }else{
                return [];
            }
        });
};

export const getTasks = async (criteria, signal) => {
    let url = `${process.env.REACT_APP_API_URL}/tasks/listing?`;

    if (criteria.sessionId && criteria.sessionId.length) {
        url += `/${criteria.sessionId}/tasks/listing`;
    }
    if (criteria.type && criteria.type.length) {
        url += `type=${criteria.type}&`;
    }

    if (criteria.status && criteria.status.length) {
        url += `status=${criteria.status}&`;
    }
    if (criteria.assignmentStatus && criteria.assignmentStatus.length) {
        url += `assignmentStatus=${criteria.assignmentStatus}&`;
    }

    if (criteria.since && criteria.since.length) {
        url += `since=${criteria.since}&`;
    }

    if (criteria.until && criteria.until.length) {
        url += `until=${criteria.until}&`;
    }

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        signal:signal??null,
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            logger.log(response);
            throw new Error('Error getting tasks');
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                // var item = createTaskModel(item);
                // var task = new TaskModel({...item,items:[{id:item.item,previewLo:item.productionThumb}]});
                var task = new TaskModel({ ...item });
                task.tags = task.tags.length ? task.tags.replace(/ (?=[^,]*,)/g, '').split(',') : [];

                return task;
                //return new TaskModel(item);
            });
            // var items = data.Items.map((item,idx) => {
            //     var newItem = {...item};
            //     newItem.id = `${newItem.taskPart}__${newItem.taskSort}`;
            //     return newItem;
            // });

            return items;
        })
        .catch((err) => {
            if(doThrowOnResponse(err)){
                throw err
            }else{
                // return empty values back to the caller
                return []
            }
        });
};

export const getTask = async (criteria) => {
    logger.log(criteria, ' get task');
    let url = `${process.env.REACT_APP_API_URL}/tasks/${encodeURIComponent(criteria.taskPart)}/${criteria.taskSort}`;

    if (criteria.sessionId && criteria.sessionId.length) {
        url += `/${criteria.sessionId}/tasks/listing`;
    }
    if (criteria.type && criteria.type.length) {
        url += `type=${criteria.type}&`;
    }

    if (criteria.status && criteria.status.length) {
        url += `status=${criteria.status}&`;
    }
    if (criteria.assignmentStatus && criteria.assignmentStatus.length) {
        url += `assignmentStatus=${criteria.assignmentStatus}&`;
    }

    if (criteria.since && criteria.since.length) {
        url += `since=${criteria.since}&`;
    }

    if (criteria.until && criteria.until.length) {
        url += `until=${criteria.until}&`;
    }

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            logger.log(response);
            throw new Error('Error getting tasks');
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                // var item = createTaskModel(item);
                // var task = new TaskModel({...item,items:[{id:item.item,previewLo:item.productionThumb}]});
                var task = new TaskModel({ ...item });
                task.tags = task.tags.length ? task.tags.replace(/ (?=[^,]*,)/g, '').split(',') : [];

                return task;
                //return new TaskModel(item);
            });

            return items[0];
        })
        .catch((err) => {
            console.error('ERROR getting task', err);
            throw err;
        });
};

const createTaskModel = (item) => {
    const keys = Object.keys(item);
    var newItem = {};
    keys.forEach((key, idx) => {
        let tmpKey = key.replace(' ', '');
        tmpKey = key.replace(':', '_');
        // lowercase the first.
        let char = tmpKey.charAt(0).toLowerCase();
        let subStr = tmpKey.substring(1, tmpKey.length);
        const newKey = `${char}${subStr}`;
        newItem[newKey] = item[key]['S'];
        newItem.id = `${newItem.taskPart}__${newItem.taskSort}`;
    });
    return new TaskModel(newItem);
};

export const udpateTask = async (session, task) => {
    var url = `${process.env.REACT_APP_API_URL}/session/${session.code}/tasks`;
    var method = 'PUT';

    // if(task.taskSort && task.taskSort.length){
    //     url += `?taskSort=${task.taskSort}`;
    //     method = "PUT";
    // }
    // const items = task.items.map(val => `{${val}}`);
    const payload = { ...task };

    // need to wrap the items in a JSON array
    // if(!payload.taskSort.length){
    //     delete payload.id;
    //     delete payload.taskPart;
    //     delete payload.taskSort;
    // }

    const formData = JSON.stringify(payload);

    logger.log(' UPDATING TASK: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: method,
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error updating the task');
        })
        .then((data) => {
            // const task = createTaskModel(data)

            return { ...data, id: data.taskSort };
        })
        .catch((err) => {
            throw err;
        });
};
export const deleteTask = async (task, token) => {
    const url = `${process.env.REACT_APP_API_URL}/session/search`;

    const formData = JSON.stringify({});

    logger.log(' DELETING Task DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error deleting the task');
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            logger.error('ERROR deleting tasks', err);
            throw err;
        });
};

const createSessionModel = (item) => {
    const keys = Object.keys(item);
    var newItem = new SessionModel();
    keys.forEach((key, idx) => {
        let tmpKey = key.replace(' ', '');
        tmpKey = key.replace(':', '_');
        // lowercase the first.
        let char = tmpKey.charAt(0).toLowerCase();
        let subStr = tmpKey.substring(1, tmpKey.length);
        const newKey = `${char}${subStr}`;
        newItem[newKey] = item[key]['S'];
        if (newKey === 'guides') {
            var guides = [];
            if (item[newKey]['L'].length) {
                item[newKey]['L'].forEach((guide) => {
                    guides.push(new StyleGuideModel({ code: guide['S'], id: guide['S'] }));
                });
            }

            newItem[newKey] = guides;
        }
        if (newKey === 'team') {
            // var team = [];
            // const tmpItem = parseValue(item[newKey]);
            // logger.log(tmpItem)
            // if(item[newKey]["M"]){
            //     const teamKeys = Object.keys(item[newKey]["M"]);
            //     teamKeys.forEach(tmpKey => {
            //         team.push({role:tmpKey,name:item[newKey]["M"][tmpKey]["S"]});
            //     })
            // }
            if (item[newKey]['L']) {
                const teamArray = item[newKey].L.map((item) => {
                    const data = item.M;
                    const entry = {};
                    for (const key in data) {
                        entry[key] = data[key].S;
                    }
                    return entry;
                });

                newItem[newKey] = teamArray;
            } else {
                newItem[newKey] = [];
            }
        }
    });
    newItem.id = `${newItem.ssPart}|${newItem.ssSort}`;
    newItem.sessionId = `${newItem.ssSort}`;
    newItem.sessionSort = `${newItem.ssSort}`;
    newItem.deadline = newItem.deadline !== 'Invalid Date' ? moment(newItem.deadline, DataFormats.DATE_FORMAT_REST).toDate() : '';
    newItem.shootDate = newItem.shootDate !== 'Invalid Date' ? moment(newItem.shootDate, DataFormats.DATE_FORMAT_REST).toDate() : '';
    newItem.samples = null;
    // newItem.name = newItem.label;

    return newItem;
};

///////////////////////
//
// CAPTURES
//
///////////////////////

export const addSampleToCapture = async (samples, capture, description) => {
    const url = `${process.env.REACT_APP_API_URL}/studioactions/${capture.actionPart}/${capture.actionSort}`;
    const formData = JSON.stringify({
        styles: samples,
        styleDescription: description,
    });

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error adding style to session');
        })
        .then((data) => {
            const vals = parseValue(data.Attributes);
            return vals;
        })
        .catch((err) => {
            console.error('ERROR adding style to session', err);
            throw err;
        });
};

export const getFileUploadPath = async (file, task, session) => {
    const url = `${process.env.REACT_APP_API_URL}/session/${encodeURIComponent(session.code)}/tasks/candidate?taskPart=${encodeURIComponent(task.taskPart)}&taskSort=${
        task.taskSort
    }&originalFilename=${file.path}`;
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'x-amz-meta-user':`${user.first} ${user.last}`,
            // 'x-amz-meta-part':task.taskPart,
            // 'x-amz-meta-sort':task.taskSort
        },
        method: 'PUT',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('ERROR uploading file');
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.error('ERROR uploading file', err);
            throw err;
        });
};

export const uploadTaskFile = async (file, task, url, user) => {
    return fetch(url, {
        headers: {
            // 'hgio':getToken(),
            'x-amz-meta-ofn': `${file.path}`,
            'x-amz-meta-user': `${user.first} ${user.last}`,
            // 'x-amz-meta-user':`${user.first} ${user.last}`,
            // 'x-amz-meta-part':task.taskPart,
            // 'x-amz-meta-sort':task.taskSort
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            if (response.ok) {
                return true;
            }
            throw new Error('ERROR uploading file');
        })
        .catch((err) => {
            console.error('ERROR uploading file', err);
            throw err;
        });
};

export const uploadTaskFileWithProgress = async (file, task, url, user, onProgress) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                onProgress({ percentComplete, file });
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(true);
            } else {
                reject(new Error('ERROR uploading file'));
            }
        };

        xhr.onerror = () => {
            console.error('ERROR uploading file', xhr.statusText);
            reject(new Error('ERROR uploading file'));
        };

        xhr.open('PUT', url, true);

        xhr.setRequestHeader('x-amz-meta-ofn', `${file.path}`);
        xhr.setRequestHeader('x-amz-meta-user', `${user.first} ${user.last}`);

        xhr.send(file);
    });
};

// export const getSessionGuides = async(criteria,token) => {
//     const url = `${process.env.REACT_APP_API_URL}/jobs/${session.code}/guides`;

//     const formData = JSON.stringify({

//     })

//     logger.log(" GETTING Guides DATA: " , formData);
//     return fetch(url, {
//         headers: {
//             'hgio':getToken(),
//             // 'Content-Type': 'application/x-www-form-urlencoded'
//         },
//     method: 'GET',
//     // body: formData,
//     }).then((response) => {
//         return response.json();
//     }).then((data)=>{
//         logger.log(data)
//         var items = data.map((item,idx) => {
//             return new GuideModel(item);
//         })

//         //items = items.splice(0,50)
//         logger.log(items, ' getsamples')
//         return items
//         //return imgUploadUrl;
//     })
//     .catch((err) => {
//        console.error('ERROR getting guides',err);
//         throw(err);
//     })
// }

import { Button, Group, Paper, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconFile, IconUpload, IconX } from '@tabler/icons';
import { logger } from 'utils/logger';
import { useState } from 'react';
import { pluralize } from 'utils/helper';

const CsvUpload = ({data,callback}) => {
    const theme = useMantineTheme();

    const [files, setFiles] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    
    const previews = files.map((file, index) => {
        return (
            <Paper
                withBorder
                key={index}
                position='apart'
                mt={'lg'}
                mb={'sm'}
                shadow='sm'>
                <Group position='apart' mt={'sm'} mx={'sm'} mb={'sm'}>
                    <Group spacing={'xs'}>
                        <ThemeIcon radius='xl'>
                            <IconFile size={16} />
                        </ThemeIcon>
                        <Text size={'sm'}>{file.name}</Text>
                    </Group>
                    <Button variant='subtle' onClick={() => setFiles([])}>
                        Remove
                    </Button>
                </Group>
            </Paper>
        );
    });

    const onUploadClick = () => {
        setIsSaving(true);
        // dispatch(uploadCSVToSeason(data,files))
        callback(data,files);
    };

    return (
        <div>
            <Text my={'lg'}>
                Drop or select a CSV or XLSX file to upload it.
            </Text>

            <Dropzone
                onReject={(files) => logger.log('rejected files', files)}
                maxSize={3 * 1024 ** 2}
                accept={[MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
                onDrop={setFiles}>
                <Group
                    position='center'
                    spacing='sm'
                    style={{ minHeight: 150, pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            size={50}
                            stroke={1.5}
                            color={
                                theme.colors[theme.primaryColor][
                                    theme.colorScheme === 'dark' ? 4 : 6
                                ]
                            }
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size={50}
                            stroke={1.5}
                            color={
                                theme.colors.red[
                                    theme.colorScheme === 'dark' ? 4 : 6
                                ]
                            }
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconFile size={50} stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                        <Text size='xl' inline>
                            Drag your file here or click to select
                        </Text>
                        <Text size='sm' color='dimmed' inline mt={7}>
                            The file should not exceed 50mb
                        </Text>
                    </div>
                </Group>
            </Dropzone>

            <Group grow>{previews}</Group>

            <Group position='right' mt='md'>
                <Button
                    disabled={files.length ? false : true}
                    type='submit'
                    loading={isSaving}
                    onClick={onUploadClick}>
                    Upload {files.length} {pluralize('file', files.length)}
                </Button>
            </Group>

            {/* 
                      
                    {guide.url?
                      <Group grow mt={'md'}>
                        {downloads}
                      </Group>
                    :null} */}
        </div>
    );
};

// const mapStateToProps = (state,props) => {
    
//     return (
//     {
//        user: state.auth.user,
//        season: state.seasonReducer.selectedSeason,
//        success: state.seasonReducer.success,
//        error: state.seasonReducer.error,
       
//     })
//   }

// const mapDispatch = {   }

// export default connect(
//     mapStateToProps,
//     mapDispatch
//   )(CsvUpload)

export default CsvUpload
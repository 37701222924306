import { ActionIcon, Center, createStyles, Grid, Group, Menu, SegmentedControl, Slider, Text, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import {
    IconArrowsSort,
    IconCheck,
    IconLayoutGrid,
    IconLayoutSidebarLeftCollapse,
    IconLayoutSidebarLeftExpand,
    IconLayoutSidebarRight,
    IconLayoutSidebarRightCollapse,
    IconLayoutSidebarRightExpand,
    IconMessage,
    IconRectangle,
    IconSquareToggle,
} from '@tabler/icons';
import { CaptureToolbar } from 'core/components/index';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ApprovalLayoutTypes } from 'utils/constants';
import { ApprovalEventTypes, CaptureSortFields } from '../ApprovalModule';
import { logger } from 'utils/logger';
import TagTalent from '../../../core/components/capture/TagTalent';
import { CaptureEventType } from 'core/components/capture/CaptureThumb';

const useStyles = createStyles((theme) => ({
    container: {
        // marginLeft:`-${theme.spacing.lg}px`,
        position: 'sticky',
        top: 60,
        zIndex: 9,
        // marginRight:`-${theme.spacing.lg}px`
        borderBottom: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[6]}` : `1px solid ${theme.colors.gray[3]}`,
        paddingTop: 13,
        paddingBottom: 13,
        paddingLeft: theme.spacing.sm,
        paddingRight: 0,
        margin: 0,
        // paddingBottom:theme.spacing.sm,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,

        // '.hidden':{
        //   display:'none'
        // }
    },
    col: {
        padding: 0,
    },
    tabContent: {
        padding: theme.spacing.xs,
        paddingTop: 0,
    },
    hiddenContainer: {
        opacity: 0,
        width: 0,
        height: 0,
        visibility: 'none',
        overflow: 'hidden',
    },
}));

const Toolbar = ({
    settings,
    settingsChange,
    selectedItems,
    onImageControlClick,
    selectedCompareFrame,
    onCaptureChange,
    showLayoutControls = true,
    showCaptureToolbar = true,
    showSettingControls = true,
}) => {
    const { classes } = useStyles();
    const [starRating, setStarRating] = useState(-1);
    const theme = useMantineTheme();
    const [thumbSize, setThumbsize] = useState(150);

    const controlToolsRef = useRef(null);
    const [showTagTalent, setShowTagTalent] = useState(false);

    const onLayoutChange = (val) => {
        const tmpSettings = { ...settings, layoutType: val };
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
    };

    const onSliderChange = (val, settingObj) => {
        setThumbsize(val, () => {
            logger.log(settings, settingObj);
            if (val !== settings.thumbnailSize) {
                const tmpSettings = { ...settings, thumbnailSize: val };
                settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
            }
        });
    };

    // update the settings when the thumbnail size has changed
    useEffect(() => {
        const tmpSettings = { ...settings, thumbnailSize: thumbSize };
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
    }, [thumbSize]);

    const onPanelToggleClick = () => {
        const tmpSettings = { ...settings, infoOpen: !settings.infoOpen };
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
    };

    const onFilterPanelToggleClick = () => {
        const tmpSettings = { ...settings, filterOpen: !settings.filterOpen };
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
    };

    // useEffect(() => {
    //   logger.log('TOOLBAR REF CHANGE')
    //   if(controlToolsRef.current){
    //     // get the children.
    //     let ref = controlToolsRef.current;
    //     let controls = ref.getElementsByClassName('openseacontrols');

    //     logger.log(controlToolsRef.current.children, controls)
    //     if(controls && controls.length){
    //       if(selectedCompareFrame === 0){
    //         controls[1] && controls[1].classList.add("hidden");
    //         controls[0].classList.remove("hidden");
    //       }if(selectedCompareFrame === 1){
    //         controls[1] && controls[1].classList.remove("hidden");
    //         controls[0].classList.add("hidden");
    //       }
    //     }
    //   }

    // },[selectedCompareFrame,settings.layoutType,controlToolsRef.current])

    const ratingsCls = selectedItems && selectedItems.length > 1 ? '' : classes.hiddenContainer;

    useEffect(() => {
        /**
         * Method to calculate the common rating across all selected items. If they are the same we set them otherwise
         * we set to empty state
         */
        const calculateRating = () => {
            var isSame = true;
            var rating = selectedItems && selectedItems.length ? selectedItems[0].rating : -1;

            if (rating > -1) {
                for (let i = 0; i < selectedItems.length; i++) {
                    const val = selectedItems[i];
                    if (val.rating !== rating) {
                        isSame = false;
                        break;
                    }
                }
            }
            logger.log(rating);
            return isSame ? rating : -1;
        };
        setStarRating(calculateRating());
    }, [selectedItems]);

    const onSortChange = (type, val) => {
        logger.log(type);
        const tmpSettings = { ...settings, [type]: val };
        settingsChange(ApprovalEventTypes.SETTINGS_CHANGE, tmpSettings);
    };
    const spans = settings.layoutType === ApprovalLayoutTypes.GRID ? [5, 2, 5] : [3, 6, 3];

    return (
        <Group
            sx={{ width: '100%' }}
            position='apart'
            cols={3}
            className={`approvaltoolbar ${classes.container} unhightable`}
            data-layouttype={`${settings.layoutType}`}
            data-selectedframe={`${selectedCompareFrame}`}
            pr='md'>
            <Group span={spans[2]} sx={{ display: 'flex', width: 300 }}>
                {showLayoutControls ? (
                    <Group>
                        <Tooltip position='bottom-start' label={settings.filterOpen ? 'Hide Filter Panel' : 'Show Filter Panel'}>
                            <ActionIcon variant='filled' onClick={() => onFilterPanelToggleClick()}>
                                {settings.filterOpen ? <IconLayoutSidebarLeftCollapse size={18} /> : <IconLayoutSidebarLeftExpand size={18} />}
                            </ActionIcon>
                        </Tooltip>
                        <SegmentedControl
                            value={settings.layoutType}
                            onChange={onLayoutChange}
                            data={[
                                {
                                    value: ApprovalLayoutTypes.GRID,
                                    label: (
                                        <Center>
                                            <IconLayoutGrid size={16} />
                                            {/* <Box ml={10}>Preview</Box> */}
                                        </Center>
                                    ),
                                },
                                {
                                    value: ApprovalLayoutTypes.SINGLE,
                                    label: (
                                        <Center>
                                            <IconRectangle size={16} />
                                            {/* <Box ml={10}>Code</Box> */}
                                        </Center>
                                    ),
                                },
                                {
                                    value: ApprovalLayoutTypes.COMPARE,
                                    label: (
                                        <Center>
                                            <IconSquareToggle size={16} />
                                            {/* <Box ml={10}>Export</Box> */}
                                        </Center>
                                    ),
                                },
                                {
                                    value: ApprovalLayoutTypes.COMMENT,
                                    label: (
                                        <Center>
                                            <IconMessage size={16} />
                                            {/* <Box ml={10}>Export</Box> */}
                                        </Center>
                                    ),
                                },
                            ]}
                        />
                    </Group>
                ) : null}
            </Group>

            <Group span={spans[1]}>
                <Group ref={controlToolsRef} id='controllerCol' sx={{ justifyContent: 'center' }}>
                    {showCaptureToolbar ? (
                        <div className={ratingsCls} style={{position:'relative'}}>
                            <TagTalent opened={showTagTalent} onChange={setShowTagTalent} captures={selectedItems}>
                                <div>
                                <CaptureToolbar
                                    selectedItems={selectedItems}
                                    onCaptureChange={(type, data, items) => {
                                        onCaptureChange(type, data, items);
                                        if (type === CaptureEventType.TAG_TALENT) {
                                            setShowTagTalent(true);
                                        }
                                    }}
                                    rating={starRating}
                                />
                                </div>
                            </TagTalent>
                        </div>
                    ) : null}

                    {/* <ImageControlToolbar onControlsClick={onImageControlClick} /> */}
                </Group>
            </Group>

            <Group span={spans[2]} sx={{ display: 'flex', justifyContent: 'end', width: 300 }}>
                {showSettingControls ? (
                    <Group sx={{ display: 'flex', justifyContent: 'end' }}>
                        {settings.layoutType === ApprovalLayoutTypes.GRID ? (
                            <Group>
                                <Text size={'sm'} className='unhightable'>
                                    Size
                                </Text>
                                <Slider min={150} max={300} sx={{ width: 120 }} value={thumbSize} onChange={setThumbsize} />
                            </Group>
                        ) : null}
                        {/* <Select
                    sx={{width:150}}
                    placeholder="Sort By"
                    data={[
                      { value: 'sku', label: 'Capture Date' },
                      { value: 'desc', label: 'Modified Date' },
                      { value: 'location', label: 'Star Rating' },
                      { value: 'status', label: 'Session Name' },
                    ]}
                  /> */}
                        <SortMenu theme={theme} settings={settings} onSortChange={onSortChange} />
                        <Tooltip position='bottom' label={settings.infoOpen ? 'Hide Info Panel' : 'Show Info Panel'}>
                            <ActionIcon variant='filled' onClick={() => onPanelToggleClick()}>
                                {settings.infoOpen ? <IconLayoutSidebarRightCollapse size={18} /> : <IconLayoutSidebarRightExpand size={18} />}
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                ) : null}
            </Group>
        </Group>
    );
};

export default Toolbar;

const SortMenu = (props) => {
    return (
        <Menu shadow='md' width={200}>
            <Menu.Target>
                <Tooltip position='bottom' label='Sort Items'>
                    <ActionIcon>
                        <IconArrowsSort size={18} />
                    </ActionIcon>
                </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Sort By</Menu.Label>
                {CaptureSortFields.map((val, idx) => {
                    return (
                        <Menu.Item key={idx} onClick={(e) => props.onSortChange('sortBy', val)}>
                            <Group spacing={'xs'}>
                                <div style={{ width: 20 }}>{val.value === props.settings.sortBy.value ? <IconCheck size={14} color={props.theme.colors.brand[4]} /> : null}</div>
                                <Text>{val.label}</Text>
                            </Group>
                        </Menu.Item>
                    );
                })}
                <Menu.Label>Order</Menu.Label>
                <Menu.Item onClick={(e) => props.onSortChange('sortDirection', 'asc')}>
                    <Group spacing={'xs'}>
                        <div style={{ width: 20 }}>{'asc' === props.settings.sortDirection ? <IconCheck size={14} color={props.theme.colors.brand[4]} /> : null}</div>
                        <Text>Ascending</Text>
                    </Group>
                </Menu.Item>
                <Menu.Item onClick={(e) => props.onSortChange('sortDirection', 'desc')}>
                    <Group spacing={'xs'}>
                        <div style={{ width: 20 }}>{'desc' === props.settings.sortDirection ? <IconCheck size={14} color={props.theme.colors.brand[4]} /> : null}</div>
                        <Text>Descending</Text>
                    </Group>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

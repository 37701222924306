/**
 * @file outfitterSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit'
import undoable from 'redux-undo'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: { 
    theme: 'dark',
    error:null, 
    success:null, 
    loading:false, 
    action:null,
  },
  reducers: {

    setTheme(state,action){
      state.theme = action.payload;
    },

  }
})

export const { selectSample
  , setTheme
} = settingsSlice.actions

export default undoable(settingsSlice.reducer,{
  limit: 20 // set a limit for the size of the history
})

import { Button, Group, Input, Select, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { FormInputWrapper } from 'core/components/';

import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppConstants } from 'utils/constants';
import { logger } from 'utils/logger';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import moment from 'moment';

import { updateSample, updateSampleSuccess, clearNotifications } from 'modules/Warehouse/warehouseSlice';

const SampleEdit = ({ sample, onCancel, onSampleSave, season, departments, success }) => {
    const [active, handlers] = useDisclosure(false);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();

    const form = useForm({
        initialValues: {
            photoCode: '',
            styleCode: '',
            name: '',
            notes: '',
            size: '',
            department: '',
            otbDate: '',
        },

        validate: {
            photoCode: (value) => (value.length ? null : 'Invalid Photo Code'),
            styleCode: (value) => (value.length ? null : 'Invalid Style Code'),
            name: (value) => (value.length ? null : 'Invalid Name'),
        },
    });

    useEffect(() => {
        if (success) {
            switch (success.type) {
                case updateSampleSuccess.toString(): {
                    onSampleSave();
                    dispatch(clearNotifications());
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }, [success]);

    useEffect(() => {
        logger.log(active);
        if (!active) {
            logger.log('toggling focus ');
            setTimeout(() => {
                handlers.toggle();
            }, 1000);
        }
    }, [active, handlers]);

    /**
     * Function to submit values to create a new sample
     * @param {*} values
     */
    const formHandler = (values) => {
        setIsSaving(true);
        // we need to update a selectedTask.
        const deadline = moment(values.otbDate).format('YYYY-MM-DD');

        // var payload = new SampleModel({
        //     ...values,
        //     deadline: deadline,
        // });

        // if (mode === ViewModes.ADD) {
        //     delete payload.id;
        // }

        // we add the department
        const payload = { ...values, Dept: values.department };
        dispatch(updateSample(season, payload));
    };

    return (
        <>
            <form onSubmit={form.onSubmit((values) => formHandler(values))}>
                {/* <FocusTrap active={true}> */}
                <FormInputWrapper required label='Photo Code' id='input-photoCode'>
                    <Input id='input-photoCode' required placeholder='Enter Photo Code' data-autofocus variant={AppConstants.inputVariant} {...form.getInputProps('photoCode')} />
                </FormInputWrapper>

                <FormInputWrapper required label='Style' id='input-style'>
                    <Input id='input-style' required placeholder='Enter Style' variant={AppConstants.inputVariant} {...form.getInputProps('styleCode')} />
                </FormInputWrapper>

                <FormInputWrapper required label='Name' id='input-name'>
                    <Input id='input-name' required placeholder='Enter Name' variant={AppConstants.inputVariant} {...form.getInputProps('name')} />
                </FormInputWrapper>

                <FormInputWrapper label='Notes' id='input-notes'>
                    <Textarea id='input-notes' placeholder='Notes' variant={AppConstants.inputVariant} {...form.getInputProps('notes')} />
                </FormInputWrapper>

                <FormInputWrapper label='Sample size' id='input-sampleSize'>
                    <Input id='input-sampleSize' placeholder='Enter Sample Size' variant={AppConstants.inputVariant} {...form.getInputProps('size')} />
                </FormInputWrapper>

                <FormInputWrapper label='Department' id='input-department'>
                    <Select data={departments?.map(val => ({value:val.code, label:`${val.code} - ${val.name}`}))} id='input-department' placeholder='Enter Department' variant={AppConstants.inputVariant} {...form.getInputProps('department')} />
                </FormInputWrapper>

                <FormInputWrapper label='OTB Date' id='input-otbDate' required>
                    <CustomDatePicker required withAsterisk placeholder='OTB Date' {...form.getInputProps('otbDate')} />
                </FormInputWrapper>

                {/* <FormInputWrapper label='Season' id='input-season'>
                <Select
                    data={seasonData}
                    placeholder="Select Season"
                    nothingFound="Nothing found"
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setSeasonData((current) => [...current, item]);
                      return item;
                    }}
                  />
                </FormInputWrapper>


                <FormInputWrapper label='Tags' id='input-tags'>
                <Input id='input-tags'
                    placeholder="Tags" variant={AppConstants.inputVariant}
                />
                </FormInputWrapper>

                <FormInputWrapper label='Hero CAD' id='input-desc' description='Upload the CAD file for this sample. CAD file should be of type SVG.'>
                <SampleDropzone></SampleDropzone>
                </FormInputWrapper>       */}

                <Group position='right' mt='md'>
                    <Button type='submit' loading={isSaving}>
                        Create Style
                    </Button>
                </Group>
                {/* </FocusTrap> */}
            </form>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        sample: state.warehouse.selectedSample,
        departments: state.appReducer.departments,
        success: state.warehouse.success,
        error: state.warehouse.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(SampleEdit);

import { Box, Center, Group, Text, Title } from '@mantine/core';
import { IconCamera } from '@tabler/icons';

import React, {useRef, useState } from 'react';


import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';

import { cacheProp, getProp, useEffectOnce } from 'utils/helper';
import OpenSeaDragonViewer from './annotation/OpenSeaDragonViewer';
import { ApplicationTip, CaptureToolbar } from 'core/components/index';
import { TipTypes } from 'core/components/applicationTip/ApplicationTip';


// const useStyles = createStyles((theme) => ({
//   tooltip:{
//       position:'absolute',
//       fontSize:theme.fontSizes.xs,
//   },
  
// }));


const CommentLayout = ({image,onImageClick,imageControlSettings,settings,onSettingsChange,onCaptureChange}) => {

  // const { classes } = useStyles();

  const containerRef = useRef();
  const imageRef = useRef();

  // const [tipPosition,setTipPosition] = useState({left:0,top:0})
  // const imgEl = useRef();

  // The current Annotorious instance
  // const [ anno, setAnno ] = useState();

  // Current drawing tool name
  // const [ tool, setTool ] = useState('rect');

  // show draw tip
  const [showTip, setShowTip] = useState(false);

  useEffectOnce(() => {
    const hasAcknowledged = getProp(TipTypes.APPROVAL);
    if(!hasAcknowledged){
      setShowTip(true);
    }
  },[])
  /**
   * Handler for the onTipClose
   * Store the value in local storage so we don't have to display again.
   */
  const onTipClose = () => {
    cacheProp(TipTypes.APPROVAL,true);
    setShowTip(false);
  }


    return (
        <Box spacing={'lg'} sx={{height:'100%', display:'flex', flexDirection:'column', position:'relative'}} ref={containerRef}>
            
            {image?
              <OpenSeaDragonViewer image={image} 
                    imageControlSettings={imageControlSettings} 
                    settings={settings} 
                    onSettingsChange={onSettingsChange}>

              </OpenSeaDragonViewer>
            :
              <Center sx={{height:'100%'}}>
                <div style={{textAlign:'center'}}>
                  <IconCamera size={48} stroke={1} />
                  <Title color={'dimmed'} order={3}>Select an Image</Title>
                </div>
              </Center>
            }

            {image?
            <Group position='center' px={'xs'} py={'xs'} sx={{width:imageRef.current?.outerWidth, position:'relative'}}>
                <Group position='apart'>
                    <Text size={'xs'}>{image.name}</Text>
                    <CaptureToolbar onCaptureChange={(type,data) => onCaptureChange(type,data,[image])} rating={image.star} status={image.sak} />
                </Group>
                {/* <div className={classes.tooltip} style={{left:tipPosition.left,top:tipPosition.top}}>Add Comment <Kbd>(hold shift)</Kbd> </div> */}
                
            </Group>
            :
            null
            }
            
            {showTip?
              <ApplicationTip onClose={() => onTipClose()} />
            :null}
            
            
        </Box>
    )
}

export default CommentLayout;


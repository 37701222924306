import { Checkbox, Grid } from '@mantine/core';

import { SampleCard } from './SampleCard';

const SampleGrid = ({data,onItemClick,onItemSelected,selectedItems}) =>{

    const rows = data.map((element) => (
        <Grid.Col key={element.sku} md={6} lg={4} xl={3} sx={{position:'relative'}}>
          <SampleCard data={element} onShowClick={onItemClick} />
          <div style={{position:'absolute',top:20, left:20}}>
            <Checkbox checked={selectedItems.indexOf(element) > -1} onChange={(e) => onItemSelected(element,e.currentTarget.checked)}></Checkbox>
          </div>
        </Grid.Col>       
      ));
    return (
      <Grid>
        {rows}
      </Grid>        
    )
}

export default SampleGrid;

// function SampleItemMenu() {
//     return (
//       <Menu size='lg'>
//         <Menu.Item icon={<IconEdit size={14} />}>Edit Sample</Menu.Item>
//         <Menu.Item icon={<IconShare size={14} />}>Share Sample</Menu.Item>
//         <Menu.Item icon={<IconFolders size={14} />}>Assign to Brief</Menu.Item>
//         <Menu.Item icon={<ColorSwatch size={14} />}>Assign to StyleGuide</Menu.Item>
//         <Menu.Item color="red" icon={<IconTrash size={14} />} size={14}>Delete Sample</Menu.Item>
//       </Menu>
//     );
//   }
/**
 * @file outfitterSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit'
import undoable from 'redux-undo'
import { logger } from "utils/logger";
// let nextSampleId = 0;

// const filterInitialState = {
//   keyword : "",
//     showCount : 10,
//     statusList : [],
//     projectManagers : [],
//     sortBy : {key:'name',direction:SortDirection.ASC},
//     displayColumns : {
//         customer:{selected:true,label:"Customer"},
//         reportDate:{selected:true,label:"Report Date"},
//         status:{selected:true,label:"Status"},
//         notes:{selected:true,label:"Notes"},
//         concerns:{selected:true,label:"Concerns"},
//         projectManager:{selected:true,label:"PM"},
//     }
// }
// const filterSamples = (samples,filter) => {
//   return samples.filter(sample => {
//     var match = false;
//     // filter by brand
//     if(filter.brands){
//       match = filter.brands.indexOf(sample.facet_brand) > -1?true:false;
//     }else{
//       match = true;
//     }
//     logger.log(match, ' MATCH')
//     return match;
//   })
// }


const outfitterSlice = createSlice({
  name: 'outfitter',
  initialState: { 
    frames: [
      {
        id: 1,
        label: 'Headwear',
        items:[],
        width:100,
        height:100,
      },
      {
        id: 2,
        label: 'Top',
        items:[],
        width:100,
        height:200,
      },
      {
        id: 3,
        label: 'Bottom',
        items:[],
        width:100,
        height:200,
      },
      {
        id: 4,
        label: 'Footwear',
        items:[],
        width:100,
        height:100,
      },
  
    ],
    selectedFrame:null,
    selectedSample:null,
    // OUTFITS
    error:null, 
    success:null, 
    loading:false, 
    action:null,
  },
  reducers: {
    selectFrame(state,action){
      var val = null;
      if(action.payload){
        val = state.frames.find(frame => frame.id === action.payload.id);
      }
      state.selectedFrame = val?{...val}:null;
      
      
    },
    setFrame(state,action){
      //state.selectedFrame = state.frames.find(frame => frame.id === action.payload.id);

    },
    setFrames(state,action){
      state.frames = [...action.payload];
    },

    // SAMPLES
    selectSample(state,action){       
      logger.log('SELECTION SAMPLE, ', action.payload)
      state.selectedSample = action.payload?{...action.payload}:null;
    },

  }
})

export const { selectSample
  , selectFrame
  , setFrame
  , setFrames
} = outfitterSlice.actions

export default undoable(outfitterSlice.reducer,{
  limit: 20 // set a limit for the size of the history
})

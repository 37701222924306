import { ActionIcon, Center, RingProgress, ThemeIcon } from '@mantine/core';
import { IconCheck, IconFileDownload } from '@tabler/icons';
import { useState } from 'react';
import { downloadFile } from 'utils/helper';

export const FileDownloader = ({ url, filename, onComplete }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [percent, setPercent] = useState(0);

    const onProgress = (val) => {
        setPercent(val);
        if (val === 100) {
            onComplete();
        }
    };

    const onError = () => {
        setError(true);
    };

    return (
        <ActionIcon
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsLoading(true);
                downloadFile(url, filename, onProgress);
            }}
            variant={percent === 100 ? 'light' : 'filled'}
            sx={{ position: 'absolute', top: 3, left: 3 }}>
            {isLoading && percent ? <DownloadProgress isLoading={isLoading} percent={percent} /> : <IconFileDownload />}
            {/* <div style={{width:`${percent}%`,background:'blue', position:'absolute', left:0, height:28}}></div> */}
        </ActionIcon>
    );
};

export const DownloadProgress = ({ percent, isLoading }) => {
    let view;

    if (percent < 100) {
        view = <RingProgress size={25} thickness={2} sections={[{ value: percent, color: 'brand' }]} />;
    } else {
        view = (
            <RingProgress
                size={25}
                thickness={2}
                sections={[{ value: percent, color: 'brand' }]}
                label={
                    <Center>
                        <ThemeIcon color='brand' variant='solid' radius='xl' size='25'>
                            <IconCheck size={14} />
                        </ThemeIcon>
                    </Center>
                }
            />
        );
    }

    return view;
};

import { Group, Indicator, Text } from "@mantine/core";
import { forwardRef } from "react";

const IndicatorSelectItem = forwardRef(({ image, label, color, description, labelClass, ...others }, ref) => (
    <div ref={ref} {...others}>
        <Group noWrap>
            <Indicator position='middle-start' color={color}>
                <Text sx={{ paddingLeft: 12 }} className={labelClass}>
                    {label}
                </Text>
            </Indicator>
        </Group>
    </div>
));

export default IndicatorSelectItem
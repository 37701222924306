import { createStyles } from '@mantine/core';
import { useDrag } from 'react-dnd';
import { ItemTypes } from 'utils/constants';
import { logger } from "utils/logger";

const useStyles = createStyles((theme, _params, getRef) => ({

    container:{
        border: `2px dashed ${theme.colors.brand[5]}`,
        borderRadius:4,
        // backgroundColor: 'white',
        // padding: '0.5rem 1rem',
        // marginRight: '1.5rem',
        // marginBottom: '1.5rem',
        flexGrow:1,
        cursor: 'move',
        overflow:'hidden',
        borderColor:theme.colorScheme === 'dark'? theme.colors.brand[6]:theme.colors.gray[4],
    }
    
}))

const SampleDragBox = ({data,onSampleDrop,children,style={},type=ItemTypes.BOX}) => {
    
    const { classes } = useStyles();

    const drop = (item,target) => {
      onSampleDrop(item,target)
    }

    const [{ isDragging }, drag] = useDrag(() => ({
      type,
      item: { ...data },
      // we let the trash collector handle the drop
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          //  alert(`You dropped ${item.id} into ${dropResult.id}!`);
          drop(item,dropResult);  
          //dropResult.data.items.push(item);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),[data])
    
    const opacity = isDragging ? 0.4 : 1
  
    return (
      <div ref={drag} className={classes.container} style={{...style, opacity }} data-testid={ItemTypes.BOX}>
        {children}
      </div>
    )
  }

  export default SampleDragBox;
import { Button, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { FormInputWrapper } from 'core/components/';

import { useEffect } from 'react';
import { connect } from 'react-redux';
import SampleDropzone from '../components/sampleDropzone';
import { logger } from "utils/logger";

const SamplesUpload = ({sample,onCancel,onSampleSave}) => {

    const [active, handlers] = useDisclosure(false);
    // const [files, setFiles] = useState([]);
    

    // const [seasonData, setSeasonData] = useState([

    //   { value: 'winter', label: 'Winter' },
    //   { value: 'spring', label: 'Spring' },

    // ])
    // const previews = files.map((file, index) => {
    //   return (
    //     <Group key={index} position='apart' mt={'lg'} mb={'sm'} >
    //         <Group spacing={'xs'}>
    //             <ThemeIcon radius="xl">
    //                 <IconFile size={16} />
    //             </ThemeIcon>

    //             <Text size={'sm'}>{file.name}</Text>
    //         </Group>
    //         <Button variant='subtle' onClick={() => setFiles([])}>Remove</Button>
    //     </Group>
    //   );
    // });

    const form = useForm({
        initialValues: {
          sku:'',
          name: '',
          description:'',
          tags:'',
        },
    
        validate: {
          name: (value) => (value.length ? null : 'Invalid name'),
        },
      });

      useEffect(() => {
        logger.log(active)
        if(!active){
            logger.log('toggling focus ')
            setTimeout(() => {handlers.toggle();},1000)
        }
      },[active,handlers])

    //   useEffect(() => {
        
    //     // Specify how to clean up after this effect:
    //     return function cleanup() {
    //       logger.log('cleanup');
    //       if(active)handlers.toggle();
    //     };
    //   });

    /**
     * Function to submit values to create a new sample
     * @param {*} values 
     */
    const formHandler = (values) => {
        
        // dispatch(updateSampl(values))
    }

    return (
        <>
            <form onSubmit={form.onSubmit((values) => formHandler(values))}>
            
                <FormInputWrapper label='Speadsheet' id='input-file' description='Upload the Speadsheet. CAD file should be of type csv.'>
                <SampleDropzone></SampleDropzone>
                </FormInputWrapper>      

                <Group position="right" mt="md">
                    <Button type="submit">Next</Button>
                </Group>
            
            </form>
        </>
    )
}

const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
       user: state.auth.user,
       sample: state.warehouse.selectedSample,
       success: state.warehouse.success,
       error: state.warehouse.error,
       
    })
  }

const mapDispatch = {   }

export default connect(
    mapStateToProps,
    mapDispatch
  )(SamplesUpload)
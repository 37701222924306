import { Button, Group, MultiSelect } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { FormInputWrapper } from 'core/components/index';
import SessionSelect from 'core/components/sessionSelect/SessionSelect';
import { SessionModel } from 'core/models';
import { assignStylesToSession, assignStylesToSessionSuccess } from 'modules/Seasons/seasonSlice';

import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { logger } from 'utils/logger';

const AssignToSession = ({ onCancel, onSuccess, onSubmit, styles, availableStyles, success, error }) => {
    const [active, handlers] = useDisclosure(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const dispatch = useDispatch();

    logger.log(availableStyles);
    const [styleData, setStyleData] = useState(
        availableStyles
            ? [
                  ...availableStyles.map((val) => {
                      const styleNumber = val.seasonSort && val.seasonSort.length ? val.seasonSort : val.targetSort;
                      return { label: styleNumber, value: val.id };
                  }),
              ]
            : [],
    );
    const form = useForm({
        initialValues: {
            sessionId: '',
            styles: styles ? [...styles.map((val) => val.id)] : [],
        },
    });

    // useEffect(() => {
    //     if (success) {
    //         switch (success.type) {
    //             case assignStylesToSessionSuccess.toString(): {
    //                 if (onSuccess) onSuccess();
    //                 break;
    //             }
    //             default: {
    //                 break;
    //             }
    //         }
    //     }
    // }, [success]);

    const onFormSubmit = (values) => {
        const code = values.sessionId.split('|')[1];
        const session = new SessionModel({ code });
        const tmpStyles = values.styles.map((id) => {
            let item = availableStyles.find((val) => val.id === id);
            if (item && !item.instructions) {
                item.instructions = {};
                item.instructions['department'] = item.department;
                item.instructions['Dept'] = item.department;
                item.instructions['Web Photography Code'] = item.photoCode;
                item.instructions['Web Photography Sample Size'] = item.size;
                item.instructions['Web Stock OTB Date'] = item.otbDate;
            }
            const styleNumber = item.seasonSort && item.seasonSort.length ? item.seasonSort : item.targetSort;
            const itemName = item.instructions && item.instructions.Name ? item.instructions.Name : item.name;
            const style = { ...item, stylenumber: styleNumber, name: itemName, descr: '', targetSort: styleNumber };
            return style;
        });

        if(onSubmit){
            onSubmit(session,tmpStyles)
        }else{
            dispatch(assignStylesToSession(session, tmpStyles));
        }
        
    };

    return (
        <>
            <form
                onSubmit={form.onSubmit((values) => {
                    onFormSubmit({ ...values });
                    setIsLoading(true);
                })}>
                <FormInputWrapper label='Choose a Session' labelWidth={150} required={true}>
                    <SessionSelect showDefaultValues={false} onChange={(val) => form.setFieldValue('sessionId', val)} value={form.values.sessionId} required={true} />
                </FormInputWrapper>

                <FormInputWrapper label='Styles' id='input-tags' labelWidth={150}>
                    <MultiSelect data={styleData} searchable placeholder='Type to enter Styles' {...form.getInputProps('styles')} />
                </FormInputWrapper>

                <Group position='right' mt='md'>
                    <Button type='submit' loading={isLoading}>
                        Assign
                    </Button>
                </Group>
            </form>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        session: state.sessionReducer.selectedSession,
        guides: state.guideReducer.guides,
        success: state.seasonReducer.success,
        error: state.seasonReducer.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(AssignToSession);

export const OutfitModel = {
    name:'',
    description:'',
    notes:'',
    frames:[],
}

export const DefaultFrames = {
    FASHION:[
        {
          id: 1,
          label: 'Headwear',
          items:[],
          width:100,
          height:100,
        },
        {
          id: 2,
          label: 'Top',
          items:[],
          width:100,
          height:200,
        },
        {
          id: 3,
          label: 'Bottom',
          items:[],
          width:100,
          height:200,
        },
        {
          id: 4,
          label: 'Footwear',
          items:[],
          width:100,
          height:100,
        },
    
      ],
    TOOLBAR_WIDTH:60,
}
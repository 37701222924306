import { Badge, Box, Center, Container, createStyles, Group, Stack, Text, Title } from '@mantine/core';
// import { get, set } from 'idb-keyval';
import { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'utils/helper';
import { CaptureEventType } from './CaptureThumb';
// import { ApprovalEventTypes } from '../ApprovalModule';
import CaptureToolbar from './CaptureToobar';
import { FlagRating } from 'utils/constants';
import ResolverImage from '../resolverImage/ResolverImage';

const useStyles = createStyles((theme, _params, getRef) => ({
    container: {
        // marginLeft:`-${theme.spacing.lg}px`,
        // marginRight:`-${theme.spacing.lg}px`
        borderBottom: `1px solid ${theme.colors.dark[6]}`,
        paddingTop: theme.spacing.sm,
        paddingLeft: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    imageClass: {
        ref: getRef('imageClass'),
        border: '3px solid transparent',
        padding: 2,
        maxWidth: '100%',
        objectFit: 'contain',

        // height:'100%',
        // width:'100%',
    },
    imageBadge: {
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: theme.radius.md,
    },
    imageThumb: {
        borderRadius: theme.radius.sm,
        paddingTop: theme.spacing.xs,
        border: `1px solid transparent`,
        img: {
            userSelect: 'none',
        },
        '&.selected': {
            [`.${getRef('imageClass')}`]: {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[3] : theme.colors.gray[3],
            },
        },
        // '&:hover':{
        //     background:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.gray[3],
        // },
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

const CaptureDetail = ({ image, onImageEvent, size = '100%', selected, badgeLabel, onCaptureChange, showSettings = true, src, resoloveImage = false, scaleToFit = false }) => {
    const [imageSrc, setImageSrc] = useState(src);
    const imgSrc = useRef({ original: '', url: '' });
    const imgRef = useRef();
    const isAliveRef = useRef(true);
    const [star, setStar] = useState(image.star);
    const [sak, setSak] = useState(image.sak);

    const onClick = useCallback(
        (event) => {
            onImageEvent(CaptureEventType.IMAGE_CLICK, image, event);
        },
        [image, onImageEvent],
    );

    const onDoubleClick = (event) => {
        onImageEvent(CaptureEventType.IMAGE_DBL_CLICK, image, event);
    };

    const { classes } = useStyles();
    const imgClass = selected ? ' selected' : '';

    useEffectOnce(() => {
        if (src && src.length) {
            setImageSrc(src);
        } else {
            // test()
        }

        window.addEventListener('keydown', keyHandler);

        return () => {
            isAliveRef.current = false;
            window.removeEventListener('keydown', keyHandler);
        };
    });

    const handleClick = (e) => {
        if (e.detail === 2) {
            onImageEvent(CaptureEventType.IMAGE_DBL_CLICK, image, e);
        } else {
            onImageEvent(CaptureEventType.IMAGE_CLICK, image, e);
        }
    };

    const keyHandler = (e) => {
        switch (e.code) {
            case 'Numpad5':
            case 'Numpad4':
            case 'Numpad3':
            case 'Numpad2':
            case 'Numpad1':
            case 'Digit5':
            case 'Digit4':
            case 'Digit3':
            case 'Digit2':
            case 'Digit1': {
                // set the rating
                const data = { data: e.key };
                onCaptureChange(CaptureEventType.RATING_CHANGE, data, [image]);
                setStar(e.key);
                break;
            }
            case 'BracketLeft':
            case 'BracketRight': {
                // set the rating.
                const data = { data: e.code === 'BracketLeft' ? FlagRating.FLAG_SELECT : FlagRating.FLAG_KILL };
                setSak(data.data);
                onCaptureChange(CaptureEventType.STATUS_CHANGE, data, [image]);

                break;
            }
            default: {
                break;
            }
        }
    };

    return (
        <Stack className={`${classes.imageThumb} ${imgClass}`} onClick={(e) => handleClick(e)}>
            <div style={{ position: 'relative', width: '100%', height: scaleToFit ? 'calc(100vh - 200px)' : '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {badgeLabel ? (
                    <Badge size='xs' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                        {badgeLabel}
                    </Badge>
                ) : null}

                {imageSrc && imageSrc.length ? (
                    <>
                        {resoloveImage ? (
                            <ResolverImage src={src} fit={scaleToFit ? 'contain' : 'cover'} height={scaleToFit ? 'calc(100vh - 200px)' : '100%'} />
                        ) : (
                            <img alt={image.originalFilename} ref={imgRef} src={imageSrc} className={`${classes.imageClass} unhightable`} fit='contain' style={{ maxWidth: size, maxHeight: size }} />
                        )}
                    </>
                ) : (
                    <Group style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                        <Center>
                            {/* <Loader /> */}
                            <Text>Loading..</Text>
                        </Center>
                    </Group>
                )}
            </div>
            {showSettings && image ? (
                <Box position='apart' px={'xs'} py={'xs'} sx={{ opacity: 1 }}>
                    {/* <Text size={'xs'}>{imageSrc} </Text> */}
                    {/* <Checkbox  /> */}
                    <CaptureToolbar size={'sm'} image={image} rating={star} onCaptureChange={(type, data, items) => onCaptureChange(type, data, [image])} status={sak} />
                    <Text size={'xs'} mt={'xs'} color='dimmed' className='unhightable'>
                        {image.originalFilename}
                    </Text>
                </Box>
            ) : (
                <Box position='apart' px={'xs'} sx={{ opacity: 1 }}>
                    {image ? (
                        <Text size={'xs'} color='dimmed' className='unhightable'>
                            {image.name}
                        </Text>
                    ) : null}
                </Box>
            )}

            {showSettings ? (
                <Container>
                    <Box mb={'lg'}>
                        <Title className='main-content-heading' order={6} mb='xs'>
                            Photography Notes
                        </Title>
                        <Group spacing={'xs'} sx={{ justifyContent: 'center' }}>
                            {image &&
                                image.photoNotes &&
                                image.photoNotes.length?
                                <Text>{image.photoNotes}</Text>
                                : <Text color='dimmed'>No notes available</Text>}
                               

                        </Group>
                    </Box>{' '}
                    <Box mb={'lg'}>
                        <Title className='main-content-heading' order={6} mb='lg'>
                            Samples
                        </Title>
                        <Group spacing={'xs'} sx={{ justifyContent: 'center' }}>
                            {image &&
                                image.styles &&
                                image.styles.length &&
                                image.styles.split(',').map((val, idx) => (
                                    <Badge key={idx} variant='light' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                        {val}
                                    </Badge>
                                ))}

                            {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>Winter</Badge> */}
                        </Group>
                    </Box>
                    <Box>
                        <Title className='main-content-heading' order={6} mb='lg'>
                            Tags
                        </Title>
                        <Group spacing={'xs'} sx={{ justifyContent: 'center' }}>
                            {image &&
                                image.tags &&
                                image.tags.length &&
                                image.tags.split(',').map((val, idx) => (
                                    <Badge key={idx} variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                        {val}
                                    </Badge>
                                ))}

                            {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>Winter</Badge> */}
                        </Group>
                    </Box>
                </Container>
            ) : null}
        </Stack>
    );
};

export default CaptureDetail;

import { configureStore } from '@reduxjs/toolkit'
//import { ThunkAction } from 'redux-thunk'
// import rootReducer, { RootState } from 'app/rootReducer'
import rootReducer from '../reducers'
// Import the `thunk` middleware
import thunk from 'redux-thunk';
import { logger } from 'utils/logger';

const stateName = `${process.env.NODE_ENV}_persistantState`

// convert object to string and store in localStorage
function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem(stateName, serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

// load string from localStarage and convert into an Object
// invalid output must be undefined
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem(stateName);
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export function clearLocalStorage() {
  logger.log('clearing local storage')
  localStorage.removeItem(stateName);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  // preloadedState:loadFromLocalStorage()
})

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => {
  logger.log('store changed')
  // saveToLocalStorage(store.getState())
});


//export const AppDispatch = typeof store.dispatch
//export const AppThunk = ThunkAction

export default store
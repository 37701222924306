import { useState } from 'react';
import { Group, Text, useMantineTheme } from '@mantine/core';

import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconFile, IconPhoto, IconUpload, IconX } from '@tabler/icons';
import { logger } from "utils/logger";

function getIconColor(status, theme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
    : theme.colorScheme === 'dark'
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}

function ImageUploadIcon({
  status,
  ...props
}) {
  if (status.accepted) {
    return <IconUpload {...props} />;
  }

  if (status.rejected) {
    return <IconX {...props} />;
  }

  return <IconPhoto {...props} />;
}

export const dropzoneChildren = (status, theme) => (
  <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
    <ImageUploadIcon status={status} style={{ color: getIconColor(status, theme) }} size={80} />

    <div>
      <Text size="xl" inline>
        Drag images here or click to select files
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed 5mb
      </Text>
    </div>
  </Group>
);

export default function SampleDropzone() {
  const theme = useMantineTheme();
  const [setFiles] = useState([]);

  return (

    <Dropzone
        onReject={(files) => logger.log('rejected files', files)}
        maxSize={3 * 1024 ** 2}
        accept={IMAGE_MIME_TYPE}
        onDrop={setFiles}
        >
        <Group position="center" spacing="sm" style={{ minHeight: 150, pointerEvents: 'none' }}>
            <Dropzone.Accept>
            <IconUpload
                size={50}
                stroke={1.5}
                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
            </Dropzone.Accept>
            <Dropzone.Reject>
            <IconX
                size={50}
                stroke={1.5}
                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
            </Dropzone.Reject>
            <Dropzone.Idle>
            <IconFile size={50} stroke={1.5} />
            </Dropzone.Idle>

            <div>
            <Text size="xl" inline>
                Drag your file here or click to select
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
                The file should not exceed 5mb
            </Text>
            </div>
        </Group>
    </Dropzone>

  );
}
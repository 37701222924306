import React, { memo, useEffect} from 'react';
import { ApprovalLayoutTypes } from 'utils/constants';
import { logger } from 'utils/logger';
import CommentLayout from './CommentLayout';
import CompareLayout from './CompareLayout';
import GridLayout from '../../../core/components/scrollLoadCaptures/GridLayout';
import SingleLayout from './SingleLayout';

const MainPanel = ({settings,onImageEvent,captures, selectedItems, selectedItem, comparisonItems, selectedCompareFrame, imageControlSettings, onCaptureChange, onSettingsChange, imageLocHash, showLoading, isSearching, searchCursor, loadNextPage}) => {

    var layout =  <GridLayout />;

    /**
     * Effect to remove the zoom controls
     */
    useEffect(() => {

        function removeControls(){
            var elems = document.getElementsByClassName('openseacontrols');
            logger.log('removing controls')
            if(elems){      
                for (var i = 0; i < elems.length; i++) {
                    elems[i].remove();
                }               
            }
        }    
        removeControls();        

    },[])
   
    switch (settings.layoutType){
        case ApprovalLayoutTypes.GRID:{
            layout = <GridLayout 
                        onImageEvent={onImageEvent} 
                        thumbnailSize={settings.thumbnailSize} 
                        captures={captures} 
                        selectedItems={selectedItems} 
                        onCaptureChange={onCaptureChange} 
                        imageLocHash={imageLocHash}
                        showLoading={showLoading}
                        isSearching={isSearching}
                        searchCursor={searchCursor}
                        loadNextPage={loadNextPage} />;
            break;
        }
        case ApprovalLayoutTypes.SINGLE:{
            layout = <SingleLayout 
                        image={selectedItem}  
                        onCaptureChange={onCaptureChange} 
                        settings={settings} 
                        imageLocHash={imageLocHash}
                        />
            break;
        }
        case ApprovalLayoutTypes.COMPARE:{
            layout = <CompareLayout 
                        onImageEvent={onImageEvent}
                        captures={captures} 
                        selectedItems={selectedItems} 
                        comparisonItems={comparisonItems} 
                        selectedFrame={selectedCompareFrame} 
                        onCaptureChange={onCaptureChange} 
                        settings={settings} 
                        imageLocHash={imageLocHash}/>
            break;
        }
        case ApprovalLayoutTypes.COMMENT:{
            layout = <CommentLayout 
                        onSettingsChange={onSettingsChange} 
                        settings={settings} 
                        image={selectedItem} 
                        onImageEvent={onImageEvent} 
                        captures={captures} 
                        selectedItems={selectedItems} 
                        comparisonItems={comparisonItems} 
                        selectedFrame={selectedCompareFrame} 
                        imageControlSettings={imageControlSettings}  
                        onCaptureChange={onCaptureChange} 
                        imageLocHash={imageLocHash}/>
            break;
        }
        default:{
            break;
        }
    }
    return (
        <div style={{flexGrow:1}} className="unhightable">
        {/* <ScrollArea pt={'lg'} px={'lg'}> */}
        {layout}
        {/* </ScrollArea> */}
        </div>
    )
}

export default memo(MainPanel);
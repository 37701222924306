import { GuideCategoryModel } from 'core/models/GuideCategoryModel';
import { GuideModel } from 'core/models/GuideModel';
import { getToken, parseObjectTypes, parseValue } from 'utils/helper';
import { logger } from 'utils/logger';

export const getGuide = async (id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/guides/${id}`;

    const formData = JSON.stringify({});

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            if (!data.Items || (data.Items && data.Items.length === 0)) {
                throw new Error('No style exists');
            }
            let item = parseValue(data.Items[0]);
            item.guideCategories = item.guideCategories?.map((val, idx) => ({ ...val, id: idx.toString() }));
            if(item.tags && !Array.isArray(item.tags) || item.tags === ''){
                item.tags = item.tags.length?item.tags.split(','):[];
            }
            item.id = item.code;
            return new GuideModel(item);
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR getting guide', err);
            throw err;
        });
};

export const getGuides = async (criteria, token) => {
    logger.log('getting guides');
    const url = `${process.env.REACT_APP_API_URL}/guides`;

    const formData = JSON.stringify({});

    logger.log(' GETTING Guides DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                let guide = {...item};
                guide.id = guide.code;
                if(guide.tags && !Array.isArray(guide.tags)  || guide.tags === '' ){
                    guide.tags = guide.tags.length?guide.tags.split(','):[];
                }
                return new GuideModel(guide);
            });

            return items;
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR getting guides', err);
            throw err;
        });
};

///////////////////////
//
// GUIDE UPDATE
//
///////////////////////
export const udpateGuide = async (guide, token) => {
    var url = `${process.env.REACT_APP_API_URL}/guides`;
    let method = 'POST';
    let payload = { ...guide };
    delete payload.files;

    if (guide.code) {
        url += `/${guide.code}`;
        method = 'PUT';
    } else {
        delete payload.id;
    }

    let cats = [];

    // delete file from the shots and update the reference url if the file exists
    if(payload.guideCategories){
        cats = payload.guideCategories.map(cat => {
            var tmpCat = {...cat};
            if(tmpCat.shots){
                var shots = [...tmpCat.shots].map(shot => {
                    let tmpShot = {...shot, seq:shot.seq.toString()}
    
                    if(shot.files && shot.files.length){
                        tmpShot.referenceUrl = `${process.env.REACT_APP_API_URL}/guides/${guide.code}/cats/${cat.id}/${shot.files[0].name}`;
                        delete tmpShot.files;
                    }
                    return tmpShot;
                })
                tmpCat.shots = shots;
            }
            return tmpCat;
        });
    
    }
   
    payload.guideCategories = cats;

    const formData = JSON.stringify(payload);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: method,
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            let guide;
            if (data.Attributes) {
                guide = new GuideModel(data.Attributes);
            } else {
                guide = new GuideModel(data);
            }
            if(guide.tags && !Array.isArray(guide.tags)  || guide.tags === ''){
                guide.tags = guide.tags.length?guide.tags.split(','):[];
            }
            guide.id = guide.code;
            return guide;
        })
        .catch((err) => {
            console.error('ERROR udpating guide', err);
            throw err;
        });
};

export const getUploadUrl = async (id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/guide/${id}/upload`;

    const formData = JSON.stringify({});

    logger.log(' GETTING UPLOAD URL: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR getting upload url', err);
            throw err;
        });
};

export const uploadGuideFile = async (file, guide, url, user) => {
    //const url = `${process.env.REACT_APP_API_URL}/guides/${guide.id}`;
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            // return response.json();
            return;
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR udpating guide', err);
            throw err;
        });
};

export const getCategoryShotFile = async (shot) => {
    const url = `${shot.referenceUrl}`;
    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
    })
        .then((response) => {
            // return response.json();
            return response;
        })
        
        .catch((err) => {
            console.error('ERROR udpating guide', err);
            throw err;
        });
};
export const uploadCategoryShotFile = async (file, guide, category, user) => {
    const url = `${process.env.REACT_APP_API_URL}/guides/${guide.code}/cats/${category.id}/${file.name}`;
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            // return response.json();
            return;
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR udpating guide', err);
            throw err;
        });
};

export const deleteGuide = async (task, token) => {
    const url = `${process.env.REACT_APP_API_URL}/guides`;

    const formData = JSON.stringify({});

    logger.log(' DELETING Task DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR deleting tasks', err);
            throw err;
        });
};

export const getGuideCategories = async (guide, token) => {
    const url = `${process.env.REACT_APP_API_URL}/guides/${guide.id}/cats`;

    const formData = JSON.stringify({});

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                const obj = parseObjectTypes(item);
                return new GuideCategoryModel(obj);
            });

            //items = items.splice(0,50)
            logger.log(items, ' getsamples');
            return items;
            //return imgUploadUrl;
        })
        .catch((err) => {
            console.error('ERROR getting guides', err);
            throw err;
        });
};

export const udpateGuideCategory = async (guide, category, token) => {
    var url = `${process.env.REACT_APP_API_URL}/guides/${guide.id}/cats`;

    const formData = JSON.stringify(guide);

    logger.log(' UPDATING GUIDE: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR udpating guide', err);
            throw err;
        });
};

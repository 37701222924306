import { GuideEventType } from "core/models/GuideModel";
import { ViewModes } from "utils/constants";

const { Group, Anchor, Text, Box, Skeleton } = require("@mantine/core");
const { IconPalette } = require("@tabler/icons");
const { default: GuideItemMenu } = require("modules/Guides/features/guide/GuideItemMenu");

const StyleGuideTableRow = (props) => {
  const { element,onGuideAction,index,mode=ViewModes.EDIT } = props;

  const row = element.id ? (
    <tr key={index}>
      <td>
        <Group>
          <Group sx={{ flexShrink: 0 }}>
            <Group>
              <IconPalette />
            </Group>
            <Box sx={{ flexShrink: 1 }}>
              <Anchor
                size='sm'
                onClick={() =>
                  onGuideAction({ type: GuideEventType.GUIDE_VIEW }, element)
                }
              >
                <Text size='sm' lineClamp={1} sx={{ maxWidth: '250px' }}>
                  {element.name}
                </Text>
              </Anchor>
             
            </Box>
          </Group>
        </Group>
      </td>
      <td>{element.descr}</td>
      <td align='right'>
        {mode === ViewModes.VIEW?null:<GuideItemMenu onMenuItemClick={(e) => onGuideAction(e, element)} />}
      </td>
    </tr>
  ) : (
    <tr key={index}>
      <td>
        <Group>
          <Group sx={{ flexShrink: 0 }}>
            <Group>
              <Skeleton height={26} radius='xl' width={26} />
            </Group>
            <Box sx={{ flexShrink: 1 }}>
              <Skeleton height={16} radius='xl' width={200} mb={'xs'} />
              <Skeleton height={10} radius='xl' width={100} />
            </Box>
          </Group>
        </Group>
      </td>
      <td>
        <Skeleton height={10} radius='xl' width={'100%'} />
      </td>
      <td>
        <Skeleton height={10} radius='xl' width={'100%'} />
      </td>
    </tr>
  );

  return (
    <>
    {row}
    </>
  );
};

export default StyleGuideTableRow;
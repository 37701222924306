import { TeamEventType } from 'core/models/TeamMemberModel';

const { Menu, ActionIcon } = require('@mantine/core');
const { IconDots, IconTrash } = require('@tabler/icons');

const TeamItemMenu = (props) => {
    return (
        <Menu size='lg' position='bottom-end'>
            <Menu.Target>
                <ActionIcon>
                    <IconDots />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={() => props.onMenuItemClick({ type: TeamEventType.TEAM_DELETE_MEMBER })} icon={<IconTrash size={14} />}>
                    Remove
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
export default TeamItemMenu;

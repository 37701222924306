import { useDisclosure } from '@mantine/hooks';
import { IconShirt } from '@tabler/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStyle } from 'services/styleService';
import ResolverImage from '../resolverImage/ResolverImage';
import { logger } from 'utils/logger';
import { getStatusColor, getStyleStatusColor } from 'utils/helper';
import { getSeason } from 'services/seasonService';

const { Popover, Text, Group, Button, createStyles, Box, Badge } = require('@mantine/core');

const useStyles = createStyles((theme) => ({
    card: {
        //   margin:-theme.spacing.md,
        padding: 0,
        position: 'relative',
    },

    imageSection: {
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    },

    label: {
        marginBottom: theme.spacing.xs,
        lineHeight: 1,
        fontWeight: 700,
        fontSize: theme.fontSizes.xs,
        //   letterSpacing: -0.25),
        textTransform: 'uppercase',
    },

    section: {
        paddingTop: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        margin: 0,
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },
}));

const SeasonPopover = (props) => {
    const [opened, { close, open }] = useDisclosure(false);
    const [data, setData] = useState();
    const { classes } = useStyles();
    const popRef = useRef();
    const doClose = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (opened) {
            logger.log(props.data, data);
            async function fetchSample() {
                const val = await getSeason(props.data.seasonPart);
                // setData(val);
                setData({ ...props.data, ...val });
            }
            // fetch the sample
            if (!data || (data && data.seasonPart !== props.data.seasonPart && opened)) fetchSample();
        }
    }, [opened]);

    const mouseHandler = (e) => {
        setTimeout(() => {
            if (doClose.current) {
                close();
                doClose.current = true;
            }
        }, 200);
    };

    const showDetails = () => {
        navigate(`/seasons/${props.data.seasonPart}`);
        props.onShowDetails();
    };

    return (
        <Popover withinPortal={true} width={300} position='bottom-end' withArrow shadow='md' opened={opened}>
            <Popover.Target>
                <div ref={popRef} onMouseEnter={open} onMouseLeave={(e) => mouseHandler(e)}>
                    {props.children}
                </div>
            </Popover.Target>

            <Popover.Dropdown sx={{ pointerEvents: 'initial' }}>
                {data ? (
                    <Box
                        className={classes.card}
                        style={{ padding: 0 }}
                        onMouseLeave={() => {
                            close();
                            doClose.current = true;
                        }}
                        onMouseEnter={() => {
                            open();
                            doClose.current = false;
                        }}>
                        {/* <Box className={classes.imageSection}>
                            <Image src="https://i.imgur.com/ZL52Q2D.png" />
                            <IconShirt size={72} stroke={1} />
                            <ResolverImage width={120} height={180} src={data.preview} />
                        </Box> */}

                        <Group position='apart' mt='md'>
                            <div>
                                <Text fw={500}>{data?.name}</Text>
                                <Text color='dimmed' size={'sm'} lineClamp={2}>
                                    {data?.id}
                                </Text>
                            </div>
                            {data?.status?
                            <Badge color={getStyleStatusColor(data?.status)} variant="light" >{data?.status}</Badge>
                            :null}
                            
                        </Group>

                        <Box className={classes.section} mt='md'>
                            <Group>
                                <div>
                                    {/* <Text size='sm' c='dimmed' className={classes.label}>
                                        Description
                                    </Text> */}

                                    <Group spacing={8} mb={-8}>
                                    <Text size={'sm'}>{data?.description}</Text>
                                    {/* {data.assets.map((val,idx) => {

                                        return (
                                            <React.Fragment key={idx}>
                                            <ResolverImage src={val.thumb} height={30} width={30} />
                                            </React.Fragment>
                                        )
                                    })} */}
                                    </Group>
                                </div>
                            </Group>
                        </Box>
                        <Box className={classes.section}>
                            <Group position='apart'>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Guide
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.guideName}
                                    </Text>
                                </div>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Style Count
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.styleCount}
                                    </Text>
                                </div>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Department
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.instructions.department}
                                    </Text>
                                </div>
                            </Group>
                        </Box>
                        <Box className={classes.section}>
                            <Group spacing={30}>
                                <Button style={{ flex: 1 }} onClick={showDetails}>
                                    View Details
                                </Button>
                            </Group>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Text size={'sm'} color='dimmed'>
                            There is no event information available
                        </Text>
                    </Box>
                )}
            </Popover.Dropdown>
        </Popover>
    );
};

export default SeasonPopover;

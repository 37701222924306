export class ScanFilterModel {
  constructor(obj) {
    this.keyword = '';
    this.colours = [];
    this.department = [];
    this.extractStatus = [];
    this.season = [];
    this.size = [];
    this.tags = [];
    this.completed = [null, null];
    
  }

  if (obj) {
    Object.assign(this, obj);
  }

}
import { Box, useMantineTheme } from '@mantine/core';
import { useState, useRef, useEffect } from 'react';
import { StarIcon } from './StarIcon';
import { logger } from 'utils/logger';


const StarRating = ({
  precision = 1,
  totalStars = 5,
  emptyIcon = StarIcon,
  filledIcon = StarIcon,
  size,
  rating=-1,
  onChange,
  readOnly=false
}) => {
  const [activeStar, setActiveStar] = useState(rating);
  const [hoverActiveStar, setHoverActiveStar] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef(null);
  const theme = useMantineTheme();

  const calculateRating = (e) => {
    const { width, left } = ratingContainerRef.current.getBoundingClientRect();
    let percent = (e.clientX - left) / width;
    const numberInStars = percent * totalStars;
    const nearestNumber = Math.round((numberInStars + precision / 2) / precision) * precision;

    return Number(nearestNumber.toFixed(precision.toString().split('.')[1]?.length || 0));
  };

  useEffect(() => {
    setActiveStar(rating)
  },[rating])

  const handleClick = (e) => {

    if(readOnly){
      e.stopImmediatePropagation();
      return;
    };

    setIsHovered(false);
    var rating = calculateRating(e);
    if(activeStar === 1 && rating === 1){
      setActiveStar(-1);
      rating = -1;
    }else{
      // we let the rating handle this
      // from the passed in props with the onchange event
      //setActiveStar(rating);
    }
    onChange(rating);
  };

  const handleMouseMove = (e) => {
    if(readOnly){
      e.stopImmediatePropagation();
      return;
    };

    setIsHovered(true);
    setHoverActiveStar(calculateRating(e));
  };

  const handleMouseLeave = (e) => {
    if(readOnly){
      e.stopImmediatePropagation();
      return;
    };

    setHoverActiveStar(-1); // Reset to default state
    setIsHovered(false);
  };
  const EmptyIcon = emptyIcon;
  const FilledIcon = filledIcon;

  const starSize = size === 'sm'?14:18;

  const starStyle = {fillColor : '#ffbc0b'};
  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'relative',
        cursor: readOnly?'default':'pointer',
        textAlign: 'left'
      }}

      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={ratingContainerRef}
    >
     
      {[...new Array(totalStars)].map((arr, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar;

        const showEmptyIcon = activeState === -1 || activeState < index + 1;

        const isActiveRating = activeState !== 1;
        const isRatingWithPrecision = activeState % 1 !== 0;
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

        return (
          <Box
            position={'relative'}
            sx={{
              cursor: readOnly?'default':'pointer',
              lineHeight:1,
            //   height:30,
            }}
            key={index}
          >          
            <Box
              sx={{
                width: showRatingWithPrecision ? `${(activeState % 1) * 100}%` : '0%',
                overflow: 'hidden',
                position: 'absolute',
                lineHeight:1,
              }}
            >
              <FilledIcon size={12} style={starStyle} />
            </Box>
            {/*Note here */}
            <Box
              sx={{
                color: showEmptyIcon ? 'gray' : 'inherit'
              }}
            >
              {showEmptyIcon ? <EmptyIcon fill="currentColor" size={starSize} /> : <FilledIcon  fill={theme.colors.yellow[7]} size={starSize} />}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StarRating;
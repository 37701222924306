import { v4 as uuidv4 } from 'uuid';

export class CategoryShotModel {
    constructor(obj) {
        this.id = uuidv4();
        this.seq = 0;
        this.req = 'yes';
        this.notes = '';
        this.label = '';
        this.referenceUrl = '';
        this.tag = '';

        if (obj) {
            Object.assign(this, obj);
        }
    }
}

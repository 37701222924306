const { createSelector } = require("@reduxjs/toolkit");

const selectCaptures = (state) => state.captures;

export const selectSortedCaptures = createSelector([selectCaptures, (state) => state.settings], (a, b) => {
  
    const sortBy = b.sortBy.value;
    return [...a].sort((val1, val2) => {
      
      if ( val1[sortBy] < val2[sortBy] ){
        return b.sortDirection === 'asc'?-1:1;
      }
      if ( val1[sortBy] > val2[sortBy] ){
        return b.sortDirection === 'asc'?1:-1;
      }
      return 0;
      
    });
    
});
  
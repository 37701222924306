/**
 * @file helper.js
 * Reducer comine
 * @author Ben Yee
 */

import { combineReducers } from 'redux'
import appReducer from '../app/appSlice'
import warehouseReducer from '../modules/Warehouse/warehouseSlice'
import outfitterReducer from '../modules/Warehouse/outfitterSlice'
import authReducer from '../modules/Auth/authSlice'
import sessionReducer from '../modules/Sessions/sessionSlice'
import settingsReducer from '../modules/Settings/settingsSlice'
import approvalReducer from '../modules/Approval/approvalSlice'
import guideReducer from '../modules/Guides/guideSlice'
import seasonReducer from '../modules/Seasons/seasonSlice'
import styleReducer from '../modules/Styles/styleSlice'
import talentReducer from '../modules/Talent/talentSlice'

export default combineReducers({
  warehouse:warehouseReducer,
  outfitter:outfitterReducer,
  sessionReducer:sessionReducer,
  guideReducer:guideReducer,
  seasonReducer:seasonReducer,
  settingsReducer:settingsReducer,
  appReducer:appReducer,
  auth:authReducer,
  approvalReducer:approvalReducer,
  styleReducer:styleReducer,
  talentReducer:talentReducer,
})

const { Group, Avatar, Text } = require("@mantine/core");
const { forwardRef } = require("react");

const SelectItem = forwardRef(({ label, color, avatarUrl, ...others }, ref) => {
    return (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Avatar variant='filled' title={`${label}`} color={color} size={30} radius={40}>
                    {label.charAt(0)}
                </Avatar>
                <Text sx={{ paddingLeft: 0 }}>{label}</Text>
            </Group>
        </div>
    );
});

export default SelectItem;
import { Badge, Box, Button, Group, Input, MultiSelect, Stack, Text, Textarea } from '@mantine/core';

import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
// import { IconFile} from '@tabler/icons';
import { FormInputWrapper } from 'core/components/';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import { TagInput } from 'core/components/index';
import { SeasonModel } from 'core/models';
import { createSeason } from 'modules/Seasons/seasonSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppConstants, DataFormats, ViewModes } from 'utils/constants';
import { logger } from 'utils/logger';

const SeasonEdit = ({ season = new SeasonModel(), mode = ViewModes.EDIT, onCancel, onSave }) => {
    const [active, handlers] = useDisclosure(false);
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();

    const form = useForm({
        initialValues: {
            ...season,
        },

        validate: {
            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    useEffect(() => {
        logger.log(active);
        if (!active) {
            logger.log('toggling focus ');
            setTimeout(() => {
                handlers.toggle();
            }, 1000);
        }
    }, [active, handlers]);

    /**
     * Function to submit values to create a new season
     * @param {*} values
     */
    const formHandler = (values) => {
        const tmpTags = tags.join(', ');
        const payload = { ...values, tags: values.tags.join(', ') };

        dispatch(createSeason(payload));
    };

    const disabled = mode === ViewModes.VIEW ? true : false;
    return (
        <>
            {mode === ViewModes.VIEW ? (
                <Stack spacing={0}>
                    <FormInputWrapper labelWidth={100} label='Name:' id='input-sku'>
                        <Text pt={4} size={'sm'}>
                            {form.values.name}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Description:' id='input-desc'>
                        <Text pt={4} size={'sm'}>
                            {form.values.description}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Tags' id='input-tags'>
                        <Text pt={4} size={'sm'} color={form.values.tags.length ? '' : 'dimmed'}>
                            {form.values.tags.length ? form.values.tags : 'No Tags'}
                        </Text>
                    </FormInputWrapper>

                    <FormInputWrapper labelWidth={100} label='Deadline'>
                        <Box>
                            <Badge>
                                <Moment fromNow>{form.values.deadline}</Moment>
                            </Badge>
                            <Text pt={4} size={'sm'}>
                                <Moment format={DataFormats.DATE_FORMAT}>{form.values.deadline}</Moment>
                            </Text>
                        </Box>
                    </FormInputWrapper>
                </Stack>
            ) : (
                <form onSubmit={form.onSubmit((values) => formHandler(values))}>
                    {/* <FocusTrap active={true}> */}
                    <FormInputWrapper label='Name' id='input-sku' required>
                        <Input id='input-name' data-autofocus required placeholder='Enter Name' variant={AppConstants.inputVariant} {...form.getInputProps('name')} />
                    </FormInputWrapper>

                    <FormInputWrapper label='Description' id='input-desc' required>
                        <Textarea id='input-desc' {...form.getInputProps('description')} placeholder='Description' variant={AppConstants.inputVariant} />
                    </FormInputWrapper>

                    <FormInputWrapper label='Tags' id='input-tags'>
                        <TagInput
                            {...form.getInputProps('tags')}
                            onChange={(val) => {
                                setTags([...val.map((item) => ({ value: item, label: item }))]);
                                form.setFieldValue('tags', val);
                            }}
                        />

                        {/* <MultiSelect
                            size='md'
                            data={tags}
                            placeholder='Enter Tags'
                            searchable
                            creatable
                            {...form.getInputProps('tags')}
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const item = { value: query, label: query };
                                setTags((current) => [...current, item]);
                                return item;
                            }}
                        /> */}
                    </FormInputWrapper>

                    <FormInputWrapper label='Deadline' id='input-tags' required>
                        <Group>
                            <CustomDatePicker required withAsterisk placeholder='Due Date' {...form.getInputProps('deadline')} />
                        </Group>
                    </FormInputWrapper>
                    <Group position='right' mt='md'>
                        <Button type='submit'>Create</Button>
                    </Group>
                    {/* </FocusTrap> */}
                </form>
            )}
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        success: state.seasonReducer.success,
        error: state.seasonReducer.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(SeasonEdit);

import { Anchor, Breadcrumbs, Text } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import CopyContentLink from '../copyContentLink/CopyContentLink';

const Crumbline = ({items}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [crumbsItems,setCrumbsItems] = useState([]);
    
    const crumbClickHander = (item) => {
        logger.log(item)
        navigate(`${item.route}${item.search}`);
    }

    useEffect(()=>{

        const createCrumbItems = (location) => {
            const module = getModule(location.pathname);
            // const moduleName = module.name;
            const arr = module.items;
            let items = [];
            let prefix = '';
    
            arr.forEach((item,idx)=>{            
                if(idx < arr.length){
    
                    // if the crumbitem exists in that position set it otherwise set null
                    let ci = crumbsItems[idx]?{...crumbsItems[idx]}:null;
                    
                    // if the crumb item exists then we update it otherwise we create
                    // one from the current location.
                    if(ci){
                        // if the item is the current location then we update the search
                        if(ci.route === location.pathname){
                            ci.search = location.search;
                        }else{
                            ci.search = item === ci.title?ci.search:'';
                            ci.title = item;                            
                            ci.route = `${prefix}/${item}`;
                        }                    
                        items.push(ci);
                    }else{
                        items.push({title:item, route:`${prefix}/${item}`,search:idx === (arr.length-1)?location.search:''});
                    }
                    
                }
                prefix += `/${item}`;
                
            })          
            if(items !== crumbsItems)
                setCrumbsItems(items)
        }

        if(!items){
            createCrumbItems(location);       
        }else{
            setCrumbsItems(items)
        }
            
    },[location,items])

    const getModule = (pathname) => {
        let arr = pathname.split('/');
        arr = arr.filter(item => item.length);

        return {name:arr[0],items:arr};
    }

    const crumbs = crumbsItems.map((item, index) => {
        // const weight = (index+1) < crumbsItems.length?'400':'700';
        const weight = (index+1) < crumbsItems.length?'400':'400';
        const isLast = (index+1) === crumbsItems.length?true:false;

        return (
            <div  key={index}>
            {isLast?
                <CopyContentLink value={item.title}><Text size={'sm'} weight={weight} transform='capitalize' >{item.title}</Text></CopyContentLink>
            :
                <Anchor onClick={()=>{crumbClickHander(item)}}>
                    <Text size={'sm'} weight={weight} transform='capitalize' >{item.title}</Text>
                </Anchor>
            }
            </div> 
        )
    });

    return (
        <Breadcrumbs separator="›">{crumbs}</Breadcrumbs>
    )
}

export default Crumbline;

import OpenSeaDragon from 'openseadragon';
import React, { useEffect, useRef, useState } from "react";
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import { Button, createStyles, Title } from "@mantine/core";
import { ApprovalImageToolbarModes } from "utils/constants";
import ImageControlToolbar from "../ImageControlToolbar";
import { useEffectOnce } from "utils/helper";
import { connect } from "react-redux";
import { logger } from "utils/logger";
import { setMarkup, deleteMarkup, fetchMarkups } from './../../approvalSlice';

// import { HelloWorldWidget } from "./HelloWorld";
const useStyles = createStyles((theme) => ({
    container:{
        padding:0,
        '&:focus':{
            border:'3px solid red',
        },
        '.a9s-osd-annotationlayer':{
            
        },
        // '&.mousedisabled':{
            
        //     '.a9s-osd-annotationlayer':{
        //         pointerEvents:'none !important',
        //     }
        // },
        '&.annotating':{
            cursor:'crosshair',
            '.a9s-osd-annotationlayer':{
                pointerEvents:'auto !important',
            }
        },
        '&.shiftdown':{
            cursor:'crosshair',
            '.a9s-osd-annotationlayer':{
                pointerEvents:'auto !important',
            },
        },
        'div':{
            outline:'none',
        },
        '.r6o-editor':{
            fontFamily:'apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;',
            '.r6o-editor-inner':{
                background:theme.colorScheme === 'dark'?theme.colors.dark[7]:theme.colors.white,
                border:theme.colorScheme === 'dark'?`1px solid ${theme.colors.dark[5]}`:`1px solid ${theme.colors.brand[6]}`,
            },
            'button':{
                background:theme.colorScheme === 'dark'?theme.colors.brand[4]:theme.colors.brand[3],
                border:theme.colorScheme === 'dark'?`1px solid ${theme.colors.brand[4]}`:`1px solid ${theme.colors.brand[6]}`,
                borderRadius:theme.radius.sm,
                fontWeight:600,
                '&.outline':{
                    background:'transparent',
                    border:theme.colorScheme === 'dark'?`1px solid ${theme.colors.brand[5]}`:`1px solid ${theme.colors.brand[6]}`,
                    color:theme.colorScheme === 'dark'?theme.colors.brand[5]:theme.colors.brand[5],
                }

            },
            '.delete-annotation':{
                color:theme.colorScheme === 'dark'?`${theme.colors.brand[5]} !important`:`${theme.colors.brand[5]} !important`,
            },
            // '.comment':{
            //     background:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.white,
            //     color: '#C1C2C5',
            //     display: 'block',
            //     border: `1px solid #373A40`,
            //     backgroundColor: `#25262b`
            // },

            '.r6o-widget.comment':{
                background:theme.colorScheme === 'dark'?theme.colors.dark[7]:theme.colors.white,
                color: '#C1C2C5',
                display: 'block',
                borderBottom:theme.colorScheme === 'dark'?`1px solid ${theme.colors.dark[4]}`:`1px solid ${theme.colors.gray[4]}`,
                '&.editable':{
                    background:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.white,
                },
                'textarea':{
                    fontFamily:'apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;',
                    color: theme.colorScheme === 'dark'?theme.colors.gray[3]:theme.colors.gray[8],
                    '&:focus':{
                        outline: 'none',
                        borderColor: '#7EAEA9',
                    },
                    '&::placeholder':{
                        color: theme.colorScheme === 'dark'?theme.colors.gray[6]:theme.colors.gray[8],
                    }

                },

                '.r6o-comment-dropdown-menu':{
                    background:theme.colorScheme === 'dark'?theme.colors.dark[7]:theme.colors.white,
                    border:theme.colorScheme === 'dark'?`1px solid ${theme.colors.dark[5]}`:`1px solid ${theme.colors.brand[6]}`,
                    'li:hover':{
                        background:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.gray[2],
                    }
                },

                '.r6o-lastmodified':{
                    opacity:.5
                }
            },

            '.r6o-arrow:after':{
                backgroundColor:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.white,
            },

            '.r6o-icon.r6o-arrow-down':{
                backgroundColor:theme.colorScheme === 'dark'?theme.colors.dark[6]:theme.colors.white,
                border:theme.colorScheme === 'dark'?`1px solid ${theme.colors.dark[5]}`:`1px solid ${theme.colors.brand[6]}`,
                borderRadius:theme.radius.sm,
                outline:'none'
            },
            


        },
        // '.mantine-Accordion-control':{
        //     borderTopWidth:1,
        //     borderTopStyle:'solid',
        //     borderColor:theme.colorScheme === 'dark'?theme.colors.dark[4]:theme.colors.gray[3],
        // },
        // '.mantine-Stack-root':{
        //     gap:0
        // },
        // '.capture-selected > div > div:first-of-type':{
        //     borderWidth:1,
        //     borderStyle:'solid',
        //     borderColor:theme.colorScheme === 'dark'?theme.colors.brand[1]:theme.colors.gray[3],
        // }
        
    },
    badgeIndex:{
        position:'absolute',
        left:0,
        top:0
    }
   
  }));


const OpenSeaDragonViewer = ({ image,imageControlSettings,settings,onSettingsChange, setMarkup, fetchMarkups, user }) => {

    const [viewer, setViewer] = useState(null);
    // const [anno, setAnno] = useState(null)
    const anno = useRef(null);
    const [isAnno, setIsAnno] = useState(false);
    const [imgUrl,setImgUrl] = useState(''); // keep track of loaded image url

    const { classes } = useStyles();
    const zoomWrapper = useRef(null);
    const controlRef = useRef(null);

    const annotations = useRef(null);

    const ZOOM_FACTOR = .5;

    var init = useRef(false);

    const [clickCount, setClickCount] = useState(1);
    const [shiftPressed, setShiftPressed] = useState(false);

    useEffect(() => {
        if(viewer && viewer.viewport){

            if(imageControlSettings.zoomDirection === ApprovalImageToolbarModes.ZOOM_RESET){
                viewer.viewport.goHome();
            }else{
                let level = imageControlSettings.zoomDirection === ApprovalImageToolbarModes.ZOOM_IN?viewer.viewport.getZoom() + ZOOM_FACTOR:viewer.viewport.getZoom() - ZOOM_FACTOR;
                if(level < .5){
                    viewer.viewport.goHome();
                }else{
                    viewer.viewport.zoomTo(level,null,false)
                }
            }   
            
        }
        
    },[imageControlSettings, viewer])

    useEffect(() => {

        const imageMatch = (img1,img2) => {
            let str1 = img1.split('?')[0];
            let str2 = img2.split('?')[0];
            return str1 === str2;
        }

        if (image && viewer) {

            var imageUrl = image.previewHi;
            // let sourceUrl = viewer.source?viewer.source.url:'';
            let sourceUrl = imgUrl;

            if(!imageMatch(sourceUrl,imageUrl)){
                
                // image change
                logger.log(image, ' IMAGE CHANGE')

                // get the image annotations
                fetchMarkups(image.id,user);
                
                viewer.open({
                    type: "image",
                    url: image.previewHi
                });

                setImgUrl(imageUrl);
            }

            // anno.current.getAnnotations().forEach(val => {
            //     anno.current.removeAnnotation(val)
            // })

            anno.current.clearAnnotations();
            anno.current.cancelSelected();


            if(image.annotations && image.annotations.length){
                image.annotations.forEach(element => {
                   anno.current.addAnnotation(element.payload ,false);
                });
                //anno.current.setAnnotations(image.annotations);
            }
            
            annotations.current = image.annotations?([...image.annotations]):[];
            
        }

    }, [image,viewer]);

    const InitOpenseadragon = () => {

        // var col = document.getElementById('controllerCol');
        // var elems = col.getElementsByClassName('openseacontrols');
        // logger.log(col,elems)
        // if(elems){
        //     for(let i = 0; i<elems.length; i++){
        //         elems[i].remove();
        //     }
            
        // }


        if(viewer){
            viewer.zoomInButton.tracker.destroy();
            viewer.zoomOutButton.tracker.destroy();
            viewer && viewer.destroy();
            controlRef.current.remove();
        }

        if(viewer && viewer.zoomInButton){
            viewer.zoomInButton.destroy();                
        }

        if(!viewer){

            logger.log('initing viewer',init)

            const initViewer = OpenSeaDragon({
                id: "openSeaDragon",
                prefixUrl: "openseadragon/images/",
                animationTime: 0.2,
                blendTime: 0.1,
                constrainDuringPan: false,
                maxZoomPixelRatio: 5,
                // defaultZoomLevel:imageControlSettings.zoomLevel,
                // minZoomLevel: .5,
                visibilityRatio: 1,
                zoomPerScroll: 1.1,
                
                gestureSettingsMouse:{
                  clickToZoom:false,
                  dragToPan:true,
                },
                tileSources: {
                    type: "image",
                    url: image.previewHi
                },
                // toolbar:       "toolbarDiv",
                zoomInButton:   "openseadragon-zoom-in",
                zoomOutButton:   "openseadragon-zoom-out",
                homeButton:     "openseadragon-home",
            });
    
            setViewer(initViewer);
                
            const config = {
                crosshair:false,
                hotkey:{key:'Shift',inverted:false},
                widgets: [
                    // TestWidget,
                    'COMMENT'
                  ]
            };
            const annotate = Annotorious(initViewer, config);
            logger.log(user)
            annotate.setAuthInfo({
                id: user.email,
                displayName: `${user.first} ${user.last}`
              });
            
            // setAnno(annotate);
            anno.current = annotate;

            annotate.on('createAnnotation', function(annotation, overrideId) {
               logger.log(annotation, " _________");
               setMarkup(image.id,{payload:annotation},user);
               //enableAnnotation(settings.isCommenting);
            });

            annotate.on('updateAnnotation', function(annotation, previous) {
              logger.log(annotation, ' ---------- update');
              
              const annot = annotations.current.find(val => {
                    logger.log(val.payload.id, annotation.id)
                    return val.payload.id === annotation.id
                });
              const data = {...annot,payload:annotation}
              logger.log(data)
              setMarkup(image.id,data,user);
            //  enableAnnotation(settings.isCommenting);
            });

            annotate.on('selectAnnotation', function(annotation, element) {
                setClickCount(1); 
            });


            // annotate.on('startSelection', (point) => {
            //     logger.log(point.x, point.y);
            //     return;
            //     const clsList = document.getElementById('openSeaDragon').classList;
            //     const isShiftDown = clsList.contains('shiftdown');
            //     if(!clsList.contains('annotating') && !isShiftDown){
            //         logger.log("DESELECTING", isAnno)
            //         try{
                        
            //             anno.current.setDrawingEnabled(false);
            //             //anno.current.setDrawingEnabled(true);
            //             anno.current.cancelSelected();
            //         }catch(e){
            //             logger.log(e)
            //         }
                    
            //             // setTimeout(() => {
            //             //     // const event = new KeyboardEvent("keydown",{
            //             //     //     'key': 'Escape',
            //             //     //     'keyCode':27,
            //             //     //     'bubbles': true,
            //             //     //     'cancelable': false,
            //             //     //     'code':'Escape',
            //             //     //     'isTrusted':true,
            //             //     //     });
            //             //     // window.dispatchEvent(event);
            //             //     document.dispatchEvent(new MouseEvent('click'))
            //             //     logger.log("DISPATCHING EVENT")
            //             //     anno.current.cancelSelected();
                           
            //             // },200)
                    
                   
            //     }
            // });

            
            // move the controls to the top nav
            var elem = document.getElementById('controllerCol');
            elem.append(controlRef.current)

        }
       
    };

    const keyHandler = (e) => {

        logger.log(e, ' KEY')
        if(e.type === 'keydown'){
            if (e.key === 'Shift') {
                setShiftPressed(true);
                logger.log("SETTING DRAWING ENABLED KEY PRESS")
                //anno.current.setDrawingEnabled(false);
                // setTimeout(() => {
                //     anno.current.setDrawingEnabled(true);
                // },1000)
            }
        }else if(e.type === 'keyup'){
            if (e.key === 'Shift') {
                setShiftPressed(false);
            }
        }
        
      };

    useEffectOnce(() => {

        if(!init.current){
            InitOpenseadragon();            
            // setIsInit(true);   
            init.current = true;
        }
        
        window.addEventListener('keydown', keyHandler, false);
        window.addEventListener('keyup', keyHandler, false);

        return () => {
            
            viewer && viewer.zoomInButton.destroy();
            viewer && viewer.zoomOutButton.destroy();
            viewer && viewer.destroy();
            //controlRef.current && document.getElementById('openseacontroller').remove();
            window.removeEventListener('keydown', keyHandler, false);
            window.removeEventListener('keyup', keyHandler, false);
            
            function removeControls(){
                var elems = document.getElementsByClassName('openseacontrols');
                if(elems){      
                    for (var i = 0; i < elems.length; i++) {
                        elems[i].remove();
                    }               
                }
            }    
            removeControls();      

            if(viewer && viewer.zoomInButton){
                viewer.zoomInButton.destroy();                
            }            
            
        };
        
    }, []);

    const onControlSettingsChange = (e,data) => {
        onSettingsChange(e,data);        
    }

    useEffect(() => {
        logger.log(settings)
        setIsAnno(settings.isCommenting);
        // if(!settings.isCommenting && anno.current){
        //     anno.current.readOnly = true;
        // }

        if(!settings.isCommenting && anno.current){
           anno.current.setDrawingEnabled(false);
                setTimeout(() => {
                    anno.current.setDrawingEnabled(true);

                    logger.log('Dispatching SHIFT Press')

                    setTimeout(() => {
                        const event = new KeyboardEvent("keydown",{
                            'key': 'Shift',
                            // 'bubbles': true,
                            // 'cancelable': false,
                            'code':'ShiftLeft',
                            // 'isTrusted':true,
                            'shiftKey':true,
                            });
                        window.dispatchEvent(event);
                    },3000)
                   


                },1000)
        }
    },[settings]);

    const enableAnnotation = (enable) => {
        
        if(anno.current){
            //anno.current.readOnly = false;
            setClickCount(0);
            anno.current.setDrawingEnabled(enable);
            // anno.current.setDrawingTool('rect');
            setIsAnno(enable);
        }
        
    }

    /*
    const onMouseDown = (e) => { 

        logger.log(e, " MOUSE DOWN")
        if(!settings.isCommenting){
            setClickCount(1);
            anno.current.cancelSelected();
        }
            
        logger.log('onMouseDown', e)
        const clsList = document.getElementById('openSeaDragon').classList;
        const isShiftDown = clsList.contains('shiftdown');
        if(!clsList.contains('annotating') && !isShiftDown){
            logger.log("DESELECTING", isAnno)
            try{
               // anno.current.cancelSelected();
            }catch(e){
                logger.log(e)
            }
            
                setTimeout(() => {
                    // const event = new KeyboardEvent("keydown",{
                    //     'key': 'Escape',
                    //     'keyCode':27,
                    //     'bubbles': true,
                    //     'cancelable': false,
                    //     'code':'Escape',
                    //     'isTrusted':true,
                    //     });
                    // window.dispatchEvent(event);
                    document.dispatchEvent(new MouseEvent('click'))
                    logger.log("DISPATCHING EVENT")
                   // anno.current.cancelSelected();
                   
                },200)
            
           
        }
    }*/

    /*const onMouseLeave = () => {
        anno.current.setDrawingEnabled(false);
    }*/

    const onMouseEnter = (e) => {

       // logger.log('MOUSE ENTER',e.target.classList, settings.isCommenting)
        
        if(settings.isCommenting){
           // if(e.target.classList && !e.target.classList.contains('a9s-inner')){
                enableAnnotation(true)
           // }
                
        }else{

            // if(!clickCount){
            //     enableAnnotation(false);
            //     setClickCount(1);
            // }else{
            //     anno.current.setDrawingEnabled(true);
            // }
            //
            // const elems = document.getElementsByClassName('openseadragon-canvas');
            // logger.log(elems)
            // if(elems){
            //     elems[0].focus();
            //     elems[0].trigger('click')
            // }
            //anno.current.readOnly = true;
        }
    }

    return (
        <div ref={zoomWrapper} style={{
            height: "100%",
            width: "100%"
        }}
        >
            {/* <Button onClick={() => anno.current.cancelSelected()}>Cancel</Button>
            <Button onClick={() => anno.current.setDrawingEnabled(false)}>Drawing Mode False</Button>
            <Button onClick={() => anno.current.setDrawingEnabled(true)}>Drawing Mode True</Button>
             */}
            <div ref={controlRef} id="openseacontroller" className="openseacontrols">
                <ImageControlToolbar settingsChange={onControlSettingsChange} settings={settings}/>
            </div>
            
            <div className={`${classes.container} ${isAnno?'annotating':''} ${clickCount===0?'mousedisabled':''} ${shiftPressed?'shiftdown':''}`} 
                // onMouseLeave={(e) => onMouseLeave(e)} 
                onMouseMove={(e) => onMouseEnter(e)}
                // onMouseEnter={(e) => onMouseEnter(e)}
                id="openSeaDragon" 
                style={{
                    height: "100%",
                    width: "100%"
                }}
                >
            </div>
            
        </div>
    );
};

const mapStateToProps = (state,props) => {
    
    return (
    {
       user: state.auth.user,
       
    //    captures:state.approvalReducer.present.captures,
    //    selectedItem: state.approvalReducer.present.selectedItem,
    //    selectedItems: state.approvalReducer.present.selectedItems,
    //    comparisonItems: state.approvalReducer.present.comparisonItems,
    //    selectedCompareFrame: state.approvalReducer.present.selectedCompareFrame,
    //    approvalFilter: state.approvalReducer.present.approvalFilter,
    //    settings: state.approvalReducer.present.settings,
    //    success: state.approvalReducer.present.success,
    //    error: state.approvalReducer.present.error,
    //    isLoading: state.approvalReducer.present.loading,
    //    action:state.approvalReducer.present.action,
    })
  }
  
  const mapDispatch = { setMarkup, deleteMarkup, fetchMarkups }
  
  export default connect(
    mapStateToProps,
    mapDispatch
  )(OpenSeaDragonViewer)
  
import { IconCheck, IconCopy, IconLink } from '@tabler/icons';

const { Button, Group, ActionIcon, CopyButton, Tooltip } = require('@mantine/core');

const CopyContentLink = (props) => {
    const { value, caption="Copy to clipboard", children } = props;

    
    return (
        <CopyButton value={value}>
            {({ copied, copy }) => (
                <Group className='copy-content-link'>
                    {children}
                
                    <Tooltip label={copied ? 'Copied' : caption} withArrow position='right'>
                        <ActionIcon size={'xs'} color={copied ? 'teal' : 'brand'} variant='subtle' onClick={copy} className='copy-content-link-btn'>
                            {copied ? <IconCheck stroke={1} size={16} /> : <IconCopy stroke={1} size={16} />}
                        </ActionIcon>
                    </Tooltip>
                </Group>
            )}
        </CopyButton>
    );
};
export default CopyContentLink;

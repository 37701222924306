import { Button, Group } from "@mantine/core";
import SeasonEdit from "modules/Seasons/features/season/SeasonEdit";
import { ViewModes } from "utils/constants";
import SessionEdit from "../../../Sessions/features/session/SessionEdit";
import TaskEdit from "../../../Sessions/features/task/TaskEdit";

const EventSummary = (props) => {
    const {data,onViewClick} = props;
  
    const getView = () => {
        var view;
        switch(data.type){
            case 'season':{
                view =  <SeasonEdit season={data.data} mode={ViewModes.VIEW} />
                break;
            }
            case 'session':{
                let season = props.seasons.find(val => val.seasonSort === data.data.seasonCode)
                view =  <SessionEdit season={season} session={data.data} mode={ViewModes.VIEW} />
                break;
            }
            case 'task':{
                view =  <TaskEdit task={data.data} mode={ViewModes.VIEW} />
                break;
            }
            default:{
                break;
            }
        }
        return view;
    }
    return (
        <>
        {getView()}
        <Group mt={'xl'}>
            <Button onClick={onViewClick} fullWidth>View Details</Button>
        </Group>
        </>
    )
}

export default EventSummary;
import { Accordion, Box, createStyles, Group, Skeleton, Text } from '@mantine/core';
import { useCallback, useRef, useState } from 'react';
import { areEqual, FixedSizeList as ListWindow } from 'react-window';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { memo } from 'react';
import { CaptureThumb } from 'core/components/index';
import { logger } from 'utils/logger';
import InfiniteLoader from 'react-window-infinite-loader';

const useStyles = createStyles((theme) => ({
    container: {
        padding: 0,
        '.mantine-Accordion-content': {
            paddingBottom: 0,
        },
        '.mantine-Accordion-control': {
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
        },
        '.mantine-Stack-root': {
            gap: 0,
        },
        '.capture-selected > div > div:first-of-type': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[1] : theme.colors.gray[3],
        },
    },
    badgeIndex: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
}));

const BottomTray = ({
    captures,
    onImageEvent,
    thumbnailSize = 150,
    selectedItems = [],
    comparisonItems = [],
    selectedComparisonFrame,
    showComapirsonSelection = false,
    imageLocHash,
    showLoading = false,
    onCaptureChange,
    searchCursor,
    isSearching,
    loadNextPage,
}) => {
    const { classes } = useStyles();
    const listContainerRef = useRef(null);
    const infiniteLoaderRef = useRef(null);
    const [scrollState, setScrollState] = useState({ rowCount: 0, colCount: 0, scrolledColumn: 0 });

    // const [listWidth,setListWidth] = useState(400);

    const isSelected = (val) => {
        return selectedItems.find((item) => val.id === item.id) ? true : false;
    };

    const ProxyCol = memo((props) => {
        const { index, style } = props;
        // const idx = columnIndex + ((rowIndex) * data.colCount);

        const ThumbCallback = useCallback(({ index, style }) => {
            return (
                <div style={{ height: '100%', width: 150, padding: 5 }}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Skeleton width={'100%'} height={'60%'} />
                        <Skeleton height={8} mt={10} radius='xl' />
                        <Skeleton height={8} mt={5} width='70%' radius='xl' />
                    </Box>
                </div>
            );
        }, []);

        // if(!captures[idx]){
        //     return (<div></div>);
        // }

        const tmpStyle = { ...style };
        return (
            <div key={`${index}`} style={tmpStyle}>
                <ThumbCallback {...props} />
            </div>
        );
    }, areEqual);

    const Column = ({ index, style }) => {
        const val = captures[index];
        const obj = comparisonItems.find((capture) => capture.id === val.id);
        const captureIdx = comparisonItems.indexOf(obj);

        var cls = obj ? 'capture-selected' : '';
        if (captureIdx === 0) {
            cls += ' capture-primary';
        } else if (captureIdx === 1) {
            cls += ' capture-secondary';
        }

        if (!showComapirsonSelection) {
            cls = '';
        }

        var imageURL = val.previewLo;
        let imageRefKey = thumbnailSize > 200 ? 'previewMid' : 'previewLo';
        if (val && imageLocHash.current[val.id] && val[imageRefKey] === imageLocHash.current[val.id]) {
            imageURL = imageLocHash.current[val.id];
        } else {
            //getImage();
            imageLocHash.current[val.id] = thumbnailSize > 200 ? val.previewMid : val.previewLo;
            imageURL = imageLocHash.current[val.id];
        }

        return (
            <Box key={index} sx={{ width: 150, height: '100%', textAlign: 'center', position: 'relative' }} spacing='xs' className={cls} style={style}>
                <CaptureThumb
                    image={val}
                    src={imageURL}
                    onImageEvent={onImageEvent}
                    size={thumbnailSize}
                    selected={isSelected(val)}
                    onCaptureChange={onCaptureChange}
                    badgeLabel={obj && showComapirsonSelection ? captureIdx + 1 : null}
                />
            </Box>
        );
    };

    // useEffect(() => {
    //     if(listContainerRef && listContainerRef.current){
    //         logger.log(listContainerRef.current)
    //         let w  = listContainerRef && listContainerRef.current?listContainerRef.current.offsetWidth-30:400;
    //          if(listWidth !== w)
    //              setListWidth(w);
    //     }
    // })

    return (
        <div ref={listContainerRef} style={{ position: 'relative' }} className={`${classes.container} unhightable`}>
            <Accordion defaultValue='images'>
                <Accordion.Item value='images'>
                    <Accordion.Control>
                        <Group>
                            Images{' '}
                            <Text color={'dimmed'} size='sm'>
                                Displaying {captures.length} Images
                            </Text>
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <div style={{ height: 260 }}>
                            <ReactVirtualizedAutoSizer>
                                {({ height, width }) => {
                                    const colWidth = 200;

                                    var colCount = showLoading ? 4 : captures.length;
                                    var rowCount = 1;
                                    // Only load 1 page of items at a time.
                                    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
                                    const loadMoreItems = (startIndex, endIndex) => {
                                        if (!isSearching && searchCursor) {
                                            loadNextPage();
                                            logger.log('should grab more items');
                                        }
                                    };

                                    // Every row is loaded except for our loading indicator row.
                                    // const isItemLoaded = (index) => !searchCursor || index < rowCount;
                                    const isColLoaded = (index) => {
                                        return index < colCount - 1;
                                    };

                                    return (
                                        <>
                                            {showLoading ? (
                                                <ListWindow height={height} itemCount={5} itemSize={200} layout='horizontal' width={width}>
                                                    {ProxyCol}
                                                </ListWindow>
                                            ) : (
                                                <InfiniteLoader ref={infiniteLoaderRef} isItemLoaded={isColLoaded} itemCount={scrollState.colCount} loadMoreItems={loadMoreItems}>
                                                    {({ onItemsRendered, ref }) => {
                                                        return (
                                                            <ListWindow
                                                                height={height}
                                                                itemCount={captures.length}
                                                                itemSize={200}
                                                                layout='horizontal'
                                                                width={width}
                                                                onItemsRendered={(e) => {
                                                                    const { visibleStartIndex, visibleStopIndex, overscanStopIndex, overscanStartIndex } = e;
                                                                    logger.log('on items rendered');
                                                                    if (scrollState.colCount !== colCount || scrollState.rowCount !== rowCount) {
                                                                        setScrollState({ rowCount, colCount, scrolledColumn: visibleStartIndex });
                                                                    }
                                                                    // props.setScrollRowAndColumn(visibleRowStartIndex, visibleColumnStartIndex)
                                                                    onItemsRendered({
                                                                        overscanStartIndex: overscanStartIndex,
                                                                        overscanStopIndex: overscanStopIndex,
                                                                        visibleStartIndex: visibleStartIndex,
                                                                        visibleStopIndex: visibleStopIndex,
                                                                    });
                                                                }}
                                                                ref={ref}>
                                                                {Column}
                                                            </ListWindow>
                                                        );
                                                    }}
                                                </InfiniteLoader>
                                            )}
                                        </>
                                    );
                                }}
                            </ReactVirtualizedAutoSizer>
                        </div>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default BottomTray;

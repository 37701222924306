import {
    Accordion,
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Group,
    Image,
    Input,
    MultiSelect,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    Textarea,
    ThemeIcon,
    Title,
    createStyles,
    useMantineTheme,
} from '@mantine/core';
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconCategory, IconFile, IconUpload, IconX } from '@tabler/icons';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { FormInputWrapper, TagInput } from 'core/components/index';
import { clearNotifications, fetchGuideCategories, getGuideCategoriesSuccess, updateGuide } from 'modules/Guides/guideSlice';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { downloadFile, pluralize, useEffectOnce } from 'utils/helper';
import { logger } from 'utils/logger';
import GuideCategory from './GuideCategory';
import { ViewModes } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { closeAllModals } from '@mantine/modals';

const useStyles = createStyles((theme) => ({
    accordianLoader: {
        border: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        padding: theme.spacing.md,
    },
}));

const GuideEdit = ({ guide, onCancel, onGuideSave, success, user, mode = ViewModes.EDIT }) => {
    const [active, handlers] = useDisclosure(false);
    const theme = useMantineTheme();
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { classes } = useStyles();
    const [tags, setTags] = useState([]);
    const navigate = useNavigate();
    const isReadOnly = mode === ViewModes.VIEW ? true : false;

    const previews = files.map((file, index) => {
        return (
            <Group key={index} position='apart' mt={'lg'} mb={'sm'}>
                <Group spacing={'xs'}>
                    <ThemeIcon radius='xl'>
                        <IconFile size={16} />
                    </ThemeIcon>
                    <Text size={'sm'}>{file.name}</Text>
                </Group>
                <Button variant='subtle' onClick={() => setFiles([])}>
                    Remove
                </Button>
            </Group>
        );
    });

    const downloads = (
        <Group position='apart' mt={0} mb={'sm'}>
            <Group spacing={'xs'}>
                <ThemeIcon radius='xl'>
                    <IconFile size={16} />
                </ThemeIcon>
                <Text size={'sm'}>Style Guide Document</Text>
            </Group>
            <Button variant='subtle' onClick={() => downloadFile(guide.url, guide.name)}>
                Download
            </Button>
        </Group>
    );

    const form = useForm({
        initialValues: {
            name: guide.name,
            descr: guide.descr,
            tags: guide.tags,
        },

        validate: {
            name: (value) => (value.length ? null : 'Invalid name'),
        },
    });

    useEffect(() => {
        if (!active) {
            setTimeout(() => {
                handlers.toggle();
            }, 1000);
        }
    }, [active, handlers]);

    useEffectOnce(() => {
        // fetch the guide categories

        if (guide && guide.id.length) {
            setIsLoading(false);
            // dispatch(fetchGuideCategories(guide));
        } else {
            setIsLoading(false);
        }
        return () => {
            setIsLoading(false);
            dispatch(clearNotifications());
        };
    });

    useEffect(() => {
        if (success) {
            switch (success.type) {
                case getGuideCategoriesSuccess.toString(): {
                    setIsLoading(false);
                    break;
                }
            }
        }
    }, [success]);

    logger.log(isLoading);

    /**
     * Function to submit values to create a new guide
     * @param {*} values
     */
    const formHandler = (values) => {
        setIsSaving(true);
        const payload = { ...values, id: guide.id, files: files, uploadUrl: guide.uploadUrl, tags: form.values.tags.join(','), guideCategories:[] };
        dispatch(updateGuide(payload, user));
    };

    function AccordionLabel({ name, image, descr, shots }) {
        return (
            <Group noWrap grow>
                <Group position='apart'>
                    <Group>
                        <IconCategory stroke={1} />
                        <Title order={5}>{name}</Title>
                    </Group>
                    {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>{shots.length} Shots</Badge> */}
                    <Badge>
                        {shots.length} {pluralize('Shot', shots.length)}
                    </Badge>
                    {/* <Text size="sm" color="dimmed" weight={400}>
                {shots.length}
            </Text> */}
                </Group>
            </Group>
        );
    }

    const items = guide?.guideCategories?.map((item, idx) => (
        <Accordion.Item value={item.id} key={idx}>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>
                <GuideCategory category={item} fullscreen={false} />

                {/* <Text size="sm">{item.descr}</Text> */}
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const loaderRows = [1, 2, 3].map((val) => (
        <Group key={val} position='apart' className={classes.accordianLoader}>
            <Group>
                <Skeleton height={26} radius='xl' width={200} />
                <Skeleton height={26} radius='xl' width={100} />
            </Group>
            <Skeleton height={26} radius='xl' width={50} />
        </Group>
    ));

    return (
        <>
            <form onSubmit={form.onSubmit((values) => formHandler(values))}>
                {/* <FocusTrap active={true}> */}
                <FormInputWrapper label='Name' id='input-name'>
                    <Input id='input-name' required readOnly={isReadOnly} data-autofocus={isReadOnly ? true : false} placeholder='Enter Name' {...form.getInputProps('name')} />
                </FormInputWrapper>

                <FormInputWrapper label='Description' id='input-desc'>
                    <Textarea id='input-desc' readOnly={isReadOnly} placeholder='Description' {...form.getInputProps('descr')} />
                </FormInputWrapper>

                <FormInputWrapper label='File' id='input-files'>
                    <div>
                        {!isReadOnly ? (
                            <Dropzone onReject={(files) => logger.log('rejected files', files)} maxSize={3 * 1024 ** 2} accept={PDF_MIME_TYPE} onDrop={setFiles}>
                                <Group position='center' spacing='sm' style={{ minHeight: 150, pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload size={50} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <IconFile size={50} stroke={1.5} />
                                    </Dropzone.Idle>

                                    <div>
                                        <Text size='xl' inline>
                                            Drag your file here or click to select
                                        </Text>
                                        <Text size='sm' color='dimmed' inline mt={7}>
                                            The file should not exceed 50mb
                                        </Text>
                                    </div>
                                </Group>
                            </Dropzone>
                        ) : null}

                        <Group grow>{previews}</Group>

                        {guide.url ? (
                            <Group grow mt={'md'}>
                                {downloads}
                            </Group>
                        ) : null}
                    </div>
                </FormInputWrapper>

                <FormInputWrapper label='Tags' id='input-tags'>
                    <TagInput
                        {...form.getInputProps('tags')}
                        onChange={(val) => {
                            setTags([...val.map((item) => ({ value: item, label: item }))]);
                            form.setFieldValue('tags', val);
                        }}
                    />
                    {/* <MultiSelect
                                size='md'     
                                data={tags}
                                placeholder="Enter Tags"
                                searchable
                                creatable
                                {...form.getInputProps('tags')}
                                getCreateLabel={(query) => `+ Create ${query}`}
                                onCreate={(query) => {
                                  const item = { value: query, label: query };
                                  setTags((current) => [...current, item]);
                                  return item;
                                }}
                              /> */}
                </FormInputWrapper>

                {guide.id.length ? (
                    <>
                        <Divider mt={'lg'} />

                        <Stack my={'lg'} spacing={0}>
                            <Title order={5}>Guide Categories</Title>

                            {/* show empty state if no categories */}

                            {/* if loading show skeletons */}
                            {isLoading ? (
                                <>
                                    <Box py={'lg'}>{loaderRows}</Box>
                                </>
                            ) : (
                                <>
                                    <Accordion py={'lg'} chevronPosition='right' variant='contained'>
                                        {items}
                                    </Accordion>
                                    {!guide.guideCategories.length && !isLoading ? (
                                        <EmptyState style={{ paddingTop: 0, paddingBottom: 0 }} showImage={false} caption='There are no Guide Categories' />
                                    ) : null}
                                </>
                            )}
                        </Stack>
                    </>
                ) : null}

                {!isReadOnly ? (
                    <Group position='right' mt='md'>
                        {/* <Button variant='default' onClick={onCancel}>Cancel</Button> */}
                        <Button type='submit' loading={isSaving}>
                            {guide.id ? 'Update Guide' : 'Create Guide'}
                        </Button>
                    </Group>
                ) : 
                <Group position='right' mt='md'>
                        {/* <Button variant='default' onClick={onCancel}>Cancel</Button> */}
                        <Button  onClick={() => {
                            navigate(`/styleguides/${guide.id}`)
                            closeAllModals();
                        }
                            }>
                            Edit Guide
                        </Button>
                    </Group>
                }

                {/* </FocusTrap> */}
            </form>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(GuideEdit);

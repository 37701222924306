/**
 * @file guideSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
 import { createSlice } from '@reduxjs/toolkit';
import { getGuide, getGuideCategories, getGuides, udpateGuide, uploadCategoryShotFile, uploadGuideFile } from '../../services/guideService';
import { logger } from "utils/logger";
import { setObjectFromProps } from 'utils/helper';

//  const filterGuides = (samples,filter) => {
//    return samples.filter(sample => {
//      var match = false;
//      // filter by brand
//      if(filter.brands){
//        match = filter.brands.indexOf(sample.facet_brand) > -1?true:false;
//      }else{
//        match = true;
//      }
//      logger.log(match, ' MATCH')
//      return match;
//    })
//  }
 
 
 const guideSlice = createSlice({
   name: 'guide',
   initialState: { 
     guides: [],
     selectedGuide:null,
     selectedGuideCategory:null,
     selectedGuides: [],
     
     guideFilter:{brands:  [],
                     categories:[],
                     department: [],
                     location:[],
                     status:[],
                     colour:''},
     
      
     // Meta
     error:null, 
     success:null, 
     loading:false, 
     action:null,
   },
   reducers: {
     selectGuide(state,action){
       logger.log('Setting Guide: ', action.payload)
       state.selectedGuide = action.payload?{...action.payload}:null;
     },
     selectGuides(state,action){
       logger.log('setting selected samples', action.payload);
       state.selectedGuides = action.payload?[...action.payload]:[];
     },
     selectGuideCategory(state,action){
      logger.log('Setting Guide Category: ', action.payload)
      state.selectedGuideCategory = action.payload?{...action.payload}:null;
    },
     setGuideFilter(state,action){
       logger.log('Setting Guide: ', action.payload)

       state.guideFilter = action.payload?{...action.payload}:null;
 
       // filter the samples.
       // const samples = filterGuides(state.samples, state.sampleFilter);
       
       
     },
     clearNotifications(state,action){
      state.error = null;
      state.success = null;
     },

     
     //////////////////////
     //
     // SINGLE Guide
     //
     //////////////////////
     getGuideStart(state,action){
       state.success = null;
       state.error = null;
     },
     getGuideSuccess(state, action) {
       logger.log('getGuideSuccess : SUCCESS')
             
       state.loading = false;
       state.success = {type:action.type,message:'Get Guide Success'}
       // if(action.payload.urls){
       //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}  
       // }
       state.selectedGuide = {...action.payload};
       //state.openIssuesCount = action.payload.open_issues_count
       state.error = null
     },
     getGuideFailure(state, action) {
       logger.log(state,action,'fail')
       state.error = action.payload
     },
 
     //////////////////////
     //
     // GET GUIDES
     //
     //////////////////////
     getGuidesStart(state,action){
       state.success = null;
       state.error = null;
     },
     getGuidesSuccess(state, action) {
       logger.log(action.type, ' : SUCCESS')
       
       state.loading = false;
       state.success = {type:action.type,message:'Get Guides Cats Success'}
       
       state.guides = [...action.payload];
       //state.openIssuesCount = action.payload.open_issues_count
       state.error = null
     },
     getGuidesFailure(state, action) {
       logger.log(state,action,'fail')
       state.error = action.payload
     }, 
 
     //////////////////////
     //
     // GET GUIDE CATEGOREIS
     //
     //////////////////////
     getGuideCategoriesStart(state,action){
        state.success = null;
        state.error = null;
     },
     getGuideCategoriesSuccess(state, action) {
        logger.log('getGuideSuccess : SUCCESS')
        
        state.loading = false;
        state.success = {type:action.type,message:'Get Guides Success'}
        state.selectedGuide.guideCategories = [...action.payload];

        // state.guides = [...action.payload];
        //state.openIssuesCount = action.payload.open_issues_count
        state.error = null
     },
     getGuideCategoriesFailure(state, action) {
        logger.log(state,action,'fail')
        state.error = action.payload
     }, 
      

     //////////////////////
     //
     // GUIDE
     //
     //////////////////////
    
    createGuideStart(state,action){
      state.success = null;
      state.error = null;
    },
    createGuideSuccess(state, action) {
      logger.log(action.type,' : SUCCESS')
      
      state.loading = false;
      state.success = {type:action.type,message:'Create Guides Success'};

      const guides = [...state.guides,action.payload];
      state.guides = guides;
      
      state.error = null
    },
    createGuideFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload
    }, 

    updateGuideStart(state,action){
      state.success = null;
      state.error = null;
    },

    updateGuideSuccess(state, action) {
      logger.log(action.type,' : SUCCESS')
      
      state.loading = false;
      state.success = {type:action.type,message:'Update Guides Success'}

      // check if the guide exists if not add it to the array
      
      // find the capture.
      var guides = [...state.guides];
      var currentItem = action.payload;
      var guideIdx = state.guides.findIndex(val => val.taskSort === currentItem.taskSort);
      var guide = state.guides[guideIdx];

      // update the selected item
      if(state.guides && guideIdx > -1){         
        guides[guideIdx] = setObjectFromProps(guides[guideIdx],currentItem);        
      }else{
        guides.push(currentItem)
      }

      // if it's the selected guide replace.
      if(currentItem.code === state.selectedGuide.code){
        state.selectedGuide = currentItem;
      }

      state.guides = guides;
      state.error = null
    },
    updateGuideFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload
    }, 
   }
 })
 
 // GUIDE FETCH
 export const fetchGuide = ( id, user ) => async dispatch => {
   try {
 
     logger.log(id, ' IN SLICE')
     dispatch(getGuideStart())
     const data = await getGuide(id,user)
     dispatch(getGuideSuccess(data))
   } catch (err) {
     logger.log('err',err)
     dispatch(getGuideFailure(err))
   }
 }
 
 // GUIDES
 export const fetchGuides = ( criteria, user ) => async dispatch => {
   try {
 
     dispatch(getGuidesStart())
     const data = await getGuides(criteria,user?user.rawIdToken:'')
     dispatch(getGuidesSuccess(data))
   } catch (err) {
     logger.log('err',err)
     dispatch(getGuidesFailure(err))
   }
 }

 // GUIDE CREATE - Uses update now
 export const createGuide = ( guide, user ) => async dispatch => {
  try {

    dispatch(updateGuideStart());
    
    const data = await udpateGuide(guide,user?user.rawIdToken:'');
    // const uploadurl = await getUploadUrl(data);
    // const uploadData = await uploadGuideFile(guide);

    dispatch(createGuideSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(createGuideFailure(err))
  }
}

// GUIDE UPDATE
export const updateGuide = ( guide, user ) => async dispatch => {
  try {

    dispatch(updateGuideStart());
    
    const data = await udpateGuide(guide,user?user.rawIdToken:'');
    // const uploadurl = await getUploadUrl(data);
    // const uploadData = await uploadGuideFile(guide);
    if(guide.files && guide.files.length){
      // upload the files
      if(guide.files && guide.files.length){
        for (let j = 0; j<guide.files.length; j++ ){
          const file = guide.files[j];
          const fileResult = await uploadGuideFile(file,guide,guide.uploadUrl,user);
        }
      }
    }

    // update shot files.
    for(let i = 0; i < guide.guideCategories.length; i++) {
      
      const cat = guide.guideCategories[i];

      if(cat.shots){
        for(let j = 0; j < cat.shots.length; j++){

          const shot = cat.shots[j];
  
          if(shot.files){
            for(let k = 0; k < shot.files.length; k++){
              const file = shot.files[k];
              const fileResult = await uploadCategoryShotFile(file,guide,cat,user);
            }
          }
          
        }
      }     
      
    }

    dispatch(updateGuideSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(updateGuideFailure(err))
  }
}

// GUIDE CATEGORIES
export const fetchGuideCategories = ( guide, user ) => async dispatch => {
  try {
    dispatch(getGuideCategoriesStart())
    const data = await getGuideCategories(guide,user?user.rawIdToken:'')
    dispatch(getGuideCategoriesSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(getGuideCategoriesFailure(err))
  }
}

export const deleteGuide = ( task, user ) => async dispatch => {
  try {

    dispatch(updateGuideStart())
    const data = await deleteGuide(task,user?user.rawIdToken:'')
    dispatch(updateGuideSuccess(data))
  } catch (err) {
    logger.log('err',err)
    dispatch(updateGuideFailure(err))
  }
}

// export const createGuideCategory = ( guide, category, user ) => async dispatch => {
//   try {

//     dispatch(updateGuideStart());
    
//     const data = await udpateGuideCategory(guide, category, user?user.rawIdToken:'');
//     // const uploadurl = await getUploadUrl(data);
//     // const uploadData = await uploadGuideFile(guide);

//     dispatch(createGuideSuccess(data))
//   } catch (err) {
//     logger.log('err',err)
//     dispatch(createGuideFailure(err))
//   }
// }

// // GUIDE UPDATE
// export const udpateGuideCategory = ( guide, category, user ) => async dispatch => {
//   try {

//     dispatch(updateGuideStart());
    
//     const data = await udpateGuideCategory(guide, category, user?user.rawIdToken:'');
//     // const uploadurl = await getUploadUrl(data);
//     // const uploadData = await uploadGuideFile(guide);
//     if(category.files && category.files.length){
//       // upload the files
//       if(category.files && category.files.length){
//         for (let j = 0; j<category.files.length; j++ ){
//           const file = category.files[j];
//           // const fileResult = await uploadGuideFile(file,guide,guide.uploadUrl,user);
//         }
//       }
//     }

//     dispatch(updateGuideSuccess(data))
//   } catch (err) {
//     logger.log('err',err)
//     dispatch(updateGuideFailure(err))
//   }
// }

 
 
 export const { 
     selectGuide
   , selectGuides
   , setGuideFilter

   , selectGuideCategory
 
   , getGuideStart
   , getGuideFailure
   , getGuideSuccess
 
   , getGuidesStart
   , getGuidesFailure
   , getGuidesSuccess

   , createGuideStart
   , createGuideSuccess
   , createGuideFailure
   
   , updateGuideStart
   , updateGuideSuccess
   , updateGuideFailure

   , getGuideCategoriesStart
   , getGuideCategoriesSuccess
   , getGuideCategoriesFailure

   , clearNotifications

 } = guideSlice.actions
 
 export default guideSlice.reducer
 
import { Badge, Box, Button, Divider, Grid, Group, Paper, Stack, TextInput, Title, createStyles } from '@mantine/core';
import { connect, useDispatch } from 'react-redux';
import { FormInputWrapper } from '../../../../core/components';

import { selectSession } from 'modules/Sessions/sessionSlice';
import { logger } from 'utils/logger';
import { useEffect, useState } from 'react';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { pluralize } from 'utils/helper';
import GuideCategory from './GuideCategory';
import { getGuideCategoriesSuccess } from 'modules/Guides/guideSlice';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CategoryShotModel } from 'core/models/CategoryShotModel';
import { updateGuide } from '../../guideSlice';

const useStyles = createStyles((theme) => ({
    accordianLoader: {
        border: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        padding: theme.spacing.md,
    },
}));

const CategoryEdit = ({ guide, stateCategory, value, success, editable = true, showActionBar = true, onChange }) => {
    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [tags, setTags] = useState([]);
    //const [category, setCategory] = useState(value);

    const [data, setData] = useState(value);
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState(value && value.name?value.name:'');

    const onCancel = () => {};
    const dispatch = useDispatch();

    useEffect(() => {
        //logger.log('CATEOGRY CHANGE', category, guide);
        //setCategory(value);
        if (value) setData(value);
    }, [value]);

    useEffect(() => {
        // we use the statecategory in the modal but we use value inline edit
        if (!value && stateCategory) {
            setData(stateCategory);
        }
    }, [stateCategory]);

    //logger.log(category, ' CATEGORY');
    useEffect(() => {
        if (guide && guide.tags && tags.length === 0) {
            var tmpTags = guide.tags.map((val) => {
                return { label: val, value: val };
            });

            logger.log(tmpTags, '  TMP TAGS');
            setTags([...tmpTags]);
        }
    }, [guide]);

    useEffect(() => {
        logger.log(success);
        if (success && success.type === getGuideCategoriesSuccess.toString()) {
            setIsLoading(false);
        }
    }, [success]);

    // const loaderRows = [1, 2, 3].map((val) => (
    //     <Group key={val} position='apart' className={classes.accordianLoader}>
    //         <Skeleton height={26} radius='xl' width={200} />

    //         <Group>
    //             <Skeleton height={26} radius='xl' width={100} />
    //             <Skeleton height={26} radius='xl' width={50} />
    //         </Group>
    //     </Group>
    // ));

    /** get the guides */
    // const getShotFrames = () => {
    //     var guides = [];

    //     // create some dummy guides
    //     for (let i = 0; i < 10; i++) {
    //         let guide = { label: 'Here is some label', shots: [] };

    //         for (let j = 0; j < 4; j++) {
    //             const shot = {
    //                 req: true,
    //                 referenceUrl: '',
    //                 name: 'name',
    //                 label: 'Top Shot here',
    //                 seq: j,
    //                 notes: 'some notes',
    //             };
    //             guide.shots.push(shot);
    //         }

    //         guides.push(guide);
    //     }

    //     return guides;
    // };

    const onAddShotClick = () => {
        const seq = data.shots ? data.shots.length + 1 : 1;
        const tmpShots = data.shots ? data.shots : [];
        const shot = new CategoryShotModel({ seq: seq.toString() });
        logger.log(data);
        const shots = [...tmpShots, shot];
        setData({ ...data, shots: shots });
    };

    const onCategoryChange = (cat) => {
        logger.log('cat change');
        // const tmpData = data.shots.map(val => {
        //     if(val.id === cat.id){
        //         return {...cat}
        //     }else{
        //         return val;
        //     }
        // });
        setData(cat);

        // if inline we push the event
        if (onChange) onChange(cat);
    };

    const saveCategory = () => {
        setIsSaving(true);
        // update the styleguide with the edited category
        let tmpGuide = { ...guide };
        let tmpCat = { ...data, name: name };

        const match = tmpGuide.guideCategories.find((val) => val.id === tmpCat.id);
        let cats = [...tmpGuide.guideCategories];

        if (match) {
            cats = cats.map((val) => {
                // conver the seq num to a string
                if (val.id === tmpCat.id) {
                    return { ...tmpCat, name: name };
                } else {
                    return val;
                }
            });
        } else {
            cats.push(tmpCat);
        }

        tmpGuide.guideCategories = [...cats];
        dispatch(updateGuide(tmpGuide));
    };

    return (
        <div style={{ height: '100%' }}>
            {showActionBar ? (
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} mb={'lg'}>
                    <FormInputWrapper label='Category Name:' labelWidth={140}>
                        <TextInput data-autofocus sx={{ maxWidth: 300 }} placeholder='Category Name' value={name} onChange={(e) => setName(e.currentTarget.value)} />
                    </FormInputWrapper>
                </Box>
            ) : null}

            <Divider />
            <div sx={{ marginLeft: 0 }}>
                {showActionBar ? (
                    <Group my='lg' position='apart'>
                        <Group>
                            <Title className='main-content-heading' order={5}>
                                Category Shots
                            </Title>
                            <Badge>
                                {data?.shots?.length} {pluralize('Shot', 0)}
                            </Badge>
                        </Group>
                        <Button variant='light' onClick={onAddShotClick}>
                            Add Shot
                        </Button>
                    </Group>
                ) : null}

                {/* <Text mb={'xl'} color='dimmed'>
                    Manage and sort your category shots. Drag and drop to change their order
                </Text> */}
                <Grid>
                    <Grid.Col span={12}>
                        <Box>
                            <DndProvider backend={HTML5Backend}>
                                <Stack spacing={'xl'}>
                                    {/* {data?.shots.map((val, idx) => { */}
                                    {/* const captures = val.shots.filter((shot) => shot.capture);
                                        const requiredShots = val.shots.filter((shot) => shot.req === 'yes');
                                        const requiredCount = requiredShots ? requiredShots.length : 0;
                                        const capturedCount = captures ? captures.length : 0;
                                        const isComplete = capturedCount === requiredCount;
                                        const taskSaving = false;
                                        logger.log(val);
                                        return  */}
                                    {/* })} */}
                                    <Category guide={guide} editable={editable} value={data} classes={classes} onChange={onCategoryChange} />

                                    {!data?.shots?.length && !editable ? (
                                        <div style={{ minHeight: 300 }}>
                                            <EmptyState />
                                        </div>
                                    ) : null}
                                </Stack>
                            </DndProvider>
                        </Box>
                    </Grid.Col>
                </Grid>
            </div>
            {showActionBar ? (
                <>
                    <Divider mt={'lg'} />
                    <Group position='apart' mt='lg'>
                        <Button variant='default' onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button disabled={!name?.length} loading={isSaving} onClick={() => saveCategory()}>
                            Create Category
                        </Button>
                    </Group>
                </>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        stateCategory: state.guideReducer.selectedGuideCategory,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatch = { selectSession };

export default connect(mapStateToProps, mapDispatch)(CategoryEdit);

const Category = (props) => {
    const { value, classes } = props;
    // const [editable, setEditable] = useState(true);

    // const toggleMode = () => {
    //     setEditable(!editable);
    // };
    return (
        <Paper p={props.editable ? 'md' : 0} pt={'md'} className={classes.categoryPanel} sx={{background:props.editable?'default' :'transparent'}}>
            <GuideCategory
                fullscreen={false}
                editable={props.editable}
                editableShots={props.editable}
                category={value}
                guide={props.guide}
                allowAssignment={false}
                breakpoints={[
                    { maxWidth: 6000, cols: 6, spacing: 'md' },
                    { maxWidth: 1800, cols: 3, spacing: 'sm' },
                    { maxWidth: 1024, cols: 2, spacing: 'sm' },
                    { maxWidth: 600, cols: 1, spacing: 'sm' },
                ]}
                onChange={props.onChange}
                onItemDrop={(item, target) => logger.log('ondrop')}
            />
            {value?
            <>{((!value && value.shots) || (value && value.shots && value.shots.length === 0)) && (props.editable) ? <EmptyState caption='There are no category shots. Try adding one' /> : null}</>
            :null}
            
        </Paper>
    );
};

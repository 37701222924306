import React, { useState } from 'react';
import { Box, Checkbox, useMantineTheme, DefaultMantineColor } from '@mantine/core';

const SelectableMediaItem = ({ children, onSelectionChange, initiallySelected = false, color = 'brand', checkboxPosition = 'top-right' }) => {
    const theme = useMantineTheme();
    const [isSelected, setIsSelected] = useState(initiallySelected);

    const handleSelectionToggle = (e) => {
        const newSelectedState = !isSelected;
        setIsSelected(newSelectedState);

        if (onSelectionChange) {
            onSelectionChange(e, newSelectedState);
        }
    };

    const positionStyles = {
        'top-right': { top: theme.spacing.xs, right: theme.spacing.xs },
        'top-left': { top: theme.spacing.xs, left: theme.spacing.xs },
        'bottom-right': { bottom: theme.spacing.xs, right: theme.spacing.xs },
        'bottom-left': { bottom: theme.spacing.xs, left: theme.spacing.xs },
    };

    return (
        <Box
            pos='relative'
            style={{
                cursor: 'pointer',
                opacity: isSelected ? 1 : 1,
                transition: 'opacity 0.2s ease',
                // padding: 2,
                borderTop: `2px solid ${isSelected ? theme.colors.brand[4] : 'transparent'} !important`,
                borderWidth: 2,
                borderColor: isSelected ? theme.colors.brand[4] : 'transparent',
                borderStyle: 'solid',
            }}
            onClick={handleSelectionToggle}>
            {/* Checkbox Overlay */}

            {children}
            {/* <Checkbox
                pos='absolute'
                z={10}
                style={positionStyles[checkboxPosition]}
                checked={isSelected}
                color={color}
                onChange={() => {}} // Prevents default checkbox behavior
                onClick={(e) => e.stopPropagation()} // Prevents toggle on checkbox click
            /> */}
        </Box>
    );
};

export default SelectableMediaItem;

export class StyleAssetFilterModel {
    constructor(obj) {
        this.keyword = '';
        this.broughtForward = '';
        this.guideCategories = [];
        this.guides = [];
        this.tags = [];
        this.completed = [null, null];
        this.viewstatus = ['','active', undefined];
    }

    if(obj) {
        Object.assign(this, obj);
    }
}

export const StyleAssetStatusTypes = {
    ARCHIVED: 'archived',
    ACTIVE: 'active',
};

import { ActionIcon, Group, Text, TextInput, createStyles, useMantineTheme } from '@mantine/core';
import { useFocusWithin } from '@mantine/hooks';
import { IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { logger } from 'utils/logger';

const useStyles = createStyles((theme,focused) => {
    let borderColor = theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4];
    let borderColorFocussed = theme.colorScheme === 'dark'?theme.colors.brand[4]:theme.colors.brand[4];

    return ({
    
    container: {
        minHeight: '42px',
        paddingLeft: '14px',
        borderStyle: 'solid',
        borderWidth: 1,
        paddingRight: theme.spacing.sm,
        borderRadius: theme.radius.sm,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        
        WebkitTransition: 'border-color 100ms ease',
        transition: 'border-color 100ms ease',
        marginTop:3,
        marginBottom:3,
        borderColor: focused ? borderColorFocussed : borderColor
    },
    tag: {
        background: theme.colorScheme === 'dark'?theme.colors.dark[7]:theme.colors.gray[2],
        borderRadius: theme.radius.sm,
        paddingLeft: theme.spacing.md,
        marginTop:theme.spacing.xs/2,
        marginBottom:theme.spacing.xs/2,
        marginLeft:0,
        marginRight:theme.spacing.xs,

        // marginBottom:-theme.spacing.xs,
    },
})});

function TagInput(props) {
    const { ref, focused } = useFocusWithin();
    const { classes } = useStyles(focused);
    const [tags, setTags] = useState([]);
    
    const theme = useMantineTheme();

    const { placeholder = 'Enter Tags', value, onChange } = props;

    useEffect(() => {
        if (value) {
            const tmpTags = value.filter((item) => {
                if(item){
                    return (item.label ? item.label : item)
                }
            });
            setTags(tmpTags);
        }
    }, [value]);

    function handleKeyDown(e) {
        
        if (e.key === 'Enter' || e.key === 'Tab') {
            const value = e.target.value;
            if (!value.trim()) return;

            const newTags = [...tags, value];
            setTags(newTags);

            e.target.value = '';
            e.preventDefault();

            onChange(newTags);
        }else if(e.key === 'Backspace'){
            removeTag(tags.length-1)
        }
    }

    function removeTag(index) {
        const newTags = tags.filter((el, i) => i !== index);
        setTags(newTags);
        onChange(newTags);
    }

    return (
        <Group className={classes.container} spacing={0} sx={{  }}>
            {tags.map((tag, index) => (
                <Group className={classes.tag} key={index} spacing={0}>
                    <Text className='text' size={'sm'}>
                        {tag}
                    </Text>
                    <ActionIcon onClick={() => removeTag(index)}>
                        <IconX size={12} />{' '}
                    </ActionIcon>
                </Group>
            ))}
            <TextInput sx={{flexGrow:1}} classNames={classes.input} ref={ref} variant='unstyled' onKeyDown={handleKeyDown} type='text' className='tags-input' placeholder={placeholder} />
        </Group>
    );
}

export default TagInput;

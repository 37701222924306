import { ActionIcon, Avatar, Box, Group, Menu, Text } from "@mantine/core";
import { IconDots, IconEdit, IconTrash } from "@tabler/icons";

const CommentDisplay = ({indentLeft}) => {
   
    return (
      <Box mb={'xl'}>
        <Group position='apart' mb={'xs'}>
          <Group>
          <Avatar radius={'xl'} color='grape'>AW</Avatar>
          <div>
            <Text size='sm' sx={{fontWeight:'bold'}}>Amy Winehouse</Text>
            <Text color="dimmed" size='xs'>12 Hours ago</Text>
          </div>
          </Group>
  
          <NotesMenu/>
        </Group>
  
        <Text size='sm' sx={(theme) =>({paddingLeft:indentLeft?55:0})}>@Geoff  Please make sure the tags are removed from all garments before shooting. Make sure the headwear is worn backwards.</Text>
      </Box>
    )
  }
  function NotesMenu() {
    return (
      <Menu size='lg'>
        <Menu.Target>
          <ActionIcon><IconDots size={14}/></ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconEdit size={14} />}>Edit Note</Menu.Item>
          <Menu.Item color="red" icon={<IconTrash size={14} />} size={14}>Delete Note</Menu.Item>
        </Menu.Dropdown>
        
      </Menu>
    );
  }

export default CommentDisplay;
  
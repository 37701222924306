import { SampleModel } from 'core/models';
import { StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';
import { getToken, parseValue } from 'utils/helper';
import { logger } from 'utils/logger';

export const getStyle = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/style/${id}`;
    const formData = JSON.stringify({});

    logger.log(' GETTING SAMPLE DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                var newItem = {};
                newItem = new SampleModel(parseValue(item));
                newItem.id = newItem.styleNumber;
                return newItem;
            });
            return items[0];
        })
        .catch((err) => {
            logger.error('ERROR getting style', err);
            throw err;
        });
};

export const getStyles = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/style`;
    const formData = JSON.stringify({});

    logger.log(' GETTING STYLE DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                var newItem = {};
                newItem = new SampleModel(parseValue(item));
                newItem.id = newItem.styleNumber;
                return newItem;
            });
            return items;
        })
        .catch((err) => {
            logger.error('ERROR getting style', err);
            throw err;
        });
};

export const getStyleAssets = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/style/${id}/assets`;

    const formData = JSON.stringify({});

    logger.log(' GETTING SAMPLE DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/json'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                const keys = Object.keys(item);
                var newItem = {};
                newItem = parseValue(item);
                newItem.id = newItem.styleSort;
                // set the default of the view status if it doesn't exist
                if(!newItem.viewstatus){
                    newItem.viewstatus = StyleAssetStatusTypes.ACTIVE
                }
                return newItem;
            });
            return items;
        })
        .catch((err) => {
            logger.error('ERROR getting sample', err);
            throw err;
        });
};

export const getStylesByUnit = async (criteria) => {
    var url = `${process.env.REACT_APP_API_URL}/style/unit?`;

    if (criteria && criteria.status && criteria.status.length) {
        url += `status=${criteria.status}`;
    }
    if (criteria && criteria.weekStart) {
        url += `&weekstart=${criteria.weekStart}`;
    }
      
    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                var newItem = {};
                newItem = new SampleModel(item);
                newItem.id = newItem.styleNumber;
                return newItem;
            });
            return items;
        })
        .catch((err) => {
            logger.error('ERROR getting style', err);
            throw err;
        });
};
export const updateStyleAsset = async (style, asset) => {
    const url = `${process.env.REACT_APP_API_URL}/style/${style.id}/assets/${asset.id}`;

    var { id, ...payload } = asset;
    const formData = JSON.stringify(payload);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            // var items = data.Items.map((item, idx) => {
            //   const keys = Object.keys(item);
            //   var newItem = {};
            //   newItem = parseValue(item);
            //   newItem.id = newItem.styleSort;
            //   return newItem;
            // });
            return true;
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR getting sample', err);
            throw err;
        });
};

export const distributeStyles = async (styles) => {
    const url = `${process.env.REACT_APP_API_URL}/style/distro`;

    const formData = JSON.stringify(styles);

    return fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: formData,
    })
        .then((response) => {
            return true;
            // return response.json();
        })

        .catch((err) => {
            logger.error('ERROR getting sample', err);
            throw err;
        });
};

export const getStyleTimeline = async (style) => {
    const url = `${process.env.REACT_APP_API_URL}/style/${style.id}/timeline`;

    // const formData = JSON.stringify(payload);

    logger.log(' GETTING SAMPLE TIMELINE: ', style);

    //   return {
    //     Timeline: {
    //        seasons: [
    //           {
    //              sampleGrade: "Satisfactory",
    //              instructions: {
    //                 ParentKeycode: "69715426",
    //                 WebStockOTBDate: "2024-05-27",
    //                 WebPhotographyNotes: "",
    //                 guideName: "Accessories",
    //                 guideCode: "GUI-735",
    //                 WebPhotographyStyle: "PRODUCT_SHOT",
    //                 Name: "TW HALFMOON SHOULDER BAG CREAM",
    //                 WebPhotographyTreatment: "CONTOUR",
    //                 department: "FASHION ACCESSORIES",
    //                 ODBMSAPN: "9360612519668",
    //                 WebPhotographyCode: "46W160A",
    //                 Dept: "160",
    //                 WebPhotographySampleSize: ""
    //              },
    //              sampleCount: {
    //                 __Decimal__: "1"
    //              },
    //              created: "2024-05-01-22-03-26",
    //              photographyCount: "2",
    //              styleCount: {
    //                 __Decimal__: "0"
    //              },
    //              seasonPart: "SEA-437032",
    //              captures: [
    //                 "V3TQ2KVX5C__2024-05-06-04-38-15-237",
    //                 "V3TQ2KVX5C__2024-05-06-04-38-17-253"
    //              ],
    //              guideCode: "GUI-735",
    //              studioSessionCodes: [
    //                 "TAR-340147"
    //              ],
    //              guideName: "Accessories",
    //              seasonSort: "69715426"
    //           },
    //           {
    //              seasonSort: "69715426",
    //              taskCount: "2",
    //              guideName: "Accessories",
    //              captures: [
    //                 "WL4PX6YD0C__2024-05-05-23-59-12-056",
    //                 "WL4PX6YD0C__2024-05-05-23-59-46-272",
    //                 "WL4PX6YD0C__2024-05-05-23-59-48-447",
    //                 "WL4PX6YD0C__2024-05-06-00-06-55-550",
    //                 "WL4PX6YD0C__2024-05-06-00-07-01-031",
    //                 "WL4PX6YD0C__2024-05-06-00-07-02-398"
    //              ],
    //              seasonPart: "SEA-437034",
    //              guideCode: "GUI-735",
    //              studioSessionCodes: [
    //                 "TAR-340143",
    //                 "TAR-340144",
    //                 "TAR-340168"
    //              ],
    //              styleCount: {
    //                 __Decimal__: "0"
    //              },
    //              photographyCount: "6",
    //              created: "2023-05-02-20-51-12",
    //              instructions: {
    //                 ODBMSAPN: "9360612519668",
    //                 department: "FASHION ACCESSORIES",
    //                 WebPhotographyTreatment: "CONTOUR",
    //                 Name: "TW HALFMOON SHOULDER BAG CREAM",
    //                 WebPhotographySampleSize: "",
    //                 Dept: "160",
    //                 WebPhotographyCode: "46W160A",
    //                 WebPhotographyNotes: "",
    //                 WebStockOTBDate: "2024-05-27",
    //                 ParentKeycode: "69715426",
    //                 WebPhotographyStyle: "PRODUCT_SHOT",
    //                 guideCode: "GUI-735",
    //                 guideName: "Accessories"
    //              },
    //              sampleCount: {
    //                 __Decimal__: "5"
    //              },
    //              taskCompleteCount: "5",
    //              sampleGrade: "Satisfactory"
    //           }
    //        ],
    //        sessions: [
    //           {
    //              captures: [
    //                 "WL4PX6YD0C__2024-05-05-23-59-12-056",
    //                 "WL4PX6YD0C__2024-05-05-23-59-46-272",
    //                 "WL4PX6YD0C__2024-05-05-23-59-48-447"
    //              ],
    //              taskCount: "1",
    //              ssPart: "TAR-340143",
    //              photographyCount: "3",
    //              stylenumber: "69715426",
    //              name: "TW HALFMOON SHOULDER BAG CREAM",
    //              Dept: "160",
    //              created: "2024-05-02-20-56-18",
    //              sampleStatus: "",
    //              photographyStatus: "",
    //              size: "",
    //              types: "",
    //              department: "FASHION ACCESSORIES",
    //              ssSort: "69715426",
    //              photoCode: "46W160A",
    //              descr: "",
    //              otbDate: "2024-05-27",
    //              targetSort: "69715426",
    //              sampleCount: {
    //                 __Decimal__: "5"
    //              },
    //              taskCompleteCount: "4"
    //           },
    //           {
    //              captures: [
    //                 "WL4PX6YD0C__2024-05-06-00-06-55-550",
    //                 "WL4PX6YD0C__2024-05-06-00-07-01-031",
    //                 "WL4PX6YD0C__2024-05-06-00-07-02-398"
    //              ],
    //              taskCount: "1",
    //              ssPart: "TAR-340144",
    //              photographyCount: "3",
    //              stylenumber: "69715426",
    //              name: "TW HALFMOON SHOULDER BAG CREAM",
    //              Dept: "160",
    //              sampleStatus: "",
    //              photographyStatus: "",
    //              created: "2024-05-02-21-32-13",
    //              photoCode: "46W160A",
    //              descr: "",
    //              otbDate: "2024-05-27",
    //              size: "",
    //              ssSort: "69715426",
    //              types: "",
    //              department: "FASHION ACCESSORIES",
    //              targetSort: "69715426",
    //              taskCompleteCount: "1",
    //              sampleCount: {
    //                 __Decimal__: "5"
    //              }
    //           },
    //           {
    //              ssPart: "TAR-340147",
    //              captures: [
    //                 "V3TQ2KVX5C__2024-05-06-04-38-15-237",
    //                 "V3TQ2KVX5C__2024-05-06-04-38-17-253"
    //              ],
    //              name: "TW HALFMOON SHOULDER BAG CREAM",
    //              stylenumber: "69715426",
    //              photographyCount: "2",
    //              photographyStatus: "",
    //              sampleStatus: "",
    //              created: "2024-05-03-05-02-17",
    //              Dept: "160",
    //              targetSort: "69715426",
    //              photoCode: "46W160A",
    //              descr: "",
    //              otbDate: "2024-05-27",
    //              size: "",
    //              department: "FASHION ACCESSORIES",
    //              ssSort: "69715426",
    //              types: ""
    //           },
    //           {
    //              photographyCount: "2",
    //              name: "TW HALFMOON SHOULDER BAG CREAM",
    //              stylenumber: "69715426",
    //              captures: [
    //                 "TXDDXQN0G9__2024-05-14-05-40-50-282",
    //                 "TXDDXQN0G9__2024-05-14-05-40-54-490"
    //              ],
    //              taskCount: "1",
    //              ssPart: "TAR-340160",
    //              size: "",
    //              ssSort: "69715426",
    //              types: "",
    //              department: "FASHION ACCESSORIES",
    //              descr: "",
    //              photoCode: "46W160A",
    //              otbDate: "2024-05-27",
    //              targetSort: "69715426",
    //              taskCompleteCount: "1",
    //              Dept: "160",
    //              created: "2024-05-13-22-24-41",
    //              photographyStatus: "",
    //              sampleStatus: ""
    //           },
    //           {
    //              stylenumber: "69715426",
    //              name: "TW HALFMOON SHOULDER BAG CREAM",
    //              ssPart: "TAR-340168",
    //              department: "FASHION ACCESSORIES",
    //              ssSort: "69715426",
    //              types: "",
    //              size: "",
    //              otbDate: "2024-05-27",
    //              photoCode: "46W160A",
    //              descr: "",
    //              targetSort: "69715426",
    //              Dept: "160",
    //              created: "2024-05-19-01-23-02",
    //              sampleStatus: "",
    //              photographyStatus: ""
    //           }
    //        ],
    //        masters: [
    //           {
    //              targetKeycode: "69715426",
    //              variation: "",
    //              variationSize: "",
    //              otb: "27-05-2024",
    //              dept: "160 - Fashion Accessories",
    //              brand: "TARGET",
    //              styleNumber: "1600276295",
    //              productName: "TW HALFMOON SHOULDER BAG CREAM",
    //              kmartKeycode: "",
    //              productStatus: "Active",
    //              colour: "Cream",
    //              targetId: "tk#69715426",
    //              targetSort: "p#9360612519668",
    //              version: "2024-05-24-08-38-12",
    //              modified: "2024-05-23T22:38:12.991133976Z",
    //              apn: "9360612519668"
    //           }
    //        ]
    //     }
    //  };

    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
            return {
                Timeline: {
                    seasons: [
                        {
                            sampleGrade: 'Satisfactory',
                            instructions: {
                                ParentKeycode: '69715426',
                                WebStockOTBDate: '2024-05-27',
                                WebPhotographyNotes: '',
                                guideName: 'Accessories',
                                guideCode: 'GUI-735',
                                WebPhotographyStyle: 'PRODUCT_SHOT',
                                Name: 'TW HALFMOON SHOULDER BAG CREAM',
                                WebPhotographyTreatment: 'CONTOUR',
                                department: 'FASHION ACCESSORIES',
                                ODBMSAPN: '9360612519668',
                                WebPhotographyCode: '46W160A',
                                Dept: '160',
                                WebPhotographySampleSize: '',
                            },
                            sampleCount: {
                                __Decimal__: '1',
                            },
                            created: '2024-05-01-22-03-26',
                            photographyCount: '2',
                            styleCount: {
                                __Decimal__: '0',
                            },
                            seasonPart: 'SEA-437032',
                            captures: ['V3TQ2KVX5C__2024-05-06-04-38-15-237', 'V3TQ2KVX5C__2024-05-06-04-38-17-253'],
                            guideCode: 'GUI-735',
                            studioSessionCodes: ['TAR-340147'],
                            guideName: 'Accessories',
                            seasonSort: '69715426',
                        },
                        {
                            seasonSort: '69715426',
                            taskCount: '2',
                            guideName: 'Accessories',
                            captures: [
                                'WL4PX6YD0C__2024-05-05-23-59-12-056',
                                'WL4PX6YD0C__2024-05-05-23-59-46-272',
                                'WL4PX6YD0C__2024-05-05-23-59-48-447',
                                'WL4PX6YD0C__2024-05-06-00-06-55-550',
                                'WL4PX6YD0C__2024-05-06-00-07-01-031',
                                'WL4PX6YD0C__2024-05-06-00-07-02-398',
                            ],
                            seasonPart: 'SEA-437034',
                            guideCode: 'GUI-735',
                            studioSessionCodes: ['TAR-340143', 'TAR-340144', 'TAR-340168'],
                            styleCount: {
                                __Decimal__: '0',
                            },
                            photographyCount: '6',
                            created: '2023-05-02-20-51-12',
                            instructions: {
                                ODBMSAPN: '9360612519668',
                                department: 'FASHION ACCESSORIES',
                                WebPhotographyTreatment: 'CONTOUR',
                                Name: 'TW HALFMOON SHOULDER BAG CREAM',
                                WebPhotographySampleSize: '',
                                Dept: '160',
                                WebPhotographyCode: '46W160A',
                                WebPhotographyNotes: '',
                                WebStockOTBDate: '2024-05-27',
                                ParentKeycode: '69715426',
                                WebPhotographyStyle: 'PRODUCT_SHOT',
                                guideCode: 'GUI-735',
                                guideName: 'Accessories',
                            },
                            sampleCount: {
                                __Decimal__: '5',
                            },
                            taskCompleteCount: '5',
                            sampleGrade: 'Satisfactory',
                        },
                    ],
                    sessions: [
                        {
                            captures: ['WL4PX6YD0C__2024-05-05-23-59-12-056', 'WL4PX6YD0C__2024-05-05-23-59-46-272', 'WL4PX6YD0C__2024-05-05-23-59-48-447'],
                            taskCount: '1',
                            ssPart: 'TAR-340143',
                            photographyCount: '3',
                            stylenumber: '69715426',
                            name: 'TW HALFMOON SHOULDER BAG CREAM',
                            Dept: '160',
                            created: '2024-05-02-20-56-18',
                            sampleStatus: '',
                            photographyStatus: '',
                            size: '',
                            types: '',
                            department: 'FASHION ACCESSORIES',
                            ssSort: '69715426',
                            photoCode: '46W160A',
                            descr: '',
                            otbDate: '2024-05-27',
                            targetSort: '69715426',
                            sampleCount: {
                                __Decimal__: '5',
                            },
                            taskCompleteCount: '4',
                        },
                        {
                            captures: ['WL4PX6YD0C__2024-05-06-00-06-55-550', 'WL4PX6YD0C__2024-05-06-00-07-01-031', 'WL4PX6YD0C__2024-05-06-00-07-02-398'],
                            taskCount: '1',
                            ssPart: 'TAR-340144',
                            photographyCount: '3',
                            stylenumber: '69715426',
                            name: 'TW HALFMOON SHOULDER BAG CREAM',
                            Dept: '160',
                            sampleStatus: '',
                            photographyStatus: '',
                            created: '2024-05-02-21-32-13',
                            photoCode: '46W160A',
                            descr: '',
                            otbDate: '2024-05-27',
                            size: '',
                            ssSort: '69715426',
                            types: '',
                            department: 'FASHION ACCESSORIES',
                            targetSort: '69715426',
                            taskCompleteCount: '1',
                            sampleCount: {
                                __Decimal__: '5',
                            },
                        },
                        {
                            ssPart: 'TAR-340147',
                            captures: ['V3TQ2KVX5C__2024-05-06-04-38-15-237', 'V3TQ2KVX5C__2024-05-06-04-38-17-253'],
                            name: 'TW HALFMOON SHOULDER BAG CREAM',
                            stylenumber: '69715426',
                            photographyCount: '2',
                            photographyStatus: '',
                            sampleStatus: '',
                            created: '2024-05-03-05-02-17',
                            Dept: '160',
                            targetSort: '69715426',
                            photoCode: '46W160A',
                            descr: '',
                            otbDate: '2024-05-27',
                            size: '',
                            department: 'FASHION ACCESSORIES',
                            ssSort: '69715426',
                            types: '',
                        },
                        {
                            photographyCount: '2',
                            name: 'TW HALFMOON SHOULDER BAG CREAM',
                            stylenumber: '69715426',
                            captures: ['TXDDXQN0G9__2024-05-14-05-40-50-282', 'TXDDXQN0G9__2024-05-14-05-40-54-490'],
                            taskCount: '1',
                            ssPart: 'TAR-340160',
                            size: '',
                            ssSort: '69715426',
                            types: '',
                            department: 'FASHION ACCESSORIES',
                            descr: '',
                            photoCode: '46W160A',
                            otbDate: '2024-05-27',
                            targetSort: '69715426',
                            taskCompleteCount: '1',
                            Dept: '160',
                            created: '2024-05-13-22-24-41',
                            photographyStatus: '',
                            sampleStatus: '',
                        },
                        {
                            stylenumber: '69715426',
                            name: 'TW HALFMOON SHOULDER BAG CREAM',
                            ssPart: 'TAR-340168',
                            department: 'FASHION ACCESSORIES',
                            ssSort: '69715426',
                            types: '',
                            size: '',
                            otbDate: '2024-05-27',
                            photoCode: '46W160A',
                            descr: '',
                            targetSort: '69715426',
                            Dept: '160',
                            created: '2024-05-19-01-23-02',
                            sampleStatus: '',
                            photographyStatus: '',
                        },
                    ],
                    masters: [
                        {
                            targetKeycode: '69715426',
                            variation: '',
                            variationSize: '',
                            otb: '27-05-2024',
                            dept: '160 - Fashion Accessories',
                            brand: 'TARGET',
                            styleNumber: '1600276295',
                            productName: 'TW HALFMOON SHOULDER BAG CREAM',
                            kmartKeycode: '',
                            productStatus: 'Active',
                            colour: 'Cream',
                            targetId: 'tk#69715426',
                            targetSort: 'p#9360612519668',
                            version: '2024-05-24-08-38-12',
                            modified: '2024-05-23T22:38:12.991133976Z',
                            apn: '9360612519668',
                        },
                    ],
                },
            };
        })
        .catch((err) => {
            logger.error('ERROR getting sample timeline', err);
            throw err;
        });
};

import { Anchor, Avatar, Badge, Box, Button, Center, createStyles, Divider, Group, Paper, ScrollArea, Table, Tabs, Text, Textarea, Timeline, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconCamera, IconDownload, IconMessageDots, IconNotes, IconPalette } from '@tabler/icons';
import { CommentDisplay } from 'core/components/index';
import { Link } from 'react-router-dom';
import { logger } from 'utils/logger';
import SamplePopover from 'core/components/popovers/SamplePopover';
import { getSession } from 'services/sessionService';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { DataFormats } from 'utils/constants';
import moment from 'moment';
import { getUserAvatarColour, getUserInitials } from 'utils/helper';

const useStyles = createStyles((theme) => ({
    container: {
        padding: 0,
        height: '100%',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        // paddingTop: 26,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
        '.mantine-Accordion-content': {
            paddingBottom: 0,
        },
        '.mantine-Accordion-control': {
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
        },
        '.mantine-Stack-root': {
            gap: 0,
        },
        '.capture-selected > div > div:first-of-type': {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[1] : theme.colors.gray[3],
        },
    },
    badgeIndex: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
}));

const InfoPanel = ({ captures, onImageEvent, selectedItem, selectedItems = [], comparisonItems = [], selectedComparisonFrame, showComapirsonSelection = false, style = {} }) => {
    const { classes } = useStyles();
    const [activeTab, setActiveTab] = useState('info');
    const [session, setSelectedSession] = useState(null);
    const [talent, setTalent] = useState([]);
    // const dateArr = selectedItem.actionSort.split('-');
    // const y = dateArr[0];
    // const m = dateArr[1];
    // const d = dateArr[2];
    // const h = dateArr[3];
    // const min = dateArr[4];
    // const s = dateArr[5];

    // const captureDate = moment(selectedItem.actionSort, 'YYYY-MM-DD-hh-mm-ss');

    useEffect(() => {
        const getShotSession = async (id) => {
            const data = await getSession(id, null);

            if (data) {
                logger.log(data);
                setSelectedSession(data);
            }
        };
        if (selectedItem && selectedItem.sessionId && selectedItem.jobId) {
            if (selectedItem.sessionId.length || selectedItem.jobId.length) {
                const id = selectedItem.sessionId.length ? selectedItem.sessionId : selectedItem.jobId;
                getShotSession(id);
            }
        }
    }, [selectedItem]);

    logger.log(selectedItem);

    return (
        <Box className={`${classes.container} info-panel unhightable`} sx={style}>
            <div style={{ width: 400, height: 'calc(100% - 46px)' }}>
                <Tabs value={activeTab} onTabChange={setActiveTab} sx={{ height: '100%', paddingTop: 20 }}>
                    <Tabs.List>
                        <Tabs.Tab value='info' icon={<IconNotes size={14} />}>
                            General Details
                        </Tabs.Tab>
                        {/* <Tabs.Tab value='notes' icon={<IconWriting size={14} />}>
                            Notes
                        </Tabs.Tab>
                        <Tabs.Tab value='history' icon={<IconHistory size={14} />}>
                            History
                        </Tabs.Tab> */}
                    </Tabs.List>

                    <Tabs.Panel value='info' sx={{ height: '100%', '> div > div > div': { height: '100%' } }}>
                        <ScrollArea sx={{ height: '100%' }}>
                            {selectedItem ? (
                                <Box px='lg' pt='lg'>
                                    <Title className='main-content-heading' order={5} mb='lg'>
                                        Capture Detail
                                    </Title>

                                    <Box>
                                        <Group my='md'>
                                            <Text size='sm' style={{ width: '30%' }} weight='500'>
                                                Session
                                            </Text>
                                            <div>
                                                <Text size='sm'>
                                                    <Anchor component={Link} to={`/sessions/${selectedItem.sessionId}`}>
                                                        {selectedItem?.sessionId}
                                                    </Anchor>
                                                </Text>
                                            </div>
                                        </Group>
                                        <Group my='md'>
                                            <Text size='sm' style={{ width: '30%' }} weight='500'>
                                                Machine
                                            </Text>
                                            <div>
                                                <Text size='sm'>{selectedItem.machineId}</Text>
                                            </div>
                                        </Group>

                                        <Group my='md'>
                                            <Text size='sm' style={{ width: '30%' }} weight='500'>
                                                Shot by
                                            </Text>
                                            <div>
                                                <Text color={selectedItem.machineUser ? '' : 'dimmed'} size='sm'>
                                                    {selectedItem.machineUser ? selectedItem.machineUser : 'No user available'}
                                                </Text>
                                            </div>
                                        </Group>

                                        <Group my='md'>
                                            <Text size='sm' style={{ width: '30%' }} weight='500'>
                                                Shot Time
                                            </Text>
                                            <div>
                                                <Text size='sm'>
                                                    {moment.utc(selectedItem.actionSort, 'YYYY-MM-DD-hh-mm-ss').local(DataFormats.TIMEZONE).format('DD/MM/YYYY hh:mm:ss')}
                                                    <br></br>
                                                    {/* <Moment date={selectedItem.actionSort} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY hh:mm:ss' trim /> */}
                                                </Text>
                                            </div>
                                        </Group>

                                        <Group my='md'>
                                            <Text size='sm' style={{ width: '30%' }} weight='500'>
                                                Location
                                            </Text>
                                            <div>
                                                <Anchor size='sm'>Wellcom Lorimer Street</Anchor>
                                            </div>
                                        </Group>

                                        {/* <Button variant='light' color="brand" fullWidth style={{ marginTop: 14 }}>
                        View Location Details
                    </Button> */}
                                    </Box>
                                    <Divider />
                                    <Box mt={'xl'}>
                                        <Title className='main-content-heading' order={6} mb='lg'>
                                            Team
                                        </Title>

                                        {session &&
                                            session.team.map((val, idx) => {
                                                const userInitials = getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1]);
                                                const themeCol = getUserAvatarColour(userInitials);
                                                return (
                                                    <Paper my='md' key={idx} p={'xs'}>
                                                        <Text size='sm' style={{ width: '100%' }} weight='500'>
                                                            {val.role}
                                                        </Text>
                                                        <Divider mt={'xs'} />

                                                        <Group spacing='sm' pt={'xs'}>
                                                            <Avatar variant='filled' title={`${val.name} - ${val.role}`} color={themeCol} size={30} radius={40}>
                                                                {val.name.charAt(0)}
                                                            </Avatar>
                                                            <div>
                                                                <Text size='sm' weight={500}>
                                                                    {val.name}
                                                                </Text>
                                                                <Text size='xs' color='dimmed' truncate>
                                                                    {' '}
                                                                    {val.email}
                                                                </Text>
                                                            </div>
                                                        </Group>
                                                    </Paper>
                                                );
                                            })}

                                        {!session || (session && session.team.length === 0) ? <EmptyState caption='There is no team assigned' style={{ height: 200 }} /> : null}
                                        {/* <TalentList data={[{avatar:selectedItem.filePath,name:'Test User'}]}/> */}
                                    </Box>

                                    {/* <Box mt={'xl'}>
                                    <Group my='md'>
                                        <Text size='sm' style={{ width: '30%' }} weight='500'>
                                            Producer
                                        </Text>
                                        <div>
                                            <Group spacing='sm'>
                                                <Avatar src={null} alt='Test Producer' radius={'xl'} color='violet'>
                                                    TE
                                                </Avatar>
                                                <div>
                                                    <Text size='sm' weight={500}>
                                                        Test User
                                                    </Text>
                                                    <Text size='xs' color='dimmed'>
                                                        test@altitude.com.au
                                                    </Text>
                                                </div>
                                            </Group>
                                        </div>
                                    </Group>

                                    <TalentList data={[{avatar:selectedItem.filePath,name:'Test User'}]}/>
                                </Box>

                                <Box mt={'xl'}>
                                    <Group my='md'>
                                        <Text size='sm' style={{ width: '30%' }} weight='500'>
                                            Stylist
                                        </Text>

                                        <Group spacing='sm'>
                                            <Avatar src={null} alt='Test Stylist' radius={'xl'} color='grape'>
                                                TE
                                            </Avatar>
                                            <div>
                                                <Text size='sm' weight={500}>
                                                    Test Stylist
                                                </Text>
                                                <Text size='xs' color='dimmed'>
                                                    test@altitude.com.au
                                                </Text>
                                            </div>
                                        </Group>
                                    </Group>

                                   
                                </Box> */}
                                <Divider />
                                    <Box my={'xl'}>
                                        <Title className='main-content-heading' order={6} mb='xs'>
                                            Photography Notes
                                        </Title>
                                        <Group spacing={'xs'} sx={{ justifyContent: 'center' }}>
                                            {selectedItem && selectedItem.photoNotes && selectedItem.photoNotes.length ? <Text>{selectedItem.photoNotes}</Text> : <Text color='dimmed' size={'sm'}>No notes available</Text>}
                                        </Group>
                                    </Box>
                                    <Divider />
                                    <Box my={'xl'}>
                                        <Title className='main-content-heading' order={6} mb='lg'>
                                            Styles
                                        </Title>
                                        <Group spacing={'xs'}>
                                            {selectedItem &&
                                                selectedItem.styles &&
                                                selectedItem.styles.length &&
                                                selectedItem.styles.split(',').map((val, idx) => (
                                                    <SamplePopover key={idx} data={{ targetSort: val }}>
                                                        <Badge key={idx}>{val}</Badge>
                                                    </SamplePopover>
                                                ))}

                                            {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>Winter</Badge> */}
                                        </Group>
                                        {!selectedItem || (selectedItem && selectedItem.styles && selectedItem.styles.length === 0) || (selectedItem && !selectedItem.styles) ? (
                                            <EmptyState caption='There are no styles assigned' style={{ height: 200 }} />
                                        ) : null}
                                    </Box>
                                    <Divider />
                                    <Box mt={'xl'}>
                                        <Title className='main-content-heading' order={6} mb='lg'>
                                            Talent
                                        </Title>

                                        {selectedItem &&
                                            selectedItem.talentCodes?.split(',').map((val, idx) => {
                                                return (
                                                    <Paper my='md' key={idx} p={'xs'}>
                                                        <Text size='sm' style={{ width: '100%' }} weight='500'>
                                                            {val}
                                                        </Text>
                                                        {/* <Divider mt={'xs'} /> */}

                                                        {/* <Group spacing='sm' pt={'xs'}>
                                                        <Avatar variant='filled' title={`${val.name} - ${val.role}`} color={themeCol} size={30} radius={40}>
                                                            {val.name.charAt(0)}
                                                        </Avatar>
                                                        <div>
                                                            <Text size='sm' weight={500}>
                                                                {val.name}
                                                            </Text>
                                                            <Text size='xs' color='dimmed' truncate >                                                                {val.email}
                                                            </Text>
                                                        </div>
                                                    </Group> */}
                                                    </Paper>
                                                );
                                            })}

                                        {!selectedItem || (selectedItem && selectedItem.talentCodes?.length === 0) ? <EmptyState caption='There is no team assigned' style={{ height: 200 }} /> : null}
                                        {/* <TalentList data={[{avatar:selectedItem.filePath,name:'Test User'}]}/> */}
                                    </Box>

                                    <Divider />
                                    <Box my={'xl'}>
                                        <Title className='main-content-heading' order={6} mb='lg'>
                                            Tags
                                        </Title>
                                        <Group spacing={'xs'}>
                                            {selectedItem &&
                                                selectedItem.tags &&
                                                selectedItem.tags.length &&
                                                selectedItem.tags.split(',').map((val, idx) => (
                                                    <Badge key={idx} variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                                                        {val}
                                                    </Badge>
                                                ))}

                                            {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>Winter</Badge> */}
                                        </Group>
                                    </Box>
                                </Box>
                            ) : (
                                <Center sx={{ height: '100%', minHeight: 400 }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <IconCamera size={48} stroke={1} />
                                        <Title color={'dimmed'} order={3}>
                                            Select an Image
                                        </Title>
                                    </div>
                                </Center>
                            )}
                        </ScrollArea>
                    </Tabs.Panel>
                    <Tabs.Panel value='notes'>
                        <Box className={classes.tabBodyContent}>
                            <Box px={'lg'} py='sm' mb={'xs'}>
                                <Textarea placeholder='Enter your notes'></Textarea>
                                <Group mt={'sm'} position='right'>
                                    <Button variant='outline'>+ Add</Button>
                                </Group>
                            </Box>
                            <Divider></Divider>
                            <Box p={'lg'} component={ScrollArea}>
                                {[1, 1, 1, 1].map((item, idx) => (
                                    <div key={idx}>
                                        <CommentDisplay />
                                    </div>
                                ))}
                            </Box>
                        </Box>
                    </Tabs.Panel>
                    <Tabs.Panel value='history'>
                        <Box className={classes.tabBodyContent} p={'lg'} pt='xl'>
                            <Timeline active={3} bulletSize={24} lineWidth={2}>
                                <Timeline.Item bullet={<IconCamera size={12} />} title='New Capture'>
                                    <Text color='dimmed' size='sm'>
                                        You&apos;ve uploaded a new image to{' '}
                                        <Text variant='link' component='span' inherit>
                                            JOB001
                                        </Text>
                                    </Text>
                                    <Text size='xs' mt={4}>
                                        2 hours ago
                                    </Text>
                                </Timeline.Item>

                                <Timeline.Item title='Notes Added' bullet={<IconMessageDots size={12} />}>
                                    <Text color='dimmed' size='sm'>
                                        Art Director{' '}
                                        <Text variant='link' component='span' inherit>
                                            Robert Smith
                                        </Text>{' '}
                                        added notes to the image
                                    </Text>
                                    <Text size='xs' mt={4}>
                                        12 minutes ago
                                    </Text>
                                </Timeline.Item>

                                <Timeline.Item bullet={<IconPalette size={12} />} title='Retouched'>
                                    <Text color='dimmed' size='sm'>
                                        You&apos;ve uploaded the image and marked as Retouched and{' '}
                                        <Text variant='link' component='span' inherit>
                                            assigned to JOB001
                                        </Text>
                                    </Text>
                                    <Text size='xs' mt={4}>
                                        52 minutes ago
                                    </Text>
                                </Timeline.Item>

                                <Timeline.Item title='Downloaded' bullet={<IconDownload size={12} />} lineVariant='dashed'>
                                    <Text color='dimmed' size='sm'>
                                        <Text variant='link' component='span' inherit>
                                            Robert Smith
                                        </Text>{' '}
                                        downloaded hi-res of this image
                                    </Text>
                                    <Text size='xs' mt={4}>
                                        34 minutes ago
                                    </Text>
                                </Timeline.Item>
                            </Timeline>
                        </Box>
                    </Tabs.Panel>
                </Tabs>
            </div>
        </Box>
    );
};

export default InfoPanel;

export function TalentList({ data }) {
    const rows = data.map((item) => (
        <tr key={item.name}>
            <td>
                <Group spacing='sm'>
                    <Avatar size={40} src={item.avatar} radius={40} />
                    <div>
                        <Text size='sm' weight={500}>
                            {item.name}
                        </Text>
                        <Text size='xs' color='dimmed'>
                            {item.email}
                        </Text>
                    </div>
                </Group>
            </td>

            {/* <td>
        
      </td>
      <td>{Math.floor(Math.random() * 6 + 5)} days ago</td>
      <td>
        {Math.random() > 0.5 ? (
          <Badge fullWidth>Active</Badge>
        ) : (
          <Badge color="gray" fullWidth>
            Disabled
          </Badge>
        )}
      </td> */}
        </tr>
    ));

    return (
        <Table verticalSpacing='sm'>
            {/* <thead>
          <tr>
            <th>Employee</th>
            <th>Role</th>
            <th>Last active</th>
            <th>Status</th>
          </tr>
        </thead> */}
            <tbody>{rows}</tbody>
        </Table>
    );
}

import { Box, Button, Container, Grid, Group, Input, MultiSelect, Textarea, Title } from '@mantine/core';
import { connect } from 'react-redux';
import { FormInputWrapper, TagInput } from 'core/components';
import { AppConstants } from '../../../../utils/constants';
import { selectSession } from 'modules/Sessions/sessionSlice';
import { useEffect, useState } from 'react';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';

const SeasonTabGeneral = ({ user, season, success, error, form, onFormSubmit }) => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (season && season.tags && tags.length === 0) {
            var tmpTags = season.tags.map((val) => {
                return { label: val, value: val };
            });
            setTags([...tmpTags]);
        }
    }, [season, tags.length]);

    return (
        <Container fluid sx={{ marginLeft: 0 }}>
            <Grid>
                <Grid.Col span={8}>
                    <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                        <Box p='xl'>
                            <Title order={5} mb='lg'>
                                General Details
                            </Title>

                            {/* <FormInputWrapper label='ID' id='input-sku'>
              <Input id="input-sku" required placeholder="Enter ID" variant={AppConstants.inputVariant} />
            </FormInputWrapper> */}

                            <FormInputWrapper label='Name' id='input-sku'>
                                <Input id='input-name' required placeholder='Enter Name' variant={AppConstants.inputVariant} {...form.getInputProps('name')} />
                                {/* <TextInput id='input-desc'
                {...form.getInputProps('name')}
                placeholder="Description" variant={AppConstants.inputVariant}
              /> */}
                                {/* <TextInput id="input-name" required placeholder="Enter Name" variant={AppConstants.inputVariant} {...form.getInputProps('name')} /> */}
                            </FormInputWrapper>

                            <FormInputWrapper label='Description' id='input-desc'>
                                <Textarea id='input-desc' {...form.getInputProps('description')} placeholder='Description' variant={AppConstants.inputVariant} />
                            </FormInputWrapper>

                            <FormInputWrapper label='Tags' id='input-tags'>
                                <TagInput
                                    {...form.getInputProps('tags')}
                                    onChange={(val) => {
                                        setTags([...val.map((item) => ({ value: item, label: item }))]);
                                        form.setFieldValue('tags', val);
                                    }}
                                />
                                {/* <MultiSelect
                                    size='md'
                                    data={tags}
                                    placeholder='Enter Tags'
                                    searchable
                                    creatable
                                    {...form.getInputProps('tags')}
                                    getCreateLabel={(query) => `+ Create ${query}`}
                                    onCreate={(query) => {
                                        const item = { value: query, label: query };
                                        setTags((current) => [...current, item]);
                                        return item;
                                    }}
                                /> */}
                            </FormInputWrapper>

                            <FormInputWrapper label='Deadline' id='input-tags'>
                                <Group>
                                    <CustomDatePicker placeholder='Due Date' {...form.getInputProps('deadline')} />
                                </Group>
                            </FormInputWrapper>

                            <Group position='right'>
                                <Button type='submit'>Update Season</Button>
                            </Group>
                        </Box>
                    </form>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        session: state.sessionReducer.selectedSession,
        success: state.sessionReducer.success,
        error: state.sessionReducer.error,
    };
};

const mapDispatch = { selectSession };

export default connect(mapStateToProps, mapDispatch)(SeasonTabGeneral);

import { Avatar, Box, Button, CloseButton, MultiSelect, Popover, Stack, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useState } from 'react';
import { updateCapture } from 'services/approvalService';
import { getTalents, updateTalent } from 'services/talentService';
import { logger } from 'utils/logger';
import { showNotification } from '@mantine/notifications';
import { TalentModel } from 'core/models';

const TagTalent = ({ talents, captures, children, opened, onChange }) => {
    const [talent, setTalent] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchTalent = async () => {
            const result = await getTalents();
            setTalent(result);
        };

        if (opened) {
            fetchTalent();
        }
    }, [opened]);

    const form = useForm({
        initialValues: {
            talent: [],
        },

        validate: {
            talent: (value) => (value.length ? null : 'Please select a talent'),
        },
    });

    const onSubmit = async (values) => {
        try {
            // for each capture update the studio actions.
            var data;
            setIsSaving(true);
            for (let i = 0; i < captures.length; i++) {
                const capture = captures[i];
                const payload = { actionPart: capture.actionPart, actionSort: capture.actionSort, talentCodes: values.talent.join(',') };

                data = await updateCapture(payload);
                            
            }

            if(data){
                showNotification({
                    title: 'Success',
                    message: 'Talent has been tagged',
                    position: 'top-center',
                    color: 'teal',
                });
                onChange(false);    
            }

            setIsSaving(false);
        } catch (e) {
            logger.error('unable to update capture');
            setIsSaving(false);
            showNotification({
                title: 'Whoops something went wrong.',
                message: 'Unable to update capture',
                color: 'red',
            });
        }
    };

    function splitName(name) {
        // Split the name into parts
        let parts = name.trim().split(/\s+/);

        // Check if there are more than 1 part
        if (parts.length > 1) {
            // If there's a middle name, add it to the first name
            if (parts.length > 2) {
                parts[0] += ' ' + parts.slice(1, -1).join(' ');
            }
            // Assign last name
            let lastName = parts[parts.length - 1];
            // Remove the middle name if exists
            parts = [parts[0], lastName];
        }

        return { first: parts[0], last: parts[1] ?? '' };
    }

    // const [opened,setOpened] = useState(false);
    const onCreate = async (query) => {
        const item = { value: query, label: query };
        // need to add the talent
        const name = splitName(query);

        var payload = new TalentModel({ first: name.first, last: name.last });
        const data = await updateTalent(payload);
        logger.log(data);

        setTalent((current) => [...current, data]);
    };

    return (
        <Popover opened={opened} onChange={onChange} trapFocus withArrow shadow='md'>
            <Popover.Target>{children}</Popover.Target>
            <Popover.Dropdown>
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Stack sx={{ maxWidth: 400 }}>
                        <Title order={6} align='left'>
                            Select Talent
                        </Title>

                        <MultiSelect
                            valueComponent={Value}
                            {...form.getInputProps('talent')}
                            itemComponent={Item}
                            searchable
                            data={talent.map((val) => ({ value: val.code, label: `${val.first} ${val.last}`, agency: val.agency, latestURL: val.latestURL }))}
                            getCreateLabel={(query) => `+ Create ${query}`}
                            creatable
                            onCreate={(query) => {
                                const item = { value: query, label: query };
                                // need to add the talent
                                onCreate(query);
                                // setTalent((current) => [...current, item]);
                                return item;
                            }}></MultiSelect>
                        <Button type='submit' loading={isSaving} fullWidth>
                            Done
                        </Button>
                    </Stack>
                </form>
            </Popover.Dropdown>
        </Popover>
    );
};

export default TagTalent;

function Value({ value, label, onRemove, classNames, latestURL, ...others }) {
    return (
        <div {...others}>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    cursor: 'default',
                    alignItems: 'center',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
                    paddingLeft: 10,
                    borderRadius: 4,
                })}>
                <Box mr={5}>
                    <Avatar size={'sm'} src={latestURL} />
                </Box>
                <Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
                <CloseButton onMouseDown={onRemove} variant='transparent' size={22} iconSize={14} tabIndex={-1} />
            </Box>
        </div>
    );
}

const Item = forwardRef(({ label, value, latestURL, agency, ...others }, ref) => {
    return (
        <div ref={ref} {...others}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={10}>
                    <Avatar src={latestURL} />
                </Box>
                <Box>
                    <Text>{label}</Text>
                    <Text size={'xs'} color='dimmed'>
                        {agency.length ? agency : 'No agency'}
                    </Text>
                </Box>
            </Box>
        </div>
    );
});

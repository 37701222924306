import { Kbd, Table } from '@mantine/core';

const Shortcuts = props => {
 
  return (
    <>

    <Table>
      <thead>
        <tr>
          <th>Shortcut</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Kbd>+</Kbd></td>
          <td>Zoom in</td>
        </tr>
        <tr>
          <td><Kbd>-</Kbd></td>
          <td>Zoom Out</td>
        </tr>
        <tr>
          <td><Kbd>0</Kbd></td>
          <td>Zoom All</td>
        </tr>
        <tr>
          <td><Kbd>r</Kbd></td>
          <td>Rotate clockwise</td>
        </tr>
        <tr>
          <td><Kbd>R</Kbd></td>
          <td>Rotate counterclockwise</td>
        </tr>
        <tr>
          <td><Kbd>← → ↑ ↓</Kbd></td>
          <td>Move viewport when zoomed</td>
        </tr>
        <tr>
          <td><Kbd>shift</Kbd> + <Kbd>Mouse Down</Kbd></td>
          <td>Create an image markup</td>
        </tr>
        <tr>
          <td><Kbd>1,2,3,4,5</Kbd></td>
          <td>Change a capture's star rating</td>
        </tr>
        <tr>
          <td><Kbd>[ ]</Kbd></td>
          <td>Change a capture's flag status</td>
        </tr>
      </tbody>
    </Table>
    </>
  );
}

export default Shortcuts;
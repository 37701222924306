import { ActionIcon, Badge, Box, Button, Card, Center, Group, Image, Overlay, Select, Stack, Switch, Text, TextInput, Textarea, Tooltip, useMantineTheme } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import { openModal } from '@mantine/modals';
import { IconFile, IconGripVertical, IconTrash, IconUpload, IconX, IconZoomIn } from '@tabler/icons';
import CaptureDetail from 'core/components/capture/CaptureDetail';
import { ImagePreview } from 'core/components/index';
import { useEffect, useState } from 'react';
import { shotChannels, shotTags } from 'utils/constants';
import { getImageFile } from 'utils/helper';
import { logger } from 'utils/logger';

const GuideShot = (props) => {
    const { classes, shot, index, allowAssignment, editable = false, onChange } = props;
    const theme = useMantineTheme();

    const [files, setFiles] = useState([]);

    const focusTrapRef = useFocusTrap();
    const [shotImage, setShotImage] = useState();
    const [shotURL, setShotURL] = useState('');

    const form = useForm({
        initialValues: {
            ...shot,
        },
    });

    const previews = files.map((file, index) => {
        const imageUrl = file.url ? file.url : URL.createObjectURL(file);
        return (
            <Group key={index} className={classes.preview} sx={{ position: 'relative' }} align='center' position='center'>
                <Image key={index} src={imageUrl} className={classes.image} alt={file.name} height={200} withPlaceholder onLoad={() => URL.revokeObjectURL(imageUrl)} />

                <Overlay className='overlay' color='#000' />
                <Group sx={{ position: 'absolute', zIndex: 9999 }}>
                    <ActionIcon variant='filled' radius={'xl'} size={'xl'} className='btn-clear' onClick={() => zoomImage({ previewHi: shot.referenceUrl, originalFilename: '' }, shot, true)}>
                        <IconZoomIn />
                    </ActionIcon>
                    <ActionIcon variant='filled' radius={'xl'} size={'xl'} className='btn-clear' onClick={() => setFiles([])}>
                        <IconTrash />
                    </ActionIcon>
                </Group>
            </Group>
        );
    });

    const required = shot.req === 'yes' ? true : false;

    useEffect(() => {
        if (shot.referenceUrl !== shotURL) {
            getImageFile(shot.referenceUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    setShotImage(url);
                    // create a file.
                    const tmpFile = { name: shot.seq, url: url };
                    setFiles([tmpFile]);

                    setShotURL(shot.referenceUrl);
                });
        }
    }, [shot]);

    const zoomImage = (data, shot, resolve = false) => {
        logger.log(data);
        openModal({
            title: shot.label,
            size: '100%',
            children: (
                <>
                    <CaptureDetail showSettings={false} resoloveImage={resolve} image={data} src={data?.previewHi} onCaptureChange={() => {}} scaleToFit={true} />
                </>
            ),
        });
    };

    return (
        <Card withBorder className={classes.card} ref={focusTrapRef} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card.Section className={'cardSection'}>
                {shot.req === 'yes' ? (
                    <Badge size='xs' className={classes.requireBadge}>
                        Required
                    </Badge>
                ) : null}

                <Group align='center' position='center'>
                    {editable ? (
                        <>
                            {files.length ? (
                                <div>{previews}</div>
                            ) : (
                                <Dropzone
                                    sx={{ height: 200 }}
                                    onReject={(files) => logger.log('rejected files', files)}
                                    maxSize={3 * 1024 ** 2}
                                    accept={IMAGE_MIME_TYPE}
                                    onDrop={(val) => {
                                        setFiles(val);
                                        props.onChange(shot, val, 'files');
                                    }}>
                                    <Group position='center' spacing='sm' style={{ minHeight: 150, pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload size={50} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <IconFile size={50} stroke={1.5} />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text size='xl' inline>
                                                Drag your file here or click to select
                                            </Text>
                                            <Text size='sm' color='dimmed' inline mt={7}>
                                                The file should not exceed 50mb
                                            </Text>
                                        </div>
                                    </Group>
                                </Dropzone>
                            )}
                        </>
                    ) : (
                        <Center sx={{ width: 'auto', minWidth: 150 }}>
                            <ImagePreview onClick={(e) => zoomImage(e, shot)} height={200} file={{ url: shotImage, name: shot.name }} />
                            {/* <Image
                                className={classes.image}
                                src={shotImage}
                                alt={shot.name}
                                height={200}
                                withPlaceholder
                                // placeholder={<Box sx={{width:200}}><Text>Missing Image</Text></Box>}
                            /> */}

                            {/* <ResolverImage radius={'sm'} height={200} src={shot.referenceUrl} onLoaded={(val) => {
                                const tmpFile = { name: shot.seq, url: val };
                                logger.log(tmpFile, ' TMP FILE')
                                setFiles([tmpFile]);            
                            }} /> */}
                        </Center>
                    )}
                </Group>
                {editable ? (
                    <div className={classes.gripWrapper}>
                        <Badge size='lg' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                            <span className={classes.seqNum}>{shot.seq}</span>
                        </Badge>
                        <Tooltip label='Drag to change order'>
                            <Box className={[classes.grip]}>
                                <IconGripVertical size={14} color='white' />
                            </Box>
                        </Tooltip>
                    </div>
                ) : (
                    <Badge size='lg' variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} className={classes.imageBadge}>
                        {shot.seq}
                    </Badge>
                )}
            </Card.Section>

            {editable ? (
                <Group position='left' spacing={'xs'} mt='md' noWrap sx={{ alignItems: 'start' }}>
                    <Stack style={{ flexGrow: 1 }}>
                        <TextInput
                            required
                            data-autofocus
                            label='Shot Title'
                            placeholder='Shot Title'
                            mb={'xs'}
                            className={classes.title}
                            size={'sm'}
                            weight={700}
                            // value={shot.label}
                            // onChange={(e) => props.onChange(shot, e.currentTarget.value, 'label')}
                            {...form.getInputProps('label')}
                            onBlur={(e) => props.onChange(shot, e.currentTarget.value, 'label')}
                        />
                        <Textarea
                            label='Shot Notes'
                            placeholder='Notes'
                            className={classes.title}
                            size={'sm'}
                            {...form.getInputProps('notes')}
                            onBlur={(e) => props.onChange(shot, e.currentTarget.value, 'notes')}
                            // value={shot.notes}
                            // onChange={(e) => props.onChange(shot, e.currentTarget.value, 'notes')}
                        />
                        <Select label='Channel' placeholder='Select channel' value={shot.channel} data={shotChannels} onChange={(value) => props.onChange(shot, value, 'channel')} />
                        <Select label='Tag' placeholder='Select Tag' value={shot.tag} data={[{ label: 'None', value: '' }, ...shotTags]} onChange={(value) => props.onChange(shot, value, 'tag')} />
                        <Switch label='Required' checked={required} onChange={(e) => props.onChange(shot, e.currentTarget.checked ? 'yes' : 'no', 'req')} />
                        <Button color='red' variant='light' onClick={() => props.onRemoveClick(shot)}>
                            Remove
                        </Button>
                    </Stack>
                </Group>
            ) : (
                <div style={{ flexGrow: 1, alignItems: 'stretch', display: 'flex', flexDirection: 'column' }}>
                    <Group position='left' spacing={'xs'} mt='md' noWrap sx={{ alignItems: 'stretch', width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flexShrink: 0 }}>
                            <Text className={classes.title} size={'sm'}>
                                {shot.seq}.
                            </Text>
                        </div>

                        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Group position='apart' mb={'sm'}>
                                <Text className={classes.title} size={'sm'} weight={700}>
                                    {shot.label}
                                </Text>
                                {/* <Badge>{shot.channel}</Badge> */}
                            </Group>

                            <Text className={classes.title} size={'sm'} sx={{ flexGrow: 1 }} color={shot.notes ? '' : 'dimmed'}>
                                {shot.notes ? shot.notes : 'No Notes'}
                            </Text>

                            <Group className={classes.footer}>
                                <Group spacing={'xs'}>
                                    <Text size='xs' color='dimmed'>
                                        Tag:
                                    </Text>
                                    <Text fw={500} size='sm'>
                                        {shot.tag && shot.tag.length ? shot.tag : 'None'}
                                    </Text>
                                </Group>

                                <div>
                                    {/* <Text size='xs' color='dimmed'>
                                Channel
                            </Text> */}
                                    <Badge>{shot.channel}</Badge>
                                </div>
                            </Group>
                        </div>
                    </Group>
                </div>
            )}
        </Card>
    );
};

export default GuideShot;

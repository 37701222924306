import { v4 as uuidv4 } from 'uuid';

export class CaptureModel{
    // captureId,name,description,url,star,sak,clientTags,selected
    constructor (obj){
        // this.captureId = captureId?captureId:uuidv4();
        // this.name = name?name:'';
        // this.description = description?description:'';
        // this.url = url?url:'';
        // this.star = star?star:0;
        // // this.flag = flag?flag:0;
        // this.isSelected = selected?selected:false;
        // this.sak = sak?sak:-1;
        // this.clientTags = clientTags?clientTags.split():[];
        this.actionPart = "";
        this.actionSort = "";
        this.captureId = uuidv4();
        this.sessionId = "";
        this.previewHi = "";
        this.previewLo = "";
        this.previewMid = "";
        this.name = '';
        this.description = '';
        this.url = '';
        this.star = 0;
        // this.flag = flag?flag:0;
        this.isSelected = false;
        this.sak = -1;
        this.clientTags = [];
        this.annotations = [];
        
        if (obj) {
            Object.assign(this, obj);
            this.star = parseInt(this.star);
            this.sak = parseInt(this.sak);
        }
    }
    
}
import { Group, Text, UnstyledButton, Code, createStyles } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { openModal } from '@mantine/modals';
import SpotlightForm from './SpotlightForm';
import { useEffect } from 'react';
// import type { SpotlightAction } from '@mantine/spotlight';

// const useStyles = createStyles((theme, _params, getRef) => ({
//     spotlightItem: {
//         width: '100%',
//         '&:hover': {
//             background: theme.colors.dark[5],
//         },
//         borderRadius: theme.radius.sm,
//     },
// }));

const SpotlightControl = () => {
    // const spotlight = useSpotlight();
    // const classes = useStyles();

    const openGlobalSearch = () => {
        openModal({
            title: '',
            size:'lg',
            withCloseButton: false,
            className: 'spotlight-modal',
            children: (
                <>
                    <SpotlightForm />
                </>
            ),
        });
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
          // Check if the pressed key is 'K' and if the Command key is also pressed
          if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
            event.preventDefault(); // Prevent the default browser behavior
            openGlobalSearch(); // Call your function here
          }
        };
    
        // Add event listener
        window.addEventListener('keydown', handleKeyPress);
    
        // Cleanup function to remove event listener
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, []); // 

    return (
        <Group position='center'>
            {/* <UnstyledButton onClick={spotlight.openSpotlight}>test</UnstyledButton> */}
            <UnstyledButton onClick={openGlobalSearch}>
                <Group
                    sx={(theme) => ({
                        // subscribe to color scheme changes
                        // height: '34px',
                        borderRadius: `${theme.radius.md}px`,
                        color: theme.colors.gray[6],
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
                        border: `1px solid ${theme.colors.gray[4]}`,
                        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4],
                        padding: `${theme.spacing.sm / 2}px ${theme.spacing.sm / 2}px`,
                    })}
                    spacing='xs'>
                    <IconSearch size={20} color='grey' />
                    <Text sx={{ width: 200 }} size='sm'>
                        Quick Style Lookup
                    </Text>
                    <Code
                        sx={(theme) => ({
                            // subscribe to color scheme changes
                            border: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[3]}` : `1px solid ${theme.colors.gray[2]}`,
                        })}>
                        ⌘ + K
                    </Code>
                    {/* <Text size="xs" color="gray">bob@handsome.inc</Text> */}
                </Group>
            </UnstyledButton>
        </Group>
    );
}

export default SpotlightControl
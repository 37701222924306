import { useDisclosure } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import { getStyleStatusColor } from 'utils/helper';

const { Popover, Text, Group, Button, createStyles, Box, Badge, Image } = require('@mantine/core');

const useStyles = createStyles((theme) => ({
    card: {
        //   margin:-theme.spacing.md,
        padding: 0,
        position: 'relative',
    },

    imageSection: {
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    },

    label: {
        marginBottom: theme.spacing.xs,
        lineHeight: 1,
        fontWeight: 700,
        fontSize: theme.fontSizes.xs,
        //   letterSpacing: -0.25),
        textTransform: 'uppercase',
    },

    section: {
        paddingTop: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        margin: 0,
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },
}));

const TalentPopover = (props) => {
    const [opened, { close, open }] = useDisclosure(false);
    const [data, setData] = useState();
    const { classes } = useStyles();
    const popRef = useRef();
    const doClose = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (opened) {
            logger.log(props.data, data);

            setData(props.data);
            // async function fetchSample() {
            //     const val = await getStyle(props.data.stylePart);
            //     // setData(val);
            //     setData({ ...props.data, ...val });
            // }
            // // fetch the sample
            // if (!data || (data && data.styleSort !== props.data.styleSort && opened)) fetchSample();
        }
    }, [opened]);

    const mouseHandler = (e) => {
        setTimeout(() => {
            if (doClose.current) {
                close();
                doClose.current = true;
            }
        }, 200);
    };

    const showDetails = () => {
        navigate(`/talent/${props.data.code}`);
        props.onShowDetails();
    };

    return (
        <Popover withinPortal={true} width={300} position='bottom-end' withArrow shadow='md' opened={opened}>
            <Popover.Target>
                <div ref={popRef} onMouseEnter={open} onMouseLeave={(e) => mouseHandler(e)}>
                    {props.children}
                </div>
            </Popover.Target>

            <Popover.Dropdown sx={{ pointerEvents: 'initial' }}>
                {data ? (
                    <Box
                        className={classes.card}
                        style={{ padding: 0 }}
                        onMouseLeave={() => {
                            close();
                            doClose.current = true;
                        }}
                        onMouseEnter={() => {
                            open();
                            doClose.current = false;
                        }}>
                        <Box className={classes.imageSection}>
                            {/* <Image src="https://i.imgur.com/ZL52Q2D.png" /> */}
                            <Image width={120} height={180} src={data.latestURL} />
                        </Box>

                        <Group position='apart' mt='md'>
                            <div>
                                <Text fw={500}>{data?.fullName}</Text>
                                <Text color='dimmed' size={'sm'} lineClamp={2}>
                                    {data?.code}
                                </Text>
                            </div>
                            <Badge color={getStyleStatusColor(data?.status)} variant='light'>
                                {data?.status}
                            </Badge>
                        </Group>

                        <Box className={classes.section} mt='md'>
                            <Group mt={'sm'} position='apart' align='start'>
                                <Box>
                                    <Text size={'xs'} color='dimmed'>
                                        Agency
                                    </Text>
                                    <Text size={'sm'}>{data?.agency}</Text>
                                </Box>
                                <Box>
                                    <Text size={'xs'} color='dimmed'>
                                        Gender
                                    </Text>
                                    <Text size={'sm'}>{data?.gender}</Text>
                                </Box>
                                <Box>
                                    <Text size={'xs'} color='dimmed'>
                                        Age
                                    </Text>
                                    <Text size={'sm'}>{data && data.age && data.age.length?data.age:'-'}</Text>
                                </Box>
                            </Group>
                        </Box>
                       
                        <Box className={classes.section}>
                            <Group spacing={30}>
                                <Button style={{ flex: 1 }} onClick={showDetails}>
                                    View Details
                                </Button>
                            </Group>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Text size={'sm'} color='dimmed'>
                            There is no talent information available
                        </Text>
                    </Box>
                )}
            </Popover.Dropdown>
        </Popover>
    );
};

export default TalentPopover;

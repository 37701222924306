import { Box, Text } from '@mantine/core';
import {
    assignStylesToSessionSuccess,
    clearNotifications,
    createSeasonSuccess,
    createSessionSuccess,
    fetchSeasonStyles,
    fetchSeasons,
    removeStyleFromSeasonSuccess,
    selectSeasons,
    setSeasonFilter,
    updateSeasonStyleSuccess,
    updateSeasonSuccess,
    uploadCSVToSeasonSuccess,
} from 'modules/Seasons/seasonSlice';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Seasons from './features/seasons/Seasons';
import Season from './features/season/Season';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { closeAllModals, openConfirmModal } from '@mantine/modals';
import Dashboard from './features/dashboard/Dashboard';
import { useAbortController } from 'core/hooks/useAbortableController';

const SeasonsModule = ({
    user,
    season,
    seasons,
    selectedSamples,
    success,
    error,
    setSampleFilter,
    seasonFilter,
    selectSamples,
    setSeasonFilter,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const abortController = useAbortController();

    // SUCCESS AND ERROR HANDLERS
    useEffect(() => {
        if (error) {
            showNotification({
                title: 'Whoops something went wrong.',
                message: error.message,
                position: 'top-center',
                color: 'red',
            });

            dispatch(clearNotifications());
        }

        if (success) {
            switch (success.type) {
                case updateSeasonSuccess.toString(): {
                    // push the user to the session detail page.
                    if (season) {
                        showNotification({
                            title: 'Success',
                            message: success.message,
                            position: 'top-center',
                            color: 'teal',
                        });

                        // navigate(`/sessions/${session.code}`);
                        dispatch(clearNotifications());
                    }
                    break;
                }

                case createSessionSuccess.toString():
                case createSeasonSuccess.toString(): {
                    // close all modals
                    closeAllModals();

                    // refetch the season
                    dispatch(fetchSeasons());

                    showNotification({
                        title: 'Success',
                        message: success.message,
                        position: 'top-center',
                        color: 'teal',
                    });

                    // navigate(`/sessions/${session.code}`);
                    dispatch(clearNotifications());

                    // we show a notification to switch to the session
                    if (success.type === createSessionSuccess.toString()) {
                        openConfirmModal({
                            title: 'Session successfully created',
                            children: (
                                <Text size='sm'>
                                    Would you like to view this Session:
                                    {success.data.name} now?
                                </Text>
                            ),
                            labels: {
                                confirm: 'View Session',
                                cancel: 'Not now',
                            },
                            onCancel: () => console.log('Cancel'),
                            onConfirm: () =>
                                navigate(`/sessions/${success.data.code}`),
                        });
                    }

                    break;
                }
                case updateSeasonStyleSuccess.toString():
                case removeStyleFromSeasonSuccess.toString():
                case assignStylesToSessionSuccess.toString():
                case uploadCSVToSeasonSuccess.toString(): {
                    closeAllModals();

                    showNotification({
                        title: 'Success',
                        message: success.message,
                        position: 'top-center',
                        color: 'teal',
                    });

                    // navigate(`/sessions/${session.code}`);
                    dispatch(clearNotifications());

                    // refetch the season styles
                    setTimeout(() => {
                        dispatch(fetchSeasonStyles(season.id, null, abortController.current.signal));
                    }, 2000);

                    break;
                }

                default:{
                    break;
                }
            }
        }

        // if the session has been created then we load the session detail view
    }, [error, success, dispatch, clearNotifications, season]);

    return (
        <Box
            id='seasonsModule'
            sx={(theme) => ({
                backgroundColor:
                    theme.colorScheme === 'dark'
                        ? theme.colors.dark[8]
                        : theme.white,
                width: '100%',
                height: '100%',
            })}>
            <Box sx={{ height: '100%' }}>
                <Routes>
                    <Route path='/' element={<Seasons />}></Route>
                    <Route path='/dashboard' element={<Dashboard />}></Route>
                    <Route path='/:id' element={<Season />}></Route>
                    {/* <Route path="/" element={<Navigate replace to="/seasons" />} /> */}
                </Routes>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        season: state.seasonReducer.selectedSeason,
        seasons: state.seasonReducer.seasons,
        seasonFilter: state.seasonReducer.seasonFilter,
        selectedSeasons: state.seasonReducer.selectedSeasons,
        success: state.seasonReducer.success,
        error: state.seasonReducer.error,
    };
};

const mapDispatchToProps = { setSeasonFilter, selectSeasons };

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsModule);

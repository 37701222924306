import { Box, createStyles, Group } from '@mantine/core';
import React, { useState, useRef, useEffect } from 'react';
import { FlagRating } from 'utils/constants';
import { FlagIcon } from './FlagIcon';
import { FlagIconReject } from './FlagIconReject';


const useStyles = createStyles((theme) => ({
  container:{

  },
  flagIcon:{
    size:14,
    stroke:theme.colors.gray[6],
    fill:theme.colors.gray[6],
    '&:hover':{
      stroke:theme.colors.gray[5],
      fill:theme.colors.gray[5],
    },
    '&.selected':{
      stroke:theme.colors.green[5],
      fill:theme.colors.green[5],
    },
    '&.reject-flg.selected':{
      stroke:theme.colors.pink[6],
      fill:theme.colors.pink[6],
    }
  }
}));


const FlagToggle = ({size,onChange,status}) => {
  const [acceptFlag, setAcceptFlag] = useState(status === FlagRating.FLAG_SELECT?true:false);
  const [rejectFlag, setRejectFlag] = useState(status === FlagRating.FLAG_KILL?true:false);
  
  const ratingContainerRef = useRef(null);
  // const theme = useMantineTheme();
  const { classes } = useStyles();

  const flagSize = size === 'sm'?10:14;

  const getIconClass = (isSelected) => {

    let cls = `${classes.flagIcon} ${isSelected?'selected':''}`;
    
    return cls
  }

  useEffect(() => {
    setAcceptFlag(status && status.toString() === FlagRating.FLAG_SELECT?true:false);
    setRejectFlag(status && status.toString() === FlagRating.FLAG_KILL?true:false);
  },[status])



  const selectedClass = `accept-flg ${getIconClass(acceptFlag)}`;
  const rejectedClass = `reject-flg ${getIconClass(rejectFlag)}`;

  const onAcceptClick = props => {
    if(!acceptFlag)setRejectFlag(false);
    setAcceptFlag(!acceptFlag);
    onChange(FlagRating.FLAG_SELECT);
  }

  const onRejectClick = props => {
    if(!rejectFlag)setAcceptFlag(false);
    setRejectFlag(!rejectFlag);
    onChange(FlagRating.FLAG_KILL);
  }

  return (
    <Box
      className=""
      sx={{
        display: 'inline-flex',
        position: 'relative',
        cursor: 'pointer',
        textAlign: 'left'
      }}
      
    //   onMouseMove={handleMouseMove}
    //   onMouseLeave={handleMouseLeave}
      ref={ratingContainerRef}
    >
    
        {/* // const activeState = isHovered ? hoverActiveStar : activeFlag;

        // const showEmptyIcon = activeState === -1 || activeState < index + 1;

        // const isActiveRating = activeState !== 1;
        // const isRatingWithPrecision = activeState % 1 !== 0;
        // const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        // const showRatingWithPrecision =
        //   isActiveRating && isRatingWithPrecision && isRatingEqualToIndex; */}

        
          <Box
            position={'relative'}
            sx={{
              cursor: 'pointer',
              lineHeight:1,
            //   height:30,
            }}
            
          >
            {/* <Box
              sx={{
                width: showRatingWithPrecision ? `${(activeState % 1) * 100}%` : '0%',
                overflow: 'hidden',
                position: 'absolute',
                lineHeight:1,
              }}
            >
              <FilledIcon size={12} style={starStyle} />
            </Box> */}
            {/*Note here */}
            <Group spacing={'xs'} noWrap
              sx={{
                gap:5
                // color: showEmptyIcon ? 'gray' : 'inherit'
              }}
            >
              <div onClick={onAcceptClick}><FlagIcon size={flagSize} className={selectedClass} /></div>
              <div onClick={onRejectClick}><FlagIconReject size={flagSize} className={rejectedClass} /></div>
              {/* {activeFlag ? <EmptyIcon fill="currentColor" strokeColor="currentColor" size={12} /> : <FilledIcon  fill={theme.colors.brand[4]} strokeColor={theme.colors.brand[4]} />} */}
            </Group>
          </Box>
       
      
    </Box>
  );
};

export default FlagToggle;
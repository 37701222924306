import { CaptureModel, ScanModel } from 'core/models';
import { TalentModel } from 'core/models/TalentModel';
import { getToken } from 'utils/helper';
import { logger } from 'utils/logger';

export const getTalent = async(id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/talent/${id}`;

    const formData = JSON.stringify({});

    logger.log(' GETTING SCAN DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            var items = data.Items.map((item, idx) => {
                // const keys = Object.keys(item);
                // var newItem = {};
                // keys.forEach((key, idx) => {
                //     let tmpKey = key.replace(' ', '');
                //     tmpKey = key.replace(':', '_');
                //     // lowercase the first.
                //     let char = tmpKey.charAt(0).toLowerCase();
                //     let subStr = tmpKey.substring(1, tmpKey.length);
                //     const newKey = `${char}${subStr}`;
                //     newItem[newKey] = item[key]['S'];
                    
                // });
                // newItem.fullName = `${newItem.first} ${newItem.last}`
                const newItem = new TalentModel(item);
                newItem.id = newItem.talentSort;
                newItem.fullName = `${item.first} ${item.last}`
                return newItem;
            });
            if (items.length) {
                return items[0];
            } else {
                throw new Error('No talent found');
            }
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR getting talent', err);
            throw err;
        });
};

export const getTalents = async (criteria, token) => {
    var url = `${process.env.REACT_APP_API_URL}/talent`;

    if (criteria) {
        if (criteria.styleCode) {
            url += `/style/${criteria.styleCode}`;
        }
        if (criteria.status) {
            url += `/${criteria.status}`;
        }
    }

    const formData = JSON.stringify({});

    logger.log(' GETTING SCANS DATA: ', formData);
    return fetch(url, {
        headers: {
            hgio: getToken(),
            'Content-Type': 'application/json',
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            logger.log(data);
            var items = data.Items.map((item, idx) => {
                // const keys = Object.keys(item);
                // var newItem = {};
                // keys.forEach((key, idx) => {
                //     let tmpKey = key.replace(' ', '');
                //     tmpKey = key.replace(':', '_');
                //     // lowercase the first.
                //     let char = tmpKey.charAt(0).toLowerCase();
                //     let subStr = tmpKey.substring(1, tmpKey.length);
                //     const newKey = `${char}${subStr}`;
                //     newItem[newKey] = item[key]['S'];
                //     newItem.id = newItem.scanId;
                // });
                return new TalentModel({...item,id:item.talentSort,fullName:`${item.first} ${item.last}`});
            });

            logger.log(items, ' getScans');
            return items;
        })
        .catch((err) => {
            console.error('ERROR getting scans', err);
            throw err;
        });
};


export const uploadCompFile = async (file, talent ) => {
    const url = `${process.env.REACT_APP_API_URL}/talent/${talent.id}/comp`;
    
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            // return response.json();
            return true;
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR udpating comp card', err);
            throw err;
        });
};


export const uploadPortfolioFile = async (file, talent ) => {
    const url = `${process.env.REACT_APP_API_URL}/talent/${talent.id}/portfolio`;
    
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'PUT',
        body: file,
    })
        .then((response) => {
            // return response.json();
            return true;
        })
        .then((data) => {
            logger.log(data);
        })
        .catch((err) => {
            console.error('ERROR udpating file', err);
            throw err;
        });
};

export const getTalentAssets = async (talentId,criteria, cursor) => {
    // let url = `${process.env.REACT_APP_API_URL}/studioactions/search?`; 
    let url = `${process.env.REACT_APP_API_URL}/talent/${talentId}/assets?`
    
    logger.log(criteria, ' ============ CRITERIA');
    // if(criteria.actionPart && criteria.actionPart.length){
    //     url += `machine/${criteria.actionPart}/`;
    // }else if(criteria.sessionId && criteria.sessionId.length){
    //     url += `session/${criteria.sessionId}`;
    // }else if(criteria.unpinned.toString().length){
    //     if(criteria.unpinned)
    //         url += `unpinned/`;
    // }
    if (criteria.sessionId && criteria.sessionId.length) {
        url += `sessionId=${criteria.sessionId}&`;
    }
    if (criteria.client && criteria.client.length) {
        url += `client=${criteria.client}&`;
    }
    if (criteria.star > -1) {
        url += `star=${criteria.star}&`;
    }
    if (criteria.sak > -1) {
        url += `sak=${criteria.sak}&`;
    }
    if (criteria.tags && criteria.tags.length) {
        url += `tags=${criteria.tags.toString()}&`;
    }

    if (criteria.machineId && criteria.machineId.length) {
        url += `machineId=${criteria.machineId}&`;
    }
    
    if (criteria.since) url += `since=${criteria.since}&`;
    if (criteria.until) url += `until=${criteria.until}`;
    
    if(cursor){
        url += `&cursor=${cursor}`;
    }

    url += '&limit=100'
    // const formData = JSON.stringify({

    // })

    logger.log(' GETTING Captures DATA: ', url);
    return fetch(url, {
        headers: {
            // 'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error fetching captures');
        })
        .then((data) => {
            logger.log(data);
           
            var items = data.Items.map((item, idx) => {
                var newItem = new CaptureModel({ ...item });
                newItem.previewHi = item.hiURL;
                newItem.previewLo = item.loURL;
                newItem.id = `${newItem.actionPart}__${newItem.actionSort}`;

                return newItem;
            });

            return {items,nextCursor:data['next-cursor'] === 'nil'?null:data['next-cursor']};
        })
        .catch((err) => {
            console.error('ERROR getting captures', err);
            throw err;
        });
};



export const updateTalent = async (talent) => {
    let url = talent.id ? `${process.env.REACT_APP_API_URL}/talent/${talent.id}` : `${process.env.REACT_APP_API_URL}/talent`;
    let tmpData = { ...talent };
    delete tmpData.id;
    delete tmpData.filename;
    delete tmpData.location;
    delete tmpData.filepath;
    delete tmpData.lon;
    delete tmpData.lat;
    delete tmpData.scanId;
    delete tmpData.url;
    delete tmpData.upBucket;
    delete tmpData.did;
    delete tmpData.ext;
    delete tmpData.concat;

    let formData = JSON.stringify({
        ...tmpData,
    });

    logger.log(' UPDATING TALENT DATA: ', formData);
    
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            hgio: getToken(),
        },
        method: talent.id ?'PUT':'POST',
        body: formData,
    })
        .then((response) => {
            return response.bodyUsed?response.json():{};
        })
        .then((data) => {
            logger.log(data);
            if(data.__type){
                throw(new Error('ERROR updating talent'))
            }
            return data;
            //return imgUploadUrl;
        })
        .catch((err) => {
            logger.error('ERROR updating talent', err);
            throw err;
        });
};

import { useMantineTheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import moment from 'moment';

const CustomDatePicker = (props) => {
    const theme = useMantineTheme();
    return (
        <DatePicker
            dayStyle={(date) => {
                const today = moment(new Date());
                const dateVal = moment(props.value);
                const dateVar = moment(date);

                if (today.isSame(dateVar, 'day') && !dateVal.isSame(dateVar)) {
                    return {
                        backgroundColor: theme.colors.gray[theme.fn.primaryShade()],
                        color: theme.white,
                        // ...theme.fn.hover({ backgroundColor: theme.colors.gray[7] }),
                    };
                } else {
                    return null;
                }
            }}            
            {...props}
        />
    );
};
export default CustomDatePicker;
import { useEffect, useState } from "react";
import { logger } from "utils/logger";

const { Rating } = require("@mantine/core")

const StarRatingExtended = ({size='xs',rating=-1,onChange,onClick}) => {

    const [value, setValue] = useState(-1);

    useEffect(() => {
        setValue(rating)
    },[rating])

    const onRatingChange = (val) => {
        logger.log(val,rating)
        if(val === 1 && rating ===1 ){
            onChange(-1);
        }else{
            onChange(val);
        }
    }

    const onStarClick = (e) => {
        logger.log(e);
        if(rating ===1 ){
            onChange(-1);
        }
    }
    return (
        <Rating size={size} onClick={(e) => onStarClick(e)} value={rating} onChange={(val) => onRatingChange(val)}  />
    )
}

export default StarRatingExtended;
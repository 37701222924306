import { Badge, Button, Group, Text, Tooltip } from "@mantine/core";

export const CountDisplay = ({ val, icon, tooltip, onClick, theme }) => {
    return (
        <Tooltip label={tooltip}>
            {val && parseInt(val) ? (
                <Button
                    onClick={onClick}
                    variant='gradient'
                    sx={{ fontSize: 11, height: 20, paddingTop: 0, paddingBottom: 0, lineHeight: 18, letterSpacing: 0.25, paddingLeft: 10.6, paddingRight: 10.6, width: 70 }}
                    radius={'xl'}
                    size='xs'
                    gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                    {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}> */}
                    <Group spacing={4}>
                        {icon}
                        <Text size={'sm'}>{val}</Text>
                    </Group>
                    {/* </Badge> */}
                </Button>
            ) : (
                <Badge color={val && val > 0 ? 'brand' : theme.colorScheme} sx={{ paddingTop: 11, paddingBottom: 11, width: 70 }}>
                    <Group spacing={4}>
                        {icon}
                        <Text color='dimmed' size={'sm'}>
                            0
                        </Text>
                    </Group>
                </Badge>
            )}
        </Tooltip>
    );
};

import React from 'react'

export function FlagIconReject({
  size = 14,
  strokeColor,
  storkeWidth = 0,
  className = 'flag-svg',
  fill,
  style = { display: 'inline' }
}) {

    
  return (
    // <svg fill={fill} width={size} height={size} viewBox='0 0 24 24' className={className} style={{ ...style }}>
    //   <path
    //     fill={fill}
    //     stroke={strokeColor}
    //     strokeMiterlimit='10'
    //     strokeWidth={storkeWidth}
    //     d='M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z'
    //   />
    // </svg>



<div className={className}>
<svg width={size} height={size} viewBox="0 0 16 16" strokeLinecap="round" strokeLinejoin="round">    
<g transform="matrix(1,0,0,1,0.5,0.5)">
        <g>
            <g transform="matrix(0.918368,1.38848e-17,-1.38848e-17,-0.804989,-1.11022e-15,15.0265)">
                <path d="M-0,5.441L16.333,5.441L16.333,18.667L0,18.667L-0,0.033" stroke={strokeColor} fill={fill} strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <g transform="matrix(1.37931,0,0,-1.37931,4.28161,8.53204)">
                <path d="M0,4.667L4.667,0M4.667,4.667L0,0" stroke="#000000" fill="none" strokeWidth="1.75px" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </g>
    </g>
</svg>
</div>



  )
}



// import BudgetProgress, { ProgressType } from 'components/budgetProgress/budgetProgress';
import { Box, Center, Container, createStyles, Grid, Title } from '@mantine/core';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Routes,
    Route,
    Navigate,
    useLocation
  } from "react-router-dom";

import Login from './features/login/Login';
// import logo from '../../assets/images/altitude-lg.png'
import logo from '../../assets/images/altitude-brand2.png'
import bgImage from '../../assets/images/bg-logo.svg';
import { authUserSuccess, cleanRequests } from './authSlice';
import { AltHistory } from '../../utils/history';
import { useNavigate } from 'react-router-dom';
import Register from './features/register/Register';
import Access from './features/access/Access';
import ForgotPassword from './features/login/ForgotPassword';
import { showNotification } from '@mantine/notifications';
import { setPreviousRoute } from './../../app/appSlice';

const useStyles = createStyles((theme) => ({
    container: {
        height:'100vh'
    },
    bgImageContainer:{
        height:'100vh',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'bottom right',
        // backgroundImage:`url(${bgImage})`,
    },
    welcomeContainer: {
        height:'100vh',
        textAlign:'center',
        
    },
    authWrapper: {
        height:'100vh',
        backgroundColor:'#20212F',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'bottom right',
        // background: theme.fn.linearGradient(45, '#20212F', '#181820'),
      
    },
    col: {
        display:'flex',
    },
    title:{
        color:'#ffffff',
        fontWeight:'300',
    }
  }));



const Auth = ({success,error,cleanRequests, previousRoute, setPreviousRoute}) => {

        
    const { classes } = useStyles();
    const history = AltHistory(useNavigate());
    const location = useLocation();

    useEffect(() => {
       
       if(success && success.type === authUserSuccess.toString()){

            // clear the auth response states so we don't loop
            cleanRequests();

            // take them through to the app but check if they have a previous route to redirect too
            const searchParams = new URLSearchParams(location.search);
            if(location.search && location.search.length && searchParams.get('redirect') && searchParams.get('redirect').length){
                const route = decodeURIComponent(searchParams.get('redirect'))
                history.push(route,true);                
            }else{
                history.push(`/`);
            }
            
       }

       if(error){
        showNotification({
            color:'red',
            title: 'Authentication error',
            message: error.message,
          })
       }
    },[success,error,cleanRequests,history]);


    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        <div className={classes.authWrapper} id="auth-management">
            <div className={classes.bgImageContainer}>
                <Container className={classes.container}>

                    <Grid className={classes.container} sx={{margin:0}}>
                        <Grid.Col span={6} className={classes.col}>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/forgotpassword" element={<ForgotPassword />} />
                                <Route path="/access/:setupPhrase"  element={<Access />} />
                                <Route exact path="/access/:setupPhrase"  element={<Access />} />
                                <Route exact path="/*" element={<Navigate to="/auth/login" replace />} />
                            </Routes>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Container className={classes.container}>
                                <Center className={classes.welcomeContainer}>
                                    <Box sx={{width:300}}>
                                        <img alt='Altitude Logo' src={logo} width='300' />
                                        <Title order={3} mt="xl" className={classes.title}>Photographic workflow made easy</Title>
                                    </Box>
                                </Center>
                            </Container>                            
                        </Grid.Col>
                    </Grid>           
                </Container>    
            </div>
        </div>
    )
    
};

const mapStateToProps = (state,props) => {
    return (
    {
       user: state.auth.user,
       success: state.auth.success,
       error: state.auth.error,
       previousRoute: state.appReducer.previousRoute       
    })
  }

const mapDispatch = { cleanRequests, setPreviousRoute }

export default connect(
    mapStateToProps,
    mapDispatch
  )(Auth)

import {  ActionIcon, createStyles, Divider, Group, Menu, Rating } from '@mantine/core';
import { IconDots, IconDownload, IconTransferIn, IconUserCheck } from '@tabler/icons';
import { FlagToggle, StarRating } from 'core/components/index';
import React from 'react';
import { logger } from 'utils/logger';
import { CaptureEventType } from './CaptureThumb';

const useStyles = createStyles((theme) => ({
    container:{
        padding:'3px 10px',
        paddingRight:5,
        background:theme.colorScheme === 'dark'?theme.colors.dark[9]:theme.white,
        borderRadius:theme.radius.sm,
    },
    col:{
      padding:0
    },
    tabContent:{
      padding:theme.spacing.xs,
      paddingTop:0,
    }
  }));


const CaptureToolbar = ({selectedItems, settings, settingsChange, size='md',onCaptureChange, rating, status}) =>{

    const { classes } = useStyles();

    const onRatingChange = (val) => {
      onCaptureChange(CaptureEventType.RATING_CHANGE,{data:val},selectedItems);
    }

    const onFlagChange = (val) => {
      onCaptureChange(CaptureEventType.STATUS_CHANGE,{data:val},selectedItems);
    }

    const downloadHandler = (type) => {
      logger.log('CAPTURE DOWNLOAD')
      onCaptureChange(CaptureEventType.DOWNLOAD,{data:null,type:type},selectedItems);
    }

    const onTagTalent = (val) => {
      onCaptureChange(CaptureEventType.TAG_TALENT,{data:val},selectedItems);
    }

    return (  
        <Group sx={{width:'auto'}} className={classes.container} spacing='xs' noWrap>
            
          <StarRating size={size} rating={rating} onChange={onRatingChange} />
          {/* <Rating value={rating} onClick={(e) => e.stopPropagation()} onChange={onRatingChange} size={'xs'} /> */}
          {/* <StarRatingExtended rating={rating} onClick={(e) => e.stopPropagation()} onChange={onRatingChange} size={'xs'} /> */}
          <Divider orientation="vertical" />
          <FlagToggle size={size} status={status} onChange={onFlagChange} />

          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon><IconDots size={size === 'sm'?12:14} /></ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              {/* <Menu.Label>Application</Menu.Label> */}
              <Menu.Item onClick={(e) => {downloadHandler('previewHi')}} icon={<IconDownload size={14} />}>Download Hi-res</Menu.Item>
              <Menu.Item onClick={(e) => {downloadHandler('eipURL')}} icon={<IconDownload size={14} />}>Download EIP</Menu.Item>
              <Menu.Item onClick={(e) => {onTagTalent('tag')}} icon={<IconUserCheck size={14} />}>Tag Talent</Menu.Item>
                
              {/* <Menu.Item icon={<IconTransferIn size={14} />}>Assign to session</Menu.Item>         */}
            </Menu.Dropdown>
          </Menu>

        </Group>
    )
}

export default CaptureToolbar;



import {
    Accordion,
    Badge,
    Box,
    Button,
    Container, Grid,
    Group,
    Input, Skeleton, Text,
    Textarea,
    ThemeIcon,
    Title,
    createStyles,
    useMantineTheme
} from '@mantine/core';
import { connect } from 'react-redux';
import { FormInputWrapper, TagInput } from '../../../../core/components';

import { selectSession } from 'modules/Sessions/sessionSlice';
import { IconCategory, IconFile, IconUpload, IconX } from '@tabler/icons';
import { logger } from 'utils/logger';
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useEffect, useState } from 'react';
import { pluralize } from 'utils/helper';
import GuideCategory from './GuideCategory';
import { getGuideCategoriesSuccess } from 'modules/Guides/guideSlice';

const useStyles = createStyles((theme) => ({
    accordianLoader: {
        border: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        padding: theme.spacing.md,
    },
}));

const GuideTabGeneral = ({ user, guide, success, error, form, onFormSubmit }) => {
    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    const theme = useMantineTheme();
    const [files, setFiles] = useState([]);
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (guide && guide.tags && tags.length === 0) {
            var tmpTags = guide.tags.map((val) => {
                return { label: val, value: val };
            });

            logger.log(tmpTags, '  TMP TAGS');
            setTags([...tmpTags]);
        }
    }, [guide]);

    useEffect(() => {
        logger.log(success);
        if (success && success.type === getGuideCategoriesSuccess.toString()) {
            setIsLoading(false);
        }
    }, [success]);
    const previews = files.map((file, index) => {
        return (
            <Group key={index} position='apart' mt={'lg'} mb={'sm'}>
                <Group spacing={'xs'}>
                    <ThemeIcon radius='xl'>
                        <IconFile size={16} />
                    </ThemeIcon>

                    <Text size={'sm'}>{file.name}</Text>
                </Group>
                <Button variant='subtle' onClick={() => setFiles([])}>
                    Remove
                </Button>
            </Group>
        );
    });

    function AccordionLabel({ name, image, descr, shots }) {
        return (
            <Group noWrap grow>
                <Group position='apart'>
                    <Group>
                        <IconCategory stroke={1} />
                        <Title order={6}>{name}</Title>
                    </Group>
                    {/* <Badge variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>{shots.length} Shots</Badge> */}
                    <Badge>
                        {shots.length} {pluralize('Shot', shots.length)}
                    </Badge>
                    {/* <Text size="sm" color="dimmed" weight={400}>
              {shots.length}
          </Text> */}
                </Group>
            </Group>
        );
    }

    const items = guide?.guideCategories?.map((item, idx) => (
        <Accordion.Item value={item.id} key={idx}>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>
                <Box>
                    <GuideCategory category={item} />
                </Box>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const loaderRows = [1, 2, 3].map((val) => (
        <Group key={val} position='apart' className={classes.accordianLoader}>
            <Skeleton height={26} radius='xl' width={200} />

            <Group>
                <Skeleton height={26} radius='xl' width={100} />
                <Skeleton height={26} radius='xl' width={50} />
            </Group>
        </Group>
    ));

    /** get the guides */
    const getShotFrames = () => {
        var guides = [];

        // create some dummy guides
        for (let i = 0; i < 10; i++) {
            let guide = { label: 'Here is some label', shots: [] };

            for (let j = 0; j < 4; j++) {
                const shot = {
                    req: 'yes',
                    referenceUrl:'',
                    name:'name',
                    seq:j,
                    notes:'some notes'
                };
                guide.shots.push(shot);
            }

            guides.push(guide)
        }

        return guides;
    };

    return (
        <Container fluid sx={{ marginLeft: 0 }}>
            <Grid>
                <Grid.Col span={8}>
                    <Box p='xl'>
                        <Title order={5} mb='lg'>
                            General Details
                        </Title>

                        <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                            <FormInputWrapper label='Name' id='input-name'>
                                <Input id='input-name' required data-autofocus placeholder='Enter Name' {...form.getInputProps('name')} />
                            </FormInputWrapper>

                            <FormInputWrapper label='Description' id='input-desc'>
                                <Textarea id='input-desc' placeholder='Description' {...form.getInputProps('descr')} />
                            </FormInputWrapper>

                            <FormInputWrapper label='File' id='input-files'>
                                <div>
                                    <Dropzone onReject={(files) => logger.log('rejected files', files)} maxSize={3 * 1024 ** 2} accept={PDF_MIME_TYPE} onDrop={setFiles}>
                                        <Group
                                            position='center'
                                            spacing='sm'
                                            style={{
                                                minHeight: 150,
                                                pointerEvents: 'none',
                                            }}>
                                            <Dropzone.Accept>
                                                <IconUpload size={50} stroke={1.5} color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]} />
                                            </Dropzone.Accept>
                                            <Dropzone.Reject>
                                                <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
                                            </Dropzone.Reject>
                                            <Dropzone.Idle>
                                                <IconFile size={50} stroke={1.5} />
                                            </Dropzone.Idle>

                                            <div>
                                                <Text size='xl' inline>
                                                    Drag your file here or click to select
                                                </Text>
                                                <Text size='sm' color='dimmed' inline mt={7}>
                                                    The file should not exceed 5mb
                                                </Text>
                                            </div>
                                        </Group>
                                    </Dropzone>
                                    <Group grow>{previews}</Group>
                                </div>
                            </FormInputWrapper>

                            <FormInputWrapper label='Tags' id='input-tags'>
                                <TagInput
                                    {...form.getInputProps('tags')}
                                    onChange={(val) => {
                                        logger.log(val, ' TAGS CHANGE')
                                        setTags([...val.map((item) => ({ value: item, label: item }))]);
                                        form.setFieldValue('tags', val);
                                    }}
                                />

                                {/* <MultiSelect
                                size='md'     
                                data={tags}
                                placeholder="Enter Tags"
                                searchable
                                creatable
                                {...form.getInputProps('tags')}
                                getCreateLabel={(query) => `+ Create ${query}`}
                                onCreate={(query) => {
                                  const item = { value: query, label: query };
                                  setTags((current) => [...current, item]);
                                  return item;
                                }}
                              /> */}
                                {/* <Input
                                    id='input-tags'
                                    placeholder='Tags'
                                    {...form.getInputProps('tags')}
                                /> */}
                            </FormInputWrapper>

                            <Group position='right' mt={'xl'}>
                                <Button type='submit'>Update Guide</Button>
                            </Group>
                        </form>
                    </Box>

                    <Box mt='md' p='lg'>
                        {/* <Group position='apart'>
                    <Title className='main-content-heading' order={5} mb='lg'>
                        Category Guides
                    </Title>
                    <Button leftIcon={<IconPlus size={14} />} variant={'light'}>
                        Add Guide
                    </Button>
                </Group> */}
                        {/* {guide?.id.length ? (
                            <>
                                <Divider my={'lg'} />

                                <Stack my={'lg'} pt={'md'} spacing={0}>
                                    <Title order={5} pb={'lg'}>
                                        Guide Categories
                                    </Title>

                                  
                                    {!isLoading ? (
                                        <>{loaderRows}</>
                                    ) : (
                                        <>
                                            <Accordion chevronPosition='right' variant='contained'>
                                                {items}
                                            </Accordion>

                                            {!guide.guideCategories?.length && !isLoading ? (
                                                <EmptyState
                                                    style={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                    showImage={false}
                                                    caption='There are no Guide Categories'
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </Stack>
                            </>
                        ) : null} */}
                        {/* <Table>
                    <thead>
                        <tr>
                            <th>Guide</th>
                            <th>Campaign</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>{styleGuideRows}</tbody> 
                </Table> */}
                {/* <DndProvider backend={HTML5Backend}>
        
                        <Stack>
                            {getShotFrames().map((val, idx) => {
                                const captures = val.shots.filter((shot) => shot.capture);
                                const requiredShots = val.shots.filter((shot) => shot.req === 'yes');
                                const requiredCount = requiredShots ? requiredShots.length : 0;
                                const capturedCount = captures ? captures.length : 0;
                                const isComplete = capturedCount === requiredCount;
                                const taskSaving = false;
                                logger.log(val);
                                return (
                                    <Box pb={'md'} key={idx} className={classes.categoryPanel}>
                                        <Group position='left' pb={'md'} grow>
                                            <Group>
                                                <Group>
                                                    <IconCategory stroke={1} />
                                                    <Title order={5}>{val.label}</Title>
                                                </Group>
                                                
                                                <Badge>
                                                    {val.shots.length} {utils.pluralize('Shot', val.shots.length)}
                                                </Badge>
                                            </Group>

                                            <Group position='right'>
                                                <Group>
                                                    
                                                </Group>
                                                <Button loading={taskSaving} disabled={!isComplete} variant='outline'>
                                                    Add Shot
                                                </Button>
                                            </Group>
                                        </Group>
                                        <GuideCategory category={val} allowAssignment={false} onItemDrop={(item, target) => logger.log('ondrop')} />
                                    </Box>
                                );
                            })}
                        </Stack>
                        </DndProvider> */}
                    </Box>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        guide: state.guideReducer.selectedGuide,
        success: state.guideReducer.success,
        error: state.guideReducer.error,
    };
};

const mapDispatch = { selectSession };

export default connect(mapStateToProps, mapDispatch)(GuideTabGeneral);

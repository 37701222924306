import { createStyles, Box, useMantineTheme, Input, Chip, Group, MultiSelect, Switch, Button, Indicator, Text, Avatar, CloseButton } from '@mantine/core';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import './CustomCalendar.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { logger } from 'utils/logger';
import EventSummary from './EventSummary';
import { closeModal, openModal } from '@mantine/modals';
import { selectSeason } from 'modules/Seasons/seasonSlice';
import { useDispatch } from 'react-redux';
import { selectSession, selectTask } from 'modules/Sessions/sessionSlice';
import { useNavigate } from 'react-router-dom';
import { IconSearch, IconX } from '@tabler/icons';
import { FormInputWrapper, IndicatorSelectItem } from 'core/components/index';
import { useForm } from '@mantine/form';
import { getUserAvatarColour, getUserFullname, getUserInitials } from 'utils/helper';
import _ from 'lodash';
import { SessionStatusTypes } from 'core/models/SampleModel';

const useStyles = createStyles((theme) => ({
    root: {
        //   padding: theme.spacing.xl * 1.5,
        // paddingTop:theme.spacing.xl * 1,
    },

    container: {
        // height: '100%',
        '.rbc-calendar': {
            fontSize: 14,
            height: '100%',
            '.rbc-month-view,.rbc-time-view': {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
            },
        },
        '.rbc-day-bg + .rbc-day-bg,.rbc-time-content,.rbc-time-content > * + * > *,.rbc-day-slot .rbc-time-slot,.rbc-timeslot-group': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
        },
        '.rbc-month-row + .rbc-month-row': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.rbc-month-row': {
            minHeight: 150,
        },
        '.rbc-today': {
            backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[5]} !important` : theme.colors.gray[3],
        },
        '.rbc-day-bg': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
        '.rbc-off-range-bg': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
        },
        '.rbc-header,.rbc-header + .rbc-header,.rbc-time-header-content, .rbc-time-header.rbc-overflowing': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.rbc-toolbar button': {
            fontWeight: 600,
            fontSize: 14,
            height: 36,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
            color: theme.colorScheme === 'dark' ? 'white' : 'inherit',
            cursor: 'pointer',
        },
        '.rbc-toolbar button:hover,.rbc-toolbar button:focus': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[7] : theme.colors.brand[6],
            color: theme.white,
            borderColor: 'transparent',
        },
        '.rbc-toolbar button:active, .rbc-toolbar button.rbc-active': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[4] : theme.colors.brand[6],
            color: theme.white,
            borderColor: 'transparent',
        },
        '.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus': {
            backgroundColor: theme.colors.brand[4],
            color: theme.white,
            borderColor: 'transparent',
        },
        '.rbc-label': {
            fontSize: 14,
        },
        '.rbc-show-more': {
            background: 'none',
            color: theme.colors.brand[4],
            borderRadius: 4,
            padding: '2px 3px',
            ':hover': {
                background: theme.colors.brand[7],
                color: theme.white,
            },
        },
        '.rbc-agenda-view table.rbc-agenda-table thead > tr > th,.rbc-agenda-view table.rbc-agenda-table,.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td': {
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
        },
        '.rbc-agenda-view table': {
            color: theme.white,
        },
        '.rbc-time-view': {
            '.rbc-events-container > div': {
                position: 'relative',
                width: '100% !important',
                left: '0 !important',
                height: 'auto !important',
                paddingBottom: 8,
                '.rbc-event-label': {
                    display: 'none',
                },
                '.mantine-Text-root': {
                    whiteSpace: 'pre-wrap',
                    marginBottom: 20,
                },
            },
            '.rbc-time-slot': {
                border: 0,
            },
            '.rbc-time-slot span': {
                visibility: 'hidden',
            },
            '.rbc-day-slot .rbc-time-slot': {
                borderColor: 'transparent',
            },
            '.rbc-timeslot-group': {
                border: 0,
                // borderBottomColor:'transparent',
            },
        },
        '.rbc-month-view': {
            '.event-title': {
                maxWidth: 200,
            },
        },
    },
    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 700,
    },
    calendar: {
        height: '100%',
    },
}));

function Event({ event }) {
    logger.log(event);
    return (
        <div style={{ height: 'auto' }}>
            <Text truncate className='event-title'>
                {event.title}
            </Text>
            <Group spacing={0}>
                {event.team.map((member, idx) => {
                    const userInitials = member.name ? getUserInitials(member.name.split(' ')[0], member.name.split(' ')[1]) : '';
                    const themeCol = getUserAvatarColour(userInitials);
                    const margin = idx ? -10 : 0;
                    return (
                        <Avatar key={idx} variant='filled' sx={{ marginLeft: margin }} title={`${member.name}`} color={themeCol} size={25} radius={40}>
                            {member.name?.charAt(0)}
                        </Avatar>
                    );
                })}
            </Group>
            {/* {event.desc && ':  ' + event.desc} */}
        </div>
    );
}

function EventAgenda({ event }) {
    return (
        <span>
            <em style={{ color: 'white' }}>{event.title}</em>
            <p>{event.desc}</p>
        </span>
    );
}

const localizer = momentLocalizer(moment);

const EventFilter = { types: ['session'], keyword: '', members: [], brands: [], categories: [], department: [], location: [], status: [], colour: '' };

const AltCalendar = ({ sessions = [], seasons = [], tasks = [], aggData, user, guides = [] }) => {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filterCount, setFilterCount] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    const [assignedToMe, setAssignedToMe] = useState(false);
    const [members, setMembers] = useState([]);

    const [events, setEvents] = useState([
        {
            start: moment().toDate(),
            end: moment().add(1, 'days').toDate(),
            title: 'Some title',
            type: '',
        },
    ]);

    const [filteredEvents, setFilteredEvents] = useState([]);

    const [eventFilter, setEventFilter] = useState(EventFilter);
    const [filterItems, setFilterItems] = useState(eventFilter);

    useEffect(() => {
        if (aggData && aggData.productionTeam) {
            const productionTeam = aggData.productionTeam.map((val, idx) => {
                const userInitials = val.name ? getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1]) : '';
                const themeCol = getUserAvatarColour(userInitials);
                return { id: val.id, name: val.name, email: val.email, color: themeCol, team: 'Production', initials: userInitials };
            });
            const postTeam = aggData.postProductionTeam.map((val, idx) => {
                const userInitials = val.name ? getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1]) : '';
                const themeCol = getUserAvatarColour(userInitials);
                return { id: val.id, name: val.name, email: val.email, color: themeCol, team: 'Post Production', initials: userInitials };
            });
            var tmpMembers = postTeam.concat(productionTeam);
            tmpMembers.sort((a, b) => a.name.localeCompare(b.name));

            const newMembers = tmpMembers.map((val, idx) => {
                return { ...val, value: idx, label: val.name, group: val.team };
            });
            setMembers([...newMembers]);
        }
    }, [aggData]);

    const filterData = useCallback(() => {
        logger.log(events);
        const data = events.filter((item) => {
            var match = true;

            if (eventFilter.types && eventFilter.types.length) {
                match = eventFilter.types.indexOf(item.type) > -1 ? true : false;
            }

            if (eventFilter.members && eventFilter.members.length && match) {
                // see if the team members exist in the event.
                var tmpMatch = false;
                for (var i = 0; i < eventFilter.members.length; i++) {
                    const val = eventFilter.members[i];
                    let member = members[val];
                    if (val.email === user.email) {
                        member = user;
                    }

                    logger.log(item);
                    const isFound = item.team.find((val) => val.email === member.email);

                    if (isFound) {
                        tmpMatch = true;
                        break;
                    }
                }

                match = tmpMatch;
            }

            // filter by brand
            // if (eventFilter.brands && eventFilter.brands.length) {
            //     match = eventFilter.brands.indexOf(item.facet_brand) > -1 ? true : false;
            // }

            // if (eventFilter.categories && eventFilter.categories.length && match) {
            //     match = eventFilter.categories.indexOf(item.metadata_toplevelcategory) > -1 ? true : false;
            // }

            if (eventFilter.keyword && eventFilter.keyword.length && match) {
                match = item.title.indexOf(eventFilter.keyword) > -1 ? true : false;
                if (!match) {
                    match = item.type.indexOf(eventFilter.keyword) > -1 ? true : false;
                }
                if (!match) {
                    const matchedItems = item.data.tags.filter((tag) => tag.toLowerCase().includes(eventFilter.keyword.toLowerCase()));
                    match = matchedItems.length ? true : false;
                }
                if (!match) {
                    match = item.uuid.indexOf(eventFilter.keyword) > -1 ? true : false;
                }
            }

            return match;
        });

        setFilteredEvents(data);
    }, [events, eventFilter.types, eventFilter.members, eventFilter.brands, eventFilter.categories, eventFilter.keyword]);

    const setFilterVars = (vals) => {
        logger.log(vals, ' FILTER VALS');
        setEventFilter(vals);
    };

    useEffect(() => {
        logger.log('setting samples, ');
        //setSampleData(samples);

        // set the brands/season/category
        var members = {};
        var types = {};
        var brands = {};
        var categories = {};
        var seasons = {};

        events.forEach((item, idx) => {
            members[item.id] = item;
            types[item.type] = item;
            brands[item.facet_brand] = item;
            categories[item.metadata_toplevelcategory] = item;
            seasons[item.facet_season] = item;
        });

        setFilterItems({ members, types, brands, categories, seasons });

        filterData();
    }, [events, filterData]);

    useEffect(() => {
        // filter the data.
        filterData();
    }, [eventFilter, filterData]);

    const getFilterCount = (val) => {
        var count = 0;
        Object.entries(val).forEach(([key, value]) => {
            logger.log(`${key}: ${value}`);
            if (value.length) {
                ++count;
            }
        });

        return count;
    };

    useEffect(() => {
        const count = getFilterCount(eventFilter);
        setFilterCount(count);
    }, [eventFilter]);

    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();

        setEventFilter(EventFilter);
        setAssignedToMe(false);
        logger.log('CLEAR CLICK', e);
    };

    const filterButtonHandler = (e) => {
        logger.log('BUTTON CLICK', e, e.nativeEvent);
        setFilterOpen((o) => !o);
    };

    const onChipChange = (val) => {
        const tmpUser = getUserFullname(user.first, user.last);
        var filtervars = { ...eventFilter };
        if (val) {
            setAssignedToMe(true);
            // push the current user into the filter params
            filtervars.members.push({ name: tmpUser, email: user.email, team: '', label: tmpUser, value: members.length });
        } else {
            setAssignedToMe(false);
            _.remove(filtervars.members, (member) => member.email === user.email);
        }
        setEventFilter(filtervars);
    };

    useEffect(() => {
        if (sessions) {
            var mergedArray = sessions
                .filter((val) => {
                    return val.status !== SessionStatusTypes.ARCHIVE;
                })
                .map((val) => {
                    return { ...val, color: theme.colors.blue[6], type: 'session' };
                })
                .concat(
                    seasons.map((val) => {
                        return { ...val, color: theme.colors.grape[6], type: 'season' };
                    }),
                );

            mergedArray = mergedArray.concat(
                tasks.map((val) => {
                    return { ...val, color: theme.colors.pink[6], type: 'task' };
                }),
            );

            const tmpEvents = mergedArray.map((val) => {
                var title, route, eventColour, startDate, endDate, team, uuid;
                switch (val.type) {
                    case 'task':
                        title = `Task: ${val.taskPart}`;
                        route = `/sessions/tasks/${val.taskSort}`;
                        eventColour = theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.pink[6], 0.65) : theme.fn.rgba(theme.colors.pink[7], 0.9);
                        startDate = moment(val.deadline).toDate();
                        endDate = moment(val.deadline).toDate();
                        team = [{ name: val.assignee, email: val.assigneeEmail, id: val.assigneeEmail }];
                        uuid = val.taskPart;
                        break;
                    case 'season': {
                        title = `Event: ${val.name}`;
                        route = `/${val.type}s/${val.seasonSort}`;
                        eventColour = theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.blue[6], 0.65) : theme.fn.rgba(theme.colors.blue[6], 0.9);
                        startDate = moment(val.deadline).toDate();
                        endDate = moment(val.deadline).toDate();
                        uuid = val.id;
                        team = [];
                        break;
                    }
                    case 'session': {
                        title = `Session: ${val.code} - ${val.name}`;
                        route = `/${val.type}s/${val.sessionId}`;
                        eventColour = theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors.grape[4], 0.6) : theme.fn.rgba(theme.colors.grape[8], 0.9);
                        startDate = moment(val.shootDate).toDate();
                        endDate = moment(val.shootDate).toDate();
                        team = val.team;
                        uuid = val.id;
                        break;
                    }
                    default:
                        break;
                }

                startDate.setHours(0, 0);
                endDate.setHours(2.5, 0);
                return {
                    start: startDate,
                    end: endDate,
                    title: title,
                    colour: eventColour,
                    id: val.id,
                    data: val,
                    type: val.type,
                    route: route,
                    team: team,
                    uuid: uuid,
                };
            });

            setEvents(tmpEvents);
            setFilteredEvents([...tmpEvents]);
        }
    }, [sessions, tasks, seasons, theme]);

    const eventStyleGetter = (event, start, end, isSelected) => {
        // logger.log(event, event.colour);
        return {
            style: {
                opacity: isSelected ? 1 : 0.9,
                borderColor: event.colour,
                backgroundColor: event.colour,
            },
        };
    };

    const onEventSelect = (data) => {
        switch (data.type) {
            case 'season': {
                dispatch(selectSeason(data.data));
                break;
            }
            case 'session': {
                var tmpGuides = data.data.guides;
                if (tmpGuides) {
                    tmpGuides = guides.filter((guide) => {
                        var guideIds = data.data.guides.map((g) => g.id);
                        return guideIds.indexOf(guide.id) > -1;
                    });
                }
                dispatch(selectSession({ ...data.data, guides: tmpGuides }));
                break;
            }
            case 'task': {
                dispatch(selectTask(data.data));
                break;
            }
            default: {
                break;
            }
        }

        const onViewClick = () => {
            closeModal('eventModal');
            navigate(data.route);
        };

        openModal({
            title: `${data.title} overview`,
            size: 'lg',
            modalId: 'eventModal',
            children: (
                <>
                    <EventSummary data={data} onViewClick={onViewClick} seasons={seasons} />
                </>
            ),
        });
    };

    const MemberItem = forwardRef(({ name, id, role, color, description, userInitials, ...others }, ref) => {
        return (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <Avatar variant='filled' title={`${name} - ${role}`} color={color} size={30} radius={40}>
                        {name.charAt(0)}
                    </Avatar>
                    <Text sx={{ paddingLeft: 0 }} className={classes.label}>
                        {name}
                    </Text>
                </Group>
            </div>
        );
    });

    const components = {
        agenda: {
            event: EventAgenda,
        },
        event: Event,
    };

    function MemberValue({ value, label, onRemove, classNames, color, name, ...others }) {
        logger.log(value, label, others);
        return (
            <div {...others}>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        cursor: 'default',
                        alignItems: 'center',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
                        paddingLeft: theme.spacing.xs,
                        borderRadius: theme.radius.sm,
                    })}>
                    <Box mr={10}>
                        <Avatar variant='filled' title={`${others.name} - ${others.team}`} color={color} size={10} radius={40}>
                            {/* {name.charAt(0)} */}&nbsp;
                        </Avatar>
                    </Box>
                    <Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
                    <CloseButton onMouseDown={onRemove} variant='transparent' size={22} iconSize={14} tabIndex={-1} />
                </Box>
            </div>
        );
    }

    return (
        <Box className={classes.container}>
            <Group
                align='start'
                className='container-action-bar'
                sx={{ width: '100%', position: 'sticky', top: 50, background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'transparent', zIndex: 9 }}
                position='apart'
                height={'100%'}
                pt='xl'
                pb={'sm'}
                mb={'sm'}>
                <Group>
                    <Input
                        className='tab-filter-search'
                        icon={<IconSearch />}
                        placeholder='Search Events'
                        value={eventFilter.keyword}
                        onChange={(e) =>
                            setEventFilter({
                                ...eventFilter,
                                keyword: e.currentTarget.value,
                            })
                        }
                        rightSection={
                            <IconX
                                size={14}
                                onClick={(e) =>
                                    setEventFilter({
                                        ...eventFilter,
                                        keyword: '',
                                    })
                                }
                            />
                        }
                    />

                    <Chip size='lg' className='taskbar-chip' checked={assignedToMe} onChange={(val) => onChipChange(val)} sx={{ label: { fontSize: 14 } }}>
                        Assigned to me
                    </Chip>
                </Group>

                <Group align='start' position='right'>
                    <MultiSelect
                        sx={{ width: 320 }}
                        data={[
                            { label: 'Events', value: 'season', color: theme.colors.blue[6], labelClass: classes.label },
                            { label: 'Sessions', value: 'session', color: theme.colors.grape[6], labelClass: classes.label },
                            { label: 'Tasks', value: 'task', color: theme.colors.pink[6], labelClass: classes.label },
                        ]}
                        label=''
                        value={eventFilter.types}
                        placeholder='Select Event Type'
                        nothingFound='Nothing found'
                        itemComponent={IndicatorSelectItem}
                        onChange={(value) => setEventFilter({ ...eventFilter, types: value })}
                    />

                    <MultiSelect
                        sx={{ width: 300, display: 'inline-block' }}
                        data={members}
                        label=''
                        searchable
                        value={eventFilter.members}
                        placeholder='Select Users'
                        valueComponent={MemberValue}
                        itemComponent={MemberItem}
                        nothingFound='Nothing found'
                        onChange={(value) => setEventFilter({ ...eventFilter, members: value })}
                    />

                    <Button onClick={onClearClick}>Clear</Button>
                    {/* <Popover opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} position='bottom' withArrow closeOnClickOutside={false}>
                        <Popover.Target>
                            <div>
                                <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div style={{ display: 'flex' }}>
                                <FilterPanel
                                    filter={eventFilter}
                                    onCancel={() => setFilterOpen(false)}
                                    onApply={(vals) => {
                                        setFilterVars(vals);
                                        setFilterOpen(false);
                                    }}
                                    filterItems={filterItems}
                                />
                            </div>
                        </Popover.Dropdown>
                    </Popover> */}
                </Group>
            </Group>

            <Calendar
                components={components}
                showMultiDayTimes={true}
                onSelectEvent={onEventSelect}
                localizer={localizer}
                defaultDate={new Date()}
                defaultView='week'
                events={filteredEvents}
                eventPropGetter={eventStyleGetter}
            />
        </Box>
    );
};

export default AltCalendar;

const FilterPanel = ({ onCancel, onApply, filter, filterItems }) => {
    logger.log(filterItems);

    const form = useForm({
        initialValues: {
            ...filter,
        },
    });

    // useEffect(() => {
    //     if (filter && form) {
    //         form.setValues({ ...filter });
    //     }

    //     logger.log(form.getInputProps('status'));
    // }, [filter, form]);

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Type' id='input-type' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('types')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('types')} />
                </FormInputWrapper>

                <FormInputWrapper label='Category' id='input-category' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('categories')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('categories')} />
                </FormInputWrapper>

                <FormInputWrapper label='Department' id='input-department' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('department')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Location' id='input-location' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('location')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect
                        data={[
                            { label: 'United States', value: 'US' },
                            { label: 'Great Britain', value: 'GB' },
                            { label: 'Finland', value: 'FI' },
                            { label: 'France', value: 'FR' },
                            { label: 'Russia', value: 'RU' },
                        ]}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('status')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Assigned Product' id='input-colour' labelWidth={'120px'}>
                    <Switch />
                </FormInputWrapper>

                <Group position='apart' mt='lg'>
                    <Button variant='subtle' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

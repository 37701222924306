// import BudgetProgress, { ProgressType } from 'components/budgetProgress/budgetProgress';
import { Anchor, Box, Button, Center, Checkbox, createStyles, Group, Paper, PasswordInput, TextInput, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from '@mantine/form';
import { Link } from "react-router-dom";
import { loginUser } from '../../authSlice';
import { IconEyeCheck, IconEyeOff } from '@tabler/icons';
import { logger } from "utils/logger";
// import Dashboard from './features/components/Dashboard';

const useStyles = createStyles((theme) => ({
  container: {
      // backgroundColor:'rgb(174 182 255 / 7%)',
      backgroundColor:theme.white,
      padding:`${theme.spacing.md *2}px`,
      borderRadius:theme.radius.md,
  },
  title:{
      // color:'#ffffff',
  }
}));


const mapDispatch = { loginUser }

const Login = ({success,error}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();  
  const [isLoading,setIsLoading] = useState(false); 

  useEffect(() => {
    setIsLoading(false);
  },[success,error]);

  const form = useForm({
      initialValues: {
        username:process.env.NODE_ENV === 'development'?process.env.REACT_APP_EMAIL:'',
        password: process.env.NODE_ENV === 'development'?process.env.REACT_APP_PW:'',
        
      },
  
      // validate: {
      //   confirmPassword: (value, values) =>
      //     value !== values.password ? 'Passwords did not match' : null,
      // },
    });

    const onSubmit = (values) => {
      logger.log('here', values);
      setIsLoading(true)
      dispatch(loginUser(values))
      //form.onSubmit((values) => logger.log(values))
    }


    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        
          <Center sx={{width:'100%'}}>
           <Box sx={{ width: '100%' }} mr='xl'>
            <Paper className={classes.container}>
            <Title order={1} mb='xl' sx={{textAlign:'center'}}>Sign In</Title>
            <form onSubmit={form.onSubmit(onSubmit)}>
                
                <TextInput label="Username" placeholder='Enter username or email' className={classes.title} required
                 {...form.getInputProps('username')} />

                <PasswordInput mt="lg"
                  label="Password"
                  placeholder="Enter password" required
                  {...form.getInputProps('password')}
                  visibilityToggleIcon={({ reveal, size }) =>
                    reveal ? <IconEyeOff size={size} /> : <IconEyeCheck size={size} />
                  }
                />

                <Group mt='xl' mb='xl' position='apart'>
                  <Checkbox label="Remember me"></Checkbox>
                  <Anchor component={Link} to="/auth/forgotpassword" size={'sm'}>
                    Forgot Password
                  </Anchor>
                  
                </Group>                

                <Group position="right" mt="xl" mb="lg">
                  <Button sx={{ width: '100%' }} type="submit" loading={isLoading}>Log In</Button>
                </Group>
                
            </form>
            </Paper>
            {/* <Center mt={'xl'}>
              <Link to="/auth/register" size='sm'>Don't have an account? Register.</Link>
            </Center> */}
            </Box>
            </Center>
                    
        
    )
    
};

const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
        user: state.auth.user,
        success: state.auth.success,
        error: state.auth.error,
    })
  }



export default connect(
    mapStateToProps,
    mapDispatch
  )(Login)


// export default Auth;
// export default {
//     routeProps: {
//         path: '/auth',
//         component: Auth,
//     },
//     name: 'Auth',
// };

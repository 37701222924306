// import BudgetProgress, { ProgressType } from 'components/budgetProgress/budgetProgress';
import { Box, Button, Center, createStyles, Group, Paper, TextInput, Title } from '@mantine/core';
import { connect } from 'react-redux';
import { useForm } from '@mantine/form';
import { loginUser } from '../../authSlice';
import { logger } from "utils/logger";

const useStyles = createStyles((theme) => ({
  container: {
      backgroundColor:'rgb(174 182 255 / 7%)',
      padding:`${theme.spacing.md *2}px`,
  },
  title:{
      color:'#ffffff',
  }
}));

const mapDispatch = { loginUser }

const Register = (location) => {
  
  const { classes } = useStyles();  
    logger.log('login', location)

    const form = useForm({
        initialValues: {
          username:'',          
        },
    
        // validate: {
        //   confirmPassword: (value, values) =>
        //     value !== values.password ? 'Passwords did not match' : null,
        // },
      });

      const onSubmit = (values) => {
        logger.log('here', values)
        // dispatch(User(values))
        //form.onSubmit((values) => logger.log(values))

      }
    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        
          <Center sx={{width:'100%'}}>
           <Box sx={{ width: '100%' }} mr='xl'>
            <Paper className={classes.container}>
            <Title order={1} mb='xl' sx={{textAlign:'center'}}>Create your account!</Title>
            <form onSubmit={form.onSubmit(onSubmit)}>
                
                <TextInput label="Email" placeholder='Enter your email' className={classes.title} required
                 {...form.getInputProps('username')} />          

                <Group position="right" mt="xl" mb="lg">
                  <Button sx={{ width: '100%' }} type="submit">Continue</Button>
                </Group>

            </form>
            </Paper>
            </Box>
            </Center>
                    
        
    )
    
};

const mapStateToProps = (state,props) => {
    //logger.log(state,props, ' mapstate')
    return (
    {
        user: state.auth.user,
    })
  }



export default connect(
    mapStateToProps,
    mapDispatch
  )(Register)


// export default Auth;
// export default {
//     routeProps: {
//         path: '/auth',
//         component: Auth,
//     },
//     name: 'Auth',
// };

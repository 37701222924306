const TableBody = ({ tableData, columns, rows,showLoading }) => {
    const dataProvider = showLoading && tableData.length === 0?rows:tableData;

    return (
      <tbody>
        {dataProvider.map((data,idx) => {
          var row;
          if(rows){
            try{
              row = rows.find((elem,i) => {                
                return elem.key && data.id && elem.key.toString() === data.id.toString()?elem:null;
              })
            }catch(e){
              console.error(e)
            }
            
          }
          
          if(!row){
            row = rows[0];
          }

          // get the corresponding row
          return (
            
            <tr key={idx}>
              {row && row.props && row.props.children && row.props.children.map((cell,colIdx) => {
                const col = columns[colIdx];
                return col?<td key={col.accessor} {...cell.props}>{cell.props.children}</td>:<td key={colIdx}>{cell.props.children}</td>
                
              })}

            </tr>

          );
        })}
      </tbody>
    );
  };
  
  export default TableBody;
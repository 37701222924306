/**
 * @file App.js
 * @description Main Application
 * @author Ben Yee
 *
 */
import React, { Suspense, useEffect } from 'react';
import { useState } from 'react';

import { MantineProvider, ColorSchemeProvider, ThemeIcon, Box, Text } from '@mantine/core';
import { SpotlightProvider } from '@mantine/spotlight';
//  import modules from 'modules';
import './App.scss';
import { connect, useDispatch } from 'react-redux';
import Shell from './shell/Shell';
//  import ErrorBoundary from 'components/errorBoundary/errorBoundary';

import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Auth from '../modules/Auth';
import { IconBook, IconCamera, IconSearch, IconColorSwatch, IconCalendarEvent } from '@tabler/icons';
import { NotificationsProvider } from '@mantine/notifications';
// import ApprovalModule from 'modules/Approval/ApprovalModule';
import { logoutUser, setUser } from 'modules/Auth/authSlice';
import { getProp } from 'utils/helper';
import { logger } from 'utils/logger';
import { openConfirmModal } from '@mantine/modals';
import { fetchAggData, fetchDepartments, setPreviousRoute } from './appSlice';
import ErrorBoundary from 'core/components/errorBoundary/errorBoundary';
import { useLocalStorage } from '@mantine/hooks';
// import ErrorBoundary from 'components/errorBoundary/errorBoundary';
// import ImageControlToolbar from 'modules/Approval/components/ImageControlToolbar';

var loadingTimer = null;
var psudoLoad = 60;

const ApprovalModule = React.lazy(() => import('modules/Approval/ApprovalModule'));

const App = ({ user, setUser, isLoading, setPreviousRoute }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            const userStr = getProp('user');
            if (userStr) {
                const tmpUser = JSON.parse(userStr);
                setUser(tmpUser);
            }
        } else {
            dispatch(fetchAggData());
            dispatch(fetchDepartments());
        }
    }, [user, dispatch, setUser]);

    const signOut = () => {
        // set the previous route so we can redirect them after they login in again we pass it as
        // a param as the logout function refreshes the state.
        var redirect = `${window.location.pathname}`;
        if (window.location.search && window.location.search.length) {
            redirect += `${window.location.search}`;
        }

        dispatch(logoutUser(user, redirect));
    };

    /**
     * Need a better place to put this for auth checking right now we'll place it here
     */
    useEffect(() => {
        const { fetch: originalFetch } = window;
        window.fetch = async (...args) => {
            let [resource, config] = args;
            let response = await originalFetch(resource, config);

            if (!response.ok && (response.status === 401 || response.status === 404)) {
                // 404 error handling
                let path = window.location.pathname.toLowerCase();
                path = path.split('/');

                if (path[1] !== 'auth' && !modalOpen) {
                    // we need to show a modal to let them know whe're loggin them out.
                    const openModal = () =>
                        openConfirmModal({
                            title: 'Session Expired',
                            children: <Text size='sm'>Your session has expired, you'll need to log back in to continute using Altitude.</Text>,
                            labels: { confirm: 'Login', cancel: 'Cancel' },
                            onCancel: () => {
                                console.log('Cancel');
                                setModalOpen(false);
                            },
                            onConfirm: () => signOut(),
                        });

                    openModal();
                    setModalOpen(true);
                }

                return Promise.reject(response);
            }
            return response;
        };
    }, []);

    useEffect(() => {
        console.log('IS LOADING', isLoading);
        const cancelLoading = () => {
            clearInterval(loadingTimer);
            loadingTimer = null;
        };
        if (isLoading && !loadingTimer) {
            loadingTimer = setInterval(() => {
                const progress = psudoLoad + (100 - psudoLoad) / 2;
                logger.log(progress, ' ======= LOAD PROGRES');
                psudoLoad = progress;

                if (progress >= 90) {
                    cancelLoading();
                }
                setLoadProgress(psudoLoad);
            }, 5);
        }
        if (!isLoading && loadingTimer) {
            cancelLoading();
        }
        if (!isLoading) {
            psudoLoad = 60;
            setLoadProgress(100);
            setTimeout(() => {
                setLoadProgress(0);
                cancelLoading();
            }, 1500);
        }
    }, [isLoading]);

    const [loadProgress, setLoadProgress] = useState(20);

    // const navigate = useNavigate();
    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'mantine-color-scheme',
        defaultValue: 'dark',
        getInitialValueInEffect: true,
    });
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    //  useEffect(() => {
    //    (async () => {
    //      const token =  await authProvider.getIdToken();
    //      const idToken = token.idToken;
    //      // call the mapped reducer function
    //      setUser(idToken);
    //    })();
    //  },[setUser])

    //  const [currentTab, setCurrentTab] = useState('dashboard')
    //  const linkClick = () => {
    //    document.querySelector('body').classList.remove('project-drawer-open')
    //  }
    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                styles={{
                    Title: (theme) => ({
                        root: {
                            color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[9],
                        },
                    }),
                    Text: (theme) => ({
                        root: {
                            color: theme.colorScheme === 'dark' ? theme.colors.gray[3] : '',
                        },
                        dimmed: {
                            color: 'red',
                        },
                    }),
                }}
                theme={{
                    colorScheme: colorScheme,
                    background: 'red',
                    // fontFamily: 'Verdana, sans-serif',
                    fontFamilyMonospace: 'Monaco, Courier, monospace',
                    headings: { fontFamily: 'Poppins, sans-serif' },
                    globalStyles: (theme) => ({
                        '.body-panels': {
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                        },
                        '.scrollTableHeader': {
                            position: 'sticky',
                            top: 0,
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                            transition: 'box-shadow 150ms ease',
                            zIndex: 2,
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]}`,
                            },
                        },
                        '.tableHeader-scrolled': {
                            boxShadow: theme.shadows.sm,
                        },

                        '.displayFooter': {
                            // position:'fixed',
                            bottom: 0,
                            background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                            // right:theme.spacing.lg,
                            padding: theme.spacing.sm,
                            paddingLeft: theme.spacing.lg,
                            // right:0,
                            // left:221,
                            borderTop: '1px solid',
                            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
                        },
                        '.cellChecked': {
                            background: theme.colorScheme === 'dark' ? `${theme.colors.dark[6]} !important` : `${theme.white} !important`,
                        },
                        '.list-stack':{
                            gap:0,
                            '.list-renderer-item':{
                                paddingBottom:theme.spacing.md,
                                paddingLeft:theme.spacing.md,
                                paddingRight:theme.spacing.md,
                                '&:hover':{
                                    background: theme.colorScheme === 'dark' ? `${theme.colors.dark[6]} !important` : `${theme.white} !important`,
                                }
                            }
                        },
                        '.list-renderer-item': {
                            borderTop: '1px solid',
                            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
                        },
                        '.menuItem': {
                            display: 'block',
                            width: '100%',
                            padding: theme.spacing.xs,
                            borderRadius: theme.radius.sm,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                            '&:hover': {
                                backgroundColor:
                                    // theme.colorScheme === 'dark' ? theme.colorScheme : theme.colors.gray[0],
                                    theme.colorScheme === 'dark' ? theme.colorScheme : theme.colorScheme,
                                '.themeIcon': {
                                    color: theme.colors.brand[1],
                                    backgroundColor: theme.colors.brand[4],

                                    svg: {
                                        transform: 'scale(1.1)',
                                    },
                                },
                            },
                            '&.active': {
                                color: theme.white,
                                '.themeIcon': {
                                    color: theme.colors.brand[1],
                                    backgroundColor: theme.colors.brand[6],
                                },
                            },
                        },
                        '.panelHeading': {
                            borderBottom: `1px solid ${theme.colors.gray[2]}`,
                            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
                        },
                        '.h-scroll-table': {
                            'thead th': {
                                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[8]}` : `${theme.white}`,
                            },
                            'thead th:nth-child(2),tbody td:nth-child(2)': {
                                borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.black}` : `1px solid ${theme.colors.gray[3]}`,
                                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[8]}` : `${theme.white}`,
                            },
                            'tr:hover': {
                                'td:nth-child(2)': {
                                    backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[5]}` : `${theme.colors.gray[1]}`,
                                },
                            },
                        },
                        '.h-scroll-table-first-column': {
                            'thead th': {
                                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[8]}` : `${theme.white}`,
                            },
                            'thead th:nth-child(1),tbody td:nth-child(1)': {
                                borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.black}` : `1px solid ${theme.colors.gray[3]}`,
                                backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[8]}` : `${theme.white}`,
                            },
                            'tr:hover': {
                                'td:nth-child(1)': {
                                    backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[5]}` : `${theme.colors.gray[1]}`,
                                },
                            },
                        },
                        '.router-link':{
                            color:theme.colors.brand[4],
                            textDecoration:'none',
                            ':hover':{
                                textDecoration:'underline'
                            }
                        }
                    }),
                    colors: {
                        brand:
                            colorScheme === 'light'
                                ? ['#e6efea', '#F0F4E6', '#d0dfe0', '#7e9b9d', '#658284', '#BBD1C6', '#577470', '#36494a', '#1d2c2d', '#2B3A3B']
                                : ['#FAFCF6', '#F0F4E6', '#d0dfe0', '#7e9b9d', '#7EAEA9', '#BBD1C6', '#577470', '#36494a', '#7EAEA9', '#2B3A3B'],

                        // brand: [
                        //   "#E9EAF5",
                        //   "#C8CBEA",
                        //   "#A5ACE6",
                        //   "#7E89E9",
                        //   "#5263F4",
                        //   "#4656DF",
                        //   "#3F4DC9",
                        //   "#414DAC",
                        //   "#444C92",
                        //   "#444A7C",
                        //   "#43476B"
                        // ],
                    },
                    primaryColor: 'brand',
                    components: {
                        // Divider: {
                        //   // Subscribe to theme and component params
                        //   styles: (theme, params) => ({
                        //     root: {
                        //       height: 42,
                        //       padding: '0 30px',
                        //       backgroundColor:
                        //         params.variant === 'filled'
                        //           ? theme.colors[params.color || theme.primaryColor][9]
                        //           : undefined,
                        //     },
                        //   }),
                        // },
                    },
                }}>
                <NotificationsProvider>
                    <BrowserRouter>
                        {/* <AltitudeSpotlight> */}
                            <div>
                                {/* progress loader */}
                                {loadProgress ? (
                                    <Box className='progress-bar' sx={(theme) => ({ position: 'fixed', top: 0, left: 0, right: 0, height: 3, backgroundColor: theme.colors.dark, zIndex: 101 })}>
                                        <Box
                                            className='progress-inner'
                                            sx={(theme) => ({
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                minWidth: `${loadProgress}%`,
                                                height: 3,
                                                background: theme.fn.linearGradient(45, '#BBD1C6', '#FDABA4'),
                                            })}></Box>
                                    </Box>
                                ) : null}
                                <ErrorBoundary user={user}>
                                    <Routes>
                                        <Route path='/*' element={<Shell theme={colorScheme} user={user} />} />
                                        <Route
                                            path='/approval/*'
                                            element={
                                                // <ErrorBoundary>
                                                <Suspense fallback={<div></div>}>
                                                    <ApprovalModule theme={colorScheme} />
                                                </Suspense>
                                                // </ErrorBoundary>
                                            }
                                        />
                                        <Route path='/auth/*' element={<Auth />} />
                                    </Routes>
                                </ErrorBoundary>
                            </div>
                        {/* </AltitudeSpotlight> */}
                    </BrowserRouter>
                </NotificationsProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    );
};

const AltitudeSpotlight = ({ children }) => {
    const navigation = useNavigate();
    const spotlightActions = [
        {
            title: 'Captures',
            description: 'Search and manage captures',
            onTrigger: () => navigation('/approval'),
            icon: (
                <ThemeIcon color='yellow' size='lg' variant='light'>
                    <IconCamera size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Events',
            description: 'Search and manage events',
            onTrigger: () => navigation('/seasons'),
            icon: (
                <ThemeIcon color='brand' size='lg' variant='light'>
                    <IconCalendarEvent size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Events / Sessions',
            description: 'Search and manage sessions',
            onTrigger: () => navigation('/sessions'),
            icon: (
                <ThemeIcon color='brand' size='lg' variant='light'>
                    <IconCalendarEvent size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Events / Sessions / Tasks',
            description: 'Search and manage all session tasks',
            onTrigger: () => navigation('/sessions/tasks'),
            icon: (
                <ThemeIcon color='brand' size='lg' variant='light'>
                    <IconCalendarEvent size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Warehouse',
            description: 'Search and manage Samples and Scans',
            onTrigger: () => navigation('/warehouse'),
            icon: (
                <ThemeIcon color='grape' size='lg' variant='light'>
                    <IconColorSwatch size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Warehouse / Feed',
            description: 'View and manage styles',
            onTrigger: () => navigation('/warehouse/feed'),
            icon: (
                <ThemeIcon color='grape' size='lg' variant='light'>
                    <IconColorSwatch size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Warehouse / Scans',
            description: 'Search and manage Samples and Scans',
            onTrigger: () => navigation('/warehouse'),
            icon: (
                <ThemeIcon color='grape' size='lg' variant='light'>
                    <IconColorSwatch size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        // {
        //   title: 'Outfitter',
        //   description: 'Create outfits from samples',
        //   onTrigger: () => navigation('/warehouse/outfitter/create') ,
        //   icon:  <ThemeIcon color='teal' size="lg" variant="light">
        //   <IconSearch size={18} strokeWidth={2} />
        // </ThemeIcon>,
        // },
        {
            title: 'Library / Style Guides',
            description: 'Search and manage Style Guides',
            onTrigger: () => navigation('/styleguides'),
            icon: (
                <ThemeIcon color='lime' size='lg' variant='light'>
                    <IconBook size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
        {
            title: 'Library / Locations',
            description: 'Search and manage Locations for shoots',
            onTrigger: () => navigation('/locations'),
            icon: (
                <ThemeIcon color='lime' size='lg' variant='light'>
                    <IconBook size={18} strokeWidth={2} />
                </ThemeIcon>
            ),
        },
    ];

    return (
        <SpotlightProvider actions={spotlightActions} searchIcon={<IconSearch size={18} />} searchPlaceholder='Search...' shortcut={['mod + P', 'mod + K', '/']} nothingFoundMessage='Nothing found...'>
            {children}
        </SpotlightProvider>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isLoading: state.appReducer.loading,
});

const mapDispatchToProps = { setUser, setPreviousRoute };

export default connect(mapStateToProps, mapDispatchToProps)(App);

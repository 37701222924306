import { Badge, Box, keyframes } from '@mantine/core';

// Define the pulse animation
const pulse = keyframes({
  '0%': { transform: 'scale(1)', opacity: 0.75 },
  '50%': { transform: 'scale(2)', opacity: 0 },
  '75%': { transform: 'scale(1)', opacity: 0.0 },
  '100%': { transform: 'scale(1)', opacity: 0.75 }
});

// Mantine component with styles
const PulseIndicator = ({ 
  color = 'brand', 
  size = 'sm',
  label = 'Live'
}) => {
  return (
    <Badge
      variant="light"
      size={size}
      color={color}
      styles={(theme) => ({
        root: {
          padding: '8px 12px',
          cursor:'default',
        },
        inner: {
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          overflow:'visible'
        },
        'span':{
            overflow:'visible'
        }
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          width: size === 'xs' ? 6 : size === 'sm' ? 8 : size === 'md' ? 10 : 12,
          height: size === 'xs' ? 6 : size === 'sm' ? 8 : size === 'md' ? 10 : 12,
        })}
      >
        {/* Static dot */}
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: theme.colors.lime[9],
          })}
        />
        {/* Animated pulse */}
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: theme.colors.lime[3],
            animation: `${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`
          })}
        />
      </Box>
      {label}
    </Badge>
  );
}

export default PulseIndicator
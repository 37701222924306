export class SortByModel {
    constructor(obj) {
        this.id = '';
        this.desc = true;

        if (obj) {
            Object.assign(this, obj);
        }
    }
}

import { useEffect, useState } from 'react';
import { Center, SegmentedControl } from '@mantine/core';
import { IconGridDots, IconList } from '@tabler/icons';

export default function ListToggleControl({value,onChange,types=[
  { label: (
      <Center>
        <IconList size={16} />
      </Center>
    ),value: 'list' },
  { label:(
      <Center>
        <IconGridDots size={16} />
      </Center>
    ), value: 'grid' },
  
]}) {
  
  const [type, setValue] = useState(value);

  useEffect(() => {
    if(type !== value){
      setValue(value)
    }
  },[value,type])

  const changerHandler = (val) => {
    onChange(val);
  }

  return (
    <SegmentedControl
      value={type}
      onChange={(val) => {
        changerHandler(val);
      }}
      data={types}
    />
  );
}

export const ListViewOption = {
  LIST:'list',
  GRID:'grid',
  BOARD:'board'
}
import { Box, Button, Center, createStyles, Group, Paper, PasswordInput, Popover, Progress, Text, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from "react-router-dom";
import { cleanRequests, initialiseUser, initUserSuccess, loginUser } from '../../authSlice';
import { useState } from 'react';
import { IconCheck, IconCircleCheck, IconEyeCheck, IconEyeOff, IconX } from '@tabler/icons';
import Lottie from 'lottie-react';
import successAnim from "../../../../assets/lottie/success.json";

// Styles
const useStyles = createStyles((theme) => ({
  container: {
    // backgroundColor:'rgb(174 182 255 / 7%)',
    backgroundColor:theme.white,
    padding:`${theme.spacing.md *2}px`,
    borderRadius:theme.radius.md,

    '.mantine-Popover-dropdown':{
      background:'white',
      boxShadow:theme.shadows.lg,
      borderColor:theme.colors.gray[1]
    },
    '.mantine-Popover-arrow':{
      borderColor:theme.colors.gray[1],
    }
},
  title: {
    // color: '#ffffff',
  },
  lottieHolder:{
    height:140,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    '>div':{
      marginTop:-30,
    }
  }
}));

/**
 * Access
 * Component to let the user get setup via initialisation
 * @returns 
 */
const Access = ({success,error}) => {
  
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [setupPhrase, setSetupPhrase] = useState('');
  const [isAuthed,setIsAuthed] = useState(false);
  
  const params = useParams();  
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
    },
  });

  useEffect(() => {
    dispatch(cleanRequests());
  },[])

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.getInputProps('password').value)} />
  ));

  const strength = getStrength(form.getInputProps('password').value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  useEffect(() => {
    setSetupPhrase(params.setupPhrase);
  }, [params])

  const onSubmit = (values) => {
    setIsLoading(true);
    const data = { password: values.password, setupPhrase: '' }
    dispatch(initialiseUser(setupPhrase,data))
  }

  useEffect(() => {

    if(success && success.type === initUserSuccess.toString()){
      setIsAuthed(true);
      setIsLoading(false);
      dispatch(cleanRequests());
    }

    if(error){
      setIsLoading(false);
    }
    
  },[error,success])

  const loginClick = () => {
    navigate('/auth/login');        
  }

  return (

    <Center sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }} mr='xl'>

        {isAuthed?
        <Paper className={classes.container}>
          <Group>
            {/* <IconCircleCheck size={100} stroke="1" color="teal" /> */}
            <Box className={classes.lottieHolder}>
              <Lottie animationData={successAnim} loop={false} />
            </Box>
            <Box className={classes.instructions}>
                <Title order={2} mb='xl' sx={{ textAlign: 'center' }} className={classes.title}>Account Created</Title>
                <Text size='sm' sx={(theme) => ({color:theme.colors.dark[8]})}>
                Thanks for signing up to Altitude.
                <br></br>
                <br></br>
                We've created your account so you're ready to go. You can now login to get started
                </Text>
                <Group mt={'xl'} mb="lg">
                  <Button onClick={() => loginClick()} fullWidth className='submit'>Login</Button>
                </Group>
              </Box>
          </Group>
        
          

        </Paper>
        :
        <Paper className={classes.container}>
          <Title order={2} mb='xl' sx={{ textAlign: 'center' }} className={classes.title}>Create your account</Title>

          {setupPhrase && setupPhrase.length ?
            <form onSubmit={form.onSubmit(onSubmit)}>

              <Popover
                opened={popoverOpened}
                position="bottom"
                placement="start"
                withArrow
                styles={{ popover: { width: '100%' } }}
                trapFocus={false}
                transition="pop-top-left"
                onFocusCapture={() => setPopoverOpened(true)}
                onBlurCapture={() => setPopoverOpened(false)}>
                <Popover.Target>
                  <PasswordInput
                    required
                    label="Your password"
                    placeholder="Your password"
                    description="Strong password should include letters in lower and uppercase, at least 1 number, at least 1 special symbol"
                    {...form.getInputProps('password')}
                  />
                </Popover.Target>
                <Popover.Dropdown>
                  <Progress color={color} value={strength} size={5} sx={(theme)=>({ marginBottom: 10, backgroundColor:theme.colors.gray[3]})} />
                  <PasswordRequirement label="Includes at least 6 characters" meets={form.getInputProps('password').value.length > 5} />
                  {checks}
                </Popover.Dropdown>
              </Popover>


              <PasswordInput mt="lg"
                label="Confirm Password"
                placeholder="" required
                {...form.getInputProps('confirmPassword')}
                visibilityToggleIcon={({ reveal, size }) =>
                  reveal ? <IconEyeOff size={size} /> : <IconEyeCheck size={size} />
                }
              />

              <Group position="right" mt="xl" mb="lg">
                <Button sx={{ width: '100%' }} type="submit" loading={isLoading}>{isLoading?'Please wait...':'Continue'}</Button>
              </Group>

            </form>
            :
            <Box>
              <Title order={3} className={classes.title}>Sorry you don't have access</Title>
            </Box>
          }

        </Paper>
        }
        
      </Box>
    </Center>
  )

};

const mapStateToProps = (state, props) => {
  return (
    {
      user: state.auth.user,
      success: state.auth.success,
      error: state.auth.error
    })
}

const mapDispatch = { loginUser }

export default connect(
  mapStateToProps,
  mapDispatch
)(Access)


function PasswordRequirement({ meets, label }) {
  return (
    <Text
      color={meets ? 'teal' : 'red'}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? <IconCheck /> : <IconX />} <Box ml={10}>{label} {meets}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export function PasswordStrength() {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState('');
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(value)} />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      placement="start"
      withArrow
      styles={{ popover: { width: '100%'}, dropdown:{backgroundColor:'white', width:10} }}
      trapFocus={false}
      transition="pop-top-left"
      onFocusCapture={() => setPopoverOpened(true)}
      onBlurCapture={() => setPopoverOpened(false)}
      target={
        <PasswordInput
          required
          label="Your password"
          placeholder="Your password"
          description="Strong password should include letters in lower and uppercase, at least 1 number, at least 1 special symbol"
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
        />
      }
    >
      
      <Progress color={color} value={strength} size={5} style={{ marginBottom: 10 }} />
      <PasswordRequirement label="Includes at least 6 characters" meets={value.length > 5} />
      {checks}
      
    </Popover>
  );
}  
import { useQuery } from "./helper";

// class AppHistory {

//     constructor(){
//         this.history = useNavigate();
//         this.query = useQuery();
//     }

//     push(val){
//         this.history(val + "?" + this.query.toString());
//     }

// }
// export default AppHistory;

export const AltHistory = (history) => {
    
    const query = useQuery();

    const push = (val,clearParams=false) =>{
        
        if(val === './'){
            history(val);
        }else{
            const params = clearParams?'':`?${query.toString()}`;
            history(val + params);
        }
        
    }

    return {push}
}

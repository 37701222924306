import { useEffect, useState } from "react";
import TableBody from "./SortableTableBody";
import TableHead from "./SortableTableHead";
import { Table } from '@mantine/core';
import { logger } from "utils/logger";

const SortableTable = ({data,rows,columns,showLoading}) => {
  const [tableData, setTableData] = useState([...data]);
  
  useEffect(() => {
    setTableData(data);
  },[data])

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;

        logger.log(sortField, a[sortField])
        if(a[sortField] && a[sortField] !== undefined && b[sortField]){
          return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        }else{
          return 1
        }
        
      });
      setTableData(sorted);
    }
  };

  return (
    <>
      <Table className="table">
        {/* <caption>
          Developers currently enrolled in this course, column headers are
          sortable.
        </caption> */}
        <TableHead {...{ columns, handleSorting }} />
        <TableBody {...{ columns, tableData, rows, showLoading }} />
      </Table>
    </>
  );
};

export default SortableTable;
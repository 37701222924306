import {  Button } from '@mantine/core';
import { IconX } from '@tabler/icons';
import React from 'react';

const FilterButton = ({count,onClick,onClear}) => {
   
   
    return (
        <Button sx={(theme) => ({marginRight:0,paddingRight:count?3:theme.spacing.md})} variant={count?'filled':'default'} onClick={(e) => onClick(e)} rightIcon={count?<div className={'button-clear-icon'} onClick={(e) => onClear(e)} color={'brand'} variant='light'><IconX size={14} /></div>:null}>Filter {count?`(${count})`:null} </Button>
    )
}

export default FilterButton;




import { Card, Image, Text, Badge, Button, Group, useMantineTheme } from '@mantine/core';

export function SampleCard({data, size='md', onShowClick, labelPosition='inside'}) {
  const theme = useMantineTheme();
  const imgURL = `${process.env.PUBLIC_URL}/${data.filepath}`;
  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];


  return (
    <div style={{ width: 'auto', margin: 'auto' }}>
      <Card shadow="" p={size} sx={(theme) => ({
        border:`1px solid #ffffff`,
        borderColor:theme.colorScheme === 'dark'? theme.colors.dark[4]:theme.white,
        '&:hover': {
          border: `1px solid ${theme.colors.gray[1]}`,
          borderColor:theme.colorScheme === 'dark'? theme.colors.dark[2]:theme.colors.gray[2],
        }
      })}>
        <Card.Section>
          <Image fit="contain" src={imgURL} height={160} />
        </Card.Section>

        {size !== 'sm'?
        <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
          <Text weight={500}>{data.name}</Text>
          <Badge color="pink" variant="light">
            Delayed
          </Badge>
        </Group>
        :null}

        {labelPosition==='inside'?
        <Group mb='xs'>
            <Text size="xs" color="dimmed" style={{ color: secondaryColor, lineHeight: 1.5 }}>
            SKU: {data.id}
            </Text>
        </Group>
        :null    
        }
        
        {/* <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5 }}>
          {data.notes}
        </Text> */}
        {size !== 'sm'?
        <Button variant="light" color="brand" fullWidth style={{ marginTop: 14 }} onClick={() => onShowClick(data)}>
          View Sample Details
        </Button>
        :null}
      </Card>

      {labelPosition==='outside'?
        <Group mb='xs'>
            <Text size="xs" color="dimmed" style={{ color: secondaryColor, lineHeight: 1.5 }}>
            SKU: {data.id}
            </Text>
        </Group>
        :null    
        }

    </div>
  );
}
import Lottie from 'lottie-react';
import successAnim from '../../../../assets/lottie/success.json';

import {
    Badge,
    Box,
    Button,
    Card,
    Center,
    createStyles,
    Divider,
    Grid,
    Group,
    Paper,
    ScrollArea,
    Image,
    SimpleGrid,
    Skeleton,
    Stack,
    Stepper,
    Text,
    Title,
    Table,
    Switch,
    Tooltip,
    ThemeIcon,
    Modal,
} from '@mantine/core';
import { IconAlertCircle, IconCategory, IconGripVertical, IconWebhook } from '@tabler/icons';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { DroppableShot } from 'modules/Guides/features/guide/GuideCategory';
import { fetchGuides } from 'modules/Guides/guideSlice';
import { assignCaptureToGuideCategory } from 'modules/Sessions/sessionSlice';
import { batchUpdateStyleAsset, batchUpdateStyleAssetSuccess, distributeStyles, updateStyleAsset, updateStyleAssetSuccess } from 'modules/Styles/styleSlice';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect, useDispatch } from 'react-redux';
import { getStyleAssets } from 'services/styleService';

import { pluralize } from 'utils/helper';
import { logger } from 'utils/logger';
import { closeAllModals } from '@mantine/modals';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, closestCenter, defaultDropAnimationSideEffects, useSensor, useSensors } from '@dnd-kit/core';
import { useHover, useLocalStorage } from '@mantine/hooks';

import { CSS } from '@dnd-kit/utilities';
import { DndProvider } from 'react-dnd';
import { createPortal } from 'react-dom';
import { ListToggleControl } from 'core/components/index';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { StyleAssetStatusTypes } from 'core/models/StyleAssetFilterModel';
import { CaptureEventType } from 'core/components/capture/CaptureThumb';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import { showNotification } from '@mantine/notifications';

const headerHeight = 450;
const panelHeights = `calc(100vh - ${headerHeight}px)`;

const useStyles = createStyles((theme, _params, getRef) => ({
    mainContainer: {
        border: '1px solid black',
        borderRadius: theme.radius.md,

        '.cardSection': {
            margin: 0,
            marginTop: '0 !important',
        },
    },
    accordianLoader: {
        border: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
        padding: theme.spacing.md,
    },
    leftPanel: {
        minHeight: 120,
        width: 350,
        maxWidth: 350,
        // position:'relative',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        // bottom:0,
        // top:136,
        // left:220,

        alignSelf: 'stretch',
        // height: panelHeights,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.radius.md,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: '1px solid #000',
        flex: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        // flexDirection:'column',
    },
    capturePanel: {
        borderTop: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    },
    rightPanel: {
        alignSelf: 'stretch',
        // height: panelHeights,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        borderLeft: `1px solid #efefef`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    },

    useIndicator: {
        position: 'absolute',
        top: theme.spacing.sm,
        right: theme.spacing.sm,
        opacity: 0,
        transition: 'opacity .2s eas-in-out',

        '&.inUse': {
            opacity: 1,
        },
    },

    imageWrapper: {
        // background:'red',
        borderRadius: theme.radius.sm,
        '&:hover': {
            background: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
        },
    },
    categoryPanel: {
        borderBottom: `1px solid`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
        height: '100%',
    },
    grip: {
        ref: getRef('grip'),
        opacity: 0,
        top: 15,
        position: 'absolute',
        zIndex: 10,
        color: theme.colors.white,
        paddingLeft: 0,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: 26,
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
        background: 'transparent',
    },
    seqNum: {
        ref: getRef('seqNum'),
        opacity: 1,
        transition: 'opacity .2s ease-in-out',
    },
    buttonGrip: {
        ref: getRef('buttonGrip'),
        padding: 0,
    },
    draggableItem: {
        [`&:hover .${getRef('grip')}`]: {
            opacity: 1,
            top: 0,
        },
        [`&:hover .${getRef('seqNum')}`]: {
            opacity: 0,
        },
        [`&:hover .${getRef('imageBadge')}`]: {
            background: `${theme.colors.brand[4]} !important`,
        },
        [`&:hover .${getRef('buttonGrip')}`]: {
            background: `${theme.colors.brand[4]} !important`,
        },
    },
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        cursor: 'pointer',
        '.cardSection': {
            textAlign: 'center',
            // paddingTop:theme.spacing.xl,
            // paddingBottom:theme.spacing.xl,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
    },
    label: {
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
    image: {
        objectFit: 'contain',
        borderRadius: theme.radius.sm,
        overflow: 'hidden',
    },
    requireBadge: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 998,
        backgroundColor: theme.colors.brand[4],
        color: theme.colorScheme === 'dark' ? theme.white : theme.white,
    },
    gripWrapper: {
        opacity: 1,
        overflow: 'hidden',
        height: 26,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        width: 40,
    },
    imageBadge: {
        ref: getRef('imageBadge'),
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: theme.radius.md,
        zIndex: 9,
        // background:'none'
    },
    seqTitle: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.white,
        position: 'absolute',
    },
    preview: {
        '.overlay': {
            opacity: 0,
        },
        '&:hover': {
            '.overlay': {
                opacity: 0.5,
            },
            '.btn-clear': {
                opacity: 1,
                marginTop: 0,
            },
        },
        '.btn-clear': {
            opacity: 0,
            marginTop: 10,
            transition: 'all .2s ease-in-out',
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing.sm,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        // paddingBottom: theme.spacing.sm,
        marginTop: theme.spacing.md,
        marginLeft: -38,
        marginRight: -16,
        borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[4]}`,
    },
    title: {
        fontFamily: `Greycliff CF, var(--mantine-font-family)`,
        lineHeight: 1.9,
    },

    distCard: {
        img: {
            transition: 'all .2s ease',
        },
        '&:hover': {
            border: `1px solid ${theme.colors.brand[3]}`,
            img: {
                transform: 'scale(1.1 )',
            },
        },
        '&.active': {
            border: `1px solid ${theme.colors.brand[3]}`,
        },
        cursor: 'pointer',
        backgroundColor: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[4]}`,
    },

    carTitle: {
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.xl,
        height: 90,
        backgroundColor: theme.colorScheme === 'dark' ? `${theme.colors.dark[4]}` : `${theme.colors.gray[4]}`,
        transition: `boxShadow '150ms ease' transform 100ms ease`,
        '&:hover': {
            boxShadow: theme.shadows.xl,
            transform: 'scale(1.05)',
        },
    },
    lottieHolder: {
        height: 140,
        width: 450,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '>div': {
            // marginTop: -30,
        },
    },
}));

const Distribution = ({ selectedItems, guides, success }) => {
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const session = null;
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [active, setActive] = useState(0);
    const hasFetched = useRef(false);
    const [listView, setListView] = useLocalStorage({ key: 'distribution-viewType', defaultValue: ListViewOption.GRID });

    const nextStep = async () => {
        setActive((current) => (current < 3 ? current + 1 : current));
        // if we are on the last step save the distrbution
        if (active === 2) {
            dispatch(distributeStyles(selectedItems.map((val) => val.id)));
            setIsLoading(true);
        } else if (active === 0) {
            // we update the styles assignedSequences
            for (let i = 0; i < data.length; i++) {
                const style = data[i];

                // loop over each style item.
                for (let j = 0; j < style.shots.length; j++) {
                    const item = style.shots[j];
                    if(item.capture){
                        const assignedSequence = `${item.capture.assignedSequence.split('_')[0]}_${item.distIndex}`;
                        dispatch(updateStyleAsset({ id: item.capture.stylePart }, { id: item.capture.styleSort, assignedSequence: assignedSequence }));
                    }
                    
                }
            }
        }
    };

    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const [distSelections, setDistSelections] = useState(['web']);
    const [showShotMeta, setShowShotMeta] = useLocalStorage({ key: 'distribution-showShotMeta', defaultValue: false });

    const loaderRows = [1, 2, 3, 4, 5, 6].map((val) => (
        <Card key={val} withBorder className={classes.card}>
            <Card.Section className={'cardSection'} p={'sm'}>
                <Group align='center' position='center' noWrap>
                    <Skeleton height={200} radius='md' width='100%' />
                    <Skeleton height={200} radius='md' width='100%' />
                </Group>
            </Card.Section>

            <Group position='left' spacing={'xs'} mt='md' noWrap sx={{ alignItems: 'start' }}>
                <div style={{ flexShrink: 1 }}>
                    <Skeleton height={18} radius='xl' width={100} />
                </div>
            </Group>
        </Card>
    ));


    useEffect(() => {
        document.querySelector('.mantine-Modal-inner').style.overflow = 'hidden';
        console.log('Modal element:', document.querySelector('.mantine-Modal-inner'));

    },[])

    const fetchAllStyles = useCallback(async () => {
        var items = [];

        /**
         * Prepare the shots and set the correct orders for the assinged Sequence and
         * Guide Category orders
         * @param {*} style
         * @returns
         */
        const prepareShots = (style) => {
            if (style) {
                var guide;
                var category;
                var catHash = {};

                logger.log(style.shots);
                if (style.shots && style.shots.length) {
                    for (let i = 0; i < style.shots.length; i++) {
                        const guideId = style.shots[i].guideID;
                        guide = guides.find((g) => g.id === guideId);

                        if (guide && guide.guideCategories) {
                            var foundCat = [...guide.guideCategories].find((gc) => (gc.id === style.shots[i].catId ? { ...gc, shots: [] } : null));
                            category = { ...foundCat, shots: [] };

                            if (category && foundCat && foundCat.shots) {
                                category.shots = [...foundCat.shots].map((val) => ({ ...val }));
                            }
                            if (category && category.shots) {
                                // find the shot with the matching cat and seq
                                // var uniqueShots = ensureUniqueSequence([...style.shots]);
                                var uniqueShots = [...style.shots].filter((valShot) => (valShot.viewstatus && valShot.viewstatus === StyleAssetStatusTypes.ARCHIVED ? false : true));
                                const categoryId = category.id; // create a local ref

                                // grab the shots with that cat and order them by assigned seq.
                                var catShots = uniqueShots.filter((itemShot) => {
                                    return itemShot.catId === categoryId;
                                });

                                if (catShots) {
                                    catShots.sort((a, b) => {
                                        const aCatIdx = parseInt(a.assignedSequence.split('_')[0]);
                                        const bCatIdx = parseInt(b.assignedSequence.split('_')[0]);

                                        return aCatIdx - bCatIdx;
                                    });
                                }
                                // for (var j = 0; j < category.shots.length; j++) {
                                for (var j = 0; j < category.shots.length; j++) {
                                    var shot = category.shots[j];
                                    var tmpShot;

                                    if (catShots) {
                                        // match the shot with the correct position
                                        // we can't just use the index we need to the the assigned seq value.
                                        var assignedIdx = j;
                                        var foundShot = catShots.find((tmpCatShot) => {
                                            const tmpSeq = tmpCatShot.assignedSequence.split('_')[0];
                                            return tmpSeq === shot.seq;
                                        });

                                        tmpShot = foundShot ?? null;
                                    }

                                    if (tmpShot) {
                                        // var clonedObject = _.clone(originalObject, true);
                                        const { shots, ...restCat } = category;
                                        // const {shots, ...restCat} = category
                                        let sht = { ...tmpShot, guideCategory: restCat, guide: guide };
                                        // shot.capture = { ...tmpShot,guideCategory:{...category},guide:{...guide} } ?? null;
                                        // if the assigned sequence contains undefined.
                                        if (sht && sht.assignedSequence.indexOf('undefined') > -1) {
                                            sht.assignedSequence = '';
                                        }
                                        shot.capture = { ...sht };
                                    } else {
                                        shot.capture = null;
                                    }
                                }

                                // if we have excess shots.
                                if (catShots.length > category.shots.length) {
                                    logger.log(catShots);
                                    for (var k = category.shots.length; k < catShots.length; k++) {
                                        const { shots, ...restCat } = category;
                                        let sht = {
                                            id: `${k}`,
                                            referenceUrl: '',
                                            seq: `${k + 1}`,
                                            capture: {
                                                ...catShots[k],
                                                guideCategory: restCat,
                                                guide: guide,
                                                assignedSequence: catShots[k].assignedSequence.length ? catShots[k].assignedSequence : `${k + 1}_${k + 1}`,
                                            },
                                        };
                                        if (sht && sht.capture.assignedSequence.indexOf('undefined') > -1) {
                                            sht.assignedSequence = '';
                                        }
                                        category.shots.push(sht);
                                    }
                                }
                            }

                            if (category) {
                                catHash[category.id] = { ...category, guide: guide };
                            }
                        }
                    }
                }

                var shots = [];
                var distIndex = 1;
                var distIndexHash = {};
                var totalSlots =  0;
                // Get the total number of slots so we can use this to add to dist index for slots without assigned shots.
                Object.entries(catHash).forEach(([key, value]) => {
                    totalSlots = totalSlots + value.shots.length;
                });
                logger.log(catHash, totalSlots);

                // now for each category set the correct shot, also set the distIndex that's used to sort the display
                Object.entries(catHash).forEach(([key, value]) => {
                    var cat = { ...value };
                    delete cat.shots;
                    delete cat.guide;

                    const tmpCatShots = [...value.shots].map((val) => {
                        return { ...val, guide: value.guide, guideCategory: cat };
                    });

                    // sort the shots based on the assignedSequence distribution index value. 2_3 the 3 is the dist index.
                    tmpCatShots.sort((a, b) => {
                        // If either item doesn't have a capture object
                        if (!a.capture || !b.capture) {
                            // If a doesn't have capture but b does, put a last
                            if (!a.capture && b.capture) return 1;
                            // If b doesn't have capture but a does, put b last
                            if (a.capture && !b.capture) return -1;
                            // If neither has capture, keep their relative order
                            if (!a.capture && !b.capture) return 0;
                        }

                        if (a.capture && b.capture) {
                            const [aFirst, aSecond] = a.capture.assignedSequence?.split('_').map(Number);
                            const [bFirst, bSecond] = b.capture.assignedSequence?.split('_').map(Number);

                            // if (aFirst !== bFirst) {
                            //     return aFirst - bFirst;
                            // }
                            return aSecond - bSecond;
                        }
                    });
                    tmpCatShots.forEach((shot) => {
                        if (shot.capture && shot.capture.viewstatus !== StyleAssetStatusTypes.ARCHIVED) {
                            // logger.log(shot, ' MAP SHOT');
                            const splitsIndex = shot.capture.assignedSequence.split('_');
                            var tmpDistIndex = splitsIndex && splitsIndex[1] ? splitsIndex[1] : distIndex;

                            // convert to integer
                            tmpDistIndex = parseInt(tmpDistIndex);

                            // the distribution index needs be unique and if it's not we reassign it
                            // based on the next available spot
                            if (distIndexHash[tmpDistIndex]) {
                                logger.log('had to get new index')
                                const length = Object.keys(distIndexHash).length + 1;
                                tmpDistIndex = length;
                                while (distIndexHash[tmpDistIndex]) {
                                    ++tmpDistIndex;
                                }
                                // tmpDistIndex = length;
                            }

                            distIndexHash[tmpDistIndex] = shot;

                            // shots.push({ ...shot, distIndex, assignedSequence: distIndex, capture: shot.capture ? { ...shot.capture, previewLo: shot.capture?.thumb, category: value } : null });
                            shots.push({ ...shot, distIndex: parseInt(tmpDistIndex),  splitIdx: splitsIndex.toString(), capture: shot.capture ? { ...shot.capture, previewLo: shot.capture?.thumb, category: value } : null });

                            ++distIndex;
                        } else if (!shot.capture) {
                            var tmpDistIndex = distIndex + totalSlots;

                            // convert to integer
                            tmpDistIndex = parseInt(tmpDistIndex);

                            // the distribution index needs be unique and if it's not we reassign it
                            // based on the next available spot
                            if (distIndexHash[tmpDistIndex]) {
                                const length = Object.keys(distIndexHash).length + totalSlots;
                                tmpDistIndex = length;
                                while (distIndexHash[tmpDistIndex]) {
                                    ++tmpDistIndex;
                                }
                                // tmpDistIndex = length;
                            }

                            distIndexHash[tmpDistIndex] = tmpDistIndex;

                            // shots.push({ ...shot, distIndex, assignedSequence: distIndex, capture: shot.capture ? { ...shot.capture, previewLo: shot.capture?.thumb, category: value } : null });
                            shots.push({ ...shot, distIndex: parseInt(tmpDistIndex + tmpCatShots.length), capture: null });

                            ++distIndex;
                        }
                    });
                });

                // update the dist index to reset indexes that include archived shots.
logger.log(distIndexHash)
                if (shots) {
                    shots
                        .sort((a, b) => a.distIndex - b.distIndex)
                        .forEach((val, idx) => {
                            val.distIndex = idx + 1;
                        });
                }

                // var tmpShots = shots.map((val,idx) => ({...val,distIndex:idx+1}))
                return shots;
            }
        };

        for (let i = 0; i < selectedItems.length; i++) {
            // var item = { ...styles.find((val) => val.id === selectedItems[i].id) };
            var item = { ...selectedItems[i] };
            const assets = await getStyleAssets(item.id);

            var shots = prepareShots({ ...item, shots: assets });
            item.shots = [...shots];
            items.push(item);
        }

        setIsLoading(false);
        setData(items);
    }, [selectedItems, guides]);

    // update the shots when the guide changes.
    useEffect(() => {
        // updateStylesShots();
        if (guides && guides.length) {
            fetchAllStyles();
        }
    }, [guides, fetchAllStyles]);

    // const updateStylesShots = () => {
    //     if (guides && guides.length && data && data.length) {
    //         var items = [];
    //         for (let i = 0; i < data.length; i++) {
    //             var item = { ...data[i] };
    //             // sort by assigned sequence
    //             var shots = prepareShots({ ...item, shots: item.assets });
    //             // var shots = prepareShots({ ...item, shots: assets });

    //             item.shots = [...shots];
    //             items.push(item);
    //         }

    //         setData(items);
    //     }
    // };

    // we need to fetch all the guides first and then fetch the assets
    // as the assets need the guide categories.
    useEffect(() => {
        // grab styles.
        // fetchAllStyles();
        if (!hasFetched.current) {
            hasFetched.current = true;
            // grab the guides
            dispatch(fetchGuides());
        }
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            switch (success.type) {
                case batchUpdateStyleAssetSuccess.toString(): // handled for archiving assets
                case updateStyleAssetSuccess.toString(): {
                    setTimeout(() => {
                        fetchAllStyles(false);
                    }, 10);

                    break;
                }
                default: {
                    break;
                }
            }
        }
    }, [success, fetchAllStyles]);

    /**
     * Handler for a Sample drop onto the outfitter
     * @param {*} item
     * @param {*} target
     */
    const onSampleDrop = useCallback(
        (item, target) => {
            //only allow drops for same guides and categories.
            if (item.capture.guideID !== target.data.guide.id && item.capture.catId !== target.data.guideCategory.id) {
                console.warn('Guides and Categories do not match');
                return;
            }

            const { data } = target;
            // const guide = guides.find((val) => val.code === target.data.capture.guideID);

            logger.log(item, target);
            // if they are't the same style then don't allow the drop.
            var sameStyle = true;

            if (item.capture && data.capture && item.capture.stylePart !== data.capture.stylePart) {
                sameStyle = false;
            }

            if (item.capture && item.capture.actionSort && sameStyle) {
                const captureFirst = {
                    actionSort: item.capture ? item.capture.actionSort : item.actionSort,
                    actionPart: item.capture ? item.capture.actionPart : item.actionPart,
                    guideId: item.capture.guideID,
                    guideName: item.capture.guide.name,
                    guideCatId: item.capture.catId,
                    guideCatName: data.guideCategory.name,
                    slotSeq: (parseInt(target.data.seq) - 1).toString(),
                    distIndex: parseInt(target.data.distIndex).toString(),
                };
                // we update the seq for both the capture and the style
                dispatch(assignCaptureToGuideCategory(captureFirst));
                // dispatch(updateStyleAsset({ id: item.capture.stylePart }, { id: item.capture.styleSort, assignedSequence: target.data.seq }));
                // dispatch(updateStyleAsset({ id: item.capture.stylePart }, { id: item.capture.styleSort, assignedSequence: (parseInt(target.data.seq)-1).toString() }));
                // dispatch(updateStyleAsset({ id: item.capture.stylePart }, { id: item.capture.styleSort, assignedSequence: `${(parseInt(target.data.distIndex)).toString()}` }));
                dispatch(updateStyleAsset({ id: item.capture.stylePart }, { id: item.capture.styleSort, assignedSequence: `${parseInt(target.data.seq)}_${target.data.distIndex}` }));

                if (data.capture) {
                    const captureSecond = {
                        actionSort: data.capture.actionSort,
                        actionPart: data.capture.actionPart,
                        guideId: data.capture.guideID,
                        guideName: data.capture.guide.name,
                        guideCatId: data.capture.catId,
                        guideCatName: data.guideCatName,
                        slotSeq: item.seq && item.seq !== undefined ? (parseInt(item.seq) - 1).toString() : item.distIndex,
                    };
                    // we update the seq for both the capture and the style.
                    // we use the guide seq number as the first reference
                    dispatch(assignCaptureToGuideCategory(captureSecond));
                    // dispatch(updateStyleAsset({ id: data.capture.stylePart }, { id: data.capture.styleSort, assignedSequence: item.seq }));
                    // dispatch(updateStyleAsset({ id: data.capture.stylePart }, { id: data.capture.styleSort, assignedSequence: (parseInt(item.distIndex)).toString() }));
                    dispatch(updateStyleAsset({ id: data.capture.stylePart }, { id: data.capture.styleSort, assignedSequence: `${item.seq}_${item.distIndex}` }));
                }
            }
        },
        [dispatch],
    );

    const onDistTypeClick = (type) => {
        setDistSelections([type]);
    };

    const rows = data.map((element) => (
        <tr key={element.styleNumber}>
            <td>{element.photoCode}</td>
            <td>{element.styleNumber}</td>
            <td>{element.seasonCode}</td>
            <td>{element.sessionCode}</td>
            <td>
                <Badge variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
                    {distSelections[0]}
                </Badge>
            </td>
        </tr>
    ));
    logger.log(data, ' DATA');

    const updateDistrbutionIndex = (style, shots) => {
        // we update the style shots
        const tmpData = data.map((val) => {
            if (style.id === val.id) {
                return { ...style, shots: shots };
            }
            return val;
        });

        setData([...tmpData]);
    };

    return (
        <Box sx={{ height: '100%' }}>
            <Divider />

            <Stepper style={{ display: 'block' }} active={active} onStepClick={setActive} mt='xl'>
                <Stepper.Step label='QA Check.' description='Confirm shot allocations'>
                    <Group position='apart'>
                        <Text mb={'xl'}>
                            Check each selected style and ensure the shot allocations are assigned correctly.
                            <br />
                            You may drag and drop shots into their correct postitions
                        </Text>
                        <Group>
                            <Switch checked={showShotMeta} onChange={(event) => setShowShotMeta(event.currentTarget.checked)} labelPosition='left' label='Show Shot Meta Data' />
                            <ListToggleControl value={listView} onChange={(val) => setListView(val)} />
                        </Group>
                    </Group>
                    {/* <Divider /> */}
                    <Box
                        pt='md'
                        sx={(theme) => ({
                            border: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[3]}`,
                            borderRadius: theme.radius.md,
                        })}>
                        <DndProvider backend={HTML5Backend}>
                            <Stack mb={'lg'} spacing={0}>
                                {isLoading ? (
                                    <>
                                        {listView === ListViewOption.GRID ? (
                                            <SimpleGrid cols={3} px={'lg'} spacing={'lg'}>
                                                {loaderRows}
                                            </SimpleGrid>
                                        ) : (
                                            <Stack>{loaderRows}</Stack>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {!selectedItems.length && !isLoading ? (
                                            <>
                                                {session && session.guides && session.guides.length ? (
                                                    <EmptyState showImage={true} caption='There are no Guide Categories' />
                                                ) : (
                                                    <Center
                                                        sx={{
                                                            flexDirection: 'column',
                                                        }}>
                                                        <EmptyState
                                                            showImage={true}
                                                            caption={
                                                                <Box
                                                                    sx={{
                                                                        justifyContent: 'center',
                                                                        textAlign: 'center',
                                                                    }}>
                                                                    <Text>No Style Guides have been assigned to this session. Please assign a Style Guide before assigning captures.</Text>
                                                                </Box>
                                                            }
                                                        />
                                                    </Center>
                                                )}
                                            </>
                                        ) : null}
                                    </>
                                )}

                                {!isLoading ? (
                                    <Box component={ScrollArea} style={{ width: '100%', height: panelHeights }}>
                                        <Stack px={'md'}>
                                            {/* loop over each selected style */}
                                            {data.map((style, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <StyleDropZone
                                                            styles={data}
                                                            style={style}
                                                            // draggableShots={draggableShots}
                                                            draggableShots={style.shots}
                                                            guides={guides}
                                                            classes={classes}
                                                            idx={idx}
                                                            onSampleDrop={onSampleDrop}
                                                            showShotMeta={showShotMeta}
                                                            onDistributionIndexChange={(shots) => updateDistrbutionIndex(style, shots)}
                                                            listView={listView}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Stack>
                                    </Box>
                                ) : null}
                            </Stack>
                        </DndProvider>
                    </Box>
                </Stepper.Step>
                <Stepper.Step label='Channels.' description='Select distribution channels'>
                    <Center py={'xl'}>
                        <SimpleGrid cols={1} mt='md' sx={{ maxWidth: 600 }}>
                            {distTypes.map((item, idx) => {
                                const active = distSelections.indexOf(item.value) > -1 ? true : false;
                                return (
                                    <Card key={idx} withBorder radius='md' p='md' className={active ? `${classes.distCard} active` : [classes.distCard]} onClick={() => onDistTypeClick(item.value)}>
                                        <Card.Section sx={{ height: 250, overflow: 'hidden' }}>
                                            <Image src={item.image} alt={item.title} height={250} />
                                        </Card.Section>

                                        <Card.Section className={classes.section} mt='md' px={'lg'} pb={'lg'}>
                                            <Group justify='apart'>
                                                <Text fz='lg' fw={500}>
                                                    {item.title}
                                                </Text>
                                            </Group>
                                            <Text fz='sm' mt='xs'>
                                                {item.description}
                                            </Text>
                                        </Card.Section>
                                        <Divider />
                                        <Card.Section className={classes.section} px={'lg'} pb={'lg'}>
                                            <Text mt='md' className={classes.label} c='dimmed'>
                                                Media Formats
                                            </Text>
                                            <Group gap={7} mt={'md'}>
                                                <Badge>webP</Badge>
                                                <Badge>jpeg</Badge>
                                                <Badge>tiff</Badge>
                                            </Group>
                                        </Card.Section>

                                        {/* <Group mt="xs">
                      <Button radius="md" style={{ flex: 1 }}>
                        Show details
                      </Button>

                    </Group> */}
                                    </Card>

                                    // <UnstyledButton key={item.title} className={classes.item}>
                                    //   <item.icon color={theme.colors[item.color][6]} size="2rem" />
                                    //   <Text size="xs" mt={7}>
                                    //     {item.title}
                                    //   </Text>
                                    // </UnstyledButton>
                                );
                            })}
                        </SimpleGrid>
                    </Center>
                </Stepper.Step>

                <Stepper.Step label='Step 3.' description='Confirm Distribution'>
                    <Stack>
                        <Title mt={'lg'} align='center' order={5}>
                            You will be distributing the following Styles to the following channels:
                        </Title>
                        <Group align={'center'} position='center'>
                            <Badge variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} size='xl'>
                                {distSelections[0]}
                            </Badge>
                        </Group>
                        <Center p={'xl'}>
                            <Table withBorder sx={{ maxWidth: 700 }}>
                                <thead>
                                    <tr>
                                        <th>Photo Code</th>
                                        <th>Style</th>
                                        <th>Season</th>
                                        <th>Session</th>
                                        <th>Channel</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        </Center>
                    </Stack>
                </Stepper.Step>

                <Stepper.Completed>
                    <Center my={'xl'}>
                        <Box className={classes.lottieHolder}>
                            <Lottie animationData={successAnim} loop={false} />
                        </Box>
                    </Center>
                    <Text align='center' size={'lg'} fw={800}>
                        Distribution Complete!
                    </Text>
                    <Text align='center'>Your Styles have been distributed. Click away or Done to close</Text>
                </Stepper.Completed>
            </Stepper>
            {active < 3 ? (
                <Group justify='center' mt='xl' sx={{ width: '100%' }} position={'center'}>
                    <Button variant='default' onClick={prevStep}>
                        Back
                    </Button>
                    <Button onClick={nextStep} loading={isLoading}>
                        {active < 2 ? 'Next step' : 'Distribute'}
                    </Button>
                </Group>
            ) : (
                <Group justify='center' mt='xl' sx={{ width: '100%' }} position={'center'}>
                    <Button onClick={() => closeAllModals()}>Done</Button>
                </Group>
            )}
        </Box>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        styles: state.styleReducer.styles,
        guides: state.guideReducer.guides,
        success: state.styleReducer.success,
        error: state.styleReducer.error,
    };
};

const mapDispatch = {};

export default connect(mapStateToProps, mapDispatch)(Distribution);

const distTypes = [{ title: 'Web', value: 'web', icon: IconWebhook, color: 'violet', image: '../../../../assets/images/dist-web.png', description: 'Distribute assets ready for web use' }];

export const DistShot = (props) => {
    const { onShotRemoveClick, onShotPropsChange, resolve, displayStyle, editable, showMetaInfo, onItemDrop, allowAssignment, shot, idx, style, listView, onAssetMenuClick, ...rest } = props;
    const sortable = useSortable({ id: props.value.id });
    const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable;
    const { hovered, ref } = useHover();

    const { classes } = useStyles();
    const styleObj = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Transform.toString(transform),
        transition,
        position: 'relative',
    };

    const shotLabel = shot.capture ? `${style.deptCode}_${shot.capture?.stylePart}_${shot.distIndex}.jpg` : ``;
    return (
        <div ref={setNodeRef} style={styleObj} {...rest}>
            <div ref={ref} className={classes.draggableItem}>
                {/* DIST INDEX: {shot.distIndex} - {shot.capture.assignedSequence} */}
                <DroppableShot
                    resolve={true}
                    shot={shot}
                    channel={''}
                    key={idx}
                    classes={classes}
                    index={idx}
                    allowAssignment={true}
                    displayStyle={'distribution'}
                    showMetaInfo={showMetaInfo}
                    onItemDrop={onItemDrop}
                    sequenceNumberLabel={parseInt(shot['distIndex'])}
                    shotLabel={shotLabel}
                    listView={listView}
                    showAssetMenu={true}
                    onAssetMenuClick={props.onAssetMenuClick}
                />
                {/* <GuideShot editable={props.editable} shot={props.value} classes={props.classes} allowAssignment={false} onChange={props.onShotPropsChange} onRemoveClick={onShotRemoveClick} /> */}
                <Tooltip label='Drag to sort' withinPortal={true}>
                    <div className={classes.gripWrapper}>
                        <Button
                            className={classes.buttonGrip}
                            size='xs'
                            variant='subtle'
                            {...listeners}
                            {...attributes}
                            sx={{
                                position: 'absolute',
                                top: 1,
                                left: -4,
                                width: 30,
                                zIndex: 0,
                                borderBottomRightRadius: 0,
                                opacity: hovered ? 1 : 1,
                                background: 'transparent',
                                ':hover': { backgroundColor: 'transparent', cursor: 'move' },
                            }}>
                            <Box className={[props.classes.grip]}>
                                <IconGripVertical size={14} color='white' />
                            </Box>
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.4',
            },
        },
    }),
};

export function SortableOverlay({ children }) {
    return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
}

const StyleDropZone = ({ styles, style, classes, idx, onSampleDrop, showShotMeta, draggableShots, onDistributionIndexChange, listView }) => {
    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    // const [draggableShots, setDraggableShots] = useState([]);
    const [warningCount, setWarningCount] = useState(0);
    const [archiveConfirmOpened, setArchiveConfirmedOpened] = useState(false);
    const [archivedAsset, setArchivedAsset] = useState([]);

    const dispatch = useDispatch();

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    async function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = draggableShots.findIndex((val) => val.id === active.id);
            const newIndex = draggableShots.findIndex((val) => val.id === over.id);

            // WE CAN ONLY MOVE ITEMS IF THEY HAVE A CAPTURE.
            if(!draggableShots[oldIndex].capture || !draggableShots[newIndex].capture){
                showNotification({
                    color:'orange',
                    title: 'Sort Warning',
                    message: 'Guide Category Slots without assets can not be moved',
                  })
                return;
            }
            let tmpShots = arrayMove(draggableShots, oldIndex, newIndex);

            tmpShots = [
                ...tmpShots.map((shot, idx) => {
                    const distIndex = (idx + 1).toString();
                    const assingedSeq = shot.capture ? `${shot.capture.assignedSequence.split('_')[0]}_${distIndex}` : `${shot.seq}_${distIndex}`;
                    // return ({ ...shot, assignedSequence: distIndex, distIndex: distIndex, capture: { ...shot.capture, assignedSequence: distIndex } });
                    return { ...shot, assignedSequence: assingedSeq, distIndex: distIndex, capture: shot.capture ? { ...shot.capture, assignedSequence: assingedSeq } : null };
                }),
            ];

            // for the original item this does't work.
            // sequence catIdx_distIndex
            // we update the positions.

            // UPDATE ALL INDEXES FROM THE START TO OLD INDEX USING THE SORTED ARRAY.
            // get the start and end index based on the direction of sorting.
            const startIdx = oldIndex > newIndex ? newIndex : oldIndex;
            const endIdx = oldIndex > newIndex ? oldIndex : newIndex;

            for (var i = startIdx; i <= endIdx; i++) {
                if (tmpShots[i].capture) {
                    dispatch(
                        updateStyleAsset(
                            { id: tmpShots[i].capture.stylePart },
                            // { id: draggableShots[oldIndex].capture.styleSort, assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}` },
                            {
                                id: tmpShots[i].capture.styleSort,
                                // assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${draggableShots[newIndex].distIndex.toString()}`,
                                // assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}`,
                                assignedSequence: `${tmpShots[i].capture.assignedSequence}`,
                            },
                        ),
                    );
                }
            }
            // __ END NEW WORK

            // THE ITEM WE'RE DRAGGING
            // dispatch(
            //     updateStyleAsset(
            //         { id: draggableShots[oldIndex].capture.stylePart },
            //         // { id: draggableShots[oldIndex].capture.styleSort, assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}` },
            //         {
            //             id: draggableShots[oldIndex].capture.styleSort,
            //             // assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${draggableShots[newIndex].distIndex.toString()}`,
            //             // assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}`,
            //             assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}`,
            //         },
            //     ),
            // );
            // dispatch(
            //     updateStyleAsset(
            //         { id: draggableShots[newIndex].capture.stylePart },
            //         // { id: draggableShots[newIndex].capture.styleSort, assignedSequence: `${draggableShots[newIndex].capture.assignedSequence.split('_')[0]}_${(oldIndex + 1).toString()}` },
            //         {
            //             id: draggableShots[newIndex].capture.styleSort,
            //             // assignedSequence: `${draggableShots[newIndex].capture.assignedSequence.split('_')[0]}_${(oldIndex + 1).toString()}`,
            //             // assignedSequence: `${draggableShots[newIndex].capture.sequence}_${(oldIndex + 1).toString()}`,
            //             // assignedSequence: `${draggableShots[oldIndex].capture.assignedSequence.split('_')[0]}_${(newIndex + 1).toString()}`,
            //             assignedSequence: `${draggableShots[newIndex].capture.assignedSequence.split('_')[0]}_${(oldIndex + 1).toString()}`,
            //         },
            //     ),
            // );

            onDistributionIndexChange(tmpShots);
            // setDraggableShots(tmpShots);
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    useEffect(() => {
        // find any shots that don't have categoryIds
        const missingCatIds = style?.assets?.filter((val) => {
            return !val.guideCategory && val.viewstatus !== StyleAssetStatusTypes.ARCHIVED;
        });

        setWarningCount(missingCatIds?.length ?? 0);
    }, [draggableShots, style.assets]);

    /**
     * Handler to archive the assets, we strip out unwanted
     * attributes and set the status.
     * @param {*} assets
     * @param {*} status
     */
    const archiveAssets = (assets, status) => {
        const items = assets.map((val) => {
            return {
                id: val.styleSort,
                actionPart: val.actionPart,
                actionSort: val.actionSort,
                viewstatus: status.toLowerCase(),
            };
        });

        logger.log(items, style);
        setArchiveConfirmedOpened(false);
        dispatch(batchUpdateStyleAsset(style, items));
    };

    const onAssetMenuClick = (e) => {
        const { asset, type } = e;
        switch (type) {
            case CaptureEventType.ARCHIVE: {
                // if (type === CaptureEventType.UNARCHIVE) {
                //     action = `Restore`;
                //     viewStatus = StyleAssetStatusTypes.ACTIVE;
                // }

                setArchivedAsset([asset.capture]);
                setArchiveConfirmedOpened(true);

                break;
            }
            default: {
                break;
            }
        }
    };
    // useEffect(() => {
    //     function ensureUniqueSequence(arr) {
    //         arr.forEach((item) => {
    //             if (item.assignedSequence === '' || isNaN(item.assignedSequence)) {
    //                 item.assignedSequence = 1; // Default to 0 for invalid sequences
    //             } else {
    //                 item.assignedSequence = parseInt(item.assignedSequence, 10); // Ensure it's an integer
    //             }
    //         });

    //         // Sort the array by sequence
    //         arr.sort((a, b) => a.assignedSequence - b.assignedSequence);

    //         // Create a set to keep track of used sequence numbers
    //         const usedSequences = new Set();

    //         // Loop through the array
    //         for (let i = 0; i < arr.length; i++) {
    //             let currentSequence = arr[i].assignedSequence;

    //             // If the sequence is already used, find the next available number
    //             while (usedSequences.has(currentSequence)) {
    //                 currentSequence++;
    //             }

    //             // Update the sequence and add it to the set of used sequences
    //             arr[i].assignedSequence = currentSequence.toString();
    //             usedSequences.add(currentSequence);
    //         }

    //         return [...arr];
    //     }

    //     logger.log(style);
    //     if (style) {
    //         var guide;
    //         var category;

    //         var catHash = {};

    //         if (style.shots && style.shots.length) {
    //             for (let i = 0; i < style.shots.length; i++) {
    //                 const guideId = style.shots[i].guideID;
    //                 const catId = style.shots[i].catId;

    //                 guide = guides.find((g) => g.id === guideId);

    //                 if (guide && guide.guideCategories) {
    //                     var foundCat = [...guide.guideCategories].find((gc) => (gc.id === style.shots[i].catId ? { ...gc, shots: [] } : null));
    //                     category = {...foundCat,shots:[]};
    //                     // { ...guide.guideCategories[style.shots[i].catId] };
    //                     category.shots = [...foundCat.shots].map((val) => ({ ...val }));

    //                     if (category && category.shots) {
    //                         for (var j = 0; j < category.shots.length; j++) {
    //                             var shot = category.shots[j];
    //                             // find the shot with the matching cat and seq
    //                             logger.log('SHOTT::: ', shot);

    //                             // var uniqueShots = ensureUniqueSequence([...style.shots]);
    //                             var uniqueShots = [...style.shots];

    //                             logger.log('UNIQUE', uniqueShots);
    //                             // grab the shots with that cat and order them by assigned seq.
    //                             var catShots = uniqueShots.filter((itemShot) => itemShot.catId === category.id);
    //                             var tmpShot;
    //                             if (catShots) {
    //                                 catShots.sort((a, b) => {
    //                                     const aCatIdx = parseInt(a.assignedSequence.split('_')[0]);
    //                                     const bCatIdx = parseInt(b.assignedSequence.split('_')[0]);
    //                                     return aCatIdx - bCatIdx;
    //                                 });
    //                                 var tmpShot = catShots[j] ?? null;
    //                             }
    //                             // var tmpShot = uniqueShots.find((itemShot) => {
    //                             //   logger.log('category seq:',shot.seq,' ', 'asset sequence:',itemShot.sequence)

    //                             //     if (itemShot.catId === category.id && itemShot.assignedSequence === (parseInt(shot.seq)).toString()) {
    //                             //         return true;
    //                             //     } else {
    //                             //         return false;
    //                             //     }
    //                             // });

    //                             // tmpShot = {...shot.capture}

    //                             if (tmpShot) {
    //                                 // var clonedObject = _.clone(originalObject, true);
    //                                 const { shots, ...restCat } = category;
    //                                 // const {shots, ...restCat} = category
    //                                 let sht = { ...tmpShot, guideCategory: restCat, guide: guide };
    //                                 // shot.capture = { ...tmpShot,guideCategory:{...category},guide:{...guide} } ?? null;
    //                                 shot.capture = { ...sht };
    //                             } else {
    //                                 shot.capture = null;
    //                             }
    //                         }
    //                     }

    //                     if (category) {
    //                         catHash[category.id] = category;
    //                     }

    //                     logger.log(category);
    //                 }
    //             }
    //         }

    //         logger.log(catHash, style.shots);

    //         var shots = [];
    //         var distIndex = 1;
    //         Object.entries(catHash).forEach(([key, value]) => {
    //             value.shots.forEach((shot) => {
    //                 if (shot.capture) {
    //                     logger.log(shot, ' MAP SHOT');
    //                     const splitsIndex = shot.capture.assignedSequence.split('_');
    //                     const tmpDistIndex = splitsIndex && splitsIndex[1] ? splitsIndex[1] : distIndex;
    //                     // shots.push({ ...shot, distIndex, assignedSequence: distIndex, capture: shot.capture ? { ...shot.capture, previewLo: shot.capture?.thumb, category: value } : null });
    //                     shots.push({ ...shot, distIndex: parseInt(tmpDistIndex), capture: shot.capture ? { ...shot.capture, previewLo: shot.capture?.thumb, category: value } : null });
    //                     ++distIndex;
    //                 }
    //             });
    //         });

    //         setDraggableShots([...shots]);
    //         logger.log(shots, ' +++++++++++++++++++++=', catHash);
    //     }
    // }, [style, guides]);

    logger.log(draggableShots);

    return (
        <Paper pb={'md'} key={idx} className={classes.categoryPanel} sx={{ borderBottom: idx < styles.length - 1 ? '' : 'none' }}>
            <Group
                position='left'
                pb={'md'}
                px={'lg'}
                mx={'-md'}
                grow
                sx={(theme) => ({
                    position: 'sticky',
                    top: -1,
                    zIndex: 999,
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    boxShadow: `0px 10px 10px rgba(0, 0, 0, 0.05)`,
                })}>
                <Group>
                    <Group>
                        <IconCategory stroke={1} />
                        <Title order={5}>
                            {style.id} / {style.photoCode}
                        </Title>
                    </Group>
                    <Badge>
                        {style.shots.length} {pluralize('Asset', style.shots.length)}
                    </Badge>

                    {warningCount ? (
                        <Tooltip position='top' label={`This Style is not showing ${warningCount} ${pluralize('asset', warningCount)} due to missing Guide Category assignment`}>
                            <ThemeIcon variant='light' size={'xs'} color='yellow'>
                                <IconAlertCircle />
                            </ThemeIcon>
                        </Tooltip>
                    ) : null}
                </Group>
            </Group>

            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
                <SortableContext items={draggableShots} strategy={rectSortingStrategy}>
                    {/* {Object.entries(catHash).map(([key, category]) => { */}

                    <>
                        {/* <Title order={6} mb={'lg'}>
                                                                                {category.name}
                                                                         </Title> */}
                        {listView === ListViewOption.GRID ? (
                            <Grid mb={'lg'}>
                                {draggableShots
                                    ?.sort((a, b) => a.distIndex - b.distIndex)
                                    .map((shot, idx) => {
                                        return (
                                            <Grid.Col key={idx} md={6} lg={6} xl={4} px={'lg'}>
                                                {shot ? (
                                                    <DistShot
                                                        value={shot}
                                                        resolve={true}
                                                        shot={shot}
                                                        channel={''}
                                                        key={idx}
                                                        classes={classes}
                                                        index={idx}
                                                        allowAssignment={true}
                                                        displayStyle={'distribution'}
                                                        showMetaInfo={showShotMeta}
                                                        onItemDrop={onSampleDrop}
                                                        style={style}
                                                        listView={listView}
                                                        onAssetMenuClick={onAssetMenuClick}
                                                    />
                                                ) : null}
                                            </Grid.Col>
                                        );
                                    })}
                            </Grid>
                        ) : (
                            <Stack mb={'lg'}>
                                {draggableShots
                                    ?.sort((a, b) => a.distIndex - b.distIndex)
                                    .map((shot, idx) => {
                                        return (
                                            <Box key={idx} md={6} lg={4} xl={3} px={'lg'}>
                                                {shot ? (
                                                    <DistShot
                                                        value={shot}
                                                        resolve={true}
                                                        shot={shot}
                                                        channel={''}
                                                        key={idx}
                                                        classes={classes}
                                                        index={idx}
                                                        allowAssignment={true}
                                                        displayStyle={'distribution'}
                                                        showMetaInfo={showShotMeta}
                                                        onItemDrop={onSampleDrop}
                                                        style={style}
                                                        listView={listView}
                                                    />
                                                ) : null}
                                            </Box>
                                        );
                                    })}
                            </Stack>
                        )}
                    </>

                    {/* })} */}
                </SortableContext>

                {/* <SortableOverlay>{activeId ? renderItem(activeItem) : null}</SortableOverlay> */}
                {createPortal(
                    <DragOverlay adjustScale={false}>
                        {activeId ? (
                            <DistShot
                                value={draggableShots.find((val) => val.id === activeId)}
                                resolve={true}
                                shot={draggableShots.find((shot) => shot.id === activeId)}
                                channel={''}
                                key={idx}
                                classes={classes}
                                index={idx}
                                allowAssignment={false}
                                displayStyle={'distribution'}
                                showMetaInfo={showShotMeta}
                                onItemDrop={() => {}}
                                style={style}
                                listView={listView}
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body,
                )}
            </DndContext>

            <Modal zIndex={999999} opened={archiveConfirmOpened} onClose={() => setArchiveConfirmedOpened(false)} title='Archive Asset Confirm'>
                <Stack pb={'md'}>
                    <Text size='sm'>Are you sure you wish to archive this asset?</Text>
                    <Group spacing={'xs'}>
                        {archivedAsset?.map((val, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <ResolverImage src={val.thumb} width={50} height={50} />
                                </React.Fragment>
                            );
                        })}
                    </Group>
                    <Group position='right'>
                        <Button variant='outline' onClick={() => setArchiveConfirmedOpened(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => archiveAssets(archivedAsset, StyleAssetStatusTypes.ARCHIVED)}>Yes, Archive</Button>
                    </Group>
                </Stack>
            </Modal>
        </Paper>
    );
};

import { useDisclosure } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from 'utils/logger';
import { getStyleStatusColor, getUserAvatarColour, getUserInitials } from 'utils/helper';
import { getSession } from 'services/sessionService';

const { Popover, Text, Group, Button, createStyles, Box, Badge, Avatar } = require('@mantine/core');

const useStyles = createStyles((theme) => ({
    card: {
        //   margin:-theme.spacing.md,
        padding: 0,
        position: 'relative',
    },

    imageSection: {
        padding: theme.spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
    },

    label: {
        marginBottom: theme.spacing.xs,
        lineHeight: 1,
        fontWeight: 700,
        fontSize: theme.fontSizes.xs,
        //   letterSpacing: -0.25),
        textTransform: 'uppercase',
    },

    section: {
        paddingTop: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        margin: 0,
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },
}));

const SessionPopover = (props) => {
    const [opened, { close, open }] = useDisclosure(false);
    const [data, setData] = useState();
    const { classes } = useStyles();
    const popRef = useRef();
    const doClose = useRef(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (opened) {
            logger.log(props.data, data);
            async function fetchSample() {
                const val = await getSession(props.data.ssPart);
                // setData(val);
                setData({ ...props.data, ...val });
            }
            // fetch the sample
            if (!data || (data && data.ssPart !== props.data.ssPart && opened)) fetchSample();
        }
    }, [opened]);

    const mouseHandler = (e) => {
        setTimeout(() => {
            if (doClose.current) {
                close();
                doClose.current = true;
            }
        }, 200);
    };

    const showDetails = () => {
        logger.log(props.data)
        navigate(`/sessions/${props.data.ssPart}`);
        props.onShowDetails();
    };

    return (
        <Popover withinPortal={true} width={300} position='bottom-end' withArrow shadow='md' opened={opened}>
            <Popover.Target>
                <div ref={popRef} onMouseEnter={open} onMouseLeave={(e) => mouseHandler(e)}>
                    {props.children}
                </div>
            </Popover.Target>

            <Popover.Dropdown sx={{ pointerEvents: 'initial' }}>
                {data ? (
                    <Box
                        className={classes.card}
                        style={{ padding: 0 }}
                        onMouseLeave={() => {
                            close();
                            doClose.current = true;
                        }}
                        onMouseEnter={() => {
                            open();
                            doClose.current = false;
                        }}>
                        {/* <Box className={classes.imageSection}>
                            <Image src="https://i.imgur.com/ZL52Q2D.png" />
                            <IconShirt size={72} stroke={1} />
                            <ResolverImage width={120} height={180} src={data.preview} />
                        </Box> */}

                        <Group position='apart' mt='md'>
                            <div>
                                <Text fw={500}>{data?.name}</Text>
                                <Text color='dimmed' size={'sm'} lineClamp={2}>
                                    {data?.sessionId}
                                </Text>
                            </div>
                            <Badge color={getStyleStatusColor(data?.status)} variant='light'>
                                {data?.status}
                            </Badge>
                        </Group>

                        <Box className={classes.section} mt='md'>
                            <Group>
                                <div>
                                    <Text size='sm' c='dimmed' className={classes.label}>
                                        Team
                                    </Text>

                                    <Group spacing={8} mb={-8}>
                                        <Text size={'sm'}>{data?.description}</Text>
                                        {data.team.map((member, idx) => {
                                            const userInitials = member.name ? getUserInitials(member.name.split(' ')[0], member.name.split(' ')[1]) : '';
                                            const themeCol = getUserAvatarColour(userInitials);
                                            const margin = idx ? -10 : 0;
                                            return (
                                                <Avatar key={idx} variant='filled' sx={{ marginLeft: margin }} title={`${member.name}`} color={themeCol} size={25} radius={40}>
                                                    {member.name?.charAt(0)}
                                                </Avatar>
                                            );
                                        })}
                                    </Group>
                                </div>
                            </Group>
                        </Box>
                        <Box className={classes.section}>
                            <Group position='apart'>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Captures
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.photographyCount??'-'}
                                    </Text>
                                </div>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Tasks
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.taskCount??'-'}
                                    </Text>
                                </div>
                                <div>
                                    <Text size='xs' color='dimmed'>
                                        Task Completed
                                    </Text>
                                    <Text weight={500} size='sm'>
                                        {data?.taskCompleteCount??'-'}
                                    </Text>
                                </div>
                            </Group>
                        </Box>
                        <Box className={classes.section}>
                            <Group spacing={30}>
                                <Button style={{ flex: 1 }} onClick={showDetails}>
                                    View Details
                                </Button>
                            </Group>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        <Text size={'sm'} color='dimmed'>
                            There is no event information available
                        </Text>
                    </Box>
                )}
            </Popover.Dropdown>
        </Popover>
    );
};

export default SessionPopover;

import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Button,
    Checkbox,
    createStyles,
    Group,
    Menu,
    Modal,
    NativeSelect,
    Pagination,
    Paper,
    Popover,
    ScrollArea,
    Select,
    Skeleton,
    Table,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CheckboxDropdown, FilterButton } from '../../../../core/components';
import { getStyleAssetsSuccess, getStyleSuccess, setDisplayPrefs } from 'modules/Styles/styleSlice';
import { setPreviousRoute } from 'app/appSlice';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { IconChevronDown, IconChevronRight, IconChevronUp, IconClearAll, IconDots, IconSearch, IconX } from '@tabler/icons';
import Moment from 'react-moment';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';

import { CaptureEventType } from 'core/components/capture/CaptureThumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApprovalLayoutTypes, PaginationValues } from 'utils/constants';
import { handleZipDownload, selectItems } from 'utils/helper';
import * as utils from 'utils/helper';

import CaptureDetail from 'core/components/capture/CaptureDetail';
import { logger } from 'utils/logger';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import ResolverImage from 'core/components/resolverImage/ResolverImage';
import { StyleAssetFilterModel } from 'core/models/StyleAssetFilterModel';
import ScanFilterPanel from './ScanFilterPanel';

var _ = require('lodash');

const useStyles = createStyles((theme, _params, getRef) => ({}));

const StyleTabScans = ({ user, style, success, guides, error, form, setPreviousRoute, onMediaAction, displayPrefs, scanFilter, setMediaFilter }) => {
    const [data, setData] = useState([]);
    // const [scanFilter, setMediaFilter] = useState(new MediaFilterModel());
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [filterItems, setFilterItems] = useState(null);

    const [imageModalOpen, setImageModalOpen] = useState(false);

    const [listView, setListView] = useState(displayPrefs.ListViewOption);

    const [hasFetched, setHasFetched] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const [styleCodes, setStyleCodes] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { cx } = useStyles();

    const [stylesPageIndex, setSessionsPageIndex] = useState(0); // keep this state so we can use it when data is refreshed
    const [scrolled, setScrolled] = useState(false);

    // const [sorting, setSorting] = useState([{ id: 'tags', desc: true }]);
    useEffect(() => {
        filterMedia();
    }, [style]);

    useEffect(() => {
        //setSampleData(samples);
        // set the brands/season/category
        var colour = {};
        var department = {};
        var extractStatus = {};
        var season = {};
        var size = {};

        logger.log('setting filter items')

        if (style && style.scans && !filterItems) {
            
            style.scans.forEach((item, idx) => {
                colour[item.colour] = item;
                department[item.department] = item;
                season[item.season] = item;
                extractStatus[item.extractStatus] = item;
                size[item.size] = item;
            });
    
            setFilterItems({ colour, department, season, extractStatus, size });

        }

        // filterSamples();
    }, [style, filterItems]);

    // Table columns and setup
    const columns = React.useMemo(
        () => [
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <>
                        {scanFilter && scanFilter.groupBy && scanFilter.groupBy.length ? (
                            <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? <IconChevronDown /> : <IconChevronRight />}</span>
                        ) : null}
                    </>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}>
                            {row.isExpanded ? <IconChevronDown /> : <IconChevronRight />}
                        </span>
                    ) : null,
            },
            {
                Header: 'Style',
                accessor: 'styleCode',
            },
            {
                Header: 'Department',
                accessor: 'department',
            },
            {
                Header: 'Description',
                accessor: 'descr',
            },
            {
                Header: 'Status',
                accessor: 'extractStatus',
            },
            {
                Header: 'Gtin',
                accessor: 'gtin',
            },
            {
                Header: 'Location',
                accessor: 'lat',
            },
            {
                Header: 'Notes',
                accessor: 'notes',
            },

            {
                Header: 'Event',
                accessor: 'season',
            },
            {
                Header: 'Size',
                accessor: 'size',
            },
            {
                Header: 'Sku',
                accessor: 'sku',
            },
            {
                Header: '',
                accessor: 'action',
            },
        ],
        [scanFilter],
    );

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            // globalFilter
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: stylesPageIndex,
                pageSize: 100,
                globalFilter: null,
                expanded: false,
            },
            sortTypes: {
                alphanumeric: (row1, row2, columnName) => {
                    const rowOneColumn = row1.values[columnName];
                    const rowTwoColumn = row2.values[columnName];
                    if (isNaN(rowOneColumn) && rowTwoColumn) {
                        return rowOneColumn.toUpperCase() > rowTwoColumn?.toUpperCase() ? 1 : -1;
                    }
                    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
                },
            },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded,
        usePagination,
    );

    // function changeSorting(columnName, isDescending) {
    //     logger.log(columnName, isDescending);
    //     const newSorting = [{ id: columnName, desc: isDescending }];
    //     setSorting(newSorting);
    // }

    /**
     * Effect to handle the success state change
     */
    useEffect(() => {
        if (success) {
            logger.log(success, ' SUCCESS');
            switch (success.type) {
                // if we have fetched the style Scan set the state.

                case getStyleSuccess.toString(): {
                    if (!style) {
                        setShowLoading(false);
                        setData([]);
                    }
                    break;
                }
                case getStyleAssetsSuccess.toString(): {
                    setHasFetched(true);
                    setShowLoading(false);
                    break;
                }
                default: {
                }
            }
        }
    }, [success]);

    /**
     * Method to filter the style Scan
     */
    const filterMedia = useCallback(() => {
        logger.log('FILTERING Scan');

        if (style && style.scans) {
            let newData = utils.filterStyleAssets(style.scans, scanFilter);

            if (scanFilter.groupBy) {
                var uniq = [
                    ...new Set(
                        Object.keys(newData).map((e) => {
                            // if(newData[e] && newData[e].hasOwnProperty(scanFilter.groupBy) && newData[e][scanFilter.groupBy])
                            return newData[e][scanFilter.groupBy];
                        }),
                    ),
                ];
                // create the group by items
                var groupedData = uniq.map((group) => {
                    var subRows = newData.filter((val) => val[scanFilter.groupBy] === group);
                    let name = group;
                    if (scanFilter.groupBy === 'catId' && subRows.length && subRows[0].guideCategory) {
                        name = subRows[0].guideCategory.name;
                    }
                    return { type: 'group', name: name, subRows, id: group };
                });
                newData = groupedData;
            }

            setData([...newData]);
        }
    }, [scanFilter, setData, style]);

    /**
     * Effect to handle the filter changes. When this happens we refilter
     * the style Scan.
     */
    useEffect(() => {
        const count = utils.getScanFilterCount(scanFilter);
        setFilterCount(count);
        filterMedia();
        setSessionsPageIndex(0);
    }, [scanFilter, filterMedia]);

    /**
     * Debounce fetch so it isn't called multiple times.
     */
    const fetchMedia = useCallback(
        _.debounce(function (criteria) {
            logger.log(criteria);
            // dispatch(fetchStyleAssets(criteria.styleId));
        }, 1000),
        [],
    );

    /**
     * Effect to fetch Scan when the style changes
     * We also need to check to see if the Scan doesn't already exist otherwise we don't
     * need to fetch.
     */
    useEffect(() => {
        // only fetch if Scan hasn't been fetched
        // if (style && style.id && !hasFetched && !style.scans) {
        //     var criteria = {};

        //     logger.log('search submit');
        //     criteria.styleId = style.id;

        //     // fetchMedia(criteria);
        //     setHasFetched(true);
        // }

        if (style && style.scans) {
            logger.log(style.scans);

            // setHasFetched(true);
            filterMedia();
        }
    }, [style, filterMedia, hasFetched]);

    /**
     * Handler for the Capture menu events.
     * @param {*} e
     * @param {*} data
     * @param {*} items
     */
    const onMenuItemClick = (e, data, items) => {
        logger.log(e);
        let captures = [];
        switch (e) {
            case CaptureEventType.DOWNLOAD: {
                // find all the items
                const arr = items.map((val) => {
                    const item = style.scans.find((style) => val.id === style.id);
                    return item;
                });
                handleZipDownload(arr, 'originalFilename', style.id, 'previewHi');
                break;
            }
            case CaptureEventType.RATING_CHANGE:
            case CaptureEventType.STATUS_CHANGE: {
                captures = items.map((val) => {
                    return style.scans.find((obj) => obj.id === val.id);
                });

                onCaptureChange(e, data, captures);
                break;
            }
            case CaptureEventType.APPLY_STYLE_INFO:
            case CaptureEventType.CREATE_TASK: {
                captures = items.map((val) => {
                    return style.scans.find((obj) => obj.id === val.id);
                });

                onMediaAction(e, data, captures);
                break;
            }
            default: {
                break;
            }
        }
    };

    // Set the stylecodes
    useEffect(() => {
        if (style && style.samples) {
            const arr = style.samples.map((val) => {
                return {
                    // label: `${val.stylenumber} - ${val.name}`,
                    label: `${val.stylenumber}`,
                    value: val.stylenumber,
                };
            });
            setStyleCodes([...arr]);
        }
    }, [style?.samples?.length, style]);

    /**
     * Handles the list toggle display
     * @param {*} val
     */
    const listToggleHandler = (val) => {
        navigate({ search: `?tab=Scan&displayType=${val}` });
        // setListView(val);
        const prefs = { ...displayPrefs, mediaDisplayType: val };
        dispatch(setDisplayPrefs(prefs));
    };

    useEffect(() => {
        if (displayPrefs && displayPrefs.mediaDisplayType && displayPrefs.mediaDisplayType !== listView) {
            setListView(displayPrefs.mediaDisplayType);
        }
    }, [displayPrefs, listView]);

    /**
     * The select all menu component part of the checkbox dropdown
     */
    const selectAllMenu = (
        <div>
            {/* <Button variant='default' leftIcon={<IconDownload size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.DOWNLOAD, { data: null }, selectedItems)}>
                Download
            </Button> */}
            <Button variant='default' leftIcon={<IconClearAll size={14} />} onClick={(e) => setSelectedItems([])}>
                Deselect All
            </Button>
            {/* <Button variant='default' leftIcon={<IconSubtask size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.CREATE_TASK, { data: null }, selectedItems)}>
                Create Task
            </Button> */}
            {/* <Button variant='default' leftIcon={<IconColorSwatch size={14} />} onClick={(e) => onMenuItemClick(CaptureEventType.APPLY_STYLE_INFO, { data: null }, selectedItems)}>
                Apply Styles
            </Button> */}

            {/* <Divider mt={'sm'} /> */}
            {/* <Group>
      <Title order={6} color={'dimmed'}>Rating</Title>
      <Divider />
    </Group> */}
            {/* <Group px={'xs'}>
                <FormInputWrapper label='Rate' id='input-rating' labelWidth={60} style={{ marginTop: 12, marginBottom: 0 }}>
                    <StarRating size={'sm'} onChange={(val) => onMenuItemClick(CaptureEventType.RATING_CHANGE, { data: val }, selectedItems)} />
                </FormInputWrapper>
            </Group>

            <Group px={'xs'}>
                <FormInputWrapper label='Flag' id='input-rating' labelWidth={60} style={{ marginTop: 12, marginBottom: 0 }}>
                    <FlagToggle size={'sm'} onChange={(val) => onMenuItemClick(CaptureEventType.STATUS_CHANGE, { data: val }, selectedItems)} />
                </FormInputWrapper>
            </Group> */}
        </div>
    );

    const onItemClick = (val) => {
        logger.log('ON ITEM CLICK');
        showImageModal(val);
    };

    /**
     * Handler for when a capture is selected.
     * @param {*} item
     * @param {*} selected
     */
    const onItemSelected = (e, item, selected) => {
        var items = [...selectedItems];

        const pageItems = page.map((val) => {
            return { ...val.original };
        });

        // call general helper to select items
        selectItems(e, item, selected, pageItems, items);

        setSelectedItems(items);
        //dispatch(selectTasks(items));
        //selectTask(items);
    };

    const onSelectAll = () => {
        // if there are items selected remove them
        if (selectedItems.length) {
            setSelectedItems([]);
            //setSelectedItems([])
            //dispatch(selectTasks([]));
        } else {
            var newArray = [...data];
            if (scanFilter.groupBy) {
                newArray = _.flatMap(data, 'subRows');
            }
            setSelectedItems([...newArray]);
            //setSelectedItems([...elements])
            //dispatch(selectTasks([...data]));
        }
    };

    /**
     * Handler for the Capture event types
     * @param {*} type
     * @param {*} data
     * @param {*} originalEvent
     */
    const onImageEvent = (type, data, originalEvent) => {
        logger.log(type, data);
        var items;

        switch (type) {
            case CaptureEventType.IMAGE_CLICK: {
                // if we already have one item
                // if control or shift is not held we set it to a single item
                if (originalEvent.metaKey) {
                    // if command held down we add the item to the array
                    // if it's not yet selected.
                    const obj = selectedItems.find((val) => val.id === data.id);
                    // if the object is already selected then we unselect it
                    if (obj) {
                        //items  = [...selectedItems];
                        items = _.remove([...selectedItems], (val) => {
                            return val.id !== data.id;
                        });
                    } else {
                        items = [...selectedItems, data];
                    }
                } else if (originalEvent.shiftKey) {
                    // if shift key we push all items from the last selected item
                    // get the index of the last element selected.
                    let lastObj = selectedItems ? selectedItems[selectedItems.length - 1] : null;
                    if (lastObj) {
                        let lastIdx = _.findIndex(style.scans, lastObj);
                        let currIdx = _.findIndex(style.scans, data);
                        let newItems = [...style.scans.slice(lastIdx + 1, currIdx + 1)];
                        items = _.concat(selectedItems, newItems);
                    }
                } else {
                    items = [data];
                    setSelectedItem({ ...data });

                    // searchParams.set('captureId',data.captureId);
                    // setSearchParams(searchParams);
                }

                setSelectedItems(items);

                break;
            }
            case CaptureEventType.IMAGE_DBL_CLICK: {
                // take user to captures
                setSelectedItem(data);

                onItemClick(data);
                // navigate(`/approval?styleId=${data.styleId}&captureId=${data.id}&layoutType=${ApprovalLayoutTypes.SINGLE}`);

                break;
            }

            default: {
                break;
            }
        }
    };

    const setFilterVars = (vals) => {
        logger.log(vals, ' FILTER VALS');
        setMediaFilter(vals);
    };

    const filterButtonHandler = (e) => {
        setFilterOpen((o) => !o);
    };

    const onCaptureChange = (type, data, items) => {
        logger.log(type, data, items);
        switch (type) {
            // case CaptureEventType.RATING_CHANGE: {
            //     const tmpItems = items.map((val) => {
            //         return {
            //             star: data.data,
            //             actionPart: val.actionPart,
            //             actionSort: val.actionSort,
            //         };
            //     });
            //     // dispatch(setSessionCaptures(tmpItems, user));
            //     break;
            // }
            // case CaptureEventType.STATUS_CHANGE: {
            //     const tmpItems = items.map((val) => {
            //         return {
            //             sak: data.data,
            //             actionPart: val.actionPart,
            //             actionSort: val.actionSort,
            //         };
            //     });
            //     // dispatch(setSessionCaptures(tmpItems, user));
            //     break;
            // }
            case CaptureEventType.DOWNLOAD: {
                // find all the items
                handleZipDownload(items, 'originalFilename', style.id, 'previewHi');
                break;
            }
            default: {
                break;
            }
        }
    };

    /**
     * Handler for the clear filter click.
     * Clears the Scan filter
     * @param {*} e
     */
    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();

        setMediaFilter({ ...new StyleAssetFilterModel() });
    };

    /**
     * Scan capture menu handler
     * @param {*} e
     * @param {*} data
     */
    const onMenuClick = (e, data) => {
        // we want to store the previous nav state so we can return if we need to.
        const tmpLoc = `${location.pathname}/${location.search}`;
        setPreviousRoute(tmpLoc);

        switch (e.type) {
            case CaptureEventType.SHOW_IN_CAPTURE_COMMENTS: {
                navigate(`/approval?styleId=${data.styleId}&captureId=${data.id}&layoutType=${ApprovalLayoutTypes.COMMENT}`);
                break;
            }
            case CaptureEventType.SHOW_IN_CAPTURE: {
                navigate(`/approval?styleId=${style.id}&layoutType=${ApprovalLayoutTypes.GRID}`);
                break;
            }
            case CaptureEventType.DOWNLOAD: {
                handleZipDownload([data], 'originalFilename', data.originalFilename, 'previewHi');
                break;
            }
            case CaptureEventType.CREATE_TASK: {
                onMenuItemClick(CaptureEventType.CREATE_TASK, { data: null }, [data]);
                break;
            }
            case CaptureEventType.APPLY_STYLE_INFO: {
                onMenuItemClick(CaptureEventType.APPLY_STYLE_INFO, { data: null }, [data]);
                break;
            }
            default: {
                break;
            }
        }
    };

    const showImageModal = (data) => {
        setSelectedItem(data);

        setImageModalOpen(true);

        logger.log(data, 'show modal');
    };

    const onPageChange = (idx) => {
        gotoPage(idx);
        setSessionsPageIndex(idx);
    };

    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        <div style={{ height: '100%' }}>
            <Box px='xl' sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} pt='xl' pb={'sm'} mb={'sm'}>
                    <Group>
                        <CheckboxDropdown selectedItems={selectedItems} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />

                        <TextInput
                            icon={<IconSearch />}
                            sx={{ width: 250 }}
                            value={scanFilter.keyword}
                            onChange={(e) =>
                                setMediaFilter({
                                    ...scanFilter,
                                    keyword: e.currentTarget.value,
                                })
                            }
                            placeholder={`Search across ${data.length} Scan`}
                            rightSection={<ActionIcon onClick={() => setMediaFilter({ ...scanFilter, keyword: '' })}>{scanFilter.keyword.length ? <IconX size={14} /> : null}</ActionIcon>}
                        />

                        <Text size='sm'>
                            Total{' '}
                            <strong>
                                {data.length} of {style?.scans?.length}
                            </strong>{' '}
                        </Text>
                    </Group>

                    <Group>
                        <Popover shadow='sm' opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} position='bottom' withArrow closeOnClickOutside={true}>
                            <Popover.Target>
                                <div>
                                    <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                                </div>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div style={{ display: 'flex' }}>
                                    <ScanFilterPanel
                                        filter={scanFilter}
                                        onCancel={() => setFilterOpen(false)}
                                        onApply={(vals) => {
                                            setFilterVars(vals);
                                            setFilterOpen(false);
                                        }}
                                        showTags={false}
                                        filterItems={filterItems}
                                        styleCodes={styleCodes}
                                    />
                                </div>
                            </Popover.Dropdown>
                        </Popover>
                        <Select
                            placeholder='Group By'
                            value={scanFilter.groupBy}
                            onChange={(val) =>
                                setMediaFilter({
                                    ...scanFilter,
                                    groupBy: val,
                                })
                            }
                            data={[
                                { value: '', label: 'No Group' },
                                {
                                    value: 'guideID',
                                    label: 'Group by Guide',
                                },
                                {
                                    value: 'catId',
                                    label: 'Group by Guide Category',
                                },
                                {
                                    value: 'seasonCode',
                                    label: 'Group by Season',
                                },
                                {
                                    value: 'sessionCode',
                                    label: 'Group by Session',
                                },
                            ]}
                        />
                        {/* <Select
              placeholder="Filter By All"
              data={[
                { value: '', label: 'Show All' },
                { value: 'sku', label: 'Filter by SKU' },
                { value: 'location', label: 'Filter by Location' },
                { value: 'status', label: 'Filter by Status' },
              ]}
            /> */}

                        {/* <Select
              placeholder="Sort By"
              value={sorting}
              onChange={(e) => changeSorting(e,true)}
              data={
                columns.map(val => {
                  return { value: val.accessor, label: val.Header }
                })
                }/> */}

                        {/* <Button variant='light' onClick={() => onMenuClick({ type: CaptureEventType.SHOW_IN_CAPTURE }, { styleId: style.id })}>
                            Open Session in Captures
                        </Button> */}

                        {/* <ListToggleControl value={listView} onChange={(val) => listToggleHandler(val)} /> */}

                        {/* <ListToggleControl value={listView} onChange={(val) => listToggleHandler(val)}/> */}
                    </Group>
                </Group>

                {listView === ListViewOption.LIST ? (
                    <ScrollArea
                        sx={(theme) => ({
                            height: '100%',
                            marginRight: -theme.spacing.xl,
                            paddingRight: theme.spacing.xl,
                        })}
                        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
                        <Table size='sm' {...getTableProps()}>
                            <thead className={cx('scrollTableHeader', { ['scrolled']: scrolled })}>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <Group position='apart'>
                                                    <Text size='sm'>{column.render('Header')}</Text>
                                                    {/* Add a sort direction indicator */}
                                                    {column.id !== 'expander' ? (
                                                        <Group>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                        <IconChevronDown size={16} />
                                                                    </ActionIcon>
                                                                ) : (
                                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                        <IconChevronUp size={16} />
                                                                    </ActionIcon>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Group>
                                                    ) : null}
                                                </Group>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            {showLoading ? (
                                <tbody>
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <tr key={i}>
                                            {columns.map((val, idx) => (
                                                <td key={idx} colSpan={2}>
                                                    <Skeleton height={8} my='lg' />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    {page.map((row, i) => {
                                        prepareRow(row);

                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    let renderCell;
                                                    let checked = selectedItems.find((val) => val.id === row.original.id) ? true : false;
                                                    let guideInfo = utils.getGuideInfo(row.original.guideID, row.original.catId, guides);

                                                    switch (cell.column.id) {
                                                        case 'expander': {
                                                            renderCell = <div>{cell.render('Cell')}</div>;
                                                            break;
                                                        }
                                                        case 'styleCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: '250px' }}>
                                                                    {row.canExpand ? (
                                                                        <Group>
                                                                            <Text size='sm' lineClamp={1}>
                                                                                {row.original.name}
                                                                            </Text>
                                                                            <Badge>{row.original.subRows.length}</Badge>
                                                                        </Group>
                                                                    ) : (
                                                                        <Group sx={{ flexShrink: 0 }}>
                                                                            <Checkbox checked={checked} onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox>
                                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}
                                                                            <Group onClick={() => onItemClick(row.original)}>
                                                                                <ResolverImage src={`https://api.hgio.com.au/beta/scans/r/${row.original.filepath}`} width={50} height={50} />
                                                                                {/* <Image radius='sm' width={50} height={50} src={row.original.thumb} /> */}
                                                                            </Group>
                                                                            <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original)}>
                                                                                <Anchor size='sm'>
                                                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '150px' }}>
                                                                                        {row.original.id}
                                                                                    </Text>
                                                                                </Anchor>
                                                                                <Text size='xs' color='dimmed'>
                                                                                    SKU: {row.original.sku}
                                                                                </Text>
                                                                            </Box>
                                                                        </Group>
                                                                    )}
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'tags': {
                                                            renderCell = <Group spacing={'xs'}>{!row.canExpand ? <Text color={'dimmed'}>{row.original.tags}</Text> : null}</Group>;
                                                            break;
                                                        }

                                                        case 'extractStatus': {
                                                            renderCell = !row.canExpand ? <Badge color={utils.getScanStatusColor(row.original.extractStatus)}>{cell.render('Cell')}</Badge> : null;
                                                            break;
                                                        }

                                                        case 'guideID': {
                                                            renderCell = (
                                                                <Tooltip label={`${guideInfo?.guide?.name}`}>
                                                                    <Anchor
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            const tmpLoc = `${location.pathname}/${location.search}`;
                                                                            setPreviousRoute(tmpLoc);
                                                                            navigate(`/styleguides/${row.original.guideID}`);
                                                                        }}>
                                                                        <Text size='sm' sx={{ minWidth: 150 }}>
                                                                            {row.original.guideID}
                                                                        </Text>
                                                                    </Anchor>
                                                                </Tooltip>
                                                            );
                                                            break;
                                                        }

                                                        case 'catId': {
                                                            renderCell = <Group sx={{ width: 200 }}>{guideInfo.category ? guideInfo.category.name : ''}</Group>;
                                                            break;
                                                        }

                                                        case 'seasonCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: 100 }}>
                                                                    <Anchor
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            const tmpLoc = `${location.pathname}/${location.search}`;
                                                                            setPreviousRoute(tmpLoc);
                                                                            navigate(`/seasons/${cell.value}`);
                                                                        }}>
                                                                        {cell.render('Cell')}
                                                                    </Anchor>
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'sessionCode': {
                                                            renderCell = (
                                                                <Group sx={{ minWidth: 100 }}>
                                                                    <Anchor
                                                                        size='sm'
                                                                        onClick={() => {
                                                                            const tmpLoc = `${location.pathname}/${location.search}`;
                                                                            setPreviousRoute(tmpLoc);
                                                                            navigate(`/sessions/${cell.value}`);
                                                                        }}>
                                                                        {cell.render('Cell')}
                                                                    </Anchor>
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'completed': {
                                                            renderCell = (
                                                                <Group sx={{ width: 120 }}>
                                                                    {!row.canExpand ? (
                                                                        <Text size='sm'>
                                                                            <Moment date={row.original.completed} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY' trim />
                                                                        </Text>
                                                                    ) : null}
                                                                </Group>
                                                            );
                                                            break;
                                                        }

                                                        case 'action': {
                                                            if (!row.canExpand) {
                                                                renderCell = (
                                                                    <Group
                                                                        sx={{
                                                                            width: 40,
                                                                            alignContent: 'center',
                                                                            justifyContent: 'center',
                                                                        }}>
                                                                        <MediaItemMenu onMenuItemClick={(e) => onMenuClick(e, row.original)} />
                                                                    </Group>
                                                                );
                                                            }

                                                            break;
                                                        }

                                                        default: {
                                                            renderCell = (
                                                                <>
                                                                    <Text lineClamp={2} size='sm'>
                                                                        {cell.render('Cell')}
                                                                    </Text>
                                                                </>
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <td {...cell.getCellProps()} className={checked ? 'cellChecked' : ''}>
                                                            {renderCell}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </Table>

                        {!showLoading && page.length === 0 ? <EmptyState /> : null}
                    </ScrollArea>
                ) : (
                    null
                    // <Group sx={{ height: '100%', marginLeft: -20, marginRight: -20 }}>
                    //     <StyleMediaGridLayout guides={guides} filter={scanFilter} captures={page} selectedItems={selectedItems} onImageEvent={onImageEvent} onCaptureChange={onCaptureChange} />
                    // </Group>
                )}

                {/* Footer */}
                <Paper shadow='sm'>
                    <Group className={'displayFooter'}>
                        <Pagination size={'sm'} total={pageOptions.length} withEdges page={pageIndex + 1} onChange={(idx) => onPageChange(idx - 1)} />
                        <Group>
                            <Text size='sm'>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </Text>

                            <NativeSelect
                                size='sm'
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                data={PaginationValues}></NativeSelect>
                        </Group>
                    </Group>
                </Paper>
            </Box>

            <Modal opened={imageModalOpen} size={'lg'} title={selectedItem.originalFileName} trapFocus={true} fullScreen onClose={() => setImageModalOpen(false)}>
                <CaptureDetail
                    showSettings={false}
                    resoloveImage={true}
                    image={selectedItem}
                    src={selectedItem.preview}
                    onImageEvent={() => logger.log('On image event')}
                    onCaptureChange={onCaptureChange}
                />
            </Modal>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        style: state.styleReducer.selectedStyle,
        guides: state.guideReducer.guides,
        displayPrefs: state.styleReducer.displayPrefs,
        // scanFilter: state.styleReducer.scanFilter,
        success: state.styleReducer.success,
        error: state.styleReducer.error,
    };
};

const mapDispatch = { setPreviousRoute };

export default connect(mapStateToProps, mapDispatch)(StyleTabScans);

function MediaItemMenu(props) {
    return (
        <Menu size='lg' position='bottom-end'>
            <Menu.Target>
                <ActionIcon>
                    <IconDots />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.DOWNLOAD })} icon={<IconDownload size={14} />}>
                    Download
                </Menu.Item> */}
                {/* <Menu.Item
                    onClick={() =>
                        props.onMenuItemClick({
                            type: CaptureEventType.SHOW_IN_CAPTURE_COMMENTS,
                        })
                    }
                    icon={<IconCamera size={14} />}>
                    View in Captures
                </Menu.Item> */}
                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.CREATE_TASK })} icon={<IconSubtask size={14} />}>
                    Create Task
                </Menu.Item> */}
                {/* <Menu.Item onClick={() => props.onMenuItemClick({ type: CaptureEventType.APPLY_STYLE_INFO })} icon={<IconColorSwatch size={14} />}>
                    Apply Styles
                </Menu.Item> */}
            </Menu.Dropdown>
        </Menu>
    );
}
